import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IArea } from '../../utils/types/areas.interface'
import { ManejoPastejoRotacionado } from '../../utils/types'
import { AreasService } from '../../services/areas.service'

@Component({
  selector: 'manejo-pastejo-rotacionado',
  templateUrl: './manejo-pastejo-rotacionado.component.html',
  styleUrls: ['./manejo-pastejo-rotacionado.component.scss']
})
export class ManejoPastejoRotacionadoComponent implements OnInit {
  @Input() manejo: ManejoPastejoRotacionado
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter<unknown>()

  @Input() area: IArea

  constructor(public areasCtrl: AreasService) {}

  ngOnInit(): void {
    this.getPiquetes()
  }

  async getPiquetes() {
    if (!this.areasCtrl.piquetesOpcoes[this.area?.hash]?.length) {
      const piquetes = await this.areasCtrl.getPiquetes({ hashArea: this.area?.hash })
      piquetes.map((piquete) => {
        this.areasCtrl.piquetesOpcoes[this.area.hash] = this.areasCtrl.piquetesOpcoes[this.area.hash] || []
        this.areasCtrl.piquetesOpcoes[this.area.hash].push({
          label: piquete.nome,
          value: piquete.hash
        })
      })
    }
  }

  emit(): void {
    this.manejo.nome_piquete = this.areasCtrl.piquetesOpcoes[this.area.hash].find((piquete) => piquete.value == this.manejo.hash_piquete)?.label
    this.manejoChange.emit(this.manejo)
  }

  async abrirOpcoesDoManejo(): Promise<void> {
    this.opcoes.emit()
  }
}
