import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { ActionSheetController } from '@ionic/angular'
import { ManejoAnimalModel } from '../../utils/types'

@Component({
  selector: 'manejo-ordenha',
  templateUrl: './manejo-ordenha.component.html',
  styleUrls: ['./manejo-ordenha.component.scss']
})
export class ManejoOrdenhaComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor(public actionSheetCtrl: ActionSheetController) {}

  async ngOnInit() {
    if (this.manejo.ordenha_1) this.manejo.ordenha_1 = Number(this.manejo.ordenha_1 || 0)
    if (this.manejo.ordenha_2) this.manejo.ordenha_2 = Number(this.manejo.ordenha_2 || 0)
    if (this.manejo.ordenha_3) this.manejo.ordenha_3 = Number(this.manejo.ordenha_3 || 0)
  }

  alterouTurno() {
    this.manejo.ordenha_1 = this.manejo.turno === 1 ? this.manejo.ordenha_1 || 0 : null
    this.manejo.ordenha_2 = this.manejo.turno === 2 ? this.manejo.ordenha_2 || 0 : null
    this.manejo.ordenha_3 = this.manejo.turno === 3 ? this.manejo.ordenha_3 || 0 : null
    this.emit()
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
