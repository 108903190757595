export const commonEnv = {
  appVersion: '2.0.1',
  jwtCode: 'TokenExpiredError'
}

export const versionamento: {
  versao: string
  data: string
  changelog: string[]
  mudancas_tecnicas?: string[]
}[] = [
  {
    versao: '2.0.1',
    data: '2024-02-01',
    changelog: [
      'Ajuste na exibição de manejos pendentes de envios criados antes da versão 2.0.0',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '2.0.0',
    data: '2024-02-01',
    changelog: [
      'Animais: Seleciona animal automaticamente ao finalizar o cadastro',
      'Cadastro de Animal: Campo de peso ao nascer agora usa o valor preenchido na configuração da propriedade',
      'Cadastro de Animal: Campo de nome aparece se configurado pela propriedade',
      'Cadastro de Animal: Novo campo de RGD e Data de RGD',
      'Manejo de Alteração de Nº de Controle: Nova opção de trocar teclado numérico / alfanumérico',
      'Parto: Nova opção de adicionar cria como Natimorto',
      'Manejos: Resumo dos manejos adicionados na tela de manejo de animais',
      'Revisar Manejo: Novos dados de peso total, peso médio e GMD médio',
      'Envios Pendentes: Novos dados de peso total, peso médio e GMD médio, em cada envio e média total',
      'Manejos de Animais: Novo manejo de Observação',
      'Manejos de Animais: Novo manejo de Secagem',
      'Manejos de Animais: Novo manejo de Registro de Perda',
      'Manejos de Animais: Novos manejos de Melhoramento Genético',
      'Manejos de Lotes: Novo manejo de Movimentação entre Áreas',
      'Manejos de Lotes: Novo manejo de Escore de Fezes',
      'Manejos de Lotes: Novo manejo de Observação',
      'Manejos de Áreas: Nova aba de manejos de áreas',
      'Manejos de Áreas: Novo manejo de Pluviometria',
      'Manejos de Áreas: Novo manejo de Altura de Capim',
      'Manejos de Áreas: Novo manejo de Pastejo Rotacionado',
      'Manejos de Áreas: Novo manejo de Observação',
      'Pesagem: Alteração do campo de peso para não necessitar digitar valores decimais',
      'Seleção de Animais: Filtro de animais do manejo é limpo ao iniciar outro manejo',
      'Envios Pendentes: Melhoria no envio de dados pendentes para aprovação',
      'Manejos: Implementada a opção de limitar os manejos disponíveis para cada usuário',
      'Cadastro de Animais: Peso ao nascer customizado no 360 agora é aplicado no Fertili Campo',
      'Contador de Animais: Melhorias na interface para evitar cliques acidentais',
      'Tela Inicial: Nova interface de escolha de manejos com ícones coloridos',
      'Tela de Adicionar Manejos: Cores implementadas para cada manejo',
      'Inseminação: Novos campos de Inseminador, Muco e Bastão',
      'Diagnóstico de Gestação: Novos campos de Ovário Esquerdo, Ovário Direito e Tônus Uterino',
      'Manejos: Opção de adicionar observação adicionada a todos os manejos',
      'Manejos: Ajustes internos para melhoria de performance e envio de dados',
      'Manejos Sanitários: Adicionados os ícones do Fertili 360 para maior clareza',
      'Cadastro de Animais: Campo de número é exibido vazio mas preenche com mesmo número da mãe caso não preenchido antes de salvar',
      'Backups de Manejos: Nova opção de continuar manejo ao clicar em opções do backup',
      'Seletor de Sêmen e Embrião: Nova informação de "Partida"',
      'Seletor de Animais do Manejo: Ao fechar a tela sem selecionar animais, o animal que estava sendo visualizado permanece selecionado',
      'Revisão de Manejos: Novo alerta de partos e inseminações não preenchidas',
      'Campos de Texto e Número: Teclado é fechado ao apertar "Enter"',
      'Manejos: Ao salvar manejo, a lista de manejos retorna para o topo para exibir solicitaçõs pendentes',
      'Áreas: Nova opção de cadastrar área',
      'Bluetooth: Nova tela de seleção de dispositivo',
      'Bluetooth: Dispositivo selecionado anteriormente é destacado na lista de dispositivos a conectar',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '1.4.4',
    data: '2023-11-01',
    changelog: [
      'Balanças: Integra balanças AnimallTAG AT55 e AT60',
      'Manejo: Resumo de manejo de Descarte na tela de manejos',
      'Cadastro de Animal: Peso ao nascer customizado no 360 agora é aplicado no Fertili Campo',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '1.4.3',
    data: '2023-10-03',
    changelog: [
      'Animais: Correção do envio de peso ao nascer no cadastro de animais',
      'Animais: Filtro do seletor de animais agora é salvo, e ao selecionar outro animal o filtro é mantido',
      'Animais: Ao cadastrar animal sem número, os a lista de animais será filtrada por animais sem número e exibirá o animal cadastrado no topo da lista',
      'Animais: Ao buscar número de animal, o animal com número exato ao da busca será exibido no topo da lista',
      'Animais: Quantidade total de animais corrigido para exibir a quantidade correta da lista',
      'Ferramentas: Nova opção em ajustes para sincronizar animais com o Fertili 360 em caso de divergência',
      'Seleção de Manejos: Correção na abertura da tela de manejos ao selecionar a opção Rebanho ou Contador',
      'Solicitações Pendentes: Quantidade de solicitações pendentes corrigida ao enviar os dados',
      'Solicitações Pendentes: Correção no envio de animais para aprovação',
      'Manejos: Novo ícone de Adicionar Animal na tela de manejos para maior clareza',
      'Animal: Correção da quantidade de crias da ficha do animal',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '1.4.2',
    data: '2023-08-10',
    changelog: [
      'Ajuste de pesagem ao ler balança',
    ],
    mudancas_tecnicas: []
  },
  {
    versao: '1.4.0',
    data: '2023-08-10',
    changelog: [
      'Integrações: Nova integração com indicadores de Balança Pesenti',
      'Tela Inicial: Nova interface opcional com botões grandes na escolha de manejos',
      'Listas: Novas listas de animais, propriedades, estoques, lotes e áreas com filtros e ordenação',
      'Cadastro de Animal: Nova interface de cadastro de animais com design similar ao Fertili 360',
      'Cadastro de Lote: Nova interface de cadastro de lotes com design similar ao Fertili 360',
      'Manejos de Animais: Novo manejo sanitário com estoque',
      'Bluetooth: Adiciona compatibilidade com leitores Tru-Test XRS2i e SRS2i',
      'Registro de Morte: Adiciona valor padrão em Motivo',
    ],
    mudancas_tecnicas: [
      'Atualizados: Ionic 7, Angular 14, Capacitor 5, Node 18'
    ]
  },
  {
    versao: '1.4.0',
    data: '2023-07-24',
    changelog: [
      'Animais: Cadastro completo ajustado para envio das informações para aprovação',
      'Envios Pendentes: Limite de animais enviados para aprovação removido',
    ],
    mudancas_tecnicas: [
      'Atualizados: Ionic 7, Angular 14, Capacitor 5, Node 18'
    ]
  },
  {
    versao: '1.3.9',
    data: '2023-07-14',
    changelog: [
      'Backups: Backups agora são enviados separadamente por tipo',
      'Manejos Pendentes: Nova opção de remover manejo pendente',
      'Manejo Diagnóstico de Gestação: Limite de data do seletor do manejo de exposição aumentado para 9 meses',
      'Manejo Registro de Morte: Ajuste do envio de manejo de registro de morte com imagens',
      'Animais Removidos: Animais excluídos antes do envio agora são removidos após a autorização no Fertili 360',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '1.3.9',
    data: '2023-07-14',
    changelog: [
      'Backups: Backups agora são enviados separadamente por tipo',
      'Manejos Pendentes: Nova opção de remover manejo pendente',
      'Manejo Diagnóstico de Gestação: Limite de data do seletor do manejo de exposição aumentado para 9 meses',
      'Manejo Registro de Morte: Ajuste do envio de manejo de registro de morte com imagens',
      'Animais Removidos: Animais excluídos antes do envio agora são removidos após a autorização no Fertili 360',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '1.3.6',
    data: '2023-06-29',
    changelog: [
      'Integrações: Ajuste de conexão com AWR250',
      'Integrações: Adiciona botão "Tara" ao conectar em balanças BPB085',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '1.3.5',
    data: '2023-06-26',
    changelog: [
      'Integrações: Ajuste de conexão em dispositivos com Android 12 ou superior',
    ],
    mudancas_tecnicas: [
    ]
  },
  {
    versao: '1.3.4',
    data: '2023-06-20',
    changelog: [
      'Integrações: Nova balança Açores HD Easy',
      'Integrações: Novo leitor TRU-TEST SRS1',
      'Integrações: Correção de conexão Bluetooth por aparelhos Android 12 ou 13',
      'Manejos: Libera castração para machos',
      'Lotes: Quantidade de animais dos lotes corrigida',
      'Ajustes: Novo botão para ressincronizar todos os dados',
      'Busca de Animais: Busca de animais para Castração, Ordenha e Descarte',
      'Manejos: Novo manejo Sanitário com integração do estoque no Lote',
    ],
    mudancas_tecnicas: [
      'Sincronização: Ajuste de sincronização com informações com aspas',
      'Padronização de comandos de serve e deploy',
      'Configuração de build staging, prod e dev'
    ]
  }
]
