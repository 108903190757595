import { TableGestorManejoReprodutivoUnitario, TiposDiagnosticos } from '../../../utils/types';
import { AnimalDoManejo } from '../../../utils/types';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { PopoverListaExposicoesComponent } from '../popover-lista-exposicoes/popover-lista-exposicoes.component';
import { ManejoReprodutivoService } from '../../../services/manejo/manejo-reprodutivo.service';
import { labelsExposicoes } from '../../../utils';
@Component({
  selector: 'manejo-diagnostico-gestacao',
  templateUrl: './manejo-diagnostico-gestacao.component.html',
  styleUrls: ['./manejo-diagnostico-gestacao.component.scss'],
})
export class ManejoDiagnosticoGestacaoComponent implements OnInit {
  @Input() manejo: {
    diagnostico?: string
    periodo_gestacional?: string
    ovario_esquerdo?: string
    ovario_direito?: string
    tonus_uterino?: string
    hash_exposicao?: string
    observacao?: string
  };
  @Output('manejoChange') manejoChange = new EventEmitter();
  @Output() opcoes = new EventEmitter();
  @Input() animalSelecionado: AnimalDoManejo;
  @Input() manejosReprodutivosAvancados: boolean;

  public exposicoes: TableGestorManejoReprodutivoUnitario[]
  public exposicaoSelecionada: TableGestorManejoReprodutivoUnitario

  public labelsExposicoes = labelsExposicoes
  dataConcepcaoFormatada = ''
  dataPrevisaoPartoFormatada = ''

  constructor(
    private popoverCtrl: PopoverController,
    private manejoReprodutivoCtrl: ManejoReprodutivoService,
  ) {}

  async ngOnInit() {
    if (!this.manejo) {
      this.manejo = {}
    }

    this.exposicoes = await this.getExposicoesFormatadaDoAnimal()

    if(this.exposicoes?.length && this.manejo?.hash_exposicao) {
      this.exposicaoSelecionada = this.exposicoes.find(exposicao => exposicao.hash === this.manejo.hash_exposicao)
    }

    if (this.manejo.periodo_gestacional) {
      this.atualizarDatasDeConcepcaoEPrevisaoDeParto()
    }
  }


  async getExposicoesFormatadaDoAnimal() {
    try {
      const dataAtual = moment(new Date()).format('YYYY-MM-DD')

      const { animais: exposicoesDoAnimal } = await this.manejoReprodutivoCtrl.getExposicoes({
        filtros: {
          hash_animal: this.animalSelecionado?.hash,
          de: moment(dataAtual).subtract('9', 'months').format('YYYY-MM-DD'),
          ate: moment(dataAtual).subtract('1', 'day').format('YYYY-MM-DD')
        }
      })

      const exposicoesFormatada = this.formatarExposicoesDoAnimal(dataAtual, exposicoesDoAnimal)
      return exposicoesFormatada

    } catch(error) {
      console.error(error)
    }
  }

  formatarExposicoesDoAnimal(dataAtual: string, exposicoesDoAnimal: {
    [key: string]: TableGestorManejoReprodutivoUnitario[];
  }) {
    const exposicoes = exposicoesDoAnimal[this.animalSelecionado?.hash]

    if(exposicoes?.length) {
      exposicoes.forEach((exposicao) => {
        exposicao['diff'] = moment(dataAtual).diff(exposicao.data, 'days')
      })
    }

    return exposicoes
  }

  async exibirExposicoes(event: Event) {
    if(this.exposicoes?.length) {
      const dataAtual = moment(new Date()).format('YYYY-MM-DD')

      const popover = await this.popoverCtrl.create({
        component: PopoverListaExposicoesComponent,
        componentProps: {
          exposicoes: this.exposicoes
        },
        event,
      })

      await popover.present()

      const { data: exposicaoSelecionada } = await popover.onDidDismiss<TableGestorManejoReprodutivoUnitario>()

      if(exposicaoSelecionada) {
        this.exposicaoSelecionada = exposicaoSelecionada
        this.manejo.periodo_gestacional = moment(dataAtual).diff(exposicaoSelecionada.data, 'days').toString()
        this.manejo.hash_exposicao = exposicaoSelecionada.hash

        this.atualizarDatasDeConcepcaoEPrevisaoDeParto()
        this.emit()
      }
    }
  }

  getOpcaoSelecionada(opcao: TiposDiagnosticos) {
    this.manejo.diagnostico = opcao;
    if (opcao === TiposDiagnosticos.negativo) {
      this.manejo = {
        diagnostico: 'negativo',
        observacao: this.manejo.observacao || ''
      }
      this.exposicaoSelecionada = null
    }
    this.emit()
  }

  limparManejoExposicao() {
    this.manejo.periodo_gestacional = null;
    this.manejo.hash_exposicao = null;
    this.exposicaoSelecionada = null
    this.emit()
  }

  atualizarDatasDeConcepcaoEPrevisaoDeParto() {
    const periodo = Number(this.manejo.periodo_gestacional)
    const { dataConcepcaoFormatada, dataConcepcao } = this.getDataDeConcepcao(periodo);
    this.dataConcepcaoFormatada = dataConcepcaoFormatada;
    this.dataPrevisaoPartoFormatada = this.getDataPrevisaoParto(dataConcepcao);
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }

  getDataDeConcepcao(periodo: number) {
    let dataConcepcaoFormatada: string;
    let dataConcepcao;

    dataConcepcaoFormatada = moment(dataConcepcao)
      .subtract(periodo, 'days')
      .format('YYYY-MM-DD')
      .split('-')
      .reverse()
      .join('/');
    dataConcepcao = moment(dataConcepcao).subtract(periodo, 'days');
    return { dataConcepcaoFormatada, dataConcepcao };
  }

  getDataPrevisaoParto(dataConcepcao) {
    let dataPrevisaoPartoFormatada: string;
    dataPrevisaoPartoFormatada = moment(dataConcepcao)
      .add(280, 'days')
      .format('YYYY-MM-DD')
      .split('-')
      .reverse()
      .join('/');
    return dataPrevisaoPartoFormatada;
  }
}
