import {  
  IManejoSanitarioTipos, 
  TableGestorManejoSanitarioUnitario, 
} from '../../utils/types';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BancoSQLiteService } from '../banco-sqlite.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ManejoSanitarioService {

  constructor(
    private db: BancoSQLiteService,
    private alertCtrl: AlertController
  ) { }

  async getManejos(opcoes: { hash_animal: string; procedimento?: IManejoSanitarioTipos; limit?: number; offset?: number }): Promise<{ manejos: TableGestorManejoSanitarioUnitario[] }> {
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;
    const { hash_animal, procedimento } = opcoes


    const manejosSalvos: {
      data: string
      procedimento: IManejoSanitarioTipos
      descricao: string
      qtd_animais: number
      nome_animal: string
      estoque: string
      unidade: string
      quantidade: number
    }[] = await this.db.safeQuery({
      query: `
      SELECT data, procedimento, descricao, qtd_animais, 
      CASE WHEN 
        qtd_animais = 1 
        THEN unico.numeracao 
        ELSE '' 
      END as nome_animal,
      estoque,
      unidade,
      quantidade

      FROM (
        SELECT manejos.data, 
        manejos.procedimento, 
        manejos.descricao, 
        COUNT(DISTINCT manejos.hash_animal) as qtd_animais, 
        MAX(manejos.hash_animal) as animal_unico,
        estoque.titulo as estoque,
        estoque_meta.valor as unidade,
        manejos.sanitario_quantidade / COALESCE(rel_lote_area.animais_no_manejo, 1) as quantidade
        FROM gestor_manejo_sanitario_unitario manejos
          
          LEFT JOIN gestor_manejo_sanitario_animais_e_lotes rel_lote_area
            ON rel_lote_area.hash_manejo = manejos.hash
            AND rel_lote_area.status = 1
      
          INNER JOIN gestor_animais animal 
            ON animal.status = 1
            ${hash_animal ? `AND animal.hash = {{hash_animal}}` : ''}
            AND (
              animal.hash = manejos.hash_animal 
              OR
              animal.hash = rel_lote_area.hash_animal
            )

          LEFT JOIN gestor_estoque estoque
            ON estoque.status = 1 AND estoque.hash = manejos.sanitario_hash_estoque
            AND estoque.id_propriedade = {{idPropriedade}}
          LEFT JOIN gestor_estoque_metas estoque_meta
            ON estoque_meta.hash_estoque = estoque.hash AND estoque_meta.status = 1
            AND estoque_meta.chave = 'unidade' AND estoque_meta.id_propriedade = {{idPropriedade}}
        WHERE manejos.status = 1
        AND manejos.id_propriedade = {{idPropriedade}}
        ${procedimento ? `AND manejos.procedimento = {{procedimento}}` : ''}
      
        GROUP BY manejos.data, hash_procedimento, sanitario_hash_estoque
        ORDER BY manejos.data DESC
      ) a
      LEFT JOIN gestor_animais unico
      ON unico.hash = animal_unico
    `,
      params: {
        hash_animal,
        idPropriedade,
        procedimento
      }
    })

    return { manejos: manejosSalvos }
  }

  async preencherDescricaoDeManejoSanitario(opcoes?: { header?: string; placeholder?: string }): Promise<{ descricao: string }> {
    return new Promise(async (resolve) => {
      const { header, placeholder } = opcoes || {}

      const alert = await this.alertCtrl.create({
        header: header || 'Insira uma descrição para o manejo',
        cssClass: 'prompt-observacao',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Cancelar',
          },
          {
            text: 'Continuar',
            handler: (value) => {
              const isExisteValor = !!Object.values(value).filter((value: string) => value.trim() !== '')?.length

              if (isExisteValor) {
                return resolve(value)
              }

              return false
            },
          }
        ],
        inputs: [
          {
            type: 'textarea',
            name: 'descricao',
            placeholder: placeholder || 'IVOMEC 6 ML'
          }
        ],
      });

      await alert.present();

      setTimeout(() => {
        try {
          (document.querySelector('ion-alert.prompt-observacao .alert-input-wrapper textarea') as HTMLIonTextareaElement).focus()
        } catch (error) {
          console.error('🚀 ~ setTimeout ~ error:', error)
        }
      }, 500)

    })
  }
}
