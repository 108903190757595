import { AreaSelecionadaModel, PayloadCadastrarLoteModel, PropriedadeModel } from '../../utils/types';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SeletorAreasComponent } from '../seletor-areas/seletor-areas.component';
import { UtilsService } from '../../services/utils.service';
import { LotesService } from '../../services/lotes.service';
import Utils from '../../utils/utils';
import { ManejoService } from '../../services/manejo/manejo.service';

type CustomPopoverOptions = {
  [key: string]: {
    header: string;
  };
};

export type CadastroLote = {
  lote: PayloadCadastrarLoteModel
}

@Component({
  selector: 'app-cadastrar-lote',
  templateUrl: './cadastrar-lote.component.html',
  styleUrls: ['./cadastrar-lote.component.scss']
})
export class CadastrarLoteComponent implements OnInit {
  public isConfinamento = false

  public utils = Utils;
  public isCadastrandoLote = false;
  public propriedadeSelecionada: PropriedadeModel;
  public payloadCadastrarLote: PayloadCadastrarLoteModel = {
    nome: '',
    hash: '',
    area_nome: '',
    area_hash: '',
    aptidao: '',
    finalidade: '',
    confinamento: 0,
    sistema_criacao: ''
  };

  constructor(
    private modalCtrl: ModalController,
    private utilsCtrl: UtilsService,
    private lotesCtrl: LotesService,
    public manejoCtrl: ManejoService
  ) {}

  async ngOnInit() {
    this.propriedadeSelecionada = await this.manejoCtrl.getPropriedadeSelecionada();

    await this.atribuirValoresIniciais();
  }

  async atribuirValoresIniciais() {
    const pripriedadesMetas = await this.lotesCtrl.getMetasConfinamentos()

    for(const meta of pripriedadesMetas) {
      if(meta.chave === 'tipo_producao' && meta.valor !== '') {
        this.payloadCadastrarLote.aptidao = meta.valor;
      }

      if(meta.chave === 'sistema_criacao' && meta.valor !== '') {
        this.payloadCadastrarLote.sistema_criacao = meta.valor;
      }
    }
    
    if(this.isConfinamento) {
      this.payloadCadastrarLote.confinamento = 1;
    }
  }

  public customPopoverOptions: CustomPopoverOptions = {
    aptidao: {
      header: 'Aptidão'
    },
    finalidade: {
      header: 'Finalidade'
    },
    sistema_criacao: {
      header: 'Sistema de Criação'
    }
  };

  async abrirModalDeAreas() {
    const modal = await this.modalCtrl.create({
      component: SeletorAreasComponent
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      const {nome, hash} = data as AreaSelecionadaModel;
      this.payloadCadastrarLote.area_nome = nome;
      this.payloadCadastrarLote.area_hash = hash;
    }
  }

  atualizarSelect(campo: string, event: boolean) {
    if (campo !== 'confinamento') {
      this.payloadCadastrarLote[campo] = event;
    } else {
      this.payloadCadastrarLote[campo] = event ? 1 : 0;
    }
  }

  async cadastrarLote() {
    this.payloadCadastrarLote['hash'] = this.utils.gerarHash();
    if (!this.payloadCadastrarLote.nome) return await this.utilsCtrl.exibirToast('O nome do lote é obrigatório');

    if(this.isConfinamento) {
      this.payloadCadastrarLote.confinamento = 1;
    }

    try {
      await this.lotesCtrl.criarLote(this.payloadCadastrarLote);

      this.dismiss({ lote: this.payloadCadastrarLote });
    } catch (error) {
      console.error(error);
      this.utilsCtrl.exibirToast('Erro ao cadastrar lote');
    }
  }

  dismiss(value?: CadastroLote) {
    this.modalCtrl.dismiss(value);
  }
}
