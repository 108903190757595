import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { EstoqueBiologicoModel, EstoqueBiologicoService } from '../../services/estoque-biologico.service'
import { BuscarService } from '../../services/buscar.service'

@Component({
  selector: 'app-seletor-semen',
  templateUrl: './seletor-semen.page.html',
  styleUrls: ['./seletor-semen.page.scss']
})
export class SeletorSemenPage implements OnInit {
  public termoSemen = ''
  public isCarregando = false
  public listaItens: EstoqueBiologicoModel[] = []

  constructor(
    private modalCtrl: ModalController,
    private estoqueBiologicoCtrl: EstoqueBiologicoService,
    private buscarService: BuscarService
  ) {}

  async ngOnInit() {
    await this.getListaSemensEmbriao()
    this.buscarService.setListaCompleta(this.listaItens)
  }

  async getListaSemensEmbriao() {
    this.listaItens = await this.estoqueBiologicoCtrl.listarItems()
  }

  buscarSemen(termo: string) {
    this.listaItens = this.buscarService.searchTouro(termo)
  }

  selecionarSemen(semen) {
    this.dismiss(semen)
  }

  dismiss(value?) {
    this.modalCtrl.dismiss(value)
  }
}
