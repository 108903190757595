import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'cta-buttons',
  templateUrl: './cta-buttons.component.html',
  styleUrls: ['./cta-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CtaButtonsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
