import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { PessoasService } from '../../services/pessoas.service'
import { NovoFiltro } from '../vasta-lista/vasta-lista.component'

@Component({
  selector: 'app-seletor-pessoas',
  templateUrl: './seletor-pessoas.component.html',
  styleUrls: ['./seletor-pessoas.component.scss'],
})
export class SeletorPessoasComponent  implements OnInit {

  @Input() filtros: NovoFiltro

  public termoPessoa = ''
  public isCarregando = false
  public listaItens = []
  public totalItens = 0

  constructor(
    private modalCtrl: ModalController,
    private pessoasCtrl: PessoasService
  ) { }

  async ngOnInit() {
    await this.getListaPessoas()
  }

  dismiss(value?) {
    this.modalCtrl.dismiss(value)
  }

  async getListaPessoas() {
    const retorno = await this.pessoasCtrl.getPessoas({ filtros: this.filtros })
    this.listaItens = retorno.centros
    this.totalItens = retorno.totais.total
  }

  selecionarPessoa(pessoa) {
    this.dismiss(pessoa)
  }
}