import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'manejo-observacao',
  templateUrl: './manejo-observacao.component.html',
  styleUrls: ['./manejo-observacao.component.scss'],
})
export class ManejoObservacaoComponent  implements OnInit {
  @Input() manejo: { observacao?: string }
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}