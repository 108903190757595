import { AnimalModel, IManejoReprodutivoTipos, ListaManejoReprodutivo, TableGestorManejoReprodutivoUnitario } from '../../utils/types';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import * as moment from 'moment';
import { AnimaisService } from '../animais.service';
import { BancoSQLiteService } from '../banco-sqlite.service';
import { EstoqueService } from '../estoque.service';

@Injectable({
  providedIn: 'root'
})
export class ManejoReprodutivoService {

  constructor(private db: BancoSQLiteService, private animaisCtrl: AnimaisService, private estoqueService: EstoqueService) { }

  async getManejos(opcoes: { 
    hash_animal: string, 
    procedimento?: IManejoReprodutivoTipos, 
    limit?: number, 
    offset?: number, 
    de?: string, 
    ate?: string 
  }): Promise<{ manejos: ListaManejoReprodutivo[] }> {
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;
    const { hash_animal, procedimento, limit, offset, de, ate } = opcoes

    const manejosSalvos: ListaManejoReprodutivo[] = await this.db.run(`
      SELECT data, procedimento, qtd_animais, 
      CASE WHEN 
        qtd_animais = 1 
        THEN unico.numeracao 
        ELSE '' 
      END as nome_animal,
      a.*
      FROM (
        SELECT manejos.*, COUNT(1) as qtd_animais, MAX(hash_animal) as animal_unico
        FROM gestor_manejo_reprodutivo_unitario manejos
          INNER JOIN gestor_animais animal 
            ON animal.hash = manejos.hash_animal 
            AND animal.status = 1
            ${hash_animal ? `AND animal.hash IN ("${hash_animal.split(',').join('","')}")` : ''}
        WHERE manejos.status = 1
        AND manejos.id_propriedade = ${idPropriedade}
        ${procedimento ? `AND manejos.procedimento IN ("${procedimento.split(',').join('","')}")` : ''}
        ${de ? `AND manejos.data >= "${de.slice(0, 10)}"` : ''}
        ${ate ? `AND manejos.data <= "${ate.slice(0, 10)}"` : ''}
      
        GROUP BY data, procedimento
        ORDER BY data DESC
      ) a
      LEFT JOIN gestor_animais unico
      ON unico.hash = animal_unico

      LIMIT ${Number(limit) || 40}
      OFFSET ${Number(offset) || 0}
    `)

    for (const manejo of manejosSalvos) {
      if (Number(manejo.qtd_animais) > 1) continue

      let hashTouroInterno: string 
      let hashTouroExterno: string
      let touro: AnimalModel

      if (manejo.procedimento == 'monta-natural') {
        hashTouroInterno = manejo.cobertura_hash_reprodutor 
        hashTouroExterno = manejo.cobertura_hash_reprodutor_externo
      }

      if (manejo.procedimento == 'inseminacao') {
        const hashEstoque = manejo.inseminacao_embriao || manejo.inseminacao_semen
        touro = await this.estoqueService.getTouroPorEstoqueBiologico(hashEstoque)
      }

      if(hashTouroInterno) {
        touro = await this.animaisCtrl.getAnimal(hashTouroInterno)
      } else if(hashTouroExterno) {
        touro = await this.animaisCtrl.getReprodutorExterno(hashTouroExterno)
      }

      if(touro) {
        manejo.nome_touro = touro.numeracao || touro.nome 
      }

      if (manejo.procedimento == 'desmame') {
        // buscar cria
      }
      if (manejo.procedimento == 'parto') {
        if(manejo.parto_hash_cria1) {
          const animalCria1: AnimalModel = await this.animaisCtrl.getAnimal(manejo.parto_hash_cria1)
          manejo.nome_cria1 = animalCria1.numeracao || animalCria1.nome 
        }

        if(manejo.parto_hash_cria2) {
          const animalCria2: AnimalModel = await this.animaisCtrl.getAnimal(manejo.parto_hash_cria1)
          manejo.nome_cria1 = animalCria2.numeracao || animalCria2.nome 
        }
        
        // buscar cria
      }
      if (manejo.procedimento == 'dg') {
        if (manejo.dg_resultado == 'positivo')
          if (manejo.dg_previsao_de_parto)
            manejo.resultado = `Parto previsto para ${moment(manejo.dg_previsao_de_parto).format('DD/MM/YYYY')}`
          else manejo.resultado = 'positivo'
        else manejo.resultado = 'negativo'
      }
      if (manejo.procedimento == 'cio') {
        manejo.resultado = manejo.cio_resultado
      }
      if (manejo.procedimento == 'escore') {
        manejo.resultado = manejo.escore_corporal
      }
    }

    return { manejos: manejosSalvos }
  }

  async getExposicoes(opcoes: {
    filtros?: { hash_animal: string; de?: string; ate?: string }
  }): Promise<{ animais: { [key: string]: TableGestorManejoReprodutivoUnitario[] } }> {
    
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;

    console.log('opcoes: ',opcoes);

    const { filtros } = opcoes
    const { hash_animal, de, ate } = filtros
    const procedimentos = ['monta-natural', 'inseminacao']

    const selectManejos = `
      SELECT manejos.hash,
      manejos.data,
      manejos.hash_animal,
      manejos.procedimento,
      manejos.cobertura_hash_reprodutor,
      manejos.cobertura_hash_reprodutor_externo,
      manejos.inseminacao_semen,
      manejos.inseminacao_embriao,
      animal.numeracao as matriz_numero, 
      animal.nome as matriz_nome
      FROM gestor_manejo_reprodutivo_unitario manejos
        INNER JOIN gestor_animais animal 
          ON animal.hash = manejos.hash_animal 
          AND animal.status = 1
          AND animal.hash = '${hash_animal}'
      WHERE manejos.status = 1
      AND manejos.id_propriedade = {{id_propriedade}}
      AND manejos.procedimento IN ("${procedimentos.join('","')}")
      ${de ? `AND manejos.data >= "${de.slice(0, 10)}"` : ''}
      ${ate ? `AND manejos.data <= "${ate.slice(0, 10)}"` : ''}
      
      GROUP BY manejos.hash

      ORDER BY data DESC
    `

    const manejosSalvos = await this.db.safeQuery({
      query: `${selectManejos}`,
      params: { id_propriedade: idPropriedade }
    })

    const exposicoesPorHash = {}

    manejosSalvos.map((m) => {
      exposicoesPorHash[m.hash_animal] = exposicoesPorHash[m.hash_animal] || []
      exposicoesPorHash[m.hash_animal].push(m)
    })

    return { animais: exposicoesPorHash }
  }
}
