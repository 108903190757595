import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { AnimalModel, ManejoAnimalModel } from '../../utils/types'
import { VastaRX } from '@vasta/rx'
import { GlobalService } from '../../services/global.service'
import { BalancaAcoresBPB085ioTService } from '../../services/dispositivos-bluetooth/balancas/balanca-le-acores-bpb085-iot.service'
import * as moment from 'moment'
import { ConexoesBluetoothService } from '../../services/dispositivos-bluetooth/conexoes-bluetooth.service'

@Component({
  selector: 'manejo-pesagem',
  templateUrl: './manejo-pesagem.component.html',
  styleUrls: ['./manejo-pesagem.component.scss']
})
export class ManejoPesagemComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel
  @Output() manejoChange = new EventEmitter()

  @Input('animal') animal: AnimalModel

  @Output() opcoes = new EventEmitter()

  pesoBalanca: string = ''
  pesoBalancaAnterior: string = ''

  public isExecutandoTara = false

  rxPesoBalanca

  constructor(
    public conexoesBluetoothSeparadaCtrl: ConexoesBluetoothService,
    private cd: ChangeDetectorRef,
    private balancaAcoresBPB085Ctrl: BalancaAcoresBPB085ioTService,
    private globalCtrl: GlobalService
  ) {
    this.rxPesoBalanca = VastaRX.getState('peso_balanca', (peso) => {
      this.pesoBalanca = peso

      if (this.pesoBalancaAnterior !== this.pesoBalanca) {
        console.warn('this.pesoBalanca: ', this.pesoBalanca)
        this.pesoBalancaAnterior = this.pesoBalanca
      }
    })
  }

  async ngOnInit() {
    this.exibirUltimoPesoEmitidoSeBalancaConectada()

    if (this.manejo.peso) {
      setTimeout(() => {
        this.calculaGMD()
      }, 300)
    }
  }

  ngOnDestroy() {
    VastaRX.unListen(this.rxPesoBalanca)
  }

  exibirUltimoPesoEmitidoSeBalancaConectada() {
    const balancaConectada = this.globalCtrl.get('balanca_conectada')

    if (balancaConectada && !this.pesoBalanca?.length) {
      this.pesoBalanca = this.globalCtrl.get('pesoNaBalanca')
    }
  }

  fecharTeclado(event: KeyboardEvent) {
    let element = event.target as HTMLInputElement
    element.blur()
  }

  async lerBalanca() {
    let pesoFormatado = Number(this.pesoBalanca?.includes('U') ? this.pesoBalanca?.slice(1) : this.pesoBalanca)
    this.manejo.peso = Number(pesoFormatado.toFixed(2) || 0)
    this.calculaGMD()
    this.emit()
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  async executarTara() {
    try {
      this.isExecutandoTara = true

      await this.balancaAcoresBPB085Ctrl.executarTaraBalanca()

      setTimeout(() => {
        this.isExecutandoTara = false
      }, 5000)
    } catch (error) {
      console.error(error)
    }
  }

  emit() {
    this.detectChanges()
    this.manejoChange.emit(this.manejo)
  }

  detectChanges() {
    this.cd.detectChanges()
  }

  calculaGMD() {
    const diasEntreUltimaPesagemEHoje = this.getDiasEntreUltimaPesagemEHoje()
    const pesoAtual = this.manejo.peso
    const pesoAnterior = this.animal?.ultimo_peso || 0
    const ganho = pesoAtual - pesoAnterior
    if (ganho == 0) {
      this.manejo.gmd = 0
    } else {
      this.manejo.gmd = Number((ganho / diasEntreUltimaPesagemEHoje).toFixed(3))
    }
  }

  getDiasEntreUltimaPesagemEHoje() {
    const dataUltimaPesagem = this.animal?.data_ultima_pesagem?.split('-').reverse().join('/')
    const dataHoje = moment().format('DD/MM/YYYY')
    const dataHojeFormatada = moment(dataHoje, 'DD/MM/YYYY')
    const dataUltimaPesagemFormatada = moment(dataUltimaPesagem, 'DD/MM/YYYY')
    const diasEntreUltimaPesagemEHoje = dataHojeFormatada.diff(dataUltimaPesagemFormatada, 'days')
    return diasEntreUltimaPesagemEHoje
  }
}
