import { Injectable } from '@angular/core'
import { BancoSQLiteService } from './banco-sqlite.service'
import { Preferences } from '@capacitor/preferences'

@Injectable({
  providedIn: 'root'
})
export class BrincosJaUtilizadosService {
  constructor(
    private sql: BancoSQLiteService
  ) { }


  async numeroControleJaUtilizado(animal: { numeracao: string; hash: string }) {
    let brincoExiste: any;
    const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value);
    let semZeros = animal.numeracao.replace(/^0+/, '');
    let lower = animal.numeracao.toLowerCase();
    let upper = animal.numeracao.toUpperCase();
    let animais: any = await this.sql.run({
      query: `
			SELECT gestor_animais.hash,
			(
				SELECT gestor_genealogia.hash_animal
				FROM gestor_genealogia
				WHERE gestor_genealogia.hash_animal = gestor_animais.hash
				AND gestor_genealogia.hash_mae = '${animal.hash}' 
			) as is_filho
			FROM gestor_animais
			WHERE (
				numeracao = '${animal.numeracao}'
				OR numeracao = '${lower}'
				OR numeracao = '${upper}'
				OR numeracao = '${semZeros}'
				OR numeracao = '0${semZeros}'
				OR numeracao = '00${semZeros}'
				OR numeracao = '000${semZeros}'
			)
			AND gestor_animais.hash <> '${animal.hash}'
			AND gestor_animais.status = 1
			AND gestor_animais.situacao IN ('vivo', 'novo')
			AND is_filho IS NULL
			AND id_propriedade = ${propriedade.id}
		`
    });
    if (animais && animais.length) {
      brincoExiste = animais[0].hash;
    } else {
      brincoExiste = null;
    }
    return brincoExiste;
  }
}
