import { Preferences } from '@capacitor/preferences';
import { labelsEstoqueFarmaciaTipo } from '../../../components/manejo-sanitario-estoque/sanitario-labels';
import { ManejoNutricional } from '../../../utils/types';
import { IEstoqueNutricionalItemsQuery } from '../../../utils/types/manejo-nutricional-types';
import Utils from '../../../utils/utils';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SanitarioService } from '../../../services/sanitario.service';
import { ColunasEstoqueFarmaciaLista } from '../../../utils/configuracoes-padrao';

@Component({
  selector: 'app-estoque-farmacia',
  templateUrl: './estoque-farmacia.page.html',
  styleUrls: ['./estoque-farmacia.page.scss'],
})
export class EstoqueFarmaciaPage implements OnInit {
  ColunasEstoqueFarmaciaLista = ColunasEstoqueFarmaciaLista
  estoqueSanitario: IEstoqueNutricionalItemsQuery[]
  estoquesSelecionadosPreviamente: ManejoNutricional[]
  hash_not_in: string[]
  utils = Utils
  termoBuscaEstoque: string
  labelsEstoqueFarmaciaTipo = labelsEstoqueFarmaciaTipo
  estoquesSelecionados: { [keyId: number]: IEstoqueNutricionalItemsQuery } = {}

  constructor(
    private modalCtrl: ModalController,
    private sanitarioService: SanitarioService,
  ) { }

  ngOnInit() {
    this.getEstoqueSanitario()
  }

  async getEstoqueSanitario(): Promise<void> {
    try {
      const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)

      const { estoques } = await this.sanitarioService.getEstoquesSanitarios({
        idPropriedade: propriedade.id,
        filtros: {
          hash_not_in: this.hash_not_in,
          titulo: this.termoBuscaEstoque || '',
          order_by: 'extra_quantidade',
          order_direction: 'desc'
        }
      })

      this.estoqueSanitario = estoques
    } catch(error) {
      console.error(error)
    }
  }

  dismiss(value?: IEstoqueNutricionalItemsQuery[]) {
    this.modalCtrl.dismiss(value)
  }

  selecionarEstoque(estoque: IEstoqueNutricionalItemsQuery) {
    this.dismiss([estoque])
  }

  confirmarSelecaoDeEstoques() {
    const listaEstoques = Object.values(this.estoquesSelecionados)
    this.dismiss(listaEstoques)
  }
}
