import { AnimalModel, ProcedimentosSanitarios } from '../../utils/types'
import { AbasManejosHome } from '../../utils/types/home-type'

export type AtalhoModel = {
  tipoManejo: string
  nome?: string
  finalidade?: string
  animal?: AnimalModel
  procedimento?: ProcedimentosSanitarios
  descricao?: string
  somente_sexo: string
}

export type TiposManejosLista =
  | 'pesagem'
  | 'associacao-de-brinco'
  | 'registrar-morte'
  | 'movimentacao-entre-lotes'
  | 'movimentacao-entre-areas'
  | 'enviar-para-confinamento'
  | 'cadastrar_lote'
  | 'sanitario'
  | 'reprodutivo'
  | 'parto'
  | 'diagnostico-gestacao'
  | 'diagnostico-cio'
  | 'inseminacao-artificial'
  | 'monta-natural'
  | 'desmame'
  | 'implante-progesterona'
  | 'remocao-implante'
  | 'aborto'
  | 'escore-corporal'
  | 'alteracao-numero'
  | 'descarte'
  | 'castracao'
  | 'nutricional'
  | 'ordenha'
  | 'sanitarioEstoque'
  | 'aprumos'
  | 'boca'
  | 'casco'
  | 'chanfro'
  | 'perimetro-escrotal'
  | 'frame'
  | 'garupa'
  | 'insercao-cauda'
  | 'musculosidade'
  | 'ossatura'
  | 'pelagem'
  | 'profundidade'
  | 'racial'
  | 'reproducao'
  | 'temperamento'
  | 'torcao-testicular'
  | 'ubere'
  | 'umbigo'
  | 'linha-dorso'
  | 'perda'
  | 'observacao'
  | 'secagem'
  | 'pluviometria'
  | 'altura-capim'
  | 'pastejo-rotacionado'
  | 'escore-fezes'

export type PageModel = {
  tipo: 'contador' | 'rebanho' | TiposManejosLista
  identificador360?: string;
  icon?: string;
  nome: string;
  finalidade?: string;
  categoria: string;
  numero?: number;
  aba: AbasManejosHome
  somente_sexo?: 'macho' | 'femea'
  cor: string

  procedimento?: "vermifugacao" | "medicacao" | "vacinacao" | "procedimentos-veterinarios" | "exames" | "banho" | "complexo-vitaminico" | "tratamento-clinico" | "antiparasitarios" | "diagnostico" | "outros";
}

let ListaManejosDisponiveis: PageModel[] = [
  /* PESAGEM */
  {
    identificador360: 'pesagem',
    icon: 'pesagem-controle',
    nome: 'Pesagem',
    tipo: 'pesagem',
    finalidade: 'controle',
    categoria: 'producao',
    numero: 1,
    aba: 'animais',
    cor: 'verde'
  },
  {
    identificador360: 'pesagem',
    icon: 'pesagem-compra',
    nome: 'Pesagem para Compra',
    tipo: 'pesagem',
    finalidade: 'compra',
    categoria: 'producao',
    numero: 2,
    aba: 'animais',
    cor: 'verde'
  },
  {
    identificador360: 'pesagem',
    icon: 'pesagem-venda',
    nome: 'Pesagem para Venda',
    tipo: 'pesagem',
    finalidade: 'venda',
    categoria: 'producao',
    numero: 3,
    aba: 'animais',
    cor: 'verde'
  },
  /* ORDENHA */
  {
    identificador360: 'ordenha',
    icon: 'leiteiro',
    nome: 'Ordenha',
    tipo: 'ordenha',
    categoria: 'producao',
    numero: 4,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'verde'
  },
  {
    identificador360: 'secagem',
    icon: 'secagem',
    nome: 'Secagem',
    tipo: 'secagem',
    categoria: 'producao',
    numero: 5,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'verde'
  },
  /* GERAL */
  {
    identificador360: 'alteracao-numero',
    icon: 'alteracao-numero',
    nome: 'Alteração do N° de Controle',
    tipo: 'alteracao-numero',
    categoria: 'geral',
    numero: 1,
    aba: 'animais',
    cor: 'azul'
  },
  {
    identificador360: 'associacao-de-brinco',
    icon: 'brinco-eletronico',
    nome: 'Identificação Eletrônica',
    tipo: 'associacao-de-brinco',
    categoria: 'geral',
    numero: 2,
    aba: 'animais',
    cor: 'azul'
  },
  {
    identificador360: 'movimentacao-entre-lotes',
    icon: 'rebanho',
    nome: 'Movimentação entre Lotes',
    tipo: 'movimentacao-entre-lotes',
    finalidade: 'controle',
    categoria: 'geral',
    numero: 3,
    aba: 'animais',
    cor: 'azul'
  },
  {
    identificador360: 'enviar-para-confinamento',
    icon: 'boi-cerca',
    nome: 'Envio para Confinamento',
    tipo: 'enviar-para-confinamento',
    finalidade: 'controle',
    categoria: 'geral',
    numero: 4,
    aba: 'animais',
    cor: 'azul'
  },
  {
    identificador360: 'registrar-morte',
    icon: 'remover-bovino',
    nome: 'Registro de Morte',
    tipo: 'registrar-morte',
    categoria: 'geral',
    numero: 5,
    aba: 'animais',
    cor: 'azul'
  },
  {
    identificador360: 'registrar-perda',
    icon: 'bovino-perda',
    nome: 'Registro de Perda',
    tipo: 'perda',
    categoria: 'geral',
    numero: 6,
    aba: 'animais',
    cor: 'azul'
  },
  {
    identificador360: 'observacao',
    icon: 'warning',
    nome: 'Observação',
    tipo: 'observacao',
    categoria: 'geral',
    numero: 7,
    aba: 'animais',
    cor: 'azul'
  },
  {
    identificador360: 'observacao-lotes',
    icon: 'warning',
    nome: 'Observação',
    tipo: 'observacao',
    categoria: 'geral',
    numero: 7,
    aba: 'lotes',
    cor: 'azul'
  },
  {
    identificador360: 'observacao-areas',
    icon: 'warning',
    nome: 'Observação',
    tipo: 'observacao',
    categoria: 'geral',
    numero: 7,
    aba: 'áreas',
    cor: 'azul'
  },
  /* NUTRICIONAL */
  {
    identificador360: 'nutricional',
    icon: 'leaf',
    nome: 'Nutricional',
    tipo: 'nutricional',
    categoria: 'geral',
    numero: 8,
    aba: 'animais',
    cor: 'azul'
  },
  {
    identificador360: 'nutricional-lotes',
    icon: 'leaf',
    nome: 'Nutricional',
    tipo: 'nutricional',
    categoria: 'geral',
    numero: 8,
    aba: 'lotes',
    cor: 'azul'
  },
  {
    identificador360: 'nutricional-areas',
    icon: 'leaf',
    nome: 'Nutricional',
    tipo: 'nutricional',
    categoria: 'geral',
    numero: 8,
    aba: 'áreas',
    cor: 'azul'
  },
  {
    identificador360: 'movimentacao-entre-areas',
    icon: 'cerca',
    nome: 'Movimentação entre Áreas',
    tipo: 'movimentacao-entre-areas',
    categoria: 'geral',
    numero: 9,
    aba: 'lotes',
    cor: 'azul'
  },
  {
    identificador360: 'pluviometria',
    icon: 'rainy',
    nome: 'Pluviometria',
    tipo: 'pluviometria',
    categoria: 'geral',
    numero: 10,
    aba: 'áreas',
    cor: 'azul'
  },
  {
    identificador360: 'escore-fezes',
    icon: 'escore-fezes',
    nome: 'Escore de Fezes',
    tipo: 'escore-fezes',
    categoria: 'geral',
    numero: 11,
    aba: 'lotes',
    cor: 'azul'
  },
  /* REPRODUTIVO */
  {
    identificador360: 'diagnostico-gestacao',
    icon: 'dg',
    nome: 'Diagnóstico de Gestação',
    tipo: "diagnostico-gestacao",
    categoria: 'reprodutivo',
    numero: 1,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'implante-progesterona',
    icon: 'implante-add',
    nome: 'Aplicação de Implante',
    tipo: "implante-progesterona",
    categoria: 'reprodutivo',
    numero: 2,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'remocao-implante',
    icon: 'implante-remove',
    nome: 'Remoção de Implante',
    tipo: "remocao-implante",
    categoria: 'reprodutivo',
    numero: 3,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'diagnostico-cio',
    icon: 'cio',
    nome: 'Diagnóstico de Cio',
    tipo: 'diagnostico-cio',
    categoria: 'reprodutivo',
    numero: 4,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'inseminacao-artificial',
    icon: 'inseminacao',
    nome: 'Inseminação Artificial',
    tipo: 'inseminacao-artificial',
    categoria: 'reprodutivo',
    numero: 5,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'monta-natural',
    icon: 'monta-natural',
    nome: 'Monta Natural',
    tipo: 'monta-natural',
    categoria: 'reprodutivo',
    numero: 6,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'parto',
    icon: 'parto',
    nome: 'Parto',
    tipo: 'parto',
    categoria: 'reprodutivo',
    numero: 7,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'aborto',
    icon: 'aborto',
    nome: 'Aborto',
    tipo: 'aborto',
    categoria: 'reprodutivo',
    numero: 8,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'escore-corporal',
    icon: 'escore',
    nome: 'Escore Corporal',
    tipo: 'escore-corporal',
    categoria: 'reprodutivo',
    numero: 9,
    aba: 'animais',
    somente_sexo: 'femea',
    cor: 'vermelho',
  },
  {
    identificador360: 'desmame',
    icon: 'desmame',
    nome: 'Desmame',
    tipo: 'desmame',
    categoria: 'reprodutivo',
    numero: 10,
    aba: 'animais',
    cor: 'vermelho',
  },
  {
    identificador360: 'descarte',
    icon: 'remover-bovino',
    nome: 'Descarte',
    tipo: 'descarte',
    categoria: 'reprodutivo',
    numero: 11,
    aba: 'animais',
    cor: 'vermelho',
  },
  {
    identificador360: 'castracao',
    icon: 'close',
    nome: 'Castração',
    tipo: 'castracao',
    categoria: 'reprodutivo',
    numero: 12,
    aba: 'animais',
    cor: 'vermelho',
  },
  {
    identificador360: 'sanitarioEstoque',
    icon: 'sanitario-estoque',
    nome: 'Item da Farmácia',
    tipo: 'sanitarioEstoque',
    categoria: 'sanitario',
    numero: 1,
    aba: 'animais',
    cor: 'laranja'
  },
  {
    identificador360: 'sanitario-estoque-lotes',
    icon: 'sanitario-estoque',
    nome: 'Item da Farmácia',
    tipo: 'sanitarioEstoque',
    categoria: 'sanitario',
    numero: 1,
    aba: 'lotes',
    cor: 'laranja'
  },
  {
    identificador360: 'sanitario-estoque-areas',
    icon: 'sanitario-estoque',
    nome: 'Item da Farmácia',
    tipo: 'sanitarioEstoque',
    categoria: 'sanitario',
    numero: 1,
    aba: 'áreas',
    cor: 'laranja'
  },
  {
    identificador360: 'sanitario-procedimentos-veterinarios',
    icon: 'sanitario-procedimentos-veterinarios',
    nome: 'Procedimentos Veterinários',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 2,
    aba: 'animais',
    procedimento: 'procedimentos-veterinarios',
    cor: 'laranja',
  },
  {
    identificador360: 'sanitario-exames',
    icon: 'search',
    nome: 'Exames',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 3,
    aba: 'animais',
    procedimento: 'exames',
    cor: 'laranja',
  },
  {
    identificador360: 'sanitario-diagnostico',
    icon: 'checkmark-circle',
    nome: 'Diagnóstico',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 4,
    aba: 'animais',
    procedimento: 'diagnostico',
    cor: 'laranja',
  },
  {
    identificador360: 'sanitario-medicacao',
    icon: 'sanitario-medicacao',
    nome: 'Medicação',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 5,
    aba: 'animais',
    procedimento: 'medicacao',
    cor: 'laranja',
  },
  {
    identificador360: 'sanitario-vacinacao',
    icon: 'seringa',
    nome: 'Vacinação',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 6,
    aba: 'animais',
    procedimento: 'vacinacao',
    cor: 'laranja',
  },
  {
    identificador360: 'sanitario-vermifugacao',
    icon: 'sanitario-vermifugacao',
    nome: 'Vermifugação',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 7,
    aba: 'animais',
    procedimento: 'vermifugacao',
    cor: 'laranja',
  },
  {
    identificador360: 'sanitario-banho',
    icon: 'sanitario-banho',
    nome: 'Banho',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 8,
    aba: 'animais',
    procedimento: 'banho',
    cor: 'laranja',
  },
  {
    identificador360: 'sanitario-antiparasitario',
    icon: 'sanitario-antiparasitario',
    nome: 'Antiparasitário',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 9,
    aba: 'animais',
    procedimento: 'antiparasitarios',
    cor: 'laranja',
  },
  {
    identificador360: 'sanitario-outros',
    icon: 'add-circle',
    nome: 'Outros',
    tipo: 'sanitario',
    categoria: 'sanitario',
    numero: 10,
    aba: 'animais',
    procedimento: 'outros',
    cor: 'laranja',
  },
  /* GENÉTICA */
  {
    identificador360: 'aprumos',
    icon: 'genetica-aprumos',
    nome: 'Aprumos',
    tipo: "aprumos",
    categoria: 'genetica',
    cor: 'preto',
    numero: 1,
    aba: 'animais'
  },
  {
    identificador360: 'boca',
    icon: 'genetica-boca',
    nome: 'Boca',
    tipo: "boca",
    categoria: 'genetica',
    cor: 'preto',
    numero: 2,
    aba: 'animais'
  },
  {
    identificador360: 'casco',
    icon: 'genetica-cascos',
    nome: 'Casco',
    tipo: "casco",
    categoria: 'genetica',
    cor: 'preto',
    numero: 3,
    aba: 'animais'
  },
  {
    identificador360: 'chanfro',
    icon: 'genetica-chanfro',
    nome: 'Chanfro',
    tipo: "chanfro",
    categoria: 'genetica',
    cor: 'preto',
    numero: 4,
    aba: 'animais'
  },
  {
    identificador360: 'frame',
    icon: 'genetica-frame',
    nome: 'Frame',
    tipo: "frame",
    categoria: 'genetica',
    cor: 'preto',
    numero: 5,
    aba: 'animais'
  },
  {
    identificador360: 'garupa',
    icon: 'genetica-garupa',
    nome: 'Garupa',
    tipo: "garupa",
    categoria: 'genetica',
    cor: 'preto',
    numero: 6,
    aba: 'animais'
  },
  {
    identificador360: 'insercao-cauda',
    icon: 'genetica-cauda',
    nome: 'Inserção de Cauda',
    tipo: "insercao-cauda",
    categoria: 'genetica',
    cor: 'preto',
    numero: 7,
    aba: 'animais'
  },
  {
    identificador360: 'linha-dorso',
    icon: 'genetica-dorso',
    nome: 'Linha Dorso',
    tipo: "linha-dorso",
    categoria: 'genetica',
    cor: 'preto',
    numero: 8,
    aba: 'animais'
  },
  {
    identificador360: 'musculosidade',
    icon: 'genetica-musculosidade',
    nome: 'Musculosidade',
    tipo: "musculosidade",
    categoria: 'genetica',
    cor: 'preto',
    numero: 9,
    aba: 'animais'
  },
  {
    identificador360: 'ossatura',
    icon: 'genetica-ossatura',
    nome: 'Ossatura',
    tipo: "ossatura",
    categoria: 'genetica',
    cor: 'preto',
    numero: 10,
    aba: 'animais'
  },
  {
    identificador360: 'pelagem',
    icon: 'genetica-pelagem',
    nome: 'Pelagem',
    tipo: "pelagem",
    categoria: 'genetica',
    cor: 'preto',
    numero: 11,
    aba: 'animais'
  },
  {
    identificador360: 'perimetro-escrotal',
    icon: 'perimetro-escrotal',
    nome: 'Perímetro Escrotal',
    tipo: "perimetro-escrotal",
    categoria: 'genetica',
    cor: 'preto',
    numero: 12,
    aba: 'animais',
    somente_sexo: 'macho'
  },
  {
    identificador360: 'profundidade',
    icon: 'genetica-profundidade',
    nome: 'Profundidade',
    tipo: "profundidade",
    categoria: 'genetica',
    cor: 'preto',
    numero: 13,
    aba: 'animais'
  },
  {
    identificador360: 'racial',
    icon: 'genetica-racial',
    nome: 'Racial',
    tipo: "racial",
    categoria: 'genetica',
    cor: 'preto',
    numero: 14,
    aba: 'animais'
  },
  {
    identificador360: 'reproducao',
    icon: 'monta-natural',
    nome: 'Reprodução',
    tipo: "reproducao",
    categoria: 'genetica',
    cor: 'preto',
    numero: 15,
    aba: 'animais'
  },
  {
    identificador360: 'temperamento',
    icon: 'genetica-temperamento',
    nome: 'Temperamento',
    tipo: "temperamento",
    categoria: 'genetica',
    cor: 'preto',
    numero: 16,
    aba: 'animais'
  },
  {
    identificador360: 'torcao-testicular',
    icon: 'genetica-torcao-testicular',
    nome: 'Torção Testicular',
    tipo: "torcao-testicular",
    categoria: 'genetica',
    cor: 'preto',
    numero: 17,
    aba: 'animais',
    somente_sexo: 'macho'
  },
  {
    identificador360: 'ubere',
    icon: 'leiteiro',
    nome: 'Úbere',
    tipo: "ubere",
    categoria: 'genetica',
    cor: 'preto',
    numero: 18,
    aba: 'animais',
    somente_sexo: 'femea'
  },
  {
    identificador360: 'umbigo',
    icon: 'genetica-umbigo',
    nome: 'Umbigo',
    tipo: "umbigo",
    categoria: 'genetica',
    cor: 'preto',
    numero: 19,
    aba: 'animais'
  },
  {
    identificador360: 'altura-capim',
    icon: 'altura-capim',
    nome: 'Altura Capim',
    tipo: 'altura-capim',
    categoria: 'pasto',
    numero: 1,
    aba: 'áreas',
    cor: 'verde'
  },
  {
    identificador360: 'pastejo-rotacionado',
    icon: 'swap-horizontal',
    nome: 'Pastejo Rotacionado',
    tipo: 'pastejo-rotacionado',
    categoria: 'pasto',
    numero: 2,
    aba: 'áreas',
    cor: 'verde'
  }
];

const ManejosPorTipo = {}

ListaManejosDisponiveis.map((manejo) => {
  ManejosPorTipo[manejo.tipo] = ManejosPorTipo[manejo.tipo] || manejo
})

export { ListaManejosDisponiveis, ManejosPorTipo }
