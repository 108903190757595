import * as moment from 'moment'
import { UsuarioService } from '../services/usuario.service'
import { AppInjector } from '.'
import { listaTodosDispositivos, TodosDisposivos } from '../components/modal-dispositivos-bluetooth/dispositivos-bluetooth.data'

function fecharTeclado(event: KeyboardEvent) {
  let element = event.target as HTMLInputElement;
  element.blur();
}

function tempoDeExibicaoDeMensagemDeErro(erroMensagem: string) {
  return erroMensagem.length > 75 ? 7000 : 3000
}

function comprimirImagem(imagemAComprimir) {
  return new Promise((done) => {
    let imagemOtimizada = null
    var image = new Image()

    image.src = imagemAComprimir
    image.onload = () => {
      var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d')
      canvas.width = image.naturalWidth
      canvas.height = image.naturalHeight

      if (canvas.width > 1080 && canvas.width > canvas.height) {
        let proporcao = (600 * 100) / canvas.width / 100
        canvas.width = Math.round(image.naturalWidth * proporcao)
        canvas.height = Math.round(image.naturalHeight * proporcao)
      } else if (canvas.height > 1080 && canvas.height > canvas.width) {
        let proporcao = (600 * 100) / canvas.height / 100
        canvas.height = Math.round(image.naturalHeight * proporcao)
        canvas.width = Math.round(image.naturalWidth * proporcao)
      }

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
      imagemOtimizada = canvas.toDataURL('image/jpeg', 0.7)
      done(imagemOtimizada)
    }
  })
}
function formataBrinco(brinco: any) {
  return brinco.slice(-7)
}

function idadeDoAnimalFormatada(data: string) {
  if (!data) return ''
  let dataDeNascimento = data.split('-').reverse().join('/').split('/')
  let dataHoje = moment().format('DD/MM/YYYY').split('/')

  let dataDeNascimentoMoment = moment(dataDeNascimento, 'DD/MM/YYYY')
  let dataHojeMoment = moment(dataHoje, 'DD/MM/YYYY')

  let years = dataHojeMoment.diff(dataDeNascimentoMoment, 'year')
  dataDeNascimentoMoment.add(years, 'years')
  let months = dataHojeMoment.diff(dataDeNascimentoMoment, 'months')
  let days = dataHojeMoment.diff(dataDeNascimentoMoment, 'days')

  let isAnoPlural = years > 1 ? ' anos ' : years === 1 ? ' ano ' : ''
  let isAno = years ? years + isAnoPlural : ''
  let isMesPlural = months > 1 ? ' meses ' : months === 1 ? ' mês ' : ''
  let isMes = years && months ? ' e ' + months + isMesPlural : months ? months + isMesPlural : ''
  let isDays = days > 1 ? days + ' dias ' : '1 dia '

  let idadeAnimal = isAno + isMes
  if(!idadeAnimal) idadeAnimal = isDays;

  return idadeAnimal
}

function getChavePorValor(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function converterArrayEmObjeto(array) {
  return {...array}
}

function gerarHash(length = 16) {
  const usuarioCtrl = AppInjector.get(UsuarioService)
  const idProprietario = usuarioCtrl.id_proprietario

  let retorno = ''
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < length; i++) retorno += chars.charAt(Math.floor(Math.random() * chars.length))

  if(idProprietario) retorno += '@' + idProprietario

  return retorno 
}

function getValorGenericoDispositivo(nomeDispositivoConectado: string): TodosDisposivos {
  if(nomeDispositivoConectado) {
    const listaDispositivos = listaTodosDispositivos()
    let valorGenericoDoDispositivo: TodosDisposivos

    for(const device of listaDispositivos) {
      const listaNomeIds = [device.value]
      const nomeIdPrefixoDispositivo = listaNomeIds.find(nomeId => nomeDispositivoConectado.startsWith(nomeId)) as TodosDisposivos
      
      if(nomeIdPrefixoDispositivo) {
        valorGenericoDoDispositivo = nomeIdPrefixoDispositivo
      }
    }
    
    return valorGenericoDoDispositivo
  }
}

const exibeNome = {
  estaNoPe: 'Ao Pé',
  lactacao: 'Lactante',
  'em-reproducao': 'Em Reprodução',
  'em-engorda': 'Em Engorda',
  vivo: 'Vivo',
  perdido: 'Perdido',
  morto: 'Morto',
  vendido: 'Vendido',
  abatido: 'Abatido',
  natimorto: 'Natimorto',
  doente: 'Doente',
  'em-tratamento': 'Tratamento',
  saudavel: 'Saudável',
  cheia: 'Prenhe',
  solteira: 'Solteira',
  vazia: 'Vazia',
  abortou: 'Abortada',
  inseminacao: 'Inseminada',
  cobertura: 'Coberta',
  castrado: 'Castrado',
  rufiao: 'Rufião',
  touro: 'Touro',
  cio: 'Cio',
  inteiro: 'Inteiro'
}

function pipeTimestamp(value: string, ...args: any[]) {
  let hojeMoment = args[0][2] ? moment(args[0][2]) : moment()
  let meses: any = moment(hojeMoment).diff(moment(value), 'month').valueOf()

  if (args[0][0] == 'periodo') {
    if (meses <= 12) return (args[0][1] == 'macho' && 'Bezerro') || (args[0][1] === 'femea' && 'Bezerra') || ''
    if (meses >= 12 && meses <= 24)
      return (args[0][1] == 'macho' && 'Garrote') || (args[0][1] === 'femea' && 'Novilhota') || ''
    if (meses >= 24 && meses <= 36)
      return (args[0][1] == 'macho' && 'Novilho') || (args[0][1] === 'femea' && 'Novilha') || ''
    if (meses >= 36 && meses <= 1800)
      return (args[0][1] == 'macho' && 'Boi') || (args[0][1] === 'femea' && 'Vaca') || ''
  }

  if (args[0] == 'meses' || args[0][0] == 'meses') {
    if (meses > 24) {
      let anos = Math.floor(meses / 12)
      let qtdMeses = Math.round(((meses / 12) % 1) * 12)
      return anos + ' Anos' + (qtdMeses ? ' e ' + qtdMeses + ' ' + (qtdMeses == 1 ? 'Mês' : 'Meses') : '')
    }

    if (meses > 1) return meses + ' Meses'
    if (meses == 1) return '1 Mês'
    if (meses < 1) return 'Recém Nascido'
  }

  if (args[0] == 'data') return value ? moment(value).format('DD/MM/YYYY') : ''
  if (args[0] == 'dataAbreviada') return value ? moment(value).format('DD/MM/YY') : ''
  if (args[0] == 'dataSimples') return value ? moment(value).format('DD/MM') : ''
  if (args[0] == 'dataSimplesExtenso') return value ? moment(value).format('DD/MMM') : ''
  if (args[0] == 'horadata') return value ? moment.utc(value).local().format('HH:mm:ss DD/MM/YYYY') : ''
  if (args[0] == 'horadatasemsegundos') return value ? moment.utc(value).local().format('HH:mm DD/MM/YYYY') : ''
  if (args[0] == 'horaminutos') return value ? moment.utc(value).local().format('HH:mm') : ''
  if (args[0] == 'ano') return value ? moment.utc(value).local().format('YYYY') : ''
  if (args[0] == 'tempoatras') return value ? moment.utc(value).fromNow() : ''
}

const manejosObjetoDeTraducao = {
  0: { slug: 'medicacao', texto: 'Medicação', id_pai: 0 },
  1: { slug: 'vacinacao', texto: 'Vacinação', id_pai: 0 },
  2: { slug: 'vermifugacao', texto: 'Vermifugação', id_pai: 0 },
  3: { slug: 'procedimentos-veterinarios', texto: 'Procedimentos veterinários', id_pai: 0 },
  4: { slug: 'exames', texto: 'Exames', id_pai: 0 },
  5: { slug: 'banho', texto: 'Banho', id_pai: 0 },
  6: { slug: 'complexo-vitaminico', texto: 'Complexo vitamínico', id_pai: 0 },
  7: { slug: 'tratamento-clinico', texto: 'Tratamento clínico', id_pai: 0 },
  8: { slug: 'antiparasitarios', texto: 'Antiparasitários', id_pai: 0 },
  9: { slug: 'outros', texto: 'Outros', id_pai: 0 },
  12: { slug: 'diagnostico', texto: 'Diagnóstico', id_pai: 0 },
  10: { slug: 'aftosa', texto: 'Aftosa', id_pai: 1 },
  11: { slug: 'brucelose', texto: 'Brucelose', id_pai: 1 }
}

const procedimentosObjetoDeTraducao = {
  11: { order: 0, slug: 'cio', texto: 'Cio', sexo: 'femea' },
  2: { order: 1, slug: 'cobertura', texto: 'Cobertura', sexo: 'femea' },
  4: { order: 2, slug: 'inseminacao', texto: 'Inseminação', sexo: 'femea' },
  5: { order: 3, slug: 'toque', texto: 'Toque/Ultrassom', sexo: 'femea' },
  6: { order: 4, slug: 'parto', texto: 'Parto', sexo: 'femea', single: true },
  7: { order: 5, slug: 'desmame', texto: 'Desmame', sexo: 'femea' },
  1: { order: 6, slug: 'aborto', texto: 'Aborto', sexo: 'femea', single: true },
  0: { order: 7, slug: 'castracao', texto: 'Castração' },
  3: { order: 8, slug: 'coleta-semen', texto: 'Coleta de sêmen', hidden: true, sexo: 'macho' },
  8: { slug: 'entouramento', texto: 'Entouramento', hidden: true, sexo: 'femea' },
  9: { slug: 'in-vitro', texto: 'In vitro', hidden: true },
  10: { slug: 'transferencia-embriao', texto: 'Transferência de embrião', hidden: true }
}

const tiposHistoricos = {
  'importa-animal': { nome: 'Transferência de Propriedade', tipo: 'alteracao', icone: 'ion-md-home' },
  'transfere-propriedade': { nome: 'Transferência de Propriedade', tipo: 'alteracao', icone: 'ion-md-home' },
  'lote-entrada': { nome: 'Entrada no Lote', tipo: 'movimentacao', icone: 'ion-md-repeat' },
  'lote-saida': { nome: 'Saída do Lote', tipo: 'movimentacao', icone: 'ion-md-repeat' },
  'lote-transferencia': { nome: 'Transferência de lote', tipo: 'movimentacao', icone: 'ion-md-repeat' },
  'alterar-nome': { nome: 'Alteração de Nome', tipo: 'alteracao', icone: 'ion-md-create' },
  'alterar-numeracao': { nome: 'Alteração de Numeração', tipo: 'alteracao', icone: 'ion-md-create' },
  'alterar-situacao': { nome: 'Situação', tipo: 'alteracao', icone: 'icone-bovino' },
  'alterar-situacao_produtiva': { nome: 'Situação Produtiva', tipo: 'alteracao', icone: 'icone-leiteiro' },
  'alterar-situacao_reprodutiva': {
    nome: 'Situação Reprodutiva',
    tipo: 'alteracao',
    icone: 'icone-fertilizacao'
  },
  'alterar-situacao_sanitaria': { nome: 'Situação Sanitária', tipo: 'alteracao', icone: 'icone-seringa' },
  'alteracao-situacao_produtiva': {
    nome: 'Situação Produtiva Alterada',
    tipo: 'alteracao',
    icone: 'icone-leiteiro'
  },
  'alteracao-situacao_reprodutiva': {
    nome: 'Situação Reprodutiva Alterada',
    tipo: 'alteracao',
    icone: 'icone-fertilizacao'
  },
  'alteracao-situacao_sanitaria': {
    nome: 'Situação Sanitária Alterada',
    tipo: 'alteracao',
    icone: 'icone-seringa'
  },
  'alteracao-nome': { nome: 'Alteração de Nome', tipo: 'alteracao', icone: 'icone-md-create' },
  'alteracao-numeracao': { nome: 'Alteração de Numeração', tipo: 'alteracao', icone: 'icone-md-create' }
}

const Utils = {
  tempoDeExibicaoDeMensagemDeErro,
  formataBrinco,
  getValorGenericoDispositivo,
  manejosObjetoDeTraducao,
  pipeTimestamp,
  procedimentosObjetoDeTraducao,
  tiposHistoricos,
  idadeDoAnimalFormatada,
  exibeNome,
  gerarHash,
  comprimirImagem,
  fecharTeclado,
  getChavePorValor,
  converterArrayEmObjeto
}

export default Utils
