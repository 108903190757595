import { TiposDiagnosticos } from '../../../utils/types'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
@Component({
  selector: 'manejo-cio',
  templateUrl: './manejo-cio.component.html',
  styleUrls: ['./manejo-cio.component.scss']
})
export class ManejoCioComponent implements OnInit {
  @Input() manejo: { diagnostico?: string; observacao?: string }
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor() {}

  async ngOnInit() {
    if (!this.manejo) {
      this.manejo = {}
    }
  }

  getOpcaoSelecionada(opcao: TiposDiagnosticos) {
    this.manejo.diagnostico = opcao
    this.emit()
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
