import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { ApiService } from './api';

@Injectable({
  providedIn: 'root'
})
export class ImagemService {

  constructor(private apiCtrl: ApiService) { }

  async enviarImagem(payload: { tipo: string; imagem: string }): Promise<[{ url: string }, Error?]> {
    try {
      const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)

      const endpoint = `/propriedade/${propriedade.id}/midias/imagem`
      const response = await this.apiCtrl.postAuth(endpoint, payload) as { url: string }

      if (response) return [response]
    } catch (error) {
      return [error]
    }
  }
}
