import { ManejoAnimalModel, BoiOuSemenSelecionadoModel, InseminadorModel } from '../../../utils/types'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { SeletorPessoasComponent } from '../../seletor-pessoas/seletor-pessoas.component'
import { SeletorSemenPage } from '../../../pages/seletor-semen/seletor-semen.page'
import { EstoqueBiologicoModel } from '../../../services/estoque-biologico.service'

@Component({
  selector: 'manejo-inseminacao-artificial',
  templateUrl: './manejo-inseminacao-artificial.component.html',
  styleUrls: ['./manejo-inseminacao-artificial.component.scss']
})
export class ManejoInseminacaoArtificialComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()
  @Input() manejosReprodutivosAvancados: boolean
  @Input() opcoesRapidasSemensEmbrioes: BoiOuSemenSelecionadoModel[] = []
  @Output() opcoesRapidasSemensEmbrioesChange = new EventEmitter()
  @Input() listaSemens: EstoqueBiologicoModel[]
  @Input() semensPorHash: { [key: string]: EstoqueBiologicoModel }
  @Input() listaInseminadores: InseminadorModel[]
  @Input() inseminadoresPorHash: { [key: string]: InseminadorModel }

  constructor(private modalCtrl: ModalController) {}

  async ngOnInit() {
  }

  async abrirOpcoesDeManejo() {
    this.opcoes.emit()
  }

  limparManejo() {
    this.manejo.semen = null
    this.manejo.bastao = null
    this.manejo.muco = null
    this.manejo.inseminador = null
    this.emit()
  }

  selecionarSemen(semen: BoiOuSemenSelecionadoModel) {
    this.manejo.semen = semen.hash
    this.processaOpcoesRapidas(semen)
    this.emit()
  }

  processaOpcoesRapidas(semen: BoiOuSemenSelecionadoModel) {
    const hashsPresentes = this.opcoesRapidasSemensEmbrioes.map((semen) => semen.hash)
    if (!hashsPresentes.includes(semen.hash)) {
      this.opcoesRapidasSemensEmbrioes.unshift(semen)
    }
    this.opcoesRapidasSemensEmbrioes = this.opcoesRapidasSemensEmbrioes.slice(0, 5)
    this.opcoesRapidasSemensEmbrioesChange.emit(this.opcoesRapidasSemensEmbrioes)
  }

  async openModalListaSemen() {
    const modal = await this.modalCtrl.create({
      component: SeletorSemenPage
    })
    await modal.present()

    const { data: semen } = await modal.onDidDismiss()

    if (semen) {
      this.selecionarSemen(semen)
    }
  }

  async openModalListaInseminador() {
    const modal = await this.modalCtrl.create({
      component: SeletorPessoasComponent,
      componentProps:{
        filtros: {
          categoria_pai: 'inseminador'
        }
      }
    })
    await modal.present()

    const { data: inseminador } = await modal.onDidDismiss()

    if (inseminador) {
      this.selecionarInseminador(inseminador)
    }
  }

  selecionarInseminador(inseminador: InseminadorModel) {
    this.manejo.inseminador = inseminador.hash
    this.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
