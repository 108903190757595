import { ManejosPorTipo } from '../../pages/home/atalho-manejos'
import {
  AnimalDoManejo,
  BoiOuSemenSelecionadoModel,
  ManejoModel
} from '../../utils/types'
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'detalhes-manejos',
  templateUrl: './detalhes-manejos.component.html',
  styleUrls: ['./detalhes-manejos.component.scss']
})
export class DetalhesManejosComponent implements OnInit {
  @Input() animais: AnimalDoManejo[]
  @Input() manejos: ManejoModel[]
  @Input('semen-selecionados') semensSelecionados: BoiOuSemenSelecionadoModel[]
  @Input('touros-selecionados') tourosSelecionados: BoiOuSemenSelecionadoModel[]

  manejosPorTipo = ManejosPorTipo

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.semensSelecionados) {
      this.semensSelecionados = changes.semensSelecionados.currentValue
    }
    if (changes.tourosSelecionados) {
      this.tourosSelecionados = changes.tourosSelecionados.currentValue
    }
  }

  qtdProcedimentosSanitarios(hash) {
    const qtdProcedimentosSanitarios = {}
    this.animais.map((animal) => {
      const dados = animal?.dados?.sanitario
      if (dados) {
        const hashsSanitarios = Object.keys(dados)
        for (const hashSanitario of hashsSanitarios) {
          const valor = dados[hashSanitario]?.procedimento_sanitario_realizado
          if (valor) {
            if (!qtdProcedimentosSanitarios[hashSanitario]) {
              qtdProcedimentosSanitarios[hashSanitario] = 0
            }
            qtdProcedimentosSanitarios[hashSanitario]++
          }
        }
      }
    })
    return qtdProcedimentosSanitarios[hash] || 0
  }

  qtdItemNutricional(hash) {
    const qtdItemNutricional = {}
    this.animais.map((animal) => {
      const dados = animal?.dados?.nutricional
      if (dados) {
        const hashsNutricionais = Object.keys(dados)
        for (const hashNutricional of hashsNutricionais) {
          const valor = dados[hashNutricional]?.nutricional_quantidade
          if (valor) {
            if (!qtdItemNutricional[hashNutricional]) {
              qtdItemNutricional[hashNutricional] = 0
            }
            qtdItemNutricional[hashNutricional]++
          }
        }
      }
    })
    return qtdItemNutricional[hash] || 0
  }

  qtdItemSanitario(hash) {
    const qtdItemSanitario = {}
    this.animais.map((animal) => {
      const dados = animal?.dados?.sanitarioEstoque
      if (dados) {
        const hashsSanitarios = Object.keys(dados)
        for (const hashSanitario of hashsSanitarios) {
          const valor = dados[hashSanitario]?.sanitario_quantidade
          if (valor) {
            if (!qtdItemSanitario[hashSanitario]) {
              qtdItemSanitario[hashSanitario] = 0
            }
            qtdItemSanitario[hashSanitario]++
          }
        }
      }
    })
    return qtdItemSanitario[hash] || 0
  }

  totalDescarte(tipoManejo: string) {
    const { qtdDiagnosticoPorTipo } = this.getTotalDeDescartes(this.animais)
    return qtdDiagnosticoPorTipo[tipoManejo]
  }

  totalDescarteRealizado(tipoManejo: string) {
    const { diagnosticos } = this.getTotalDeDescartes(this.animais)
    return diagnosticos.positivo
  }

  totalDescarteNaoRealizado(tipoManejo: string) {
    const { diagnosticos } = this.getTotalDeDescartes(this.animais)
    return diagnosticos.negativo
  }

  totalDiagnostico(tipoManejo: string) {
    const { qtdDiagnosticoPorTipo } = this.getTotalDeDiagnostico(this.animais, tipoManejo)
    return qtdDiagnosticoPorTipo[tipoManejo]
  }

  totalDiagnosticoPositiva(tipoManejo: string) {
    const { diagnosticos } = this.getTotalDeDiagnostico(this.animais, tipoManejo)
    return diagnosticos.positivo
  }

  totalDiagnosticoNegativo(tipoManejo: string) {
    const { diagnosticos } = this.getTotalDeDiagnostico(this.animais, tipoManejo)
    return diagnosticos.negativo
  }

  getTotalDeDiagnostico(animais: AnimalDoManejo[], tipoManejoDiagnostico: string) {
    let qtdDiagnosticoPorTipo = {
      'diagnostico-gestacao': 0,
      'diagnostico-cio': 0
    }
    const diagnosticos = {
      positivo: 0,
      negativo: 0
    }

    for (const animal of animais) {
      const valor = animal?.dados?.[tipoManejoDiagnostico]?.diagnostico
      if (valor) {
        qtdDiagnosticoPorTipo[tipoManejoDiagnostico]++
        if (valor === 'positivo') {
          diagnosticos.positivo++
        } else if (valor === 'negativo') {
          diagnosticos.negativo++
        }
      }
    }
    return {
      qtdDiagnosticoPorTipo,
      diagnosticos
    }
  }

  getTotalDeDescartes(animais: AnimalDoManejo[]) {
    let qtdDiagnosticoPorTipo = {
      descarte: 0
    }
    const diagnosticos = {
      positivo: 0,
      negativo: 0
    }

    for (const animal of animais) {
      const valor = animal?.dados?.descarte?.descarte
      if (valor) {
        qtdDiagnosticoPorTipo.descarte++
        if (Number(valor) === 1) {
          diagnosticos.positivo++
        } else {
          diagnosticos.negativo++
        }
      }
    }
    return {
      qtdDiagnosticoPorTipo,
      diagnosticos
    }
  }

  get qtdDesmame() {
    let qtdManejosDesmame = 0
    for (const animal of this.animais) {
      if (animal?.dados?.desmame?.desmame && Number(animal?.dados?.desmame?.desmame)) {
        qtdManejosDesmame++
      }
    }
    return qtdManejosDesmame
  }

  get qtdCastracao() {
    let qtdManejosCastracao = 0
    for (const animal of this.animais) {
      if (animal?.dados?.castracao?.castracao && Number(animal?.dados?.castracao?.castracao)) {
        qtdManejosCastracao++
      }
    }
    return qtdManejosCastracao
  }

  get qtdImplante() {
    let qtdManejosImplante = 0
    for (const animal of this.animais) {
      if (
        animal?.dados?.['implante-progesterona']?.implantado &&
        Number(animal?.dados?.['implante-progesterona']?.implantado)
      ) {
        qtdManejosImplante++
      }
    }
    return qtdManejosImplante
  }

  get qtdRemocaoImplante() {
    let qtdManejosRemocaoImplante = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['remocao-implante']?.removido && Number(animal?.dados?.['remocao-implante']?.removido)) {
        qtdManejosRemocaoImplante++
      }
    }
    return qtdManejosRemocaoImplante
  }

  get qtdInseminacaoArtificial() {
    let qtdManejosInseminacao = 0

    for (const animal of this.animais) {
      if (animal?.dados?.['inseminacao-artificial']?.semen) {
        qtdManejosInseminacao++
      }
    }

    return qtdManejosInseminacao
  }

  get qtdReprodutoresInseminacaoArtificial() {
    const qtdReprodutorPorHash = {}
    for (const animal of this.animais) {
      const hash = animal?.dados?.['inseminacao-artificial']?.semen
      if (animal?.dados?.['inseminacao-artificial']?.semen) {
        if (!qtdReprodutorPorHash[hash]) {
          qtdReprodutorPorHash[hash] = 0
        }
        qtdReprodutorPorHash[hash]++
      }
    }
    return Object.keys(qtdReprodutorPorHash).length
  }

  get qtdReprodutorInseminacaoPorHash() {
    const qtdReprodutorPorHash = {}
    for (const animal of this.animais) {
      const semen = animal?.dados?.['inseminacao-artificial']?.semen
      if (semen) {
        if (!qtdReprodutorPorHash[semen]) {
          qtdReprodutorPorHash[semen] = 0
        }
        qtdReprodutorPorHash[semen]++
      }
    }
    return qtdReprodutorPorHash
  }

  get qtdAssociacaoBrinco() {
    let qtdManejoBrincoPreenchido = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['associacao-de-brinco']?.brinco) {
        qtdManejoBrincoPreenchido++
      }
    }
    return qtdManejoBrincoPreenchido
  }

  get qtdAlteracaoNumero() {
    let qtdManejoAlteracaoNumeroPreenchido = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['alteracao-numero']?.novo_numero) {
        qtdManejoAlteracaoNumeroPreenchido++
      }
    }
    return qtdManejoAlteracaoNumeroPreenchido
  }

  get qtdManejoAborto() {
    let qtdManejosAborto = 0
    for (const animal of this.animais) {
      if (animal?.dados?.aborto?.realizado && Number(animal?.dados?.aborto?.realizado)) {
        qtdManejosAborto++
      }
    }
    return qtdManejosAborto
  }

  manejoOrdenha() {
    let producaoTotal: number = 0
    let qtdAnimais = 0

    for (const animal of this.animais) {
      const total =
        Number(animal?.dados?.ordenha?.ordenha_1 || 0) +
        Number(animal?.dados?.ordenha?.ordenha_2 || 0) +
        Number(animal?.dados?.ordenha?.ordenha_3 || 0)
      if (total) {
        producaoTotal += Number(total || 0)
        qtdAnimais++
      }
    }

    const producaoMedia = Number(producaoTotal / qtdAnimais)

    return {
      producaoTotal,
      producaoMedia,
      qtdAnimais
    }
  }

  get qtdAnimaisOrdenhados() {
    const { qtdAnimais } = this.manejoOrdenha()
    return qtdAnimais
  }

  get ordenhaMedia() {
    const { producaoMedia } = this.manejoOrdenha()
    return producaoMedia
  }

  get ordenhaTotal() {
    const { producaoTotal } = this.manejoOrdenha()
    return producaoTotal
  }

  manejoPeso() {
    let pesoTotal: number = 0
    let qtdAnimais = 0
    let gmdSoma = 0
    let qtdGMD = 0

    for (const animal of this.animais) {
      if (animal?.dados?.pesagem?.peso) {
        pesoTotal += Number(animal.dados.pesagem?.peso || 0)
        qtdAnimais++
      }
      if (animal?.dados?.pesagem?.gmd) {
        gmdSoma += Number(animal.dados.pesagem?.gmd || 0)
        qtdGMD++
      }
    }

    const pesoMedio = Number(pesoTotal / qtdAnimais)

    return {
      pesoTotal,
      pesoMedio,
      qtdAnimais,
      gmdMedio: qtdGMD ? Number(gmdSoma / qtdGMD) : 0
    }
  }

  get manejoPesoTotal() {
    const { pesoTotal } = this.manejoPeso()
    return pesoTotal
  }

  get manejoPesoMedio() {
    const { pesoMedio } = this.manejoPeso()
    return pesoMedio
  }
  
  get manejoGMDMedio() {
    const { gmdMedio } = this.manejoPeso()
    return gmdMedio
  }

  get qtdAnimaisPesados() {
    const { qtdAnimais } = this.manejoPeso()
    return qtdAnimais
  }

  get qtdManejoParto() {
    let qtdManejosParto = 0
    for (const animal of this.animais) {
      if (animal?.dados?.parto?.filhotes?.length) {
        qtdManejosParto += animal?.dados?.parto?.filhotes?.length
      }
    }
    return qtdManejosParto
  }

  get qtdManejoMontaNatural() {
    let qtdManejosMontaNatural = 0

    for (const animal of this.animais) {
      if (animal?.dados?.['monta-natural']?.touro?.hash) {
        qtdManejosMontaNatural++
      }
    }
    return qtdManejosMontaNatural
  }

  qtdEnviosConfinamento() {
    let qtdEnviosConfinamento = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['enviar-para-confinamento']?.hash_lote_destino) {
        qtdEnviosConfinamento++
      }
    }
    return qtdEnviosConfinamento
  }

  qtdMudancaLote() {
    let qtdEnviosConfinamento = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['movimentacao-entre-lotes']?.hash_lote_destino) {
        qtdEnviosConfinamento++
      }
    }
    return qtdEnviosConfinamento
  }

  get qtdReprodutoresMontaNatural() {
    const qtdReprodutorPorHash = {}
    for (const animal of this.animais) {
      const hash = animal?.dados?.['monta-natural']?.touro?.hash
      if (animal?.dados?.['monta-natural']?.touro?.hash) {
        if (!qtdReprodutorPorHash[hash]) {
          qtdReprodutorPorHash[hash] = 0
        }
        qtdReprodutorPorHash[hash]++
      }
    }
    return Object.keys(qtdReprodutorPorHash).length
  }

  get qtdReprodutorCoberturaPorHash() {
    const qtdReprodutorPorHash = {}
    for (const animal of this.animais) {
      const hash = animal?.dados?.['monta-natural']?.touro?.hash
      if (animal?.dados?.['monta-natural']?.touro?.hash) {
        if (!qtdReprodutorPorHash[hash]) {
          qtdReprodutorPorHash[hash] = 0
        }
        qtdReprodutorPorHash[hash]++
      }
    }
    return qtdReprodutorPorHash
  }

  getEscoreMedio() {
    let valorTotalEscore = 0
    let qtdEscore = 0

    for (const animal of this.animais) {
      const escore = animal?.dados?.['escore-corporal']?.escore || 0
      if (escore) {
        valorTotalEscore += Number(escore)
        qtdEscore++
      }
    }

    if (!qtdEscore) return 0

    const escoreMedio = valorTotalEscore / qtdEscore
    return escoreMedio.toFixed(2)
  }

  get qtdAprumos() {
    let qtdManejosAprumos = 0
    for (const animal of this.animais) {
      if (animal?.dados?.aprumos?.aprumos && Number(animal?.dados?.aprumos?.aprumos)) {
        qtdManejosAprumos++
      }
    }
    return qtdManejosAprumos
  }

  get qtdBoca() {
    let qtdManejosBoca = 0
    for (const animal of this.animais) {
      if (animal?.dados?.boca?.boca && Number(animal?.dados?.boca?.boca)) {
        qtdManejosBoca++
      }
    }
    return qtdManejosBoca
  }

  get qtdCasco() {
    let qtdManejosCasco = 0
    for (const animal of this.animais) {
      if (animal?.dados?.casco?.casco && Number(animal?.dados?.casco?.casco)) {
        qtdManejosCasco++
      }
    }
    return qtdManejosCasco
  }

  get qtdChanfro() {
    let qtdManejosChanfro = 0
    for (const animal of this.animais) {
      if (animal?.dados?.chanfro?.chanfro && Number(animal?.dados?.chanfro?.chanfro)) {
        qtdManejosChanfro++
      }
    }
    return qtdManejosChanfro
  }

  get qtdFrame() {
    let qtdManejosFrame = 0
    for (const animal of this.animais) {
      if (animal?.dados?.frame?.frame && Number(animal?.dados?.frame?.frame)) {
        qtdManejosFrame++
      }
    }
    return qtdManejosFrame
  }

  get qtdGarupa() {
    let qtdManejosGarupa = 0
    for (const animal of this.animais) {
      if (animal?.dados?.garupa?.garupa && Number(animal?.dados?.garupa?.garupa)) {
        qtdManejosGarupa++
      }
    }
    return qtdManejosGarupa
  }

  get qtdInsercaoCauda() {
    let qtdManejosInsercaoCauda = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['insercao-cauda']?.insercao_cauda && Number(animal?.dados?.['insercao-cauda']?.insercao_cauda)) {
        qtdManejosInsercaoCauda++
      }
    }
    return qtdManejosInsercaoCauda
  }

  get qtdLinhaDorso() {
    let qtdManejosLinhaDorso = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['linha-dorso']?.linha_dorso && Number(animal?.dados?.['linha-dorso']?.linha_dorso)) {
        qtdManejosLinhaDorso++
      }
    }
    return qtdManejosLinhaDorso
  }

  get qtdMusculosidade() {
    let qtdManejosMusculosidade = 0
    for (const animal of this.animais) {
      if (animal?.dados?.musculosidade?.musculosidade && Number(animal?.dados?.musculosidade?.musculosidade)) {
        qtdManejosMusculosidade++
      }
    }
    return qtdManejosMusculosidade
  }

  get qtdOssatura() {
    let qtdManejosOssatura = 0
    for (const animal of this.animais) {
      if (animal?.dados?.ossatura?.ossatura && Number(animal?.dados?.ossatura?.ossatura)) {
        qtdManejosOssatura++
      }
    }
    return qtdManejosOssatura
  }

  get qtdPelagem() {
    let qtdManejosPelagem = 0
    for (const animal of this.animais) {
      if (animal?.dados?.pelagem?.pelagem && Number(animal?.dados?.pelagem?.pelagem)) {
        qtdManejosPelagem++
      }
    }
    return qtdManejosPelagem
  }

  get qtdPerimetroEscrotal() {
    let qtdManejosPerimetroEscrotal = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['perimetro-escrotal']?.perimetro_escrotal && Number(animal?.dados?.['perimetro-escrotal']?.perimetro_escrotal)) {
        qtdManejosPerimetroEscrotal++
      }
    }
    return qtdManejosPerimetroEscrotal
  }

  get qtdProfundidade() {
    let qtdManejosProfundidade = 0
    for (const animal of this.animais) {
      if (animal?.dados?.profundidade?.profundidade && Number(animal?.dados?.profundidade?.profundidade)) {
        qtdManejosProfundidade++
      }
    }
    return qtdManejosProfundidade
  }

  get qtdRacial() {
    let qtdManejosRacial = 0
    for (const animal of this.animais) {
      if (animal?.dados?.racial?.racial && Number(animal?.dados?.racial?.racial)) {
        qtdManejosRacial++
      }
    }
    return qtdManejosRacial
  }

  get qtdReproducao() {
    let qtdManejosReproducao = 0
    for (const animal of this.animais) {
      if (animal?.dados?.reproducao?.reproducao && Number(animal?.dados?.reproducao?.reproducao)) {
        qtdManejosReproducao++
      }
    }
    return qtdManejosReproducao
  }

  get qtdTemperamento() {
    let qtdManejosTemperamento = 0
    for (const animal of this.animais) {
      if (animal?.dados?.temperamento?.temperamento && Number(animal?.dados?.temperamento?.temperamento)) {
        qtdManejosTemperamento++
      }
    }
    return qtdManejosTemperamento
  }

  get qtdTorcaoTesticular() {
    let qtdManejosTorcaoTesticular = 0
    for (const animal of this.animais) {
      if (animal?.dados?.['torcao-testicular']?.torcao_testicular && Number(animal?.dados?.['torcao-testicular']?.torcao_testicular)) {
        qtdManejosTorcaoTesticular++
      }
    }
    return qtdManejosTorcaoTesticular
  }

  get qtdUbere() {
    let qtdManejosUbere = 0
    for (const animal of this.animais) {
      if (animal?.dados?.ubere?.ubere && Number(animal?.dados?.ubere?.ubere)) {
        qtdManejosUbere++
      }
    }
    return qtdManejosUbere
  }

  get qtdUmbigo() {
    let qtdManejosUmbigo = 0
    for (const animal of this.animais) {
      if (animal?.dados?.umbigo?.umbigo && Number(animal?.dados?.umbigo?.umbigo)) {
        qtdManejosUmbigo++
      }
    }
    return qtdManejosUmbigo
  }
}
