import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActionSheetController, ModalController } from '@ionic/angular'
import { CameraResultType, CameraSource, Camera } from '@capacitor/camera'
import { Geolocation } from '@capacitor/geolocation'
import { ImagemAntesDeEnviarComponent } from '../../imagem-antes-de-enviar/imagem-antes-de-enviar.component'
import { UtilsService } from '../../../services/utils.service'
import Utils from '../../../utils/utils'

type Localizacao = {
  latitude: number
  longitude: number
}

@Component({
  selector: 'manejo-registrar-morte',
  templateUrl: './manejo-registrar-morte.component.html',
  styleUrls: ['./manejo-registrar-morte.component.scss']
})
export class ManejoRegistrarMorteComponent implements OnInit {
  @Input('manejo') manejo: {
    motivo?: string,
    detalhes?: string,
    fotos?: string[],
    localizacao?: Localizacao
   }
  @Output() manejoChange = new EventEmitter();

  public utils = Utils
  public isCarregandoLocalizacao = false

  public cameraOptions = {
    quality: 80,
    resultType: CameraResultType.Base64,
    encodingType: 0,
    allowEditing: false,
    correctOrientation: true,
    source: CameraSource.Camera
  }

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private utilsCtrl: UtilsService,
    private modalCtrl: ModalController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.manejo) {
      this.manejo = {}
      this.emit()
    }
  }

  async anexarImagem() {
    if (this.manejo?.fotos?.length > 4) {
      this.utilsCtrl.exibirToast('Limite de fotos atingida')
    } else {
      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Selecione método para adicionar foto:',
        mode: 'md',
        buttons: [
          {
            text: 'Selecionar foto',
            icon: 'images',
            handler: () => {
              this.buscarFotoMobile(false)
            }
          },
          {
            text: 'Tirar foto',
            icon: 'camera',
            handler: () => {
              this.buscarFotoMobile(true)
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          }
        ]
      })
      await actionSheet.present()
    }
  }

  async buscarFotoMobile(isCamera) {
    try {
      this.cameraOptions.source = isCamera ? CameraSource.Camera : CameraSource.Photos
      const foto = await Camera.getPhoto(this.cameraOptions)
      const loader = await this.utilsCtrl.mostrarLoader('Processando imagem...')
      await loader.present()

      const base64Imagem = await this.utils.comprimirImagem('data:image/jpeg;base64,' + foto.base64String)
      if (base64Imagem) this.exibirImagemAntesDeEnviar(base64Imagem)

      await loader.dismiss()
    } catch (error) {
      console.error('erro na camera: ', error)
    }
  }

  async exibirImagemAntesDeEnviar(base64Imagem) {
    const modalMostrarImagem = await this.modalCtrl.create({
      component: ImagemAntesDeEnviarComponent,
      componentProps: {
        base64Imagem
      }
    })
    await modalMostrarImagem.present()
    const modalResponse = (await modalMostrarImagem.onDidDismiss()).data
    if (modalResponse) this.adicionarNovaImagem(base64Imagem)
  }

  selecionarImagem(event: KeyboardEvent) {
    let data = event.target as HTMLInputElement
    const files = data.files[0]
    if (!files) return

    const reader = new FileReader()
    reader.onload = (event) => {
      let novaImagem = event.target.result as any
      let image = new Image()
      image.src = novaImagem
      image.onload = async () => {
        novaImagem = await this.utils.comprimirImagem(novaImagem)
        this.adicionarNovaImagem(novaImagem)
      }
    }
    reader.readAsDataURL(files)
  }

  adicionarNovaImagem(imagem) {
    this.manejo.fotos = this.manejo.fotos || []
    if (this.manejo.fotos.length < 5) {
      this.manejo.fotos.push(imagem)
      this.emit()
    }
  }

  async getLocalizacao() {
    this.isCarregandoLocalizacao = true
    try {
      const { coords } = await Geolocation.getCurrentPosition()
      if (coords) {
        this.manejo.localizacao = this.manejo.localizacao || {
          latitude: null,
          longitude: null
        }
        this.manejo.localizacao.latitude = coords.latitude
        this.manejo.localizacao.longitude = coords.longitude
        this.emit()
      }
    } catch (error) {
      console.error(error)
      this.utilsCtrl.exibirToast('Erro ao tentar adicionar localização atual')
    } finally {
      this.isCarregandoLocalizacao = false
    }
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
