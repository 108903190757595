import { Injectable } from '@angular/core';
import { BancoSQLiteService } from './banco-sqlite.service';
import { Preferences } from '@capacitor/preferences';
import { NovoFiltro, Ordenacao, Paginacao } from '../components/vasta-lista/vasta-lista.component';
import { sqlFilter } from '../utils/sqlUtils';
import { IAreaMetaChaves, IAreaPiquete, ICadastroArea } from '../utils/types/areas.interface';
import { ISucesso } from '../utils/types/utils-type';
@Injectable({
  providedIn: 'root'
})
export class AreasService {
  public piquetesOpcoes: { [key: string]: { label: string, value: string }[] } = {}

  constructor(private db: BancoSQLiteService) {}

  async getAreas(opcoes?: {
    filtros?: NovoFiltro
    ordenacao?: Ordenacao
    paginacao?: Paginacao
    termo?: string
    hashs?: string[]
    hashNotIn?: string[]
  }) {
    const { termo, hashs, hashNotIn, filtros, ordenacao, paginacao } = opcoes || {}
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;

    const {
      safeSelectIn,
      safeSelectNotIn,
      safeLike,
      safeNumberRange,
      safeDateRange,
      safeOrderBy,
    } = sqlFilter

    return await this.db.safeQuery({
      query: `
      WITH datas_pesos AS (
        SELECT MAX(data_pesagem) as data_pesagem, hash_animal
        FROM gestor_pesagens
        WHERE gestor_pesagens.status = 1
        AND gestor_pesagens.peso <> ''
        AND gestor_pesagens.peso IS NOT NULL
        AND gestor_pesagens.peso > 0
        AND gestor_pesagens.id_propriedade = {{idPropriedade}}
        GROUP BY hash_animal
      ),
      animais_por_area AS (
        SELECT COUNT(1) AS qtd,
        gestor_areas.hash as hash_area,
        SUM(ultima_pesagem.peso) as peso_total
        FROM gestor_animais
            INNER JOIN gestor_lotes ON gestor_lotes.status = 1 AND gestor_lotes.hash = gestor_animais.hash_lote
            INNER JOIN gestor_areas ON gestor_areas.status = 1 AND gestor_areas.hash = gestor_lotes.hash_area
            LEFT JOIN datas_pesos ON datas_pesos.hash_animal = gestor_animais.hash
            LEFT JOIN gestor_pesagens ultima_pesagem
                ON ultima_pesagem.hash_animal = datas_pesos.hash_animal AND ultima_pesagem.data_pesagem = datas_pesos.data_pesagem
        WHERE gestor_animais.status = 1
        AND gestor_animais.id_propriedade = {{idPropriedade}}
        AND gestor_animais.hash_lote <> ''
        AND gestor_animais.hash_lote IS NOT NULL
        AND gestor_animais.situacao = 'vivo'
        GROUP BY gestor_areas.hash
      )

      SELECT gestor_areas.*,
      (
        SELECT COUNT(*) FROM gestor_areas_piquetes WHERE gestor_areas_piquetes.status = 1 AND gestor_areas_piquetes.hash_area = gestor_areas.hash
      ) as extra_piquetes_qtd,
      piquete_ativo.nome as extra_piquete_ativo,
      gestor_areas.created_at as data_cadastro,
      GROUP_CONCAT(gestor_lotes.nome, ', ') as lotes_nomes,
      SUM(CASE WHEN gestor_lotes.id IS NOT NULL THEN 1 ELSE 0 END) as lotes_qtd,
      animais_por_area.qtd as qtd_animais,
      animais_por_area.peso_total as total_peso,
      animais_por_area.peso_total / 450 AS ua,
      (100 / (gestor_areas.max_lotacao * 450)) * animais_por_area.peso_total AS tx_ocupacao,
      animais_por_area.peso_total / 450 / gestor_areas.tamanho AS tx_lotacao,
      meta_tipo_capim.valor as meta_tipo_capim,
      meta_tipo_area.valor as meta_tipo_area,
      meta_altura_entrada_capim.valor as meta_altura_entrada_capim,
      meta_altura_saida_capim.valor as meta_altura_saida_capim
      FROM gestor_areas
        LEFT JOIN animais_por_area ON animais_por_area.hash_area = gestor_areas.hash
        LEFT JOIN gestor_lotes ON gestor_lotes.hash_area = gestor_areas.hash AND gestor_lotes.status = 1
        LEFT JOIN gestor_areas_metas meta_piquete_ativo ON meta_piquete_ativo.chave = 'pastejo-rotacionado' AND meta_piquete_ativo.status = 1
          AND meta_piquete_ativo.hash_area = gestor_areas.hash
          AND meta_piquete_ativo.id_propriedade = {{idPropriedade}}
        LEFT JOIN gestor_areas_piquetes as piquete_ativo ON piquete_ativo.status = 1 AND piquete_ativo.hash = meta_piquete_ativo.valor
        LEFT JOIN gestor_areas_metas meta_tipo_capim ON meta_tipo_capim.chave = 'tipo_capim' AND meta_tipo_capim.status = 1
          AND meta_tipo_capim.hash_area = gestor_areas.hash
          AND meta_tipo_capim.id_propriedade = {{idPropriedade}}
        LEFT JOIN gestor_areas_metas meta_tipo_area ON meta_tipo_area.chave = 'tipo_area' AND meta_tipo_area.status = 1
          AND meta_tipo_area.hash_area = gestor_areas.hash
          AND meta_tipo_area.id_propriedade = {{idPropriedade}}
        LEFT JOIN gestor_areas_metas meta_altura_entrada_capim ON meta_altura_entrada_capim.chave = 'altura_entrada_capim' AND meta_altura_entrada_capim.status = 1
          AND meta_altura_entrada_capim.hash_area = gestor_areas.hash
          AND meta_altura_entrada_capim.id_propriedade = {{idPropriedade}}
        LEFT JOIN gestor_areas_metas meta_altura_saida_capim ON meta_altura_saida_capim.chave = 'altura_saida_capim' AND meta_altura_saida_capim.status = 1
          AND meta_altura_saida_capim.hash_area = gestor_areas.hash
          AND meta_altura_saida_capim.id_propriedade = {{idPropriedade}}

      WHERE gestor_areas.status = 1
      AND gestor_areas.id_propriedade = {{idPropriedade}}

      ${termo ? `AND ( gestor_areas.nome LIKE '%${termo}%' )` : ``}
      ${hashs && hashs.length ? `AND ( gestor_areas.hash IN ("${hashs.join('","')}") )` : ``}
      ${hashNotIn && hashNotIn.length ? `AND ( gestor_areas.hash NOT IN ("${hashNotIn.join('","')}") )` : ``}

      ${safeSelectIn({ coluna: 'gestor_areas.hash', tag: '{{hash}}', valor: filtros?.hash })}
      ${safeSelectIn({ coluna: 'gestor_areas.hash', tag: '{{hashs}}', valor: filtros?.hashs })}
      ${safeLike({ coluna: 'gestor_areas.sync', tag: '{{sync}}', valor: filtros?.sync })}
      ${safeLike({ coluna: 'gestor_areas.nome', tag: '{{nome}}', valor: filtros?.nome })}
      ${safeLike({ coluna: 'lotes_nomes', tag: '{{lotes_nomes}}', valor: filtros?.lotes_nomes })}
      ${safeLike({ coluna: 'piquete_ativo', tag: '{{piquete_ativo}}', valor: filtros?.extra_piquete_ativo })}
      ${safeLike({ coluna: 'gestor_areas.observacao', tag: '{{observacao}}', valor: filtros?.observacao })}
      ${safeSelectIn({ coluna: 'meta_tipo_capim.valor', tag: '{{meta_tipo_capim}}', valor: filtros?.meta_tipo_capim })}
      ${safeSelectIn({ coluna: 'meta_tipo_area.valor', tag: '{{meta_tipo_area}}', valor: filtros?.meta_tipo_area })}
      ${safeNumberRange({ coluna: 'meta_altura_entrada_capim.valor', tag: '{{meta_altura_entrada_capim}}', valor: filtros?.meta_altura_entrada_capim })}
      ${safeNumberRange({ coluna: 'meta_altura_saida_capim.valor', tag: '{{meta_altura_saida_capim}}', valor: filtros?.meta_altura_saida_capim })}
      ${safeNumberRange({ coluna: 'gestor_areas.tamanho', tag: '{{tamanho}}', valor: filtros?.tamanho })}
      ${safeNumberRange({ coluna: 'gestor_areas.max_lotacao', tag: '{{max_lotacao}}', valor: filtros?.max_lotacao })}
      ${safeNumberRange({ coluna: 'qtd_animais', tag: '{{qtd_animais}}', valor: filtros?.qtd_animais })}
      ${safeNumberRange({ coluna: 'total_peso', tag: '{{total_peso}}', valor: filtros?.total_peso })}
      ${safeDateRange({ coluna: 'data_cadastro', tag: '{{data_cadastro}}', valor: filtros?.data_cadastro })}
      ${safeSelectNotIn({
        coluna: 'gestor_areas.hash',
        tag: '{{hash_not_in}}',
        valor: filtros?.hash_not_in
      })}

      GROUP BY gestor_areas.hash

      HAVING true
      ${safeNumberRange({ coluna: 'extra_piquetes_qtd', tag: '{{extra_piquetes_qtd}}', valor: filtros?.extra_piquetes_qtd })}
      ${safeNumberRange({ coluna: 'ua', tag: '{{ua}}', valor: filtros?.ua })}
      ${safeNumberRange({ coluna: 'tx_ocupacao', tag: '{{tx_ocupacao}}', valor: filtros?.tx_ocupacao })}
      ${safeNumberRange({ coluna: 'tx_lotacao', tag: '{{tx_lotacao}}', valor: filtros?.tx_lotacao })}

      ${safeOrderBy({ coluna: ordenacao?.coluna, direcao: ordenacao?.direcao, prefixo: true, tableCase: {
        nome: 'gestor_areas',
        created_at: 'gestor_areas',
        tamanho: 'gestor_areas',
        max_lotacao: 'gestor_areas'
      } })}
  `,
      params: {
        idPropriedade,
        ...(filtros || {}),
        ...(ordenacao || {}),
        ...(paginacao || {}),
      }
    });
  }

  async criarNovaArea(area: ICadastroArea): Promise<ISucesso> {
    try {
      const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;

      const salvarAreaPromise = this.db.safeQuery({
        query: `
          INSERT OR REPLACE INTO gestor_areas (
            hash,
            sync,
            edited,
            id_propriedade,
            nome,
            tamanho,
            max_lotacao,
            observacao,
            coordenadas_centrais,
            area,
            status,
            created_at,
            updated_at
          )
          VALUES (
            {{hash}},
            {{sync}},
            {{edited}},
            {{idPropriedade}},
            {{nome}},
            {{tamanho}},
            {{max_lotacao}},
            {{observacao}},
            {{coordenadas_centrais}},
            {{area}},
            {{status}},
            {{created_at}},
            {{updated_at}}
          )
        `,
        params: {
          hash: area.hash,
          sync: 0,
          edited: 1,
          idPropriedade,
          nome: area.nome,
          tamanho: area.tamanho || null,
          max_lotacao: area.max_lotacao || null,
          observacao: area.observacao || null,
          coordenadas_centrais: area.coordenadas_centrais || null,
          area: area.area || null,
          status: 1,
          created_at: area.created_at || this.db.getFormatedDate(),
          updated_at: this.db.getFormatedDate(),
        }
      })

      await Promise.all([salvarAreaPromise, this.setMetas({ area, idPropriedade })])

      return { success: true }
    } catch (error) {
      console.error('Erro ao criar a área', error)
      return { success: false }
    }
  }

  async setMetas(opcoes?: { area?: ICadastroArea, idPropriedade?: number }): Promise<ISucesso> {
    const whitelistMetas = ['meta_tipo_capim', 'meta_tipo_area', 'meta_altura_entrada_capim', 'meta_altura_saida_capim']
    const { area, idPropriedade } = opcoes

    const insertsAwait = []

    const metaKeys = Object.keys(area || {})
      .filter((key) => key.includes('meta_') && whitelistMetas.includes(key))
      .map((key) => key)

    const metasList = metaKeys.map((chave) => ({
      hash: `${area?.hash}:${chave.replace('meta_', '')}`,
      hash_area: area?.hash || '',
      idPropriedade,
      chave: chave.replace('meta_', '') as IAreaMetaChaves,
      valor: typeof area?.[chave] === 'object' ? JSON.stringify(area[chave]) : area?.[chave] || '',
      status: 1,
      sync: 0,
      created_at: this.db.getFormatedDate(),
      updated_at: this.db.getFormatedDate()
    }))

    if(metasList.length) {
      for(const meta of metasList) {
        insertsAwait.push(
          this.db.safeQuery({
            query: `
              INSERT INTO gestor_areas_metas (
                hash,
                hash_area,
                id_propriedade,
                chave,
                valor,
                status,
                sync,
                created_at,
                updated_at
              ) VALUES (
                {{hash}},
                {{hash_area}},
                {{id_propriedade}},
                {{chave}},
                {{valor}},
                {{status}},
                {{sync}},
                {{created_at}},
                {{updated_at}}
              )
            `,
            params: {
              hash: meta.hash,
              hash_area: meta.hash_area,
              id_propriedade: meta.idPropriedade,
              chave: meta.chave,
              valor: meta.valor,
              status: meta.status,
              sync: meta.sync || 0,
              created_at: meta.created_at,
              updated_at: meta.updated_at
            }
          })
        )
      }
    }

    await Promise.all(insertsAwait)

    return { success: true }
  }

  async getPiquetes(opcoes?: {
    termo?: string
    hashs?: string[]
    hashArea?: string
  }): Promise<IAreaPiquete[]> {
    const { termo, hashs, hashArea } = opcoes || {}
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;

    const {
      safeSelectIn,
      safeLike,
    } = sqlFilter

    return await this.db.safeQuery({
        query: `
          SELECT *
          FROM gestor_areas_piquetes piquetes

          WHERE piquetes.status = 1
            AND piquetes.id_propriedade = {{idPropriedade}}

            ${safeSelectIn({ coluna: 'piquetes.hash_area', tag: '{{hash_area}}', valor: hashArea })}
            ${safeSelectIn({ coluna: 'piquetes.hash', tag: '{{hash}}', valor: hashs })}
            ${safeLike({ coluna: 'piquetes.nome', tag: '{{nome}}', valor: termo })}
        `,
        params: {
          idPropriedade
        }
    })
  }

  async marcarAreasComoSincronizados() {
    try {
      const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id
      await this.db.run({
        query: `
          UPDATE gestor_areas
          SET sync = 1
          WHERE id_propriedade = ${idPropriedade}
          AND sync = 0
        `
      })
    } catch(error) {
      console.error(error);
    }
  }
}
