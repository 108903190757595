import { ManejoSanitarioService } from '../../services/manejo/manejo-sanitario.service'
import { ManejoAnimalModel, ProcedimentoObjeto } from '../../utils/types'
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'
import { ActionSheetController } from '@ionic/angular'
import { CheckboxChangeEventDetail } from '@ionic/core'
import { labelsSituacoesSanitarias } from '../../utils'

@Component({
  selector: 'manejo-sanitario',
  templateUrl: './manejo-sanitario.component.html',
  styleUrls: ['./manejo-sanitario.component.scss']
})
export class ManejoSanitarioComponent {
  @Input() manejo: { [key: string]: { procedimento_sanitario_realizado?: boolean } } = {}
  @Output() manejoChange = new EventEmitter<any>()
  @Output() opcoes = new EventEmitter<any>()

  @Input() procedimentos: ProcedimentoObjeto[]
  @Output() procedimentosChange: EventEmitter<ProcedimentoObjeto[]> = new EventEmitter<ProcedimentoObjeto[]>()
  @Output() adicionarManejoSanitario = new EventEmitter()
  labelsSituacoesSanitarias = labelsSituacoesSanitarias

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private manejoSanitarioCtrl: ManejoSanitarioService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  selecionarProcedimento(hash: string, event: { detail: CheckboxChangeEventDetail }) {
    this.manejo[hash] = this.manejo[hash] || {}
    this.manejo[hash].procedimento_sanitario_realizado = event.detail.checked
    this.emit()
  }

  adicionarManejo() {
    this.adicionarManejoSanitario.emit()
  }

  async abrirOpcoesDoManejo() {
    this.opcoes.emit()
  }

  async abrirOpcoesDoProcedimento(manejoSelecionado: ManejoAnimalModel) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Opções do Manejo',
      buttons: [
        {
          text: 'Remover Procedimento de todos os animais',
          role: 'remover',
          icon: 'trash',
          handler: () => {
            this.procedimentos = this.procedimentos.filter(
              (procedimento) => procedimento.hash !== manejoSelecionado.hash
            )
            this.procedimentosChange.emit(this.procedimentos)
            this.emit()
            // this.removerProcedimento.emit(manejoSelecionado);
          }
        },
        {
          text: 'Editar Descrição',
          icon: 'create',
          handler: () => {
            this.manejoSanitarioCtrl
              .preencherDescricaoDeManejoSanitario()
              .then(({ descricao: descricaoAtualizado }) => {
                this.procedimentos.find((procedimento) => procedimento.hash == manejoSelecionado.hash).descricao =
                  descricaoAtualizado
                this.procedimentosChange.emit(this.procedimentos)
              })
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          icon: 'close'
        }
      ]
    })
    await actionSheet.present()
  }

  detectChanges() {
    this.cd.detectChanges()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
