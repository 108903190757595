export const LabelsEstoqueNutricionalTipo = {
  'aditivos': 'Aditivos',
  'minerais': 'Minerais',
  'nucleo': 'Núcleo',
  'proteinado': 'Proteinado',
  'proteicos-energetico': 'Proteico Energético',
  'racao': 'Ração',
  'sal-mineral': 'Sal Mineral',
  'vitaminas': 'Vitaminas',
  'volumosos': 'Volumosos',
}