import { EstoqueNutricionalPage } from '../../pages/manejos/estoque-nutricional/estoque-nutricional.page'
import { ManejoAnimalModel } from '../../utils/types'
import { IEstoqueNutricionalItemsQuery } from '../../utils/types/manejo-nutricional-types'
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { ActionSheetController, ModalController } from '@ionic/angular'
import { LabelsEstoqueNutricionalTipo } from './nutricional-labels'

@Component({
  selector: 'manejo-nutricional',
  templateUrl: './manejo-nutricional.component.html',
  styleUrls: ['./manejo-nutricional.component.scss'],
})
export class ManejoNutricionalComponent implements OnInit {
  @Input() manejo: { [key: string]: { nutricional_quantidade?: number;  nutricional_duracao?: number } } = {};
  @Output() manejoChange = new EventEmitter<any>();

  @Input() itensEstoque: { nutricional_hash_estoque: string; nome: string; categoria: string; }[]
  @Output() itensEstoqueChange = new EventEmitter<{ nutricional_hash_estoque: string; nome: string; categoria: string }[]>()
  
  @Output() opcoes = new EventEmitter()
  @Output() removerEstoque = new EventEmitter<ManejoAnimalModel>()

  labelsEstoqueNutricionalTipo = LabelsEstoqueNutricionalTipo

  constructor(
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
  ) { }

  ngOnInit() {
  }

  async adicionarManejoEmitindoManejosSelecionados() {
    const modal = await this.modalCtrl.create({
      component: EstoqueNutricionalPage,
      componentProps: {
        hash_not_in: this.itensEstoque?.map(item => item?.nutricional_hash_estoque)
      },
      cssClass: 'modal-narrow'
    })

    await modal.present()

    const { data: estoquesSelecionados } = await modal.onDidDismiss<IEstoqueNutricionalItemsQuery[]>()


    if(estoquesSelecionados) {
      estoquesSelecionados.map(estoque => {
        this.itensEstoque = this.itensEstoque || []
        this.itensEstoque.push({
          nutricional_hash_estoque: estoque?.hash,
          nome: estoque?.titulo,
          categoria: estoque?.meta_tipoDoEstoque
        })
      })

      this.itensEstoqueChange.emit(this.itensEstoque)
      this.emit()
    }
  }

  fecharTeclado(event: KeyboardEvent) {
    let element = event.target as HTMLInputElement;
    element.blur();
  }

  async abrirOpcoesDoManejo() {
    this.opcoes.emit()
  }

  async abrirOpcoesDoEstoque(estoqueSelecionado: ManejoAnimalModel) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Opções do Manejo',
      buttons: [
        {
          text: 'Remover estoque de todos os animais',
          role: 'remover',
          icon: 'trash',
          handler: () => {
            this.removerEstoque.emit(estoqueSelecionado)
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          icon: 'close'
        }
      ]
    });
    await actionSheet.present();
  }
  
  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
