import { CadastrarAnimalComponent } from '../../../components';
import { AtalhoModel } from '../../../pages/home/atalho-manejos';
import { Racas } from '../../../utils';
import { FilhotesModel } from '../../../utils/types';
import { AnimalModel, IPayloadNovoAnimal } from '../../../utils/types';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSheetController, AlertController, ModalController, PopoverController } from '@ionic/angular';
import { OpcoesBotoesComponent } from '../../opcoes-botoes/opcoes-botoes.component';
import { ConexoesBluetoothService } from '../../../services/dispositivos-bluetooth/conexoes-bluetooth.service';
import { VastaRX } from '@vasta/rx';
import { AnimaisService } from '../../../services/animais.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'manejo-parto',
  templateUrl: './manejo-parto.component.html',
  styleUrls: ['./manejo-parto.component.scss']
})
export class ManejoPartoComponent implements OnInit {
  @Input('animal') animal: AnimalModel;
  @Input('manejo') manejo: { filhotes?: FilhotesModel[]; observacao?: string; };
  @Output() manejoChange = new EventEmitter();
  @Output() opcoes = new EventEmitter();
  @Input() brincosOutrosManejos: {[key: string]: string} = {}
  @Input() brincosDesteManejo: {[key: string]: string} = {}
 
  public racas = Racas

  isAguardandoLeituraBrinco = ''
  credencialAnimalRx

  constructor(
    public actionSheetCtrl: ActionSheetController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    public conexoesBluetoothSeparadaCtrl: ConexoesBluetoothService,
    public animaisCtrl: AnimaisService,
    public utilsCtrl: UtilsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.manejo) {
      this.manejo = {}
    }

    this.credencialAnimalRx = VastaRX.getState('credencialAnimal', async (valorCredencial) => {
      if (this.isAguardandoLeituraBrinco && valorCredencial && this.manejo.filhotes.length) {
        let brincoJaFoiAssociado = await this.verificarSeBrincoJaEstaSendoUtilizado(valorCredencial)

        if (!brincoJaFoiAssociado && valorCredencial) {
          this.manejo.filhotes.find(filhoteDoManejo => filhoteDoManejo.hash === this.isAguardandoLeituraBrinco).brinco = valorCredencial
          this.isAguardandoLeituraBrinco = '';
          
          this.detectChanges();
          VastaRX.setState('credencialAnimal', null);
    
          this.emit()
        }
      }

      this.cd.detectChanges()
    })
  }

  async verificarSeBrincoJaEstaSendoUtilizado(valorCredencial: string) {
    let brincoJaFoiAssociado = !!this.brincosOutrosManejos?.[valorCredencial] || this.brincosDesteManejo?.[valorCredencial]

    if (brincoJaFoiAssociado) {
      this.utilsCtrl.exibirToast('Atenção, esse brinco já foi associado a outro animal', 2000, 'top')
      return brincoJaFoiAssociado
    }
    
    const animal: AnimalModel[] = await this.animaisCtrl.getAnimalByBrinco(valorCredencial)

    if (animal.length === 1) {
      brincoJaFoiAssociado = true;
    }

    if (brincoJaFoiAssociado) {
      this.utilsCtrl.exibirToast('Atenção, esse brinco já foi associado a outro animal', 2000, 'top')
    }

    return brincoJaFoiAssociado;
  }

  ngOnDestroy() {
    VastaRX.unListen(this.credencialAnimalRx)
  }

  async openOpcoesCria(filhote: IPayloadNovoAnimal, hash: string) {
    const buttons = [
      {
        text: 'Remover',
        icon: 'trash',
        handler: () => {
          this.removerCria(hash)
        }
      },
      {
        text: 'Cancelar',
        role: 'cancel',
        icon: 'close'
      }
    ]

    if (hash !== 'natimorto') {
      buttons.unshift({
        text: 'Editar',
        icon: 'create',
        handler: () => {
          this.editarCria(filhote)
        }
      })
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Opções do Filhote',
      buttons
    });
    await actionSheet.present();
  }

  async removerCria(hashCria: string) {
    const alert = await this.alertCtrl.create({
      header: 'Remover Cria',
      message: 'Tem certeza que deseja remover esta cria?',
      backdropDismiss: false,
      cssClass:'alert-button-group',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Continuar',
          handler: () => {
            this.manejo.filhotes = this.manejo.filhotes.filter(filhoteDoManejo => filhoteDoManejo.hash !== hashCria)
            this.emit()
          }
        }
      ]
    })
    await alert.present()
  }



  async selecionarTipoTransacao(ev: Event): Promise<string> {
    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: {
        options: [
          {
            icone: 'add',
            chave: 'cadastrar',
            titulo: 'Cadastrar Cria',
            cor: 'primary'
          },
          {
            icone: 'remove',
            chave: 'natimorto',
            titulo: 'Natimorto',
            cor: 'vermelho'
          },
        ]
      },
      event: ev,
      reference: 'trigger'
    })

    await popover.present()

    const { data } = await popover.onWillDismiss()
    if (data) {
      console.log(data)

      return data?.chave
    }
  }

  async adicionarFilhote(ev: Event) {
    const tipo = await this.selecionarTipoTransacao(ev)
    if (tipo) {
      if (tipo === 'natimorto') {
        this.manejo.filhotes = this.manejo.filhotes || []
        this.manejo.filhotes.push({
          hash: 'natimorto'
        })
    
        this.emit()
      } else {
        const modal = await this.modalCtrl.create({
          component: CadastrarAnimalComponent,
          cssClass: 'modal-narrow',
          componentProps: {
            propsManejo: {
              tipoManejo: 'parto',
              nome: 'Parto',
              animal: this.animal
            } as AtalhoModel
          }
        });
    
        await modal.present();
    
        const { data } = await modal.onDidDismiss();
        if (data) {
          console.log('data: ', data);
          
          this.manejo.filhotes = this.manejo.filhotes || []
    
          this.manejo.filhotes.push({
            hash: data.hash,
            brinco: data.brinco,
            dadosAnimal: data,
          })
    
          this.emit()
        }
      }
    }
  }

  async editarCria(cria: IPayloadNovoAnimal) {
    const brinco = `${cria.brinco || ''}`
    const modal = await this.modalCtrl.create({
      component: CadastrarAnimalComponent,
      cssClass: 'modal-narrow',
      componentProps: {
        propsManejo: {
          tipoManejo: 'parto',
          nome: 'Parto',
          animal: this.animal,
          filhote: cria,
        }
      }
    });

    await modal.present();

    const { data: criaEditada } = await modal.onDidDismiss();
    if (criaEditada) {
      const index = this.manejo.filhotes.findIndex(filhoteDoManejo => filhoteDoManejo.hash === criaEditada.hash);
      this.manejo.filhotes[index] = {
        hash: criaEditada.hash,
        brinco,
        dadosAnimal: criaEditada
      };
      
      this.emit()
    }
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  async limparManejoParto() {
    const alert = await this.alertCtrl.create({
      header: 'Limpar manejo',
      message: 'Essa operação irá remover todos os filhotes, deseja continuar?',
      backdropDismiss: false,
      cssClass:'alert-button-group',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Continuar',
          handler: () => {
            this.manejo.filhotes = [];
            this.emit()
          }
        }
      ]
    })
    await alert.present()
  }

  fecharTeclado(event: KeyboardEvent) {
    let element = event.target as HTMLInputElement
    element.blur()
  }

  emit() {
    this.detectChanges()
    this.manejoChange.emit(this.manejo);
  }

  detectChanges() {
    this.cd.detectChanges()
  }

  async atribuirBrinco(cria: FilhotesModel): Promise<void> {
    const { hash } = cria

    if (cria.brinco) {
      const alert = await this.alertCtrl.create({
        header: 'Remover Brinco',
        message: 'Deseja remover o brinco?',
        backdropDismiss: false,
        cssClass:'alert-button-group',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: 'Continuar',
            handler: () => {
              cria.brinco = ''
              this.emit()
            }
          }
        ]
      })
      return alert.present()
    }

    if (this.isAguardandoLeituraBrinco == hash) {
      this.isAguardandoLeituraBrinco = ''
    } else {
      this.isAguardandoLeituraBrinco = hash
    }
  }
}
