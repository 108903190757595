const tabelasSync = [
    "gestor_animais",
    "gestor_animal_metas",
    "gestor_lotes",
    "gestor_areas",
    "gestor_areas_piquetes",
    "gestor_areas_metas",
    "gestor_pesagens",
    "gestor_leite",
    "gestor_genealogia",
    "gestor_reprodutores_externos",
    "gestor_propriedades_metas",
    "gestor_historico",
    "gestor_relacao_tripla",
    "gestor_procedimento",
    "gestor_manejo",
    "gestor_agenda",
    "gestor_financeiro_contas",
    "gestor_financeiro_transacoes",
    "gestor_categorias",
    "gestor_controle_pluviometrico",
    "gestor_estoque",
    "gestor_estoque_metas",
    "gestor_estoque_lancamento",
    "gestor_estoque_lancamento_formulacao",
    "gestor_manejo_geral_unitario",
    "gestor_manejo_reprodutivo_unitario",
    "gestor_manejo_sanitario_unitario",
    "gestor_manejo_sanitario_animais_e_lotes",
    "gestor_manejo_lote",
    "gestor_manejo_area",
    "gestor_manejo_nutricional"
]
let inicio = [
    'hash',
    'sync',
    'edited',
    'id_propriedade'
]

let fim = [
    'status',
    'created_at',
    'updated_at'
]
const tabelas = {
    gestor_animais: inicio.concat([
        'hash_lote',
        'data_manejo_lote',
        'nome',
        'data_nascimento',
        'situacao',
        'situacao_reprodutiva',
        'situacao_sanitaria',
        'situacao_produtiva',
        'raca',
        'numeracao',
        'sexo',
        'profile',
        'tipo',
        'id_produto',
        'observacao',
        'tipo_entrada',
        'aptidao',
    ], fim),
    gestor_agenda: inicio.concat([
        'titulo',
        'hora',
        'data',
        'observacao',
        'situacao',
        'tipo',
        'tipo_repeticao',
    ], fim),
    gestor_animal_metas: inicio.concat([
        'hash_animal',
        'chave',
        'valor'
    ], fim),
    gestor_areas: inicio.concat([
        'nome',
        'tamanho',
        'max_lotacao',
        'observacao',
        'coordenadas_centrais',
        'area',
        'status'
    ], fim),
    gestor_areas_piquetes: inicio.concat([
        'hash_area',
        'nome',
        'tamanho',
        'max_lotacao',
        'observacao',
        'coordenadas_centrais',
        'area'
    ], fim),
    gestor_areas_metas: inicio.concat([
        'hash_area',
        'chave',
        'valor',
    ], fim),
    gestor_lotes: inicio.concat([
        'especie',
        'data_entrada_area',
        'gmd',
        'producao_leiteira',
        'hash_area',
        'nome',
        'sistema_criacao',
        'aptidao',
        'finalidade'
    ], fim),
    gestor_pesagens: inicio.concat([
        'hash_pesagem_pai',
        'especie',
        'identificador',
        'balanca',
        'hash_lote',
        'gmd',
        'hash_animal',
        'peso',
        'data_pesagem',
        'observacao'
    ], fim),
    gestor_controle_pluviometrico: inicio.concat([
        'indice',
        'data',
        'hora',
        'observacao',
    ], fim),
    gestor_genealogia: inicio.concat([
        'hash_animal',
        'hash_mae',
        'hash_pai',
        'hash_reprodutor_pai',
        'hash_reprodutor_mae',
        'cache',
    ], fim),
    gestor_reprodutores_externos: inicio.concat([
        'nome',
        'reprodutor',
        'matriz',
        'sexo',
        'descricao',
        'tipo'
    ], fim),
    gestor_leite: inicio.concat([
        'hash_animal',
        'data',
        'quantidade',
        'primeira_ordenha',
        'segunda_ordenha',
        'terceira_ordenha',
        'observacao'
    ], fim),
    gestor_propriedades_metas: inicio.concat([
        'chave',
        'valor'
    ], fim),
    gestor_historico: inicio.concat([
        'hash_item',
        'acao',
        'hash_local',
        'id_usuario',
        'data',
        'dados',
    ], fim),
    gestor_relacao_tripla: inicio.concat([
        'contexto',
        'de',
        'para',
        'hash_contexto',
        'hash_de',
        'hash_para',
        'observacao'
    ], fim),
    gestor_procedimento: inicio.concat([
        'tipo',
        'identificador',
        'especificacao'
    ], fim),
    gestor_manejo: inicio.concat([
        'tipo',
        'hash_lote',
        'observacao',
        'data'
    ], fim),
    gestor_financeiro_contas: inicio.concat([
        'titulo',
        'tipo',
        'agencia',
        'conta',
        'is_padrao',
        'descricao'
    ], fim),
    gestor_financeiro_transacoes: inicio.concat([
        'id_usuario',
        'titulo',
        'descricao',
        'tipo',
        'valor',
        'hash_conta',
        'hash_conta_transferencia',
        'tipo_repeticao',
        'data',
        'realizado',
    ], fim),
    gestor_categorias: inicio.concat([
        'modulo',
        'nome',
        'slug',
        'descricao',
        'categoria_pai',
    ], fim),
    gestor_estoque: inicio.concat([
        'titulo',
        'descricao',
        'tipo',
    ], fim),
    gestor_estoque_metas: inicio.concat([
        'hash_estoque',
        'chave',
        'valor',
    ], fim),
    gestor_estoque_lancamento: inicio.concat([
        'hash_estoque',
        'data_lancamento',
        'valor_medio_momentaneo',
        'movimentacao',
        'tipo',
        'qtd',
        'valor',
        'obs',
        'hash_financeiro',
    ], fim),
    gestor_estoque_lancamento_formulacao: inicio.concat([
        'hash_lancamento',
        'hash_estoque',
        'hash_item_nutricional',
        'percentual',
        'quantidade',
        'data_lancamento',
    ], fim),
    gestor_manejo_geral_unitario: inicio.concat([
        'brinco_eletronico',
        'hash_animal',
        'procedimento',
        'data',
        'hash_confinamento',
        'hash_lote',
        'manejo_original',
        'morte_causa',
        'morte_imagem_1',
        'morte_imagem_2',
        'morte_imagem_3',
        'morte_imagem_4',
        'morte_imagem_5',
        'morte_latitude',
        'morte_longitude',
        'novo_status',
        'numero_novo',
        'observacao',
        'origem',
        'perda_observacoes',
        'responsavel_tecnico',
        'sem_lote',
        'morte_observacoes',
        'transf_propriedade_id',
        'transf_propriedade_novo_hash',
        'venda_hash_transacao'
    ], fim),
    gestor_manejo_reprodutivo_unitario: inicio.concat([
        'aborto_detalhes',
        'data',
        'castracao_detalhes',
        'cio_resultado',
        'cobertura_hash_reprodutor',
        'cobertura_hash_reprodutor_externo',
        'desmame_detalhes',
        'dg_hash_exposicao',
        'dg_periodo_gestacional',
        'dg_previsao_de_parto',
        'dg_resultado',
        'dg_ovario_direito',
        'dg_ovario_esquerdo',
        'dg_tonus_uterino',
        'observacao',
        'escore_corporal',
        'hash',
        'origem',
        'hash_animal',
        'implante_aplica',
        'implante_remove',
        'inseminacao_embriao',
        'inseminacao_ovulo',
        'inseminacao_semen',
        'manejo_original',
        'novo_status',
        'parto_hash_cria1',
        'parto_hash_cria2',
        'parto_hash_cria3',
        'parto_intervalo',
        'procedimento',
        'responsavel_tecnico'
    ], fim),
    gestor_manejo_sanitario_unitario: inicio.concat([
        'data',
        'descricao',
        'id_propriedade',
        'novo_status',
        'hash_animal',
        'hash_lote',
        'hash_area',
        'sanitario_hash_estoque',
        'sanitario_quantidade',
        'hash_procedimento',
        'manejo_original',
        'origem',
        'procedimento',
        'responsavel_tecnico',
        'observacao',
    ], fim),
    gestor_manejo_sanitario_animais_e_lotes: inicio.concat([
        'data',
        'hash_manejo',
        'hash_animal',
        'hash_lote',
        'hash_area',
        'animais_no_manejo',
    ], fim),
    gestor_manejo_lote: inicio.concat([
        'data',
        'hash',
        'hash_area_destino',
        'hash_lote',
        'id_propriedade',
        'novo_status',
        'observacao',
        'origem',
        'procedimento',
        'responsavel_tecnico',
        'sem_area',
    ], fim),
    gestor_manejo_area: inicio.concat([
        'hash',
        'hash_area',
        'chuva_hora',
        'data',
        'id_propriedade',
        'chuva_quantidade',
        'manejo_original',
        'movimentacao_hash_lote',
        'novo_status',
        'observacao',
        'origem',
        'procedimento',
        'responsavel_tecnico',
    ], fim),
    gestor_manejo_nutricional: inicio.concat([
        'hash',
        'data',
        'hash_animal',
        'hash_area',
        'id_propriedade',
        'hash_lote',
        'manejo_original',
        'novo_status',
        'nutricional_duracao',
        'nutricional_hash_estoque',
        'nutricional_quantidade',
        'observacao',
        'origem',
        'responsavel_tecnico'
    ], fim)
}

export {
    tabelas, tabelasSync
}