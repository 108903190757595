import { LoteModel, ManejoAnimalModel } from '../../../utils/types'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { SeletorLoteSingleComponent } from '../../seletor-lote-single/seletor-lote-single.component'

@Component({
  selector: 'manejo-movimentacao-confinamento',
  templateUrl: './manejo-movimentacao-confinamento.component.html',
  styleUrls: ['./manejo-movimentacao-confinamento.component.scss']
})
export class ManejoMovimentacaoConfinamentoComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()
  @Input() opcoesRapidasConfinamentos: LoteModel[]
  @Output() opcoesRapidasConfinamentosChange = new EventEmitter()
  @Input() loteAtual: string

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  async openModalListaLote() {
    const modal = await this.modalCtrl.create({
      component: SeletorLoteSingleComponent,
      componentProps: {
        titulo: 'Selecionar Lote',
        isConfinamento: true
      },
      cssClass: 'modal-narrow'
    })

    await modal.present()
    const { data: lote } = await modal.onDidDismiss()
    if (lote) {
      this.selecionarLote(lote)
    }
  }

  selecionarLote(confinamento: LoteModel) {
    this.manejo.hash_lote_destino = confinamento.hash
    this.manejo.nome_lote_destino = confinamento.nome
    this.processarOpcoesRapidasDeConfinamentos(confinamento)
    this.emit()
  }

  limparManejo() {
    this.manejo.hash_lote_destino = null
    this.manejo.nome_lote_destino = null
    this.emit()
  }

  processarOpcoesRapidasDeConfinamentos(confinamento: LoteModel) {
    if (this.opcoesRapidasConfinamentos?.length) {
      const loteJaExiste = this.opcoesRapidasConfinamentos.find((l) => l.hash === confinamento.hash)
      if (!loteJaExiste) {
        this.opcoesRapidasConfinamentos.push(confinamento)
      }
    } else {
      this.opcoesRapidasConfinamentos = [confinamento]
    }
  }

  async abrirOpcoesDeManejo() {
    this.opcoes.emit()
  }

  emit() {
    this.opcoesRapidasConfinamentosChange.emit(this.opcoesRapidasConfinamentos)
    this.manejoChange.emit(this.manejo)
  }
}
