import { TipoBluetooth } from "../../services/bluetooth/interfaces/bluetooth-interface"
import { PickUnionType } from "../../utils/types"
import { LeitorAllFlexAWR250Service } from "../../services/dispositivos-bluetooth/leitores/leitor-le-allflex-awr250.service"
import { LeitorAllFlexRS420Service } from "../../services/dispositivos-bluetooth/leitores/leitor-cl-allflex-rs420.service"
import { LeitorAcoresSmartReaderService } from "../../services/dispositivos-bluetooth/leitores/leitor-cl-acores-smartreader.service"
import { LeitorTruTestSRS2Service } from "../../services/dispositivos-bluetooth/leitores/leitor-cl-trutest-srs2.service"
import { LeitorTruTestXRS2Service } from "../../services/dispositivos-bluetooth/leitores/leitor-cl-trutest-xrs2.service"
import { LeitorSigmaciaBT05Service } from "../../services/dispositivos-bluetooth/leitores/leitor-cl-sigmacia-bt05.service"
import { LeitorAllTagsAT05Service } from "../../services/dispositivos-bluetooth/leitores/leitor-cl-alltags-at05.service"
import { BalancaIcv3Service } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-sigmacia-icv3.service"
import { BalancaTruTestId5000Service } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-trutest-id5000.service"
import { BalancaTruTestJR5000Service } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-trutest-jr5000.service"
import { BalancaTruTestXR5000Service } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-trutest-xr5000.service"
import { BalancaTruTestS2Service } from "../../services/dispositivos-bluetooth/balancas/balanca-le-trutest-s2.service"
import { BalancaSigmaciaIcvService } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-sigmacia-icv.service.service"
import { BalancaGallagherW0Service } from "../../services/dispositivos-bluetooth/balancas/balanca-le-gallagher-w-0.service"
import { BalancaAcoresBPB085ioTService } from "../../services/dispositivos-bluetooth/balancas/balanca-le-acores-bpb085-iot.service"
import { BalancaAcoresHeavyDutyService } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-acores-heavy-duty.service"
import { BalancaPrixMGR4000CampoService } from "../../services/dispositivos-bluetooth/balancas/balanca-le-prix-mgr4000campo.service"
import { BalancaTruTestS3Service } from "../../services/dispositivos-bluetooth/balancas/balanca-le-trutest-s3.service"
import { BalancaPrixMGRCampoService } from "../../services/dispositivos-bluetooth/balancas/balanca-le-prix-mgrcampo.service"
import { BalancaTruTestEW7IService } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-trutest-ew7i.service"
import { BalancaCoimmaKM3PlusService } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-coimma-km3plus.service"
import { BalancaValfranVFPlusService } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-valfran-vf-plus.service"
import { LeitorTruTestXRSService } from "../../services/dispositivos-bluetooth/leitores/leitor-cl-trutest-xrs.service"
import { BalancaAcoresHDEasyService } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-acores-hd-easy.service"
import { BalancaLEPesentiPese2090Service } from "../../services/dispositivos-bluetooth/balancas/balanca-le-pesenti-pese-2090.service"
import { BalancaAnimallTagAT55 } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-animalltag-at55.service"
import { BalancaAnimallTagAT60 } from "../../services/dispositivos-bluetooth/balancas/balanca-cl-animalltag-at60.service"

/**
 * Type utilizado para obter valores de objetos (semelhante ao keyof, que é utilizado para obter chaves de objetos)
 */
type ValueOf<T> = T[keyof T]
type valoresDoObjeto<T> = ValueOf<T> 

/**
 * Type utilizado para garantir que a lista de dispositivos esteja com os valores corretos.
 * As balanças vão ter os mesmos valores da lista de balanças. 
 * Os leitores vão ter os mesmos valores da lista de leitores
 */
type ValorNomeDispositivos = {
  balancas: {
    [key in keyof BalancasLista]: BalancasLista[key]
  },
  leitores: {
    [key in keyof LeitoresLista]: LeitoresLista[key]
  }
}

/**
 * Type de seleção de dispositivos.
 * O generics "T" é um parâmetro onde será passado um outro type (type de balança ou type de leitor)
 */
type SelecionarDispositivoModel<T> = {
  nome: string, 
  value: valoresDoObjeto<T>, 
  marca: ListaMarcasDispositivos,
  tipoBluetooth: 'serial' | 'lowenergy',
}

// IDENTIFICADORS/PREFIXO EXTRAS DOS DISPOSITIVOS
type IdentificadoresExtras = 'AT05' | 'SIGMACIA_ICV'

/**
 * Type com os valores de todos os dispositivos, em formato de "union type" ex: 'type1' | 'type2' | 'type3'
 */
type TodosDisposivos = valoresDoObjeto<BalancasLista> | valoresDoObjeto<LeitoresLista> | IdentificadoresExtras

type GuiaPassoAPasso = {
  titulo: string,
  guia: string
}

type Dispositivo = {
  nome: string,
  value: string, 
  marca: string, 
  tipoBluetooth: TipoBluetooth
}

/**
 * Type de dispositivo. 
 * a propriedade value será apenas uma string, senão, a propriedade value será do tipo "DispositivosComServicesSeparados"
 */
export type DispositivoModel = Dispositivo & {
  value: DispositivosComServicesSeparados
}

/* INSERIR AQUI NOVAS MARCAS */
type ListaMarcasDispositivos =
  | 'ALLFLEX' 
  | 'TRU-TEST' 
  | 'AÇÔRES' 
  | 'VALFRAN' 
  | 'Prix da Toledo Brasil' 
  | 'ALLTAGS' 
  | 'COIMMA' 
  | 'SIGMACIA'
  | 'AMÓS'
  | 'GALLAGHER'
  | 'Pesenti'
  | 'AnimallTAG'

/* INSERIR AQUI NOVAS BALANÇAS */
export type BalancasLista = {
  AT55: 'AT55',
  AT60: 'AT60',
  S3: 'S3 ',
  S2: 'S2 ',
  W0: 'W-0-',
  EziWeigh7i: 'EziWeigh',
  HDEasy: 'ACR_HD_EASY',
  HeavyDuty: 'ACR_HD_',
  BPB085IOT: 'BPB085',
  VFPlus: 'VALFRAN',
  MGR4000Campo: 'MGR',
  MGRCampo: 'CSB',
  KM3PLUS: 'KM3-PLUS',
  ID5000: 'ID5000',
  XR5000: 'XR5000',
  JR5000: 'JR5000',
  ICV03: 'BTBOV3000'
  SIGMACIAICV: 'BTBOV3000',
  PESE2090: 'Pesenti BLE'
}

/* INSERIR AQUI NOVOS LEITORES */
export type LeitoresLista = {
  RS420: 'RS420_',
  XRS: 'XRS',
  XRS2: 'XRS2',
  SMARTREADER: 'SmartReader ',
  AT05: 'ALLTAGS',
  AWR250: 'AWR250_',
  SRS2: 'SRS2',
  BT05: 'BT-05 ',
}

const ListaMarcasDispositivos: (ListaMarcasDispositivos)[] = [
  'ALLFLEX',
  'TRU-TEST',
  'AÇÔRES',
  'VALFRAN',
  'Prix da Toledo Brasil',
  'ALLTAGS',
  'COIMMA',
  'SIGMACIA',
  'AMÓS'
]

const ValorNomeDispositivos: ValorNomeDispositivos = {
  balancas: {
    AT55: 'AT55',
    AT60: 'AT60',
    S3: 'S3 ',
    S2: 'S2 ',
    W0: 'W-0-',
    EziWeigh7i: 'EziWeigh',
    HDEasy: 'ACR_HD_EASY',
    HeavyDuty: 'ACR_HD_',
    BPB085IOT: 'BPB085',
    VFPlus: 'VALFRAN',
    MGR4000Campo: 'MGR',
    MGRCampo: 'CSB',
    KM3PLUS: 'KM3-PLUS',
    ID5000: 'ID5000',
    XR5000: "XR5000",
    JR5000: 'JR5000',
    ICV03: 'BTBOV3000',
    PESE2090: 'Pesenti BLE',
    SIGMACIAICV: 'BTBOV3000'
  },
  leitores: {
    RS420: 'RS420_',
    XRS: 'XRS',
    XRS2: 'XRS2',
    SMARTREADER: 'SmartReader ',
    AT05: 'ALLTAGS',
    AWR250: 'AWR250_',
    SRS2: 'SRS2',
    BT05: 'BT-05 ',
  }
}

/**
 * Dispositivos que possuem service de conexão bluetooth separada
 */
export type DispositivosComServicesSeparados = PickUnionType<
  TodosDisposivos,
  | 'RS420_' 
  | 'ID5000' 
  | 'XR5000' 
  | 'JR5000' 
  | 'BTBOV3000' 
  | 'S2 ' 
  | 'SIGMACIA_ICV' 
  | 'AWR250_' 
  | 'W-0-' 
  | 'BPB085' 
  | 'ACR_HD_' 
  | 'ACR_HD_EASY' 
  | 'S3 ' 
  | 'MGR' 
  | 'AT55'
  | 'AT60'
  | 'CSB' 
  | 'EziWeigh' 
  | 'VALFRAN' 
  | 'KM3-PLUS' 
  | 'SmartReader ' 
  | 'XRS' 
  | 'SRS2' 
  | 'XRS2' 
  | 'BT-05 ' 
  | 'ALLTAGS' 
  | 'Pesenti BLE'
>

// Services de conexão separada 
type ServicesConexaoSeparada = 
  | LeitorAllFlexRS420Service 
  | LeitorAllFlexAWR250Service
  | LeitorAcoresSmartReaderService
  | LeitorTruTestXRSService
  | LeitorTruTestSRS2Service
  | LeitorTruTestXRS2Service
  | LeitorSigmaciaBT05Service
  | LeitorAllTagsAT05Service
  | BalancaTruTestEW7IService
  | BalancaTruTestId5000Service 
  | BalancaTruTestJR5000Service 
  | BalancaTruTestXR5000Service 
  | BalancaIcv3Service 
  | BalancaTruTestS2Service
  | BalancaGallagherW0Service
  | BalancaAcoresBPB085ioTService
  | BalancaAcoresHeavyDutyService
  | BalancaAcoresHDEasyService
  | BalancaAnimallTagAT55
  | BalancaAnimallTagAT60
  | BalancaTruTestS3Service
  | BalancaPrixMGR4000CampoService
  | BalancaPrixMGRCampoService
  | BalancaSigmaciaIcvService
  | BalancaCoimmaKM3PlusService
  | BalancaValfranVFPlusService
  | BalancaLEPesentiPese2090Service

type ServiceMap = {
  [key in DispositivosComServicesSeparados]: ServicesConexaoSeparada
}

export type ServicoPorNomeDispositivo = {
  [key in DispositivosComServicesSeparados]?: ServiceMap[key]
}

/* INSERIR AQUI NOVAS BALANÇAS */
const Balancas: SelecionarDispositivoModel<BalancasLista>[] = [
  { nome: 'S3', value: 'S3 ', marca: 'TRU-TEST', tipoBluetooth: 'lowenergy' },
  { nome: 'S2', value: 'S2 ', marca: 'TRU-TEST', tipoBluetooth: 'lowenergy' },
  { nome: 'W0', value: 'W-0-', marca: 'GALLAGHER', tipoBluetooth: 'lowenergy' },
  { nome: 'EziWeigh 7i', value: 'EziWeigh', marca: 'TRU-TEST', tipoBluetooth: 'serial' },
  { nome: 'HeavyDuty', value: 'ACR_HD_', marca: 'AÇÔRES', tipoBluetooth: 'serial' },
  { nome: 'HD Easy', value: 'ACR_HD_EASY', marca: 'AÇÔRES', tipoBluetooth: 'serial' },
  { nome: 'BPB085 IOT', value: 'BPB085', marca: 'AÇÔRES', tipoBluetooth: 'lowenergy' },
  { nome: 'VF - Plus', value: 'VALFRAN', marca: 'VALFRAN', tipoBluetooth: 'serial' },
  { nome: 'MGR - 4000 Campo', value: 'MGR', marca: 'Prix da Toledo Brasil', tipoBluetooth: 'lowenergy' },
  { nome: 'MGR - Campo', value: 'CSB', marca: 'Prix da Toledo Brasil', tipoBluetooth: 'lowenergy' },
  { nome: 'KM3 - PLUS', value: 'KM3-PLUS', marca: 'COIMMA', tipoBluetooth: 'serial' },
  { nome: 'ID5000', value: 'ID5000', marca: 'TRU-TEST', tipoBluetooth: 'serial' },
  { nome: 'JR5000', value: 'JR5000', marca: 'TRU-TEST', tipoBluetooth: 'serial' },
  { nome: 'XR5000', value: 'XR5000', marca: 'TRU-TEST', tipoBluetooth: 'serial' },
  { nome: 'ICV', value: 'BTBOV3000', marca: 'SIGMACIA', tipoBluetooth: 'serial' },
  { nome: 'AMÓS', value: 'BTBOV3000', marca: 'AMÓS', tipoBluetooth: 'serial' },
  { nome: 'Pesenti', value: 'Pesenti BLE', marca: 'Pesenti', tipoBluetooth: 'lowenergy' },
  { nome: 'AT55', value: 'AT55', marca: 'AnimallTAG', tipoBluetooth: 'serial' },
  { nome: 'AT60', value: 'AT60', marca: 'AnimallTAG', tipoBluetooth: 'serial' },
]

/* INSERIR AQUI NOVOS LEITORES */
const Leitores: SelecionarDispositivoModel<LeitoresLista>[] = [
  { nome: 'RS420', value: 'RS420_', marca: 'ALLFLEX', tipoBluetooth: 'serial' },
  { nome: 'XRS', value: 'XRS', marca: 'TRU-TEST', tipoBluetooth: 'serial' },
  { nome: 'XRS2 ou XRS2i', value: 'XRS2', marca: 'TRU-TEST', tipoBluetooth: 'serial' },
  { nome: 'SRS2 ou SRS2i', value: 'SRS2', marca: 'TRU-TEST', tipoBluetooth: 'serial' },
  { nome: 'SMART READER', value: 'SmartReader ', marca: 'AÇÔRES', tipoBluetooth: 'serial' },
  { nome: 'BT-05', value: 'BT-05 ', marca: 'SIGMACIA', tipoBluetooth: 'serial' },
  { nome: 'AT05', value: 'ALLTAGS', marca: 'ALLTAGS', tipoBluetooth: 'serial' },
  { nome: 'AWR250', value: 'AWR250_', marca: 'ALLFLEX', tipoBluetooth: 'lowenergy' },
]

const listaTodosDispositivos = (): DispositivoModel[] => {
  const listaDeBalancas = Object.assign(Balancas) as DispositivoModel[]
  const listaDeLeitores = Object.assign(Leitores) as DispositivoModel[]
  const todosDispositivos = listaDeLeitores.concat(listaDeBalancas)
  return todosDispositivos
}

const guiaPassosAWR250 = [
  {
    titulo: '1- Caso o seu bastão esteja configurado em outro idioma, altere para Português',
    guia: 'Setup -> Display -> Set Language -> Português'
  },
  {
    titulo: '2- Com o bastão em mãos, configure o perfil do bluetooth do leitor para "BLE"',
    guia: 'Menu -> Configuração -> Conf. das Comunicações -> Bluetooth -> Definir Perfil BT -> BLE'
  },
  {
    titulo: '3- Depois, você deve definir o modo Bluetooth do bastão para "Slave"',
    guia: 'Menu -> Configuração -> Conf. das Comunicações -> Conf. Balança -> Definir Modo Bluetooth -> Slave'
  },
  {
    titulo: '4- O leitor desliga sozinho depois de um tempo sem uso. Configure o tempo de inatividade para 60 minutos',
    guia: 'Menu -> Monitor -> Conf. Tempo Stand-by -> 60'
  },
  {
    titulo: '5- Configure o formato de saída do bastão para "Short ASCII 15"',
    guia: 'Menu -> Configuração -> Conf. Leitor -> Configurar Modo Online -> Formato de Saída -> Short ASCII 15'
  },
  {
    titulo: '6- Por último, ative a localização do seu dispositivo e certifique-se que o modo avião esteja desativado',
  }
] as GuiaPassoAPasso[]

const guiaPassosKM3Plus = [
  {
    titulo: '1- No Celular',
    guia: 'Vá para a seção de bluetooth'
  },
  {
    titulo: '2- Na Balança',
    guia: 'Ir para menu da balança',
  },
  {
    titulo: '3- Você deve parear seu dispositivo com a balança',
    guia: 'Bluetooth -> Escolha a opção "Device1" ou "Device2" -> Buscar dispositivo -> Selecione o aparelho e clique em "Parear"'
  },
  {
    titulo: '4- Na Balança',
    guia: ' Ir para seção de comunicação',
  },
  {
    titulo: '5- Após isso, selecione a forma que a balança vai emitir o peso',
    guia: 'Envia Peso -> Contínuo',
  },
  {
    titulo: 'Após parear o dispositivo, e configurar a comunicação, a balança já vai estar pronta para uso no app.'
  },
  {
    titulo: 'É só entrar no app, selecionar a balança, conectar e usar normalmente.'
  }
] as GuiaPassoAPasso[]

export {
  GuiaPassoAPasso,
  SelecionarDispositivoModel,
  ListaMarcasDispositivos,
  TodosDisposivos,
  ValorNomeDispositivos,
  Balancas,
  Leitores,
  listaTodosDispositivos,
  guiaPassosAWR250,
  guiaPassosKM3Plus,
}