import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class BluetoothHelperService {
  constructor() {
  }

  public converterStringParaArrayHexadecimal(comandoString: string) {
    let result = [];
    for (let i = 0; i < comandoString.length; i++) {
      result.push('0x' + comandoString.charCodeAt(i).toString(16))
    }
    return result;
  }

  public getComandoBalanca(comandoString: string) {
    const arrayHexaDecimal = this.converterStringParaArrayHexadecimal(comandoString)
    arrayHexaDecimal.push('0x0D')
    return arrayHexaDecimal
  }
}
