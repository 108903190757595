import { AreasService } from '../../services/areas.service';
import { ColunasAreas } from '../../utils/configuracoes-padrao';
import { AreaSelecionadaModel } from '../../utils/types';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfigTabela } from '../../utils/types/tabela-colunas';
import { NovoFiltro, Ordenacao } from '../vasta-lista/vasta-lista.component';
import { ModalCadastrarAreaPage } from '../../pages/modal-cadastrar-area/modal-cadastrar-area.component';

@Component({
  selector: 'app-seletor-areas',
  templateUrl: './seletor-areas.component.html',
  styleUrls: ['./seletor-areas.component.scss']
})
export class SeletorAreasComponent implements OnInit {
  ColunasAreas = ColunasAreas
  public config: ConfigTabela = {
    toolbar: true,
    selecao: {
      ativar: false
    }
  }
  public areas: any;
  public isCarregando = false;
  public termoAreas = '';
  public novoFiltro: NovoFiltro = {
  }
  public filtrosFixos: string[] = []
  public novaOrdenacao: Ordenacao = { coluna: 'nome', direcao: 'asc' }
  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  public areasSelecionadas: string[] = []

  constructor(private areasCtrl: AreasService, private modalCtrl: ModalController) {}

  async ngOnInit(): Promise<void> {
    await this.getAreas();
  }

  async getAreas(): Promise<void> {
    try {
      this.isCarregando = true;
      const response = await this.areasCtrl.getAreas({
        filtros: this.novoFiltro,
        ordenacao: this.novaOrdenacao,
        paginacao: this.paginate,
        hashNotIn: this.areasSelecionadas || [],
        termo: this.termoAreas
      });
      if (response) {
        this.areas = response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isCarregando = false;
    }
  }

  async buscarAreas(termo: string): Promise<void> {
    this.termoAreas = termo;
    await this.getAreas();
  }

  dismiss(value?: AreaSelecionadaModel): void {
    this.modalCtrl.dismiss(value);
  }

  async abrirModalCadastrarArea(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCadastrarAreaPage
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()

    if (data) {
      this.dismiss(data.area)
    }
  }
}
