import { Balancas, ValorNomeDispositivos } from '../../../components/modal-dispositivos-bluetooth/dispositivos-bluetooth.data'
import { setObserverMensagemStatusConexao } from '../../../services/bluetooth/dispositivos/observer-dispositivos'
import { ErrorBluetooth } from '../../../services/bluetooth/Error/ErrorBluetooth'
import {
  BluetoothConexaoDispositivoIsolado,
  DispositivoConexao,
  DispositivoLowenergy,
  DispositivoSelecionado,
  OpcoesBuscarPorDispositivos,
  OpcoesBuscarPorDispositivosLowEnergy,
  OpcoesConexaoBluetooth,
  OpcoesNotificacoesBluetoothLowEnergy,
  ResultadoConexaoBluetooth,
  StatusConexao
} from '../../../services/bluetooth/interfaces/bluetooth-interface'
import { GlobalService } from '../../../services/global.service'
import { UtilsService } from '../../../services/utils.service'
import { Injectable } from '@angular/core'
import { VastaRX } from '@vasta/rx'
import Utils from '../../../utils/utils'
import { BleClient, ScanResult } from '@capacitor-community/bluetooth-le'
import { HelperLowEnergyService } from '../_helpers/helper-low-energy.service'

@Injectable({
  providedIn: 'root'
})
export class BalancaLEPesentiPese2090Service implements BluetoothConexaoDispositivoIsolado {
  private idAparelho = ValorNomeDispositivos.balancas.PESE2090
  private dadosAparelho = Balancas.find((balanca) => balanca.value === this.idAparelho)
  private balancaLowEnergy: DispositivoSelecionado = {
    name: this.idAparelho,
    nome_modelo: this.dadosAparelho.nome,
    id: null
  }


  private isDesconectandoDispositivoManualmente: boolean

  /* MENSAGENS DE ERRO */
  private mensagemErroPerdaDeConexaoDaBalanca = 'Conexão com balança perdida, tente conecta-la novamente'
  private utils = Utils

  private opcoesDeNotificacoesLE: Omit<OpcoesNotificacoesBluetoothLowEnergy, 'deviceId'> = {
    service: '0000180a-0000-1000-8000-00805f9b34fb',
    characteristic: '2593ec70-68c7-f612-18fa-d65ba58dd476'
  }

  constructor(
    private bluetoothLEHelper: HelperLowEnergyService,
    private utilsCtrl: UtilsService,
    private globalCtrl: GlobalService
  ) { }

  async estabelecerConexao(): Promise<void> {
    try {
      const dispositivoConexao: DispositivoConexao = {
        tipoDispositivo: 'balanca',
        statusConexao: 'conectando'
      }

      VastaRX.setState('dispositivo_conexao', dispositivoConexao)


      const balancaEncontrada = await this.buscarDispositivo({ nomeDispositivo: this.balancaLowEnergy.name })

      const resultadoConexao = await this.estabelecerConexaoComBalanca({ deviceId: balancaEncontrada.deviceId })
      this.balancaLowEnergy.id = balancaEncontrada.deviceId

      if (resultadoConexao.isConnected) {
        const dispositivoConexao: DispositivoConexao = {
          dispositivoSelecionado: this.balancaLowEnergy,
          tipoDispositivo: 'balanca',
          statusConexao: 'conectado'
        }

        VastaRX.setState('dispositivo_conexao', dispositivoConexao)

        await this.receberDadosDaBalanca(balancaEncontrada)
      }
    } catch (error) {
      console.error(error)

      const dispositivoConexao: DispositivoConexao = {
        tipoDispositivo: 'balanca',
        statusConexao: 'erro-conexao'
      }

      VastaRX.setState('dispositivo_conexao', dispositivoConexao)

      if (error instanceof ErrorBluetooth) {
        this.utilsCtrl.exibirToast(error.message, this.utils.tempoDeExibicaoDeMensagemDeErro(error.message))
      }
    }
  }

  async buscarDispositivo(opcoes: OpcoesBuscarPorDispositivos): Promise<DispositivoLowenergy> {
    const resultadoBluetooth = await this.bluetoothLEHelper.isBluetoothLEHabilitado()

    if (!resultadoBluetooth?.isEnabled) {
      throw new ErrorBluetooth('O bluetooth está desabilitado')
    }

    /************ BUSCAR POR DISPOSITIVOS QUE ESTEJAM CONECTADOS ************************************* */
    const dispositivosConectados = await this.bluetoothLEHelper.getDispositivosConectados({
      services: [this.opcoesDeNotificacoesLE.service]
    })
    console.warn('dispositivosConectados: ', dispositivosConectados)

    if (dispositivosConectados?.length) {
      const dispositivoEncontrado = dispositivosConectados.find((device) =>
        device?.name?.startsWith(opcoes.nomeDispositivo)
      )
      console.warn('dispositivo já estava conectado: ', dispositivoEncontrado)

      if (dispositivoEncontrado) {
        return dispositivoEncontrado
      }
    }

    /************ BUSCAR POR DISPOSITIVOS QUE NÃO ESTEJAM CONECTADOS ********************************** */
    const opcoesDeBusca: OpcoesBuscarPorDispositivosLowEnergy = {
      limiteDeBuscas: 10,
      services: [this.opcoesDeNotificacoesLE.service]
    }

    const isBuscarDispositivoPorPainel = false

    let dispositivoEncontrado: DispositivoLowenergy = null

    /*************** PROCURAR DISPOSITIVOS DENTRO DE UM PAINEL ******************  */
    if (isBuscarDispositivoPorPainel) {
      dispositivoEncontrado = await this.buscarDispositivosPorEmPainel()
      console.warn('🚀 ~ buscarDispositivo ~ dispositivoEncontrado:', dispositivoEncontrado)
    } else {
      /*************** PROCURAR DISPOSITIVOS DE FORMA AUTOMÁTICA ******************  */

      const dispositivos = await this.pluginBuscarDispositivo(opcoesDeBusca)
      console.warn('dispositivos lowenergy ', dispositivos)

      const balancasEncontradas = dispositivos.devices.filter((device) => device?.name?.startsWith(opcoes.nomeDispositivo))

      if (!balancasEncontradas || !balancasEncontradas.length) {
        throw new ErrorBluetooth('Nenhuma balança encontrada')
      }

      console.log('balancasEncontradas', balancasEncontradas)
      if (balancasEncontradas.length === 1) {
        dispositivoEncontrado = balancasEncontradas[0]
      } else {
        try {
          dispositivoEncontrado = await this.bluetoothLEHelper.actionSheetSeletorDispositivo({ devices: balancasEncontradas })
        } catch (e) {
          console.error('e', e)
          if (!dispositivoEncontrado) {
            throw new ErrorBluetooth('Falha ao listar dispositivos')
          }
        }
      }
      console.warn('dispositivo lowenergy Encontrado: ', dispositivoEncontrado)
    }

    if (!dispositivoEncontrado) {
      throw new ErrorBluetooth('Dispositivo não encontrado')
    }

    console.warn('dispositivoEncontrado: ', dispositivoEncontrado)

    return dispositivoEncontrado
  }

  async receberDadosDaBalanca(device: DispositivoLowenergy): Promise<void> {
    const resultadoBluetooth = await this.bluetoothLEHelper.isBluetoothLEHabilitado()

    if (!resultadoBluetooth?.isEnabled) {
      throw new ErrorBluetooth('O bluetooth está desabilitado')
    }

    setObserverMensagemStatusConexao({
      mensagemStatusConexao: 'Pronto para receber dados da balança',
      isEsconderMensagem: true
    })

    this.saidaDeDadosBalanca({ deviceId: device.deviceId })
  }

  saidaDeDadosBalanca(opcoes: OpcoesConexaoBluetooth): void {
    const opcoesNotificacoes: OpcoesNotificacoesBluetoothLowEnergy = {
      ...this.opcoesDeNotificacoesLE,
      deviceId: opcoes.deviceId
    }

    this.bluetoothLEHelper.startNotifications(opcoesNotificacoes).subscribe((result: DataView) => {
      const pesoFormatado = this.formatarValorDeBalancaW0(result)
      console.log('pesoFormatado: ', pesoFormatado)
      this.bluetoothLEHelper.emitirPesoFormatado(pesoFormatado)
    })
  }

  // Custom
  async buscarDispositivosPorEmPainel(): Promise<DispositivoLowenergy> {
    try {
      const device = await BleClient.requestDevice({ namePrefix: 'Pesenti BLE' })

      const dispositivosLowenergy: DispositivoLowenergy = {
        ...device
      }

      console.warn('🚀 ~ buscarDispositivosPorEmPainel ~ dispositivosLowenergy:', dispositivosLowenergy)

      return dispositivosLowenergy
    } catch (error) {
      console.error(error)
    }
  }

  async estabelecerConexaoComBalanca(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    return new Promise((resolve, reject) => {
      this.bluetoothLEHelper.pluginConectarBalanca({ deviceId: opcoes.deviceId }).subscribe(
        (success: ResultadoConexaoBluetooth) => {
          console.warn('sucesso ao conectar: ', success)
          console.warn('🚀 ~ returnnewPromise ~ success:', success)

          BleClient.getServices(opcoes.deviceId).then((services) => {
            console.warn('🚀 ~ BleClient.requestLEScan ~ services:', services)
          })

          if (success.isConnected) {
            const { deviceId, isConnected } = success
            const resultadoConexao: ResultadoConexaoBluetooth = {
              deviceId,
              isConnected
            }

            // Habilitar para verificar quando o bluetooth for desconectado
            this.habilitarNotificacoesBluetooth()

            this.emitirResultadoConexao({
              message: 'Balança conectada',
              isConnected: true
            })

            this.bluetoothLEHelper.emitirPesoFormatado('0')

            resolve(resultadoConexao)
          }
        },
        (fail: ResultadoConexaoBluetooth) => {
          console.error('Conexão perdida!! ', fail)

          if (!this.isDesconectandoDispositivoManualmente) {
            this.emitirResultadoConexao({
              message: this.mensagemErroPerdaDeConexaoDaBalanca,
              isConnected: false
            })
          }

          const error = new ErrorBluetooth(this.mensagemErroPerdaDeConexaoDaBalanca)
          reject(error)
        }
      )
    })
  }

  private habilitarNotificacoesBluetooth(): void {
    this.bluetoothLEHelper.pluginHabilitarNotificacoesBluetooth().subscribe((resultadoBluetooth) => {
      if (!resultadoBluetooth.isEnabled) {
        this.emitirResultadoConexao({
          message: this.mensagemErroPerdaDeConexaoDaBalanca,
          isConnected: false
        })
      }
    })
  }

  private emitirResultadoConexao(opcoes: Omit<ResultadoConexaoBluetooth, 'deviceId'>): void {
    this.utilsCtrl.exibirToast(opcoes.message)
    this.globalCtrl.set('balanca_conectada', opcoes.isConnected ? this.balancaLowEnergy : null)

    const statusConexao: StatusConexao = opcoes?.statusConexao
      ? opcoes?.statusConexao
      : opcoes.isConnected
      ? 'conectado'
      : 'desconectado'

    const dispositivoConexao: DispositivoConexao = {
      dispositivoSelecionado: this.balancaLowEnergy,
      statusConexao,
      tipoDispositivo: 'balanca',
    }

    VastaRX.setState('dispositivo_conexao', dispositivoConexao)
    setObserverMensagemStatusConexao({ mensagemStatusConexao: opcoes?.message, isEsconderMensagem: !opcoes.isConnected  })
  }

  private formatarValorDeBalancaW0(result: DataView): string {
    // 53 54 2c 4e 54 2c 20 20 20 20 20 20 31 31 20 6b 67
    let a: string[] = []
    for (let i = 0; i < result.byteLength; i++) {
        a.push(('00' + result.getUint8(i).toString(16)).slice(-2))
    }
    a = a.slice(6,14)
    
    const stringArray = a.map(hex => String.fromCharCode(parseInt(hex, 16)));
    const num = stringArray.join('').replace('-', '').trim().replace(',','.')

    return `${num}`
  }

  async pluginBuscarDispositivo(
    opcoes: OpcoesBuscarPorDispositivosLowEnergy
  ): Promise<{ devices: DispositivoLowenergy[] }> {
    return new Promise(async (resolve, reject) => {
      const dispositivos: DispositivoLowenergy[] = []
      let qtdBuscas = 0
      BleClient.requestLEScan({ namePrefix: 'Pesenti BLE' }, (result: ScanResult) => {
        console.warn('🚀 ~ BleClient.requestLEScan ~ result:', result)
        BleClient.getServices(result.device.deviceId).then((services) => {
          console.warn('🚀 ~ BleClient.requestLEScan ~ services:', services)
        })
        // Retorna apenas o dispositivo associado ao service
        if (opcoes?.services) {
          resolve({ devices: [result.device] })
        } else {
          // Retorna lista de dispositivos encontrados
          qtdBuscas++

          dispositivos.push(result.device)

          if (qtdBuscas >= opcoes?.limiteDeBuscas) {
            BleClient.stopLEScan()
            resolve({ devices: dispositivos })
          }
        }
      })
    })
  }

  public async desconectarDispositivo(device: DispositivoLowenergy): Promise<void> {
    try {
      this.isDesconectandoDispositivoManualmente = true
      
      await BleClient.disconnect(device.deviceId)
      
      this.emitirResultadoConexao({
        isConnected: false,
        message: 'Balança Desconectada'
      })

    } catch(error) {
      console.error(error)
    } finally {
      this.isDesconectandoDispositivoManualmente = false
    }
  }
}
