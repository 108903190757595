import { ApplicationRef, Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { LotesService } from '../../services/lotes.service'
import { CadastrarLoteComponent, CadastroLote } from '../cadastrar-lote/cadastrar-lote.component'
import { UtilsService } from '../../services/utils.service'
import { LoteFiltro } from '../../utils/types'
import { ColunasLotes } from '../../utils/configuracoes-padrao'
import { ConfigTabela } from '../../utils/types/tabela-colunas'
import { NovoFiltro, Ordenacao } from '../vasta-lista/vasta-lista.component'

@Component({
  selector: 'app-seletor-lote-single',
  templateUrl: './seletor-lote-single.component.html',
  styleUrls: ['./seletor-lote-single.component.scss']
})
export class SeletorLoteSingleComponent implements OnInit {
  @Input() lotesSelecionados: string[] = []

  public isConfinamento = false
  public termoLotes = ''
  public lotes = []
  public carregando = true
  public isFiltroMultiplos = false
  public multiplosLotesSelecionados: {
    [key: string]: LoteFiltro
  } = {}

  public ColunasLotes = ColunasLotes
  public config: ConfigTabela = {
    toolbar: true,
    selecao: {
      ativar: false
    }
  }
  public novoFiltro: NovoFiltro = {
  }
  public filtrosFixos: string[] = []
  public novaOrdenacao: Ordenacao = { coluna: 'qtd_animais', direcao: 'desc' }
  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  public hashsSelecionados = []

  constructor(
    private lotesCtrl: LotesService,
    private modalCtrl: ModalController,
    private ref: ApplicationRef,
    private utilsCtrl: UtilsService
  ) {}

  async ngOnInit() {
    if (this.isFiltroMultiplos) {
      this.config.selecao.ativar = true
    }
    if (this.isConfinamento) {
      this.novoFiltro.confinamento = '1'
      this.filtrosFixos.push('confinamento')
    }
    await this.getLotes()
  }

  async getLotes() {
    this.carregando = true
    const lotes = <any[]>await this.lotesCtrl.getLotes({
      filtros: this.novoFiltro,
      ordenacao: this.novaOrdenacao,
      paginacao: this.paginate,
      hashNotIn: this.lotesSelecionados || [],
      termo: this.termoLotes
    })

    this.lotes = lotes
    this.carregando = false
    this.ref.tick()
  }

  selecionarLote(lote: any) {
    if (this.isFiltroMultiplos) {
      if (this.hashsSelecionados.includes(lote.hash)) {
        this.hashsSelecionados = this.hashsSelecionados.filter( hash => hash !== lote.hash)
      } else {
        this.hashsSelecionados.push(lote.hash)
      }
      this.ref.tick()
    } else {
      this.dismiss(lote)
    }
  }

  async abrirModalCadastrarLote() {
    const modal = await this.modalCtrl.create({
      component: CadastrarLoteComponent,
      componentProps: {
        isConfinamento: this.isConfinamento
      }
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      this.getLotes()
      const { lote } = data as CadastroLote
      this.utilsCtrl.exibirToast('Lote cadastrado com sucesso!')
      await this.dismiss({
        ...lote,
        lotesSelecionados: [lote]
      })
    }
  }

  async dismiss(dados = null) {
    this.modalCtrl.dismiss(dados)
  }

  async confirmar() {
    if (this.isFiltroMultiplos) {
      const lotesSelecionados = this.lotes.filter((lote) => this.hashsSelecionados.includes(lote.hash))
      this.modalCtrl.dismiss({ lotesSelecionados })
    }
  }
}
