import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ICadastroArea } from '../../utils/types/areas.interface'
import { TiposAreaLista } from '../../utils/tipos-area'
import { TiposCapimLista } from '../../utils/tipos-capim'
import Utils from '../../utils/utils'
import { UtilsService } from '../../services/utils.service'
import { AreasService } from '../../services/areas.service'

@Component({
  selector: 'app-modal-cadastrar-area',
  templateUrl: './modal-cadastrar-area.component.html',
  styleUrls: ['./modal-cadastrar-area.component.scss'],
})
export class ModalCadastrarAreaPage implements OnInit {

  public novaArea: ICadastroArea = {
    nome : '',
  }

  public isCadastroAvancado = false

  public utils = Utils

  public opcoesTipoCapim = [
    {
      value: '',
      label: ''
    },
    ...TiposCapimLista.map(capim => {
      return {
        label: capim,
        value: capim
      }
    })
  ]

  public opcoesTipoArea = [
    {
      value: '',
      label: ''
    },
    ...TiposAreaLista.map((area) => {
      return {
        label: area,
        value: area
      }
    })
  ]

  constructor(
    private modalCtrl: ModalController,
    private utilsCtrl: UtilsService,
    private areasCtrl: AreasService
  ) { }

  ngOnInit() {
  }

  async cadastrarArea(): Promise<void> {
    this.novaArea.hash = this.novaArea.hash || this.utils.gerarHash()

    if(!this.novaArea.nome) return await this.utilsCtrl.exibirToast('O nome da área é obrigatório')

    try {
      await this.areasCtrl.criarNovaArea(this.novaArea)
      await this.utilsCtrl.exibirToast('Área cadastrada com sucesso')

      this.dismiss({ area: this.novaArea })
    } catch (error) {
      console.error(error)
      this.utilsCtrl.exibirToast('Erro ao cadastrar área')
    }
  }

  dismiss(value?: { area: ICadastroArea }): void {
    this.modalCtrl.dismiss(value)
  }
}
