import { Injectable } from '@angular/core'
import { AnimalModel, IAnimalMetaChaves, IAnimalQuery, ManejoGeralModel, MetasAnimal, SolicitacaoManejosModel } from '../utils/types'
import { BancoSQLiteService } from './banco-sqlite.service'
import * as moment from 'moment'
import Utils from '../utils/utils'
import { Preferences } from '@capacitor/preferences'
import { IPayloadNovoAnimal } from '../utils/types'
import { sqlFilter } from '../utils/sqlUtils'

const whitelistMetas = [
  'meta_partos',
  'meta_n_sisbov',
  'meta_cria_ao_pe',
  'meta_nbrinco',
  'meta_morte',
  'meta_entrada',
  'meta_aptidao',
  'meta_profile',
  'meta_estaNoPe',
  'meta_nome_pai',
  'meta_nome_mae',
  'meta_hashVenda',
  'meta_hash_filho',
  'meta_data_saida',
  'meta_nota_fiscal',
  'meta_puro_origem',
  'meta_data_desmame',
  'meta_tipo_entrada',
  'meta_preco_compra',
  'meta_data_entrada',
  'meta_hash_original',
  'meta_data_aquisicao',
  'meta_produtor_origem',
  'meta_referencia_compra',
  'meta_propriedade_original',
  'meta_composicaoRacial',
  'meta_categoriaDeRegistro',
  'meta_pelagem',
  'meta_registroDefinitivo',
  'meta_registroDeNascimento',
  'meta_dataRegistroDefinitivo',
  'meta_dataRegistroDeNascimento',
  'meta_localizacao',
  'meta_fotos',
  'meta_previsao_de_parto',
  'meta_copiado_de',
  'meta_raca_composicao',
  'meta_raca_secundaria',
  'meta_raca_secundaria_composicao',
  'meta_raca_terciaria',
  'meta_raca_terciaria_composicao',
  'meta_peso_1_data',
  'meta_peso_1_valor',
  'meta_peso_desmame',
  'meta_peso_nascimento',
]


interface BuscarAnimais {
  hashs?: string[]
  termo?: string
  offset?: number
  menosEssesAnimais?: string[]
  filtro?: IAnimalQuery
  idPropriedade?: string
  situacao?: string
}

@Injectable({
  providedIn: 'root'
})
export class AnimaisService {
  constructor(private db: BancoSQLiteService) {}

  glossario(chave) {
    return (
      {
        lactacao: 'em lactação',
        saudavel: 'saudável',
        'em-tratamento': 'em Tratamento',
        estaNoPe: 'ao pé',
        null: ' '
      }[chave] || chave
    )
  }

  async setMetasAnimal(opcoes: {
    hash_animais?: string[]
    metasGeral?: { chave: IAnimalMetaChaves; valor: string | number }[]
    metasIndividuais?: { chave: IAnimalMetaChaves; valor: string | number; hash_animal: string }[]
    idPropriedade: number
  }): Promise<{ hashs: string[] }> {
    const { hash_animais, metasGeral, metasIndividuais, idPropriedade } = opcoes

    if (!metasGeral?.length && !metasIndividuais?.length) return { hashs: [] }

    const metasIndividuaisProcessados: { chave: IAnimalMetaChaves; valor: string | number; hash_animal: string }[] =
      metasIndividuais || []

    metasIndividuais.forEach(async meta => {
      await this.db.run({
        query: `
          INSERT OR REPLACE INTO gestor_animal_metas (
            hash,
            sync,
            edited,
            id_propriedade,
            hash_animal,
            chave,
            valor,
            status,
            created_at,
            updated_at
          ) VALUES (
            '${meta.hash_animal}:${meta.chave}',
            0,
            0,
            '${idPropriedade}',
            '${meta.hash_animal}',
            '${meta.chave}',
            '${typeof meta.valor == 'object' ? JSON.stringify(meta.valor) : meta.valor}',
            1, 
            '${this.db.getFormatedDate()}',
            '${this.db.getFormatedDate()}' 
          )
        `
      })
    })


    return { hashs: hash_animais || metasIndividuaisProcessados.map((m) => m.hash_animal) }
  }

  async criarNovoAnimal(payloadAnimal: IPayloadNovoAnimal) {
    const peso_1_data = payloadAnimal?.pesagens?.peso_1_data
    if(peso_1_data) {
      payloadAnimal['meta_peso_1_data'] = peso_1_data
    }
    const peso_1_valor = payloadAnimal?.pesagens?.peso_1_valor
    if(peso_1_valor) {
      payloadAnimal['meta_peso_1_valor'] = peso_1_valor
    }
    const peso_desmame = payloadAnimal?.pesagens?.peso_desmame
    if(peso_desmame) {
      payloadAnimal['meta_peso_desmame'] = peso_desmame
    }
    const peso_nascimento = payloadAnimal?.pesagens?.peso_nascimento
    if(peso_nascimento) {
      payloadAnimal['meta_peso_nascimento'] = peso_nascimento
    }

    const metasIndividuais: { chave: IAnimalMetaChaves; valor: string; hash_animal: string }[] = []

    const metaKeys = Object.keys(payloadAnimal)
      .filter((key) => key.includes('meta_') && whitelistMetas.includes(key))
      .map((key) => key)

    if (metaKeys.length) {
      for (const chave of metaKeys) {
        metasIndividuais.push({
          chave: chave.replace('meta_', '') as IAnimalMetaChaves,
          valor: payloadAnimal[chave],
          hash_animal: payloadAnimal.hash
        })
      }
    }

    const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)

    await this.setMetasAnimal({ idPropriedade: propriedade.id, metasIndividuais })

    return await this.db.run({
      query: `
        INSERT INTO gestor_animais (
          hash,
          sync,
          edited,
          id_propriedade,
        
          hash_lote,
          data_manejo_lote,
          nome,
          data_nascimento,
          situacao,
          situacao_reprodutiva,
          situacao_sanitaria,
          situacao_produtiva,
          raca,
          numeracao,
          sexo,
          profile,
          tipo,
          id_produto,
          observacao,
          tipo_entrada,
          aptidao,
          
          status,
          created_at,
          updated_at
        )
        VALUES (
          '${payloadAnimal.hash}',
          0,
          0,
          ${payloadAnimal.id_propriedade},
          '${payloadAnimal.hash_lote}',
          '${payloadAnimal.hash_lote ? this.db.getFormatedDate() : ''}',
          '${payloadAnimal.nome}',
          '${payloadAnimal.data_nascimento}',
          'novo',
          '',
          '',
          '',
          '${payloadAnimal.raca}',
          '${payloadAnimal.numeracao}',
          '${payloadAnimal.sexo}',
          '',
          'bovino',
          '',
          '${payloadAnimal.observacao}',
          '${payloadAnimal.tipo_entrada}',
          '${payloadAnimal.aptidao}',
          1,
          '${this.db.getFormatedDate()}',
          '${this.db.getFormatedDate()}'
        )
      `
    })
  }

  async getAnimalByBrinco(numeracao = '') {
    const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)
    return await this.db.run(`
      SELECT gestor_animais.*,
      gestor_lotes.nome as nome_lote,
      gestor_lotes.finalidade as finalidade_lote,
      gestor_lotes.aptidao as aptidao_lote,
      gestor_areas.nome as nome_area,
      (
        CASE WHEN gestor_animais.situacao_produtiva = 'lactacao'
        THEN (
          SELECT cast(julianday('now') - julianday(data) as int)
          FROM gestor_historico
          WHERE status = 1
            AND dados LIKE '%lactacao'
            AND acao = 'alterar-situacao_produtiva'
            AND hash_item = gestor_animais.hash
        ) ELSE '' END
      ) AS del,
      (SELECT peso FROM gestor_pesagens WHERE hash_animal = gestor_animais.hash AND gestor_pesagens.status = 1 AND gestor_pesagens.peso <> 0 AND gestor_pesagens.peso <> "" ORDER BY gestor_pesagens.data_pesagem DESC) as peso,
      (SELECT data_pesagem FROM gestor_pesagens WHERE hash_animal = gestor_animais.hash AND gestor_pesagens.status = 1 AND gestor_pesagens.peso <> 0 AND gestor_pesagens.peso <> "" ORDER BY gestor_pesagens.data_pesagem DESC) as data_ultima_pesagem,
      (SELECT gmd FROM gestor_pesagens WHERE gestor_pesagens.status = 1 AND hash_animal = gestor_animais.hash ORDER BY gestor_pesagens.data_pesagem DESC) as gmd
      FROM gestor_animais
      LEFT JOIN gestor_lotes ON gestor_animais.hash_lote = gestor_lotes.hash
      LEFT JOIN gestor_areas ON gestor_areas.hash = gestor_lotes.hash_area
      INNER JOIN gestor_animal_metas ON hash_animal = gestor_animais.hash and valor LIKE "%${numeracao}" 
      AND chave = "nbrinco"
      WHERE gestor_animais.id_propriedade = ${propriedade.id}
        AND gestor_animais.status = 1
        AND gestor_animais.situacao IN ('vivo', 'novo') 
      ORDER BY gestor_animal_metas.updated_at DESC LIMIT 1
    `)
  }

  async getAnimais(params?: BuscarAnimais): Promise<{animais: AnimalModel[], quantidade: number, lotes: number}> {
    const { filtro, termo } = params
    const {
      safeSelectIn,
      safeEquals,
      safeSelectNotIn,
      safeLike,
      safeNotNull,
      safeNumberRange,
      safeDateRange,
      safeOrderBy,
    } = sqlFilter

    const dataAmanha = moment().add(1, 'days').format('YYYY-MM-DD')

    const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)

    const selectAnimais = `
      WITH reprodutores_ate_mes AS (
        SELECT reprodutivos.cobertura_hash_reprodutor as hash_animal,
        COUNT(1) as qtd
        FROM gestor_manejo_reprodutivo_unitario AS reprodutivos
          WHERE reprodutivos.id_propriedade = {{id_propriedade}}
          AND reprodutivos.status = 1 
          AND reprodutivos.procedimento IN ('monta-natural')
        GROUP BY hash_animal
      ),
      manejos_ate_mes AS (
        SELECT reprodutivos.hash_animal, COUNT(1) as qtd
        FROM gestor_manejo_reprodutivo_unitario AS reprodutivos
            WHERE reprodutivos.id_propriedade = {{id_propriedade}}
            AND reprodutivos.status = 1
          GROUP BY reprodutivos.hash_animal
      )

      SELECT gestor_animais.*,
      gestor_lotes.nome as extra_lote,
      gestor_lotes.finalidade as finalidade_lote,
      gestor_lotes.aptidao as aptidao_lote,
      gestor_areas.nome as extra_area,

      (
        CASE WHEN gestor_animais.situacao_produtiva = 'lactacao'
        THEN (
          SELECT cast(julianday('now') - julianday(data) as int)
          FROM gestor_historico
          WHERE status = 1
            AND dados LIKE '%lactacao'
            AND acao = 'alterar-situacao_produtiva'
            AND hash_item = gestor_animais.hash
        ) ELSE '' END
      ) AS del,

      meta_nbrinco.valor as meta_nbrinco,
      meta_n_sisbov.valor as meta_n_sisbov,
      CASE WHEN
        gestor_animais.sexo = 'femea' THEN COALESCE(meta_cria_ao_pe.valor, "0")
        ELSE NULL
      END as meta_cria_ao_pe,
      CASE WHEN
        gestor_animais.sexo = 'femea' THEN COALESCE(meta_partos.valor, "0")
        ELSE NULL
      END as meta_partos,
      CASE WHEN
        gestor_animais.sexo = 'femea' THEN COALESCE(meta_partos_cache.valor, "0")
        ELSE NULL
      END as meta_partos_cache,
      CASE WHEN
        gestor_animais.sexo = 'femea' THEN cast(COALESCE(meta_partos.valor, 0) + COALESCE(meta_partos_cache.valor, 0) as text)
        ELSE NULL
      END as extra_partos_total,
      meta_previsao_de_parto.valor as meta_previsao_de_parto,
      meta_data_desmame.valor as meta_data_desmame,
      meta_registroDeNascimento.valor as meta_registroDeNascimento,
      meta_dataRegistroDeNascimento.valor as meta_dataRegistroDeNascimento,
      meta_registroDefinitivo.valor as meta_registroDefinitivo,
      meta_dataRegistroDefinitivo.valor as meta_dataRegistroDefinitivo,
      meta_pelagem.valor as meta_pelagem,
      meta_raca_composicao.valor as meta_raca_composicao,
      meta_raca_secundaria.valor as meta_raca_secundaria,
      meta_raca_secundaria_composicao.valor as meta_raca_secundaria_composicao,
      meta_raca_terciaria.valor as meta_raca_terciaria,
      meta_raca_terciaria_composicao.valor as meta_raca_terciaria_composicao,
      meta_puro_origem.valor as meta_puro_origem,
      meta_data_entrada.valor as meta_data_entrada,
  
      genealogia.hash_reprodutor_pai as genealogia_reprodutor_externo,
      genealogia.hash_pai as genealogia_reprodutor_interno,
      genealogia.hash_reprodutor_mae as genealogia_matriz_externa,
      genealogia.hash_mae as genealogia_matriz_interna,

      (
        CASE
          WHEN gestor_animais.sexo = 'femea' AND (julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento)) <= 240 AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) = 0 AND manejos_ate_mes.qtd IS NULL THEN 'bezerras'
          WHEN gestor_animais.sexo = 'macho' AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 240 AND reprodutores_ate_mes.qtd IS NULL THEN 'bezerros'
          WHEN gestor_animais.sexo = 'femea'
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 240
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 365
            AND (
              COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)
            ) = 0
            AND manejos_ate_mes.qtd IS NULL
          THEN 'novilhas_8_12'
          WHEN gestor_animais.sexo = 'macho'
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 240
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 365
          THEN 'garrotes_8_12'
          WHEN gestor_animais.sexo = 'femea'
            AND manejos_ate_mes.qtd IS NULL
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 365
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 730
            AND COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0) = 0
            AND manejos_ate_mes.qtd IS NULL
          THEN 'novilhas_13_24'
          WHEN gestor_animais.sexo = 'macho'
            AND reprodutores_ate_mes.qtd IS NULL
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 365
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 730
          THEN 'garrotes_13_24'
          WHEN gestor_animais.sexo = 'macho'
            AND reprodutores_ate_mes.qtd IS NULL
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 730
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 1095
          THEN 'bois_25_36'
          WHEN gestor_animais.sexo = 'macho'
            AND reprodutores_ate_mes.qtd IS NULL
            AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 1095
          THEN 'bois_36_mais'
          WHEN gestor_animais.sexo = 'femea' AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) = 0 THEN 'nuliparas'
          WHEN gestor_animais.sexo = 'femea' AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) > 0 AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) = 1 THEN 'primiparas'
          WHEN gestor_animais.sexo = 'femea' AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) > 0 AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) = 2 THEN 'secundiparas'
          
          WHEN gestor_animais.sexo = 'femea' AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) > 0 AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) > 2 THEN 'multiparas'
          
          WHEN gestor_animais.sexo = 'macho' AND reprodutores_ate_mes.qtd IS NOT NULL THEN 'reprodutores'
        ELSE '' END
      ) as extra_categoria,

      (SELECT peso 
        FROM gestor_pesagens 
        WHERE hash_animal = gestor_animais.hash 
          AND gestor_pesagens.status = 1 
          AND gestor_pesagens.peso <> 0 
          AND gestor_pesagens.peso <> "" 
        ORDER BY gestor_pesagens.data_pesagem DESC) as pesagem_peso,

      (SELECT peso / 30
        FROM gestor_pesagens 
        WHERE hash_animal = gestor_animais.hash 
          AND gestor_pesagens.status = 1 
          AND gestor_pesagens.peso <> 0 
          AND gestor_pesagens.peso <> "" 
        ORDER BY gestor_pesagens.data_pesagem DESC) as pesagem_arroba,

      (SELECT data_pesagem 
        FROM gestor_pesagens 
        WHERE hash_animal = gestor_animais.hash 
          AND gestor_pesagens.status = 1 
          AND gestor_pesagens.peso <> 0 
          AND gestor_pesagens.peso <> "" 
        ORDER BY gestor_pesagens.data_pesagem DESC) as pesagem_data,

      (SELECT gmd 
        FROM gestor_pesagens 
        WHERE gestor_pesagens.status = 1 
          AND hash_animal = gestor_animais.hash 
        ORDER BY gestor_pesagens.data_pesagem DESC) as gmd,

      (
        SELECT GROUP_CONCAT(gestor_animal_metas.chave || "_-_" || gestor_animal_metas.valor) FROM gestor_animal_metas 
        WHERE hash_animal = gestor_animais.hash
        AND status = 1
      ) AS metas,

      meta_nbrinco.valor as meta_nbrinco

      FROM gestor_animais
        LEFT JOIN reprodutores_ate_mes ON reprodutores_ate_mes.hash_animal = gestor_animais.hash
      LEFT JOIN manejos_ate_mes ON manejos_ate_mes.hash_animal = gestor_animais.hash

      LEFT JOIN gestor_animal_metas meta_nbrinco ON meta_nbrinco.chave = 'nbrinco' AND meta_nbrinco.status = 1
        AND meta_nbrinco.hash_animal = gestor_animais.hash
        AND meta_nbrinco.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_n_sisbov ON meta_n_sisbov.chave = 'n_sisbov' AND meta_n_sisbov.status = 1
        AND meta_n_sisbov.hash_animal = gestor_animais.hash
        AND meta_n_sisbov.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_cria_ao_pe ON meta_cria_ao_pe.chave = 'cria_ao_pe' AND meta_cria_ao_pe.status = 1
        AND meta_cria_ao_pe.hash_animal = gestor_animais.hash
        AND meta_cria_ao_pe.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_partos ON meta_partos.chave = 'partos' AND meta_partos.status = 1
        AND meta_partos.hash_animal = gestor_animais.hash
        AND meta_partos.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_partos_cache ON meta_partos_cache.chave = 'partos_cache' AND meta_partos_cache.status = 1
        AND meta_partos_cache.hash_animal = gestor_animais.hash
        AND meta_partos_cache.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_previsao_de_parto ON meta_previsao_de_parto.chave = 'previsao_de_parto' AND meta_previsao_de_parto.status = 1
        AND meta_previsao_de_parto.hash_animal = gestor_animais.hash
        AND meta_previsao_de_parto.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_data_desmame ON meta_data_desmame.chave = 'data_desmame' AND meta_data_desmame.status = 1
        AND meta_data_desmame.hash_animal = gestor_animais.hash
        AND meta_data_desmame.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_registroDeNascimento ON meta_registroDeNascimento.chave = 'registroDeNascimento' AND meta_registroDeNascimento.status = 1
        AND meta_registroDeNascimento.hash_animal = gestor_animais.hash
        AND meta_registroDeNascimento.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_dataRegistroDeNascimento ON meta_dataRegistroDeNascimento.chave = 'dataRegistroDeNascimento' AND meta_dataRegistroDeNascimento.status = 1
        AND meta_dataRegistroDeNascimento.hash_animal = gestor_animais.hash
        AND meta_dataRegistroDeNascimento.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_registroDefinitivo ON meta_registroDefinitivo.chave = 'registroDefinitivo' AND meta_registroDefinitivo.status = 1
        AND meta_registroDefinitivo.hash_animal = gestor_animais.hash
        AND meta_registroDefinitivo.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_dataRegistroDefinitivo ON meta_dataRegistroDefinitivo.chave = 'dataRegistroDefinitivo' AND meta_dataRegistroDefinitivo.status = 1
        AND meta_dataRegistroDefinitivo.hash_animal = gestor_animais.hash
        AND meta_dataRegistroDefinitivo.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_pelagem ON meta_pelagem.chave = 'pelagem' AND meta_pelagem.status = 1
        AND meta_pelagem.hash_animal = gestor_animais.hash
        AND meta_pelagem.id_propriedade = {{id_propriedade}}

      LEFT JOIN gestor_animal_metas meta_raca_composicao 
        ON meta_raca_composicao.chave = 'raca_composicao' 
        AND meta_raca_composicao.status = 1
        AND meta_raca_composicao.hash_animal = gestor_animais.hash
        AND meta_raca_composicao.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_raca_secundaria 
        ON meta_raca_secundaria.chave = 'raca_secundaria' 
        AND meta_raca_secundaria.status = 1
        AND meta_raca_secundaria.hash_animal = gestor_animais.hash
        AND meta_raca_secundaria.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_raca_secundaria_composicao 
        ON meta_raca_secundaria_composicao.chave = 'raca_secundaria_composicao' 
        AND meta_raca_secundaria_composicao.status = 1
        AND meta_raca_secundaria_composicao.hash_animal = gestor_animais.hash
        AND meta_raca_secundaria_composicao.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_raca_terciaria 
        ON meta_raca_terciaria.chave = 'raca_terciaria' 
        AND meta_raca_terciaria.status = 1
        AND meta_raca_terciaria.hash_animal = gestor_animais.hash
        AND meta_raca_terciaria.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_raca_terciaria_composicao 
        ON meta_raca_terciaria_composicao.chave = 'raca_terciaria_composicao' 
        AND meta_raca_terciaria_composicao.status = 1
        AND meta_raca_terciaria_composicao.hash_animal = gestor_animais.hash
        AND meta_raca_terciaria_composicao.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_puro_origem 
        ON meta_puro_origem.chave = 'puro_origem' 
        AND meta_puro_origem.status = 1
        AND meta_puro_origem.hash_animal = gestor_animais.hash
        AND meta_puro_origem.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_animal_metas meta_data_entrada 
        ON meta_data_entrada.chave = 'data_entrada' 
        AND meta_data_entrada.status = 1
        AND meta_data_entrada.hash_animal = gestor_animais.hash
        AND meta_data_entrada.id_propriedade = {{id_propriedade}}
        
      LEFT JOIN gestor_genealogia genealogia ON genealogia.status = 1 AND genealogia.hash_animal = gestor_animais.hash AND genealogia.id_propriedade = {{id_propriedade}}
        LEFT JOIN gestor_animais pai_interno ON pai_interno.status = 1 AND pai_interno.hash = genealogia.hash_pai AND pai_interno.id_propriedade = {{id_propriedade}}
        LEFT JOIN gestor_reprodutores_externos pai_externo ON pai_externo.status = 1 AND pai_externo.hash = genealogia.hash_reprodutor_pai AND pai_externo.id_propriedade = {{id_propriedade}}
        LEFT JOIN gestor_animais mae_interna ON mae_interna.status = 1 AND mae_interna.hash = genealogia.hash_mae AND mae_interna.id_propriedade = {{id_propriedade}}
        LEFT JOIN gestor_reprodutores_externos mae_externa ON mae_externa.status = 1 AND mae_externa.hash = genealogia.hash_reprodutor_mae AND mae_externa.id_propriedade = {{id_propriedade}}

      LEFT JOIN gestor_animal_metas meta_hashCompra ON meta_hashCompra.chave = 'hashCompra' AND meta_hashCompra.status = 1
        AND meta_hashCompra.hash_animal = gestor_animais.hash
        AND meta_hashCompra.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_relacao_tripla relacao_compra_pessoa ON relacao_compra_pessoa.hash_de = meta_hashCompra.valor 
          AND relacao_compra_pessoa.para = 'pessoas' AND relacao_compra_pessoa.status = 1
            AND relacao_compra_pessoa.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_categorias ON gestor_categorias.hash = relacao_compra_pessoa.hash_para AND gestor_categorias.status = 1
      AND gestor_categorias.id_propriedade = {{id_propriedade}}

      LEFT JOIN gestor_lotes ON gestor_animais.hash_lote = gestor_lotes.hash AND gestor_lotes.status = 1 AND gestor_lotes.id_propriedade = {{id_propriedade}}
      LEFT JOIN gestor_areas ON gestor_lotes.hash_area = gestor_areas.hash AND gestor_areas.status = 1 AND gestor_areas.id_propriedade = {{id_propriedade}}

      WHERE gestor_animais.id_propriedade = ${propriedade.id}
        AND gestor_animais.status = 1

        ${safeSelectIn({ coluna: 'meta_partos', tag: '{{meta_partos}}', valor: filtro?.meta_partos !== undefined ? String(filtro.meta_partos) : null })}
        ${safeEquals({ coluna: 'gestor_animais.hash', tag: '{{hash}}', valor: filtro?.hash })}
        ${safeSelectIn({ coluna: 'gestor_animais.hash', tag: '{{hash_in}}', valor: filtro?.hash_in })}
        ${safeSelectNotIn({ coluna: 'gestor_animais.hash', tag: '{{hash_not_in}}', valor: filtro?.hash_not_in as unknown as string[] })}
        ${safeLike({ coluna: 'gestor_animais.nome', tag: '{{nome}}', valor: filtro?.nome })}
        ${safeLike({ coluna: 'meta_nbrinco.valor', tag: '{{meta_nbrinco}}', valor: filtro?.meta_nbrinco })}
        ${safeLike({ coluna: 'gestor_animais.numeracao', tag: '{{numeracao}}', valor: filtro?.numeracao })}
        ${safeEquals({ coluna: 'gestor_animais.sexo', tag: '{{sexo}}', valor: filtro?.sexo })}
        ${safeSelectIn({ coluna: 'gestor_animais.raca', tag: '{{raca}}', valor: filtro?.raca })}
        ${safeSelectIn({ coluna: 'gestor_lotes.hash_area', tag: '{{extra_area}}', valor: filtro?.extra_area })}
        ${safeLike({ coluna: 'gestor_animais.observacao', tag: '{{observacao}}', valor: filtro?.observacao })}
        ${safeNotNull({ coluna: 'gestor_animais.profile', valor: filtro?.profile })}
        ${safeSelectIn({ coluna: 'gestor_animais.situacao', tag: '{{situacao}}', valor: filtro?.situacao })}
        ${safeLike({ coluna: 'gestor_categorias.nome', tag: '{{extra_produtor_de_origem}}', valor: filtro?.extra_produtor_de_origem })}
        ${safeLike({ coluna: 'meta_n_sisbov.valor', tag: '{{meta_n_sisbov}}', valor: filtro?.meta_n_sisbov })}
        ${safeDateRange({ coluna: 'gestor_animais.created_at', tag: '{{created_at}}', valor: filtro?.created_at })}
        ${safeDateRange({ coluna: 'gestor_animais.data_nascimento', tag: '{{data_nascimento}}', valor: filtro?.data_nascimento })}
        ${safeDateRange({ coluna: 'gestor_animais.data_manejo_lote', tag: '{{data_manejo_lote}}', valor: filtro?.data_manejo_lote })}
        ${safeSelectIn({ coluna: 'gestor_animais.situacao_produtiva', tag: '{{situacao_produtiva}}', valor: filtro?.situacao_produtiva })}
        ${safeSelectIn({ coluna: 'gestor_animais.situacao_sanitaria', tag: '{{situacao_sanitaria}}', valor: filtro?.situacao_sanitaria })}
        ${safeSelectIn({ coluna: 'gestor_animais.aptidao', tag: '{{aptidao}}', valor: filtro?.aptidao })}
        ${safeSelectIn({ coluna: 'gestor_animais.situacao_reprodutiva', tag: '{{situacao_reprodutiva}}', valor: filtro?.situacao_reprodutiva })}
        ${safeSelectIn({ coluna: 'gestor_animais.hash_lote', tag: '{{extra_lote}}', valor: filtro?.extra_lote })}
        ${safeSelectIn({ coluna: 'gestor_animais.hash_lote', tag: '{{hash_lote}}', valor: filtro?.hash_lote })}
        ${safeEquals({ coluna: 'meta_cria_ao_pe', tag: '{{meta_cria_ao_pe}}', valor: filtro?.meta_cria_ao_pe })}
        ${safeSelectIn({ coluna: 'meta_partos', tag: '{{meta_partos}}', valor: filtro?.meta_partos !== undefined ? String(filtro?.meta_partos) : null })}
        ${safeSelectIn({ coluna: 'extra_partos_total', tag: '{{extra_partos_total}}', valor: filtro?.extra_partos_total })}
        ${safeSelectIn({ coluna: 'extra_categoria', tag: '{{extra_categoria}}', valor: filtro?.extra_categoria })}
        ${safeSelectIn({ coluna: 'meta_pelagem', tag: '{{meta_pelagem}}', valor: filtro?.meta_pelagem })}
        ${safeNumberRange({ coluna: 'pesagem_arroba', tag: '{{pesagem_arroba}}', valor: filtro?.pesagem_arroba })}
        ${safeNumberRange({ coluna: 'pesagem_gmd', tag: '{{pesagem_gmd}}', valor: filtro?.pesagem_gmd })}
        ${safeNumberRange({ coluna: 'pesagem_peso', tag: '{{pesagem_peso}}', valor: filtro?.pesagem_peso })}
        ${safeDateRange({ coluna: 'pesagem_data', tag: '{{pesagem_data}}', valor: filtro?.pesagem_data })}
        ${termo && termo !== 'Sem Número' ? `AND (gestor_animais.nome LIKE "%${termo}%" OR gestor_animais.numeracao LIKE "%${termo}%" OR meta_nbrinco.valor LIKE "%${termo}%" OR gestor_animais.hash = "${termo}")` : ''}
        ${termo === 'Sem Número' ? `AND (gestor_animais.numeracao = "" OR gestor_animais.numeracao IS NULL)` : ''}

      GROUP BY gestor_animais.hash
    `
    // ${params?.hashs?.length ? `gestor_animais.hash IN ("${params.hashs.join('", "')}") AND ` : ''}
    // ${filtro?.sexo?.length ? `gestor_animais.sexo = "${filtro.sexo}" AND ` : ''}
    // ${filtro?.lotes?.length ? `gestor_animais.hash_lote IN ("${filtro?.lotes.join('", "')}") AND ` : ''}
    // ${filtro?.situacoes?.length ? `gestor_animais.situacao IN ("${filtro?.situacoes.join('", "')}") AND ` : ''}
    // ${filtro?.racas?.length ? `gestor_animais.raca IN ("${filtro?.racas.join('", "')}") AND ` : ''}
    // ${filtro?.areas?.length ? `gestor_areas.hash IN ("${filtro?.areas.join('", "')}") AND ` : ''}
    // ${filtro?.situacoesReprodutiva?.length ? `gestor_animais.situacao_reprodutiva IN ("${filtro?.situacoesReprodutiva.join('", "')}") AND ` : ''}
    // ${filtro?.extraCategorias?.length ? `extra_categoria IN ("${filtro?.extraCategorias.join('", "')}") AND ` : ''}
    // ${params?.menosEssesAnimais?.length ? `${params?.menosEssesAnimais.map((animal) => `gestor_animais.hash <> '${animal}' AND`).join(' ')} `: ''}
    // ${params?.termo?.length ? `( gestor_animais.nome LIKE '%${params?.termo}%' OR gestor_animais.numeracao LIKE '%${params?.termo}%' ) AND` : ''}

    const orderBys: string[] = []

    if (filtro?.numeracao || termo) {
      orderBys.push(`
        (gestor_animais.numeracao = '${filtro.numeracao || termo || ''}') DESC,
        (gestor_animais.numeracao = '0${filtro.numeracao || termo || ''}') DESC,
        (gestor_animais.numeracao = '00${filtro.numeracao || termo || ''}') DESC,
        (gestor_animais.numeracao LIKE '${filtro.numeracao || termo || ''}%') DESC
      `)
    }

    if (filtro?.order_by) {
      orderBys.push(
        safeOrderBy({
          coluna: filtro.order_by,
          direcao: filtro.order_direction,
          prefixo: false,
          tableCase: {
            nome: 'gestor_animais',
            data_manejo_lote: 'gestor_animais',
            data_nascimento: 'gestor_animais',
            created_at: 'gestor_animais',
            numeracao: 'gestor_animais',
            observacao: 'gestor_animais',
            raca: 'gestor_animais',
            sexo: 'gestor_animais',
            situacao: 'gestor_animais',
            situacao_sanitaria: 'gestor_animais',
            aptidao: 'gestor_animais',
            situacao_reprodutiva: 'gestor_animais',
          }
        })
      )
    }

    let novoOrderBy = ''

    if (orderBys.length) novoOrderBy += `ORDER BY ${orderBys.join(', ')} `

    novoOrderBy += sqlFilter.safeLimitOffset(filtro?.query_limit, filtro?.query_offset)




    

    const animaisPromise: Promise<AnimalModel[]> = this.db.safeQuery({ 
      query: `
        ${selectAnimais}
        ${novoOrderBy}
      `,
      params: { 
        dataAmanha, 
        id_propriedade: propriedade.id, 
        ...(filtro || {})
      }
    })

    const totaisPromise = this.db.safeQuery({
      query: `
      SELECT COUNT(DISTINCT hash) total_resultados,
      COUNT(DISTINCT hash_lote) qtd_lotes
      FROM 
      (
        ${selectAnimais}
      ) a
    `,
      params: {
        dataAmanha,
        id_propriedade: propriedade.id, 
        ...filtro
      }
    })

    const [animais, totais] = await Promise.all([animaisPromise, totaisPromise])

    console.warn(animais, totais)

    // Formatar metas dos animais
    for (const animal of animais) {
      if (typeof animal.metas === 'string') {
        const metasDividido = animal.metas?.split(',')

        const metasFormatado =
          metasDividido?.map((meta) => {
            const [chave, valor] = meta.split('_-_')
            return { chave, valor }
          }) || []

        let animal_metas: MetasAnimal = {}

        for (let meta of metasFormatado) {
          animal_metas[meta.chave] = meta.valor
        }

        animal.metas = animal_metas
      }
    }

    return { animais, quantidade: totais[0].total_resultados, lotes: totais[0].qtd_lotes }
  }

  async getQtdAnimaisFiltrados(params?: BuscarAnimais): Promise<{ qtdAnimais: number }> {
    const { safeSelectIn } = sqlFilter

    const dataAmanha = moment().add(1, 'days').format('YYYY-MM-DD')
    const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)

    const selectQtdAnimais = `
      SELECT COUNT (*) as qtd FROM (
        WITH reprodutores_ate_mes AS (
          SELECT reprodutivos.cobertura_hash_reprodutor as hash_animal,
          COUNT(1) as qtd
          FROM gestor_manejo_reprodutivo_unitario AS reprodutivos
            WHERE reprodutivos.id_propriedade = {{id_propriedade}}
            AND reprodutivos.status = 1 
            AND reprodutivos.procedimento IN ('monta-natural')
          GROUP BY hash_animal
        ),
        manejos_ate_mes AS (
          SELECT reprodutivos.hash_animal, COUNT(1) as qtd
          FROM gestor_manejo_reprodutivo_unitario AS reprodutivos
              WHERE reprodutivos.id_propriedade = {{id_propriedade}}
              AND reprodutivos.status = 1
            GROUP BY reprodutivos.hash_animal
        )
  
        SELECT gestor_animais.*,
        gestor_lotes.nome as nome_lote,
        gestor_lotes.finalidade as finalidade_lote,
        gestor_lotes.aptidao as aptidao_lote,
        gestor_areas.nome as nome_area,
  
        (
          CASE WHEN gestor_animais.situacao_produtiva = 'lactacao'
          THEN (
            SELECT cast(julianday('now') - julianday(data) as int)
            FROM gestor_historico
            WHERE status = 1
              AND dados LIKE '%lactacao'
              AND acao = 'alterar-situacao_produtiva'
              AND hash_item = gestor_animais.hash
          ) ELSE '' END
        ) AS del,
  
        CASE 
          WHEN gestor_animais.sexo = 'femea' THEN COALESCE(meta_partos.valor, "0")
          ELSE NULL
        END as meta_partos,
        
        CASE 
          WHEN gestor_animais.sexo = 'femea' THEN COALESCE(meta_partos_cache.valor, "0")
          ELSE NULL
        END as meta_partos_cache,
        (
          CASE
            WHEN gestor_animais.sexo = 'femea' AND (julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento)) <= 240 AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) = 0 AND manejos_ate_mes.qtd IS NULL THEN 'bezerras'
            WHEN gestor_animais.sexo = 'macho' AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 240 AND reprodutores_ate_mes.qtd IS NULL THEN 'bezerros'
            WHEN gestor_animais.sexo = 'femea'
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 240
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 365
              AND (
                COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)
              ) = 0
              AND manejos_ate_mes.qtd IS NULL
            THEN 'novilhas_8_12'
            WHEN gestor_animais.sexo = 'macho'
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 240
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 365
            THEN 'garrotes_8_12'
            WHEN gestor_animais.sexo = 'femea'
              AND manejos_ate_mes.qtd IS NULL
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 365
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 730
              AND COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0) = 0
              AND manejos_ate_mes.qtd IS NULL
            THEN 'novilhas_13_24'
            WHEN gestor_animais.sexo = 'macho'
              AND reprodutores_ate_mes.qtd IS NULL
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 365
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 730
            THEN 'garrotes_13_24'
            WHEN gestor_animais.sexo = 'macho'
              AND reprodutores_ate_mes.qtd IS NULL
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 730
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) <= 1095
            THEN 'bois_25_36'
            WHEN gestor_animais.sexo = 'macho'
              AND reprodutores_ate_mes.qtd IS NULL
              AND julianday({{dataAmanha}}) - julianday(gestor_animais.data_nascimento) > 1095
            THEN 'bois_36_mais'
            WHEN gestor_animais.sexo = 'femea' AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) = 0 THEN 'nuliparas'
            WHEN gestor_animais.sexo = 'femea' AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) > 0 AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) = 1 THEN 'primiparas'
            WHEN gestor_animais.sexo = 'femea' AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) > 0 AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) = 2 THEN 'secundiparas'
            
            WHEN gestor_animais.sexo = 'femea' AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) > 0 AND (COALESCE(meta_partos_cache.valor, 0) + COALESCE(meta_partos.valor, 0)) > 2 THEN 'multiparas'
            
            WHEN gestor_animais.sexo = 'macho' AND reprodutores_ate_mes.qtd IS NOT NULL THEN 'reprodutores'
          ELSE '' END
        ) as extra_categoria,
  
        (SELECT peso 
          FROM gestor_pesagens 
          WHERE hash_animal = gestor_animais.hash 
            AND gestor_pesagens.status = 1 
            AND gestor_pesagens.peso <> 0 
            AND gestor_pesagens.peso <> "" 
          ORDER BY gestor_pesagens.data_pesagem DESC) as peso,
  
        (SELECT data_pesagem 
          FROM gestor_pesagens 
          WHERE hash_animal = gestor_animais.hash 
            AND gestor_pesagens.status = 1 
            AND gestor_pesagens.peso <> 0 
            AND gestor_pesagens.peso <> "" 
          ORDER BY gestor_pesagens.data_pesagem DESC) as data_ultima_pesagem,
  
        (SELECT gmd 
          FROM gestor_pesagens 
          WHERE gestor_pesagens.status = 1 
            AND hash_animal = gestor_animais.hash 
          ORDER BY gestor_pesagens.data_pesagem DESC) as gmd,
  
        (
          SELECT GROUP_CONCAT(gestor_animal_metas.chave || "_-_" || gestor_animal_metas.valor) FROM gestor_animal_metas 
          WHERE hash_animal = gestor_animais.hash
          AND status = 1
        ) AS metas
  
        FROM gestor_animais
          LEFT JOIN reprodutores_ate_mes ON reprodutores_ate_mes.hash_animal = gestor_animais.hash
          LEFT JOIN gestor_lotes ON gestor_animais.hash_lote = gestor_lotes.hash
          LEFT JOIN gestor_areas ON gestor_areas.hash = gestor_lotes.hash_area
  
          LEFT JOIN gestor_animal_metas meta_partos ON meta_partos.chave = 'partos' AND meta_partos.status = 1
            AND meta_partos.hash_animal = gestor_animais.hash
            AND meta_partos.id_propriedade = {{id_propriedade}}
  
          LEFT JOIN manejos_ate_mes ON manejos_ate_mes.hash_animal = gestor_animais.hash
          
          LEFT JOIN gestor_animal_metas meta_partos_cache ON meta_partos_cache.chave = 'partos_cache' AND meta_partos_cache.status = 1
  
        WHERE gestor_animais.id_propriedade = ${propriedade.id}
          AND 
            ${params?.hashs?.length ? `gestor_animais.hash IN ("${params.hashs.join('", "')}") AND ` : ''}
            ${params?.filtro?.sexo?.length ? `gestor_animais.sexo = "${params?.filtro.sexo}" AND ` : ''}
            ${
              params?.menosEssesAnimais?.length
                ? `${params?.menosEssesAnimais.map((animal) => `gestor_animais.hash <> '${animal}' AND`).join(' ')}  `
                : ''
            }
            ${
              params?.termo?.length
                ? `( gestor_animais.nome LIKE '%${params?.termo}%' OR gestor_animais.numeracao LIKE '%${params?.termo}%' ) AND`
                : ''
            }
            gestor_animais.status = 1
            AND gestor_animais.situacao IN ('vivo', 'novo', 'pendente') 
  
        ${safeSelectIn({
          coluna: 'meta_partos',
          tag: '{{meta_partos}}',
          valor: params?.filtro?.meta_partos !== undefined ? String(params.filtro.meta_partos) : null
        })}
  
        GROUP BY gestor_animais.hash
  
        ORDER BY
          ${
            params?.termo?.length ?
            `
              (numeracao = '${params.termo}') DESC,
              (numeracao = '0${params.termo}') DESC,
              (numeracao = '00${params.termo}') DESC,
              (numeracao LIKE '${params.termo}%') DESC,
            ` : ''
          }
          cast(gestor_animais.numeracao AS unsigned) DESC
      ) AS qtdAnimais
    `

    // ${params?.filtro?.lotes?.length ? `gestor_animais.hash_lote IN ("${params?.filtro?.lotes.join('", "')}") AND ` : ''}            
    // ${params?.filtro?.situacoes?.length ? `gestor_animais.situacao IN ("${params?.filtro?.situacoes.join('", "')}") AND ` : ''}
    // ${params?.filtro?.racas?.length ? `gestor_animais.raca IN ("${params?.filtro?.racas.join('", "')}") AND ` : ''}
    // ${params?.filtro?.areas?.length ? `gestor_areas.hash IN ("${params?.filtro?.areas.join('", "')}") AND ` : ''}
    // ${params?.filtro?.situacoesReprodutiva?.length ? `gestor_animais.situacao_reprodutiva IN ("${params?.filtro?.situacoesReprodutiva.join('", "')}") AND ` : ''}
    // ${params?.filtro?.extraCategorias?.length ? `extra_categoria IN ("${params?.filtro?.extraCategorias.join('", "')}") AND ` : ''}

    const qtdAnimais: { qtd: number }[] = await this.db.safeQuery({ 
      query: `${selectQtdAnimais}`,
      params: { 
        dataAmanha, 
        id_propriedade: propriedade.id, 
        // extra_categoria: params?.filtro?.extraCategorias,
      }
    })

    return { qtdAnimais: qtdAnimais[0].qtd }
  }

  async getReprodutorExterno(hash_animal: string): Promise<AnimalModel> {
    const [animaisExternos]: AnimalModel[] = await this.db.run({
      query: `
        SELECT *
        FROM gestor_reprodutores_externos
        WHERE gestor_reprodutores_externos.status = 1
        AND hash = '${hash_animal}'
      `
    });

    return animaisExternos
  }

  async getAnimaisPorLote(lote?: string, termo = '') {
    return <any[]>await this.db.run(
      `SELECT 
        gestor_animais.hash,
        gestor_animais.numeracao, 
        gestor_animais.nome,
        gestor_animais.raca,
        gestor_animais.sexo, 
        gestor_lotes.nome as nome_lote,
        gestor_lotes.hash as hash_lote
      FROM  gestor_animais
      INNER JOIN gestor_lotes ON gestor_animais.hash_lote = gestor_lotes.hash
      WHERE 
        gestor_animais.status = 1 
        AND gestor_animais.situacao IN ('vivo', 'novo') 
        AND gestor_animais.hash_lote = '${lote}' 
        AND ( 
          gestor_animais.nome LIKE '%${termo}%' 
          OR gestor_animais.numeracao LIKE '%${termo}%' 
        )
      ORDER BY gestor_lotes.hash ASC
      `
    )
  }

  async getTodosOsAnimais() {
    return this.db.run(
      `SELECT 
        gestor_animais.hash,
        gestor_animais.numeracao,
        gestor_animais.nome,
        gestor_animais.raca,
        gestor_animais.sexo
      FROM gestor_animais
      WHERE id_propriedade = ${
        JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id
      } AND gestor_animais.status = 1 
      AND gestor_animais.situacao IN ('vivo', 'novo') 
      ORDER BY gestor_animais.numeracao ASC `
    )
  }

  async getAnimaisCompleto(idPropriedade: string) {
    return this.db.run(
      `
        SELECT * 
        FROM gestor_animais animal
        WHERE id_propriedade = '${idPropriedade}'
        AND animal.status = 1
      `
    )
  }

  async marcarAnimalComoPendenteDeAprovacao() {
    try {
      const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id
      await this.db.run({
        query: `
          UPDATE gestor_animais
          SET edited = 1,
          situacao = 'pendente'
          WHERE id_propriedade = ${idPropriedade}
          AND situacao = 'novo'
        `
      })
    } catch(error) {
      console.error(error);
    }
  }

  async atualizarAnimalPendente(payloadAnimal: IPayloadNovoAnimal | AnimalModel) {
    const metasIndividuais: { chave: IAnimalMetaChaves; valor: string; hash_animal: string }[] = []

    const metaKeys = Object.keys(payloadAnimal)
      .filter((key) => key.includes('meta_') && whitelistMetas.includes(key))
      .map((key) => key)

    if (metaKeys.length) {
      for (const chave of metaKeys) {
        metasIndividuais.push({
          chave: chave.replace('meta_', '') as IAnimalMetaChaves,
          valor: payloadAnimal[chave],
          hash_animal: payloadAnimal.hash
        })
      }
    }

    const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)

    await this.setMetasAnimal({ idPropriedade: propriedade.id, metasIndividuais })

    console.log('metasIndividuais editar?: ',metasIndividuais)

    return await this.db.run({
      query: `
        UPDATE gestor_animais 
        SET 
          edited=1,
          id_propriedade=${payloadAnimal.id_propriedade},
          hash_lote='${payloadAnimal.hash_lote}',
          data_manejo_lote='${payloadAnimal.hash_lote ? this.db.getFormatedDate() : ''}',
          nome='${payloadAnimal.nome}',
          data_nascimento='${payloadAnimal.data_nascimento}',
          situacao='${payloadAnimal?.situacao || 'novo'}',
          situacao_reprodutiva='',
          situacao_sanitaria='',
          situacao_produtiva='',
          raca='${payloadAnimal.raca}',
          numeracao='${payloadAnimal.numeracao}',
          sexo='${payloadAnimal.sexo}',
          profile='',
          tipo='bovino',
          id_produto='',
          observacao='${payloadAnimal.observacao}',
          tipo_entrada='${payloadAnimal.tipo_entrada}',
          aptidao='${payloadAnimal.aptidao}',
          status=1,
          created_at='${payloadAnimal.created_at}',
          updated_at='${this.db.getFormatedDate()}'
        WHERE hash='${payloadAnimal.hash}'
      `
    })
  }

  async removerAnimalPendente(payloadAnimal: AnimalModel) {
    return await this.db.run({
      query: `
        UPDATE gestor_animais 
        SET 
          edited=1,
          situacao='excluido',
          updated_at='${this.db.getFormatedDate()}'
        WHERE hash='${payloadAnimal.hash}'
      `
    })
  }

  async getAnimaisNovosPendentes(opcoes: BuscarAnimais) {
    opcoes.idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id
    const animais: AnimalModel[] = await this.db.run({
      query: `
        SELECT * 
        FROM gestor_animais
        WHERE id_propriedade = '${opcoes.idPropriedade}'
          AND ${ opcoes?.situacao ? `situacao = '${opcoes.situacao}'` : `(situacao = 'novo' OR situacao = 'pendente')` }
          ${ opcoes?.menosEssesAnimais?.length ? `AND` : '' }
          ${ (opcoes?.menosEssesAnimais || []).map((hashAnimal) => `gestor_animais.hash <> '${hashAnimal}'`).join(' AND ') }
          AND status = 1
      `
    })

    const hashs = animais.map((animal) => animal.hash)

    const metas: { chave: IAnimalMetaChaves; valor: string; hash_animal: string }[] = await this.db.run({
      query: `
        SELECT *
        FROM gestor_animal_metas
        WHERE hash_animal IN ('${hashs.join("', '")}')
        AND status = 1
      `
    })

    const chavesPesagem = ['peso_1_data', 'peso_1_valor', 'peso_desmame', 'peso_nascimento']

    for (const animal of animais) {
      const metasDoAnimal = metas.filter((meta) => meta.hash_animal === animal?.hash)
      metasDoAnimal.map((meta) => {
        if (chavesPesagem.includes(meta.chave)) {
          animal.pesagens = animal.pesagens || {}
          animal.pesagens[meta.chave] = meta.valor
        } else {
          animal['meta_' + meta.chave] = meta.valor
        }
      })
    }

    return animais
  }

  async getAnimaisMetas(idPropriedade: string) {
    return this.db.run(
      `
        SELECT * 
        FROM gestor_animal_metas animalMetas
        WHERE id_propriedade = '${idPropriedade}'
        AND animalMetas.status = 1
      `
    )
  }

  async getAnimal(hash: string): Promise<any> {
    let animal = (
      await this.db.run(`
        SELECT gestor_animais.*,
        gestor_lotes.nome as nome_lote,
        gestor_lotes.finalidade as finalidade_lote,
        gestor_lotes.aptidao as aptidao_lote,
        gestor_areas.nome as nome_area,
        (
          CASE WHEN gestor_animais.situacao_produtiva = 'lactacao' 
          THEN (
            SELECT cast(julianday('now') - julianday(data) as int)
            FROM gestor_historico
            WHERE status = 1
              AND dados LIKE '%lactacao'
              AND acao = 'alterar-situacao_produtiva'
              AND hash_item = gestor_animais.hash
          ) ELSE '' END
        ) AS del,
        (
          SELECT gestor_pesagens.peso || '_' || gestor_pesagens.data_pesagem AS peso_ultimaDataPesagem  
          FROM gestor_pesagens
          WHERE hash_animal = gestor_animais.hash 
          AND gestor_pesagens.status = 1 
          AND gestor_pesagens.peso <> 0 
          AND gestor_pesagens.peso <> "" 
          ORDER BY gestor_pesagens.data_pesagem DESC
        ) AS peso_ultimaDataPesagem,
        (
          SELECT GROUP_CONCAT(gestor_animal_metas.chave || "_-_" || gestor_animal_metas.valor) FROM gestor_animal_metas 
          WHERE hash_animal = gestor_animais.hash
          AND status = 1
          ) AS metas,
          (
            SELECT valor FROM gestor_animal_metas
            WHERE chave = 'partos'
            AND valor <> 'null'
            AND status = 1
            AND hash_animal = gestor_animais.hash
            LIMIT 1
          ) AS meta_partos,
          (
            SELECT valor FROM gestor_animal_metas
            WHERE chave = 'previsao_de_parto'
            AND status = 1
            AND hash_animal = gestor_animais.hash
            LIMIT 1
          ) AS meta_previsao_de_parto
          FROM gestor_animais

        LEFT JOIN gestor_lotes ON gestor_animais.hash_lote = gestor_lotes.hash
        LEFT JOIN gestor_areas ON gestor_areas.hash = gestor_lotes.hash_area
          WHERE gestor_animais.hash = '${hash}' 
          AND gestor_animais.status = 1
      `)
    )[0]

    if (!animal) {
      return {}
    }

    const metas: { chave: IAnimalMetaChaves; valor: string; hash_animal: string }[] = await this.db.run({
      query: `
        SELECT *
        FROM gestor_animal_metas
        WHERE hash_animal = '${hash}'
        AND status = 1
      `
    })

    const chavesPesagem = ['peso_1_data', 'peso_1_valor', 'peso_desmame']

    const metasDoAnimal = metas.filter((meta) => meta.hash_animal === animal?.hash)
    metasDoAnimal.map((meta) => {
      if (chavesPesagem.includes(meta.chave)) {
        animal.pesagens = animal.pesagens || {}
        animal.pesagens[meta.chave] = meta.valor
      } else {
        animal['meta_' + meta.chave] = meta.valor
      }
    })

    const metasDividido = animal?.metas?.split(',')
    const metasFormatado =
      metasDividido?.map((meta: any) => {
        const [chave, valor] = meta.split('_-_')
        return { chave, valor }
      }) || []

    let animal_metas = {}
    for (let meta of metasFormatado) animal_metas[meta.chave] = meta.valor == 'null' ? null : meta.valor

    const ultimoPesoData = animal?.peso_ultimaDataPesagem?.split('_')

    delete animal?.peso_ultimaDataPesagem

    if (ultimoPesoData) {
      animal = { ...animal, ultimo_peso: Number(ultimoPesoData[0]), data_ultima_pesagem: ultimoPesoData[1] }
    }

    if (animal?.metas) return { ...animal, metas: animal_metas }
    else return { ...animal, metas: animal_metas }
  }

  async buscaVacinas(hash) {}

  async buscarPesagensAnimal({ hash_animal = '', limit = 0, offset = 0 }) {
    return new Promise<any>(async (response: any) => {
      let pesagens: any = await this.db.run({
        query: `
				SELECT * 
				FROM gestor_pesagens 
				WHERE hash_animal = '${hash_animal}' 
				AND status = 1
				AND peso <> ''
				AND peso > 0
        ORDER BY data_pesagem DESC
        ${limit ? 'LIMIT ' + limit : ''}
        ${offset ? 'OFFSET ' + offset : ''}
			`
      })

      for (const pesagem of pesagens) {
        if (pesagem.peso) pesagem.peso_arroba = Number((pesagem.peso / 30).toFixed(0))

        pesagem.individual = pesagem.hash_lote ? 'Em Lote' : 'Individual'
      }

      response(pesagens)
    })
  }

  async getUltimoPeso(animal: any) {
    const response = <any[]>await this.db.run(
      `
        SELECT gestor_pesagens.*
        FROM gestor_pesagens
        WHERE hash_animal = "${animal.hash}" 
        AND status = 1
        AND data_pesagem < "${moment().format('YYYY-MM-dd')}"
        ORDER BY data_pesagem DESC 
        LIMIT 1
      `
    )

    if (response.length)
      return {
        ultimoPeso: Number(response[0]?.peso),
        ultimoPesoData: response[0]?.data_pesagem
      }
  }

  async getPesoDesmame(animal: any) {
    if (animal.metas.data_desmame) {
      const response2 = <any[]>await this.db.run(
        `
            SELECT gestor_pesagens.*
            FROM gestor_pesagens
            WHERE hash_animal = "${animal.hash}"
            AND status = 1
            AND data_pesagem = "${animal.metas.data_desmame}"
            ORDER BY ID DESC
            LIMIT 1
          `
      )
      if (response2.length) return Number(response2[0].peso)
    }
  }

  async getPesoNascimento(animal: any) {
    const response1 = <any[]>await this.db.run(
      `
        SELECT gestor_pesagens.*
        FROM gestor_pesagens
        WHERE hash_animal = "${animal.hash}"
        AND status = 1
        AND data_pesagem = "${animal.data_nascimento}"
        ORDER BY ID DESC
        LIMIT 1
      `
    )

    if (response1.length) return Number(response1[0].peso)
  }

  async historicoAnimal(hash, de = undefined, ate = undefined, tiposSolicitados: string[] = ['historico']) {
    return new Promise(async (resolve) => {
      if (!de) de = moment().subtract(3, 'years').format('YYYY-MM-DD')
      if (!ate) ate = moment().format('YYYY-MM-DD')

      let diasHistorico = [moment(de).format('YYYY-MM-DD')]
      let acontecimentos = {}
      acontecimentos[moment(de).format('YYYY-MM-DD')] = []
      let dataAtiva = moment(de)
      while (dataAtiva.add(1, 'days').diff(ate) < 1) {
        diasHistorico.push(dataAtiva.format('YYYY-MM-DD'))
        acontecimentos[dataAtiva.format('YYYY-MM-DD')] = []
      }

      let retorno = []

      if (tiposSolicitados.includes('historico')) {
        let historicoTabela: any = await this.db.run({
          query: `
					SELECT
						gestor_historico.hash,	
						gestor_historico.id,
						gestor_historico.acao,
						gestor_historico.dados,
						gestor_historico.hash_local,
						gestor_historico.data as data,
						gestor_lotes.nome as nome_lote,
						gestor_animais.nome as nome_animal
					FROM gestor_historico
					LEFT JOIN gestor_lotes ON gestor_lotes.hash = gestor_historico.hash_local
					LEFT JOIN gestor_animais ON gestor_animais.hash = gestor_historico.hash_item
					WHERE gestor_historico.hash_item = '${hash}'
					AND gestor_historico.data >= '${de} 00:00:00'
					AND gestor_historico.data <= '${ate} 23:59:59'
					AND gestor_historico.status = 1
					AND acao IN ('${Object.keys(Utils.tiposHistoricos).join("','")}')
					ORDER BY gestor_historico.id DESC, gestor_historico.data DESC
				`
        })

        var historicoFinal = []

        for (let index in historicoTabela) {
          var i = Number(index)
          var logSingle = historicoTabela[i]
          var logAnterior = i > 0 ? historicoTabela[i - 1] : null

          if (logSingle.acao == 'lote-entrada' || logSingle.acao == 'lote-saida') logSingle.dados = logSingle.nome_lote

          if (
            logAnterior &&
            logSingle.acao == 'lote-entrada' &&
            logAnterior.acao == 'lote-saida' &&
            logSingle.dados !== logAnterior.dados
          ) {
            logSingle.acao = 'lote-transferencia'
            logSingle.dados = 'De <b>' + logAnterior.dados + '</b> para <b>' + logSingle.dados + '</b>'
            historicoFinal.pop()
          }

          if (
            [
              'alterar-nome',
              'alterar-numeracao',
              'alterar-situacao',
              'alterar-situacao_produtiva',
              'alterar-situacao_reprodutiva',
              'alterar-situacao_sanitaria'
            ].includes(logSingle.acao)
          ) {
            if (logSingle.dados.includes(' -> ')) {
              let nome = logSingle.dados.split(' -> ')
              logSingle.dados =
                'De "<b>' + this.glossario(nome[0]) + '</b>" para "<b>' + this.glossario(nome[1]) + '</b>"'
            } else {
              if (logSingle.dados) logSingle.dados = 'Alterado para "<b>' + this.glossario(logSingle.dados) + '"</b>'
              else logSingle.dados = 'Removido'

              if (logSingle.hash_local) logSingle.detalhes = 'Modificado pelo manejo'
            }
          }

          if (
            [
              'alteracao-nome',
              'alteracao-numeracao',
              'alteracao-situacao_produtiva',
              'alteracao-situacao_reprodutiva',
              'alteracao-situacao_sanitaria'
            ].includes(logSingle.acao)
          ) {
            if (logSingle.dados) {
              if (logSingle.dados.includes('anterior:'))
                logSingle.detalhes = `Valor anterior: "${logSingle.dados.split('anterior:')[1]}"`
              else if (logSingle.dados.includes('manejo:')) logSingle.detalhes = `Modificado pelo manejo`
              else logSingle.detalhes = `"${logSingle.dados}"`
            }

            if (logSingle.hash_local) logSingle.dados = '<b>' + this.glossario(logSingle.hash_local) + '</b>'
            else logSingle.dados = 'Removido'
          }
          historicoFinal.push(logSingle)
        }

        historicoFinal.forEach((historico) => {
          let itemPesagem = {
            editavel: historico.acao == 'transfere-propriedade' ? false : true,
            hash: historico.hash,
            tipo: 'movimentacao',
            icone: Utils.tiposHistoricos[historico.acao].icone,
            heading: Utils.tiposHistoricos[historico.acao].nome,
            texto: historico.dados,
            detalhes: historico.detalhes
          }
          acontecimentos[historico.data.slice(0, 10)].push(itemPesagem)
        })
      }

      if (tiposSolicitados.includes('pesagens')) {
        let pesagens: any = await this.db.run({
          query: `
					SELECT *
					FROM gestor_pesagens
					WHERE status = 1
					AND peso <> ""
					AND peso > 0
					AND hash_animal = '${hash}'
					AND data_pesagem >= '${de} 00:00:00'
					AND data_pesagem <= '${ate} 23:59:59'
				`
        })

        pesagens.forEach((pesagem) => {
          let itemPesagem = {
            tipo: 'pesagem',
            hash: pesagem.hash,
            icone: 'icone-dashboard-gauge',
            heading: 'Pesagem',
            texto: `${pesagem.peso} kg${pesagem.gmd ? `, GMD de ${pesagem.gmd} kg` : ''}${
              pesagem.hash_lote ? `, pesagem em lote` : ''
            }`
          }
          acontecimentos[pesagem.data_pesagem].push(itemPesagem)
        })
      }

      if (
        tiposSolicitados.includes('manejos') ||
        tiposSolicitados.includes('manejos-reprodutivos') ||
        tiposSolicitados.includes('manejos-sanitarios')
      ) {
        let manejoSingle = ''

        if (tiposSolicitados.includes('manejos-reprodutivos') && !tiposSolicitados.includes('manejos-sanitarios'))
          manejoSingle = 'reprodutivo'

        if (!tiposSolicitados.includes('manejos-reprodutivos') && tiposSolicitados.includes('manejos-sanitarios'))
          manejoSingle = 'sanitario'

        let manejosDoAnimal: any = await this.db.run({
          query: `
					SELECT DISTINCT gestor_manejo.*,
					gestor_lotes.nome as nome_lote,
					(
						SELECT COUNT(DISTINCT gestor_relacao_tripla.hash_de)
						FROM gestor_relacao_tripla
							WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
							AND gestor_relacao_tripla.de = 'gestor_animais'
							AND gestor_relacao_tripla.para = 'gestor_procedimento'
							AND gestor_relacao_tripla.status = 1
					) as qtd_animais,
					(
						SELECT COUNT(gestor_relacao_tripla.hash_de)
						FROM gestor_relacao_tripla
							INNER JOIN gestor_procedimento
							ON gestor_procedimento.hash = gestor_relacao_tripla.hash_de
							AND gestor_procedimento.status = 1
						WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
						AND gestor_relacao_tripla.de = 'gestor_procedimento'
						AND gestor_relacao_tripla.para = 'gestor_manejo'
						AND gestor_relacao_tripla.status = 1
					) as qtd_manejos,
					(
						SELECT GROUP_CONCAT(gestor_procedimento.identificador, ';;;')
						FROM gestor_relacao_tripla
							INNER JOIN gestor_procedimento
							ON gestor_procedimento.hash = gestor_relacao_tripla.hash_de
							AND gestor_procedimento.status = 1
						WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
						AND gestor_relacao_tripla.de = 'gestor_procedimento'
						AND gestor_relacao_tripla.para = 'gestor_manejo'
						AND gestor_relacao_tripla.status = 1
					) as id_manejos,
					(
						SELECT GROUP_CONCAT(gestor_procedimento.especificacao, ';;;')
						FROM gestor_relacao_tripla
							INNER JOIN gestor_procedimento
							ON gestor_procedimento.hash = gestor_relacao_tripla.hash_de
							AND gestor_procedimento.status = 1
						WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
						AND gestor_relacao_tripla.de = 'gestor_procedimento'
						AND gestor_relacao_tripla.para = 'gestor_manejo'
						AND gestor_relacao_tripla.status = 1
					) as obs_manejos

					FROM gestor_manejo
						INNER JOIN gestor_relacao_tripla AS relacaoComProcedimento
							ON relacaoComProcedimento.hash_para = gestor_manejo.hash
							AND relacaoComProcedimento.status = 1

						INNER JOIN gestor_relacao_tripla AS relacaoAnimalProcedimento
							ON relacaoAnimalProcedimento.hash_para = relacaoComProcedimento.hash_de
							AND relacaoAnimalProcedimento.hash_de = '${hash}'
							AND relacaoAnimalProcedimento.status = 1
							AND relacaoAnimalProcedimento.contexto = 'gestor_manejo'

						LEFT JOIN gestor_lotes ON gestor_lotes.hash = gestor_manejo.hash_lote
					WHERE gestor_manejo.status = 1
            ${manejoSingle ? `AND gestor_manejo.tipo = '${manejoSingle}'` : ``}
						AND data >= '${de} 00:00:00'
						AND data <= '${ate} 23:59:59'
					ORDER BY gestor_manejo.data DESC
				`
        })

        manejosDoAnimal.forEach((manejoSalvo) => {
          let manejo = {
            tipo: manejoSalvo.tipo,
            hash: manejoSalvo.hash,
            icone: manejoSalvo.tipo == 'sanitario' ? 'icone-seringa' : 'icone-fertilizacao',
            heading: 'Manejo ' + (manejoSalvo.tipo == 'sanitario' ? 'Sanitário' : 'Reprodutivo'),
            texto: 'Nenhum manejo relacionado',
            lista: []
          }
          let idsProcedimentos = manejoSalvo.id_manejos ? manejoSalvo.id_manejos.split(';;;') : []
          let descricoesProcedimentos = manejoSalvo.obs_manejos ? manejoSalvo.obs_manejos.split(';;;') : []

          let conteudoManejos = []

          for (let index in idsProcedimentos) {
            let idProcedimento = idsProcedimentos[index]
            let novoManejo = ''

            if (manejoSalvo.tipo == 'sanitario') novoManejo += Utils.manejosObjetoDeTraducao[idProcedimento].texto
            else novoManejo += Utils.procedimentosObjetoDeTraducao[idProcedimento].texto

            if (descricoesProcedimentos[index]) novoManejo += ' <i>"' + descricoesProcedimentos[index] + '"</i>'

            conteudoManejos.push(novoManejo)
          }
          manejo['texto'] = ''
          manejo['lista'] = conteudoManejos

          acontecimentos[manejoSalvo.data].push(manejo)
        })
      }

      for (let dia of diasHistorico)
        if (acontecimentos[dia].length) retorno.push({ data: dia, dados: acontecimentos[dia] })

      resolve(retorno.reverse())
    })
  }
}
