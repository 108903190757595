import { Component, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { CalendarioComponent } from '../calendario/calendario.component'
import { DadosLinhaTabela } from '../vasta-lista/tabela-types'

export interface IOptionButtons {
  chave?: string
  icone?: string
  titulo: string
  disabled?: boolean
  classe?: string
  cor?: string
  acao?: (a: DadosLinhaTabela, dataSelecionada?: string) => Promise<void> | void
  exibirCalendario?: boolean
  qtd?: number
}

@Component({
  selector: 'app-opcoes-botoes',
  templateUrl: './opcoes-botoes.component.html',
  styleUrls: ['./opcoes-botoes.component.scss']
})
export class OpcoesBotoesComponent implements OnInit {
  public options: IOptionButtons[]
  public data = new Date().toISOString()
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  async acao(item: IOptionButtons, event?: Event): Promise<void> {

    if (item.exibirCalendario) {
      const modal = await this.popoverCtrl.create({
        component: CalendarioComponent,
        event,
        reference: 'trigger',
        cssClass: 'popover-calendario'
      })

      await modal.present()
      const { data } = await modal.onDidDismiss()

      if (data) {
        await this.popoverCtrl.dismiss({ ...item, ...{ date: data.iso }, event })
      }
    } else this.popoverCtrl.dismiss(item)
  }
}
