import * as moment from "moment";

export const sqlFilter = {
  safeOrderBy: (opcoes: { coluna: string; direcao: string; prefixo: boolean; table?: string; tableCase?: {[key: string]: string} }): string => {
    let { coluna, direcao, prefixo, table, tableCase } = opcoes
    tableCase = tableCase || {}
    table = table || tableCase[coluna] || ''
    direcao = direcao == 'desc' ? 'desc' : 'asc' 
    coluna = coluna && coluna?.length > 20 ? '' : coluna
    if (!coluna) return ''
    if (coluna == 'numeracao' && !table) {
      return coluna && direcao
        ? `
        ${prefixo ? `ORDER BY` : ''}
        gestor_animais.numeracao IS NULL,
        gestor_animais.numeracao = '',
        CAST(gestor_animais.numeracao AS unsigned) ${direcao},
        gestor_animais.numeracao ${direcao}
        `
        : ''
    } if (coluna == 'pesagem_gmd' && !table) {
      return coluna && direcao ? `
        ${prefixo ? `ORDER BY` : ''}
        ${table ? table + '.' : ''}${coluna} ${direcao}
      ` : ''
    } else {
      return coluna && direcao ? `
        ${prefixo ? `ORDER BY` : ''} 
        ${table ? table + '.' : ''}${coluna} IS NULL,
        ${table ? table + '.' : ''}${coluna} = '',
        CAST(${table ? table + '.' : ''}${coluna} AS unsigned) ${direcao},
        ${table ? table + '.' : ''}${coluna} ${direcao}
      ` : ''
    }
  },
  safeLimitOffset: (limitValue: string | number, offsetValue?: string | number): string => {
      let str = `LIMIT ${Number(limitValue || 50)}`
      str += offsetValue ? ` OFFSET ${Number(offsetValue || 0)}` : ''
      return str
  },
  safeSelectIn: (opcoes: { coluna: string; tag: string; valor: string | string[], or?: boolean }): string => {
    let { coluna, tag, valor, or } = opcoes
    if (typeof valor == 'string') valor = [valor]
    if(JSON.stringify(valor) == '[]') return ''
    if (valor === undefined || !(valor || valor?.length || Number(valor) == 0)) return ''
    if (valor) {
      return `${or ? 'OR' : 'AND' } ${coluna} IN ("${valor.join('","')}")`
    } else return ''
  },
  safeSelectNotIn: (opcoes: { coluna: string; tag: string; valor: string[] }): string => {
    const { coluna, valor } = opcoes
    if (valor === undefined || !(valor || valor?.length || Number(valor) == 0)) return ''
    if (valor) {
      return `AND ${coluna} NOT IN ('${valor.join("','")}')`
    } else return ''
  },
  safeEquals: (opcoes: { coluna: string; tag: string; valor: number | string | string[] }): string => {
    const { coluna, tag, valor } = opcoes
    if (valor === undefined || !(valor || String(valor)?.length || Number(valor) == 0)) return ''
    return `AND ${coluna} = ${tag}`
  },
  safeNotNull: (opcoes: { coluna: string; valor: string | string[] }): string => {
    const { coluna, valor } = opcoes
    return valor === 'false'
      ? `AND (${coluna} IS NULL OR ${coluna} = '')`
      : valor === 'true'
      ? `AND (${coluna} IS NOT NULL AND ${coluna} <> '')`
      : ''
  },
  safeLike: (opcoes: { coluna: string; tag: string; valor: string | string[] }): string => {
    const { coluna, tag, valor } = opcoes
    if (valor === '' || valor === undefined || !(valor || valor?.length || Number(valor) == 0)) return ''
    return `AND ${coluna} LIKE ${tag}`
  },
  safeDateRange: (opcoes: { coluna: string; tag: string; valor: string | string[] }): string => {
    const { coluna, valor } = opcoes

    if (valor === undefined || valor.length !== 2 || !(valor || valor?.length || Number(valor) == 0)) return ''

    const [de, ate] = valor

    if (String(de).length !== 10 || String(ate).length !== 10) return ''
    if (String(de)[4] !== '-' || String(de)[7] !== '-' || String(ate)[4] !== '-' || String(ate)[7] !== '-') return ''

    return `AND (${coluna} >= '${de}' AND ${coluna} < '${moment(ate).add(1, 'day').format('YYYY-MM-DD')}')`
  },
  safeNumberRange: (opcoes: { coluna: string; tag: string; valor: string | string[] | number[] }): string => {
    const { coluna, valor } = opcoes

    if (valor === undefined || valor.length !== 2 || !(valor || valor?.length || Number(valor) == 0)) return ''

    let [de, ate] = valor
    de = Number(de)
    ate = Number(ate)

    return `AND (${coluna} >= ${de} AND ${coluna} <= ${ate})`
  },
}
