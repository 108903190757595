export interface ICategoria {
  id?: string
  nome: string
  descricao?: string
  type?: string
  
  match?: boolean
}

const f_Entradas: ICategoria[] = [
  { type: 'separator', id: 'receitas_pecuarias', nome: 'Receitas Pecuárias' },
  { id: 'receitas_pecuarias_venda_de_animal', nome: 'Venda De Animal' },
  { id: 'receitas_pecuarias_venda_de_semen', nome: 'Venda De Sêmen' },
  { id: 'receitas_pecuarias_venda_de_embriao', nome: 'Venda De Embrião' },
  { id: 'receitas_pecuarias_venda_de_leite', nome: 'Venda De Leite' },
  { id: 'receitas_pecuarias_venda_de_estoque', nome: 'Venda De Estoque' },
  { id: 'receitas_pecuarias_femeas_para_abate', nome: 'Fêmeas Para Abate' },
  { id: 'receitas_pecuarias_femeas_para_recria', nome: 'Fêmeas Para Recria' },
  { id: 'receitas_pecuarias_femeas_p_reproducao', nome: 'Fêmeas P/ Reprodução' },
  { id: 'receitas_pecuarias_machos_para_abate', nome: 'Machos Para Abate' },
  { id: 'receitas_pecuarias_machos_para_recria', nome: 'Machos Para Recria' },
  { id: 'receitas_pecuarias_machos_p_reproducao', nome: 'Machos P/ Reprodução' },
  { id: 'receitas_pecuarias_outros', nome: 'Outros' },
  { type: 'separator', id: 'receitas_gerais', nome: 'Receitas Gerais' },
  { id: 'receitas_gerais_arrendamento_de_pastagem', nome: 'Arrendamento De Pastagem' },
  { id: 'receitas_gerais_aluguel_de_pastagem', nome: 'Aluguel De Pastagem' },
  { id: 'receitas_gerais_aluguel', nome: 'Aluguel' },
  { id: 'receitas_gerais_aluguel_de_espaco', nome: 'Aluguel De Espaço' },
  { id: 'receitas_gerais_aluguel_de_maquinas', nome: 'Aluguel De Máquinas' },
  { id: 'receitas_gerais_emprestimo', nome: 'Empréstimo' },
  { id: 'receitas_gerais_ferramentas_e_equipamentos', nome: 'Ferramentas E Equipamentos' },
  { id: 'receitas_gerais_madeiras_e_outros', nome: 'Madeiras E Outros' },
  { id: 'receitas_gerais_reciclaveis', nome: 'Recicláveis' },
  { id: 'receitas_gerais_redimento_financeiro', nome: 'Rendimento Financeiro' },
  { id: 'receitas_gerais_tropa_de_servico', nome: 'Tropa De Serviço' },
  { id: 'receitas_gerais_tropa_reproducao', nome: 'Tropa Reprodução' },
  { id: 'receitas_gerais_veiculos_tratores_implementos', nome: 'Veículos, Tratores Implementos' },
  { id: 'receitas_gerais_recebimento', nome: 'Recebimento' },
  { id: 'receitas_gerais_salario', nome: 'Salário' },
  { id: 'receitas_gerais_pro_labore', nome: 'Pró-Labore' },
  { id: 'receitas_gerais_13_salario', nome: '13° Salário' },
  { id: 'receitas_gerais_alimentacao_animal', nome: 'Alimentação Animal' },
  { id: 'receitas_gerais_consultoria', nome: 'Consultoria' },
  { id: 'receitas_gerais_materia_prima', nome: 'Matéria Prima' },
  { id: 'receitas_gerais_venda', nome: 'Venda' },
  { id: 'receitas_gerais_outros', nome: 'Outros' }
]

const f_Saidas: ICategoria[] = [
  { type: 'separator', id: 'despesas_investimentos_bovino', nome: 'Investimentos Bovinos' },
  { id: 'despesas_investimentos_bovino_bovinos', nome: 'Bovinos' },
  { id: 'despesas_investimentos_bovino_identificacao', nome: 'Identificação' },
  { id: 'despesas_investimentos_bovino_frete', nome: 'Frete' },
  { type: 'separator', id: 'despesas_investimentos_infra_estrutura', nome: 'Investimentos Infra Estrutura' },
  { id: 'despesas_investimentos_infra_estrutura_barracoes_e_outros', nome: 'Barrações E Outros' },
  { id: 'despesas_investimentos_infra_estrutura_cercas', nome: 'Cercas' },
  { id: 'despesas_investimentos_infra_estrutura_estradas_e_pontes', nome: 'Estradas E Pontes' },
  {
    id: 'despesas_investimentos_infra_estrutura_ferramentas_e_equipamentos',
    nome: 'Ferramentas E Equipamentos'
  },
  { id: 'despesas_investimentos_infra_estrutura_formacao_de_pasto', nome: 'Formação De Pasto' },
  {
    id: 'despesas_investimentos_infra_estrutura_imoveis_rurais_e_urbanos',
    nome: 'Imóveis Rurais E Urbanos'
  },
  {
    id: 'despesas_investimentos_infra_estrutura_informatica_telefonia_e_comunicacao',
    nome: 'Informática; Telefonia E Comunicação'
  },
  {
    id: 'despesas_investimentos_infra_estrutura_instalacoes_pecuarias',
    nome: 'Instalações Pecuárias'
  },
  {
    id: 'despesas_investimentos_infra_estrutura_instalacoes_residenciais',
    nome: 'Instalações Residenciais'
  },
  {
    id: 'despesas_investimentos_infra_estrutura_moveis_e_eletrodomesticos',
    nome: 'Móveis E Eletrodomésticos'
  },
  {
    id: 'despesas_investimentos_infra_estrutura_projetos_medicoes_e_outros',
    nome: 'Projetos, Medições E Outros'
  },
  { id: 'despesas_investimentos_infra_estrutura_rede_eletrica', nome: 'Rede Elétrica' },
  { id: 'despesas_investimentos_infra_estrutura_rede_hidraulica', nome: 'Rede Hidráulica' },
  { id: 'despesas_investimentos_infra_estrutura_reflorestamento', nome: 'Reflorestamento' },
  { id: 'despesas_investimentos_infra_estrutura_seguranca', nome: 'Segurança' },
  {
    id: 'despesas_investimentos_infra_estrutura_solo_contecoes_destoca_e_recup',
    nome: 'Solo Conteções, Destoca E Recup.'
  },
  {
    id: 'despesas_investimentos_infra_estrutura_veiculos_tratores_e_implementos',
    nome: 'Veículos, Tratores E Implementos'
  },
  { id: 'despesas_investimentos_infra_estrutura_aeronaves', nome: 'Aeronaves' },

  { type: 'separator', id: 'despesas_investimentos_rh', nome: 'Investimentos em RH' },
  { id: 'despesas_investimentos_rh_eventos_presentes', nome: 'Eventos Presentes' },
  { id: 'despesas_investimentos_rh_treinamentos', nome: 'Treinamentos' },
  { id: 'despesas_investimentos_rh_uniformes_e_epi', nome: 'Uniformes E Epi' },

  { type: 'separator', id: 'despesas_mao_de_obra_permanente', nome: 'Mão de Obra Permanente' },
  { id: 'despesas_mao_de_obra_permanente_premios_e_beneficios', nome: 'Prêmios E Benefícios' },
  { id: 'despesas_mao_de_obra_permanente_rescisoes_e_acertos', nome: 'Rescisões E Acertos' },
  { id: 'despesas_mao_de_obra_permanente_salarios_e_encargos', nome: 'Salários E Encargos' },
  { id: 'despesas_mao_de_obra_permanente_13_salario', nome: '13 Salário' },
  { id: 'despesas_mao_de_obra_permanente_adiantamento_salarial', nome: 'Adiantamento Salarial' },
  { id: 'despesas_mao_de_obra_permanente_ferias', nome: 'Férias' },
  { id: 'despesas_mao_de_obra_permanente_pro_labore', nome: 'Pró-Labore' },
  {
    id: 'despesas_mao_de_obra_permanente_exames_medicos_dos_funcionarios',
    nome: 'Exames Médicos Dos Funcionários'
  },
  { type: 'separator', id: 'despesas_contratacao_de_servicos', nome: 'Contratação de Serviços' },
  { id: 'despesas_contratacao_de_servicos_servicos_gerais', nome: 'Serviços Gerais' },
  { id: 'despesas_contratacao_de_servicos_terceiros', nome: 'Terceiros' },
  { id: 'despesas_contratacao_de_servicos_servicos_veterinarios', nome: 'Serviços Veterinários' },
  { id: 'despesas_contratacao_de_servicos_servicos_agronomicos', nome: 'Serviços Agronômicos' },
  { id: 'despesas_contratacao_de_servicos_marketing_propaganda', nome: 'Marketing/Propaganda' },
  { id: 'despesas_contratacao_de_servicos_consultoria', nome: 'Consultoria' },

  { type: 'separator', id: 'despesas_administrativa', nome: 'Administrativa' },
  { id: 'despesas_administrativa_agua', nome: 'Água' },
  { id: 'despesas_administrativa_alimentacao', nome: 'Alimentação' },
  { id: 'despesas_administrativa_alugueis_e_condominio', nome: 'Aluguéis E Condomínio' },
  { id: 'despesas_administrativa_assinaturas_e_publicacoes', nome: 'Assinaturas E Publicações' },
  { id: 'despesas_administrativa_associacoes_e_registros', nome: 'Associações E Registros' },
  { id: 'despesas_administrativa_custeio', nome: 'Custeio' },
  { id: 'despesas_administrativa_combustivel', nome: 'Combustível' },
  { id: 'despesas_administrativa_credito_rural', nome: 'Crédito Rural' },
  { id: 'despesas_administrativa_cartao_de_credito', nome: 'Cartão de Crédito' },
  { id: 'despesas_administrativa_doacoes', nome: 'Doações' },
  { id: 'despesas_manutencao_da_fazenda_estradas_e_pontes', nome: 'Estradas E Pontes' },
  { id: 'despesas_administrativa_emprestimo', nome: 'Empréstimo' },
  { id: 'despesas_administrativa_financiamento_bancario', nome: 'Financiamento Bancário' },
  { id: 'despesas_administrativa_impostos_e_taxas', nome: 'Impostos E Taxas' },
  { id: 'despesas_administrativa_internet', nome: 'Internet' },
  { id: 'despesas_administrativa_manutencao_e_conservacao', nome: 'Manutenção E Conservação' },
  { id: 'despesas_administrativa_softwares', nome: 'Softwares' },
  {
    id: 'despesas_administrativa_material_de_expediente_escritorio',
    nome: 'Material De Expediente, Escritório'
  },
  { id: 'despesas_administrativa_seguro', nome: 'Seguro' },
  { id: 'despesas_administrativa_seguro_de_animais', nome: 'Seguro de Animais' },
  { id: 'despesas_administrativa_telefone', nome: 'Telefone' },
  { id: 'despesas_administrativa_luz', nome: 'Luz' },
  { id: 'despesas_administrativa_pedagio', nome: 'Pedágio' },
  {
    id: 'despesas_administrativa_taxas_e_impostos_sobre_a_venda',
    nome: 'Taxas E Impostos Sobre A Venda'
  },
  {
    id: 'despesas_administrativa_taxas_e_impostos_sobre_o_lucro',
    nome: 'Taxas E Impostos Sobre O Lucro'
  },
  { id: 'despesas_administrativa_taxas_e_impostos', nome: 'Taxas E Impostos' },
  { id: 'despesas_administrativa_juros_e_despesas_bancarias', nome: 'Juros e Despesas Bancárias' },
  { type: 'separator', id: 'despesas_manutencao_da_fazenda', nome: 'Manutenção da Fazenda' },
  { id: 'despesas_manutencao_da_fazenda_cercas', nome: 'Cercas' },
  { id: 'despesas_manutencao_da_fazenda_cochos_e_bebedouros', nome: 'Cochos E Bebedouros' },
  {
    id: 'despesas_manutencao_da_fazenda_ferramentas_e_equipamentos',
    nome: 'Ferramentas E Equipamentos'
  },
  { id: 'despesas_manutencao_da_fazenda_barracoes_e_outros', nome: 'Barrações E Outros' },
  { id: 'despesas_manutencao_da_fazenda_horta_pomar_e_jardins', nome: 'Horta, Pomar E Jardins' },
  {
    id: 'despesas_manutencao_da_fazenda_informatica_telefonia_e_comunicacao',
    nome: 'Informática; Telefonia E Comunicação'
  },
  {
    id: 'despesas_manutencao_da_fazenda_instalacoes_residenciais',
    nome: 'Instalações Residenciais'
  },
  {
    id: 'despesas_manutencao_da_fazenda_moveis_e_eletrodomesticos',
    nome: 'Móveis E Eletrodomésticos'
  },
  { id: 'despesas_manutencao_da_fazenda_rede_eletrica', nome: 'Rede Elétrica' },
  { id: 'despesas_manutencao_da_fazenda_rede_hidraulica', nome: 'Rede Hidráulica' },
  { id: 'despesas_manutencao_da_fazenda_seguranca', nome: 'Segurança' },
  { id: 'despesas_manutencao_da_fazenda_pastagens', nome: 'Pastagens' },
  { id: 'despesas_manutencao_da_fazenda_adubos_e_fertilizantes', nome: 'Adubos E Fertilizantes' },
  { type: 'separator', id: 'despesas_outros', nome: 'Outros Animais' },
  { id: 'despesas_outros_animais_aves', nome: 'Aves' },
  { id: 'despesas_outros_animais_ovinos', nome: 'Ovinos' },
  { id: 'despesas_outros_animais_suinos', nome: 'Suínos' },
  { id: 'despesas_outros_animais_equinos', nome: 'Equinos' },
  { type: 'separator', id: 'despesas_tropa', nome: 'Tropa' },
  { id: 'despesas_tropa_de_servico', nome: 'Tropa de Serviço' },
  { id: 'despesas_tropa_de_reproducao', nome: 'Tropa de Reprodução' },
  { type: 'separator', id: 'despesas_maquinas', nome: 'Máquinas' },
  { id: 'despesas_maquinas_combustiveis', nome: 'Combustíveis' },
  { id: 'despesas_maquinas_manutencao_e_conserto', nome: 'Manutenção E Conserto' },
  {
    id: 'despesas_maquinas_seguros_impostos_multas_e_outros',
    nome: 'Seguros, Impostos, Multas E Outros'
  },
  { type: 'separator', id: 'despesas_nutricao', nome: 'Nutrição' },
  { id: 'despesas_nutricao_nutricao_animal', nome: 'Nutrição Animal' },
  { id: 'despesas_nutricao_aveia', nome: 'Aveia' },
  { id: 'despesas_nutricao_cana', nome: 'Cana' },
  { id: 'despesas_nutricao_feno', nome: 'Feno' },
  { id: 'despesas_nutricao_milheto', nome: 'Milheto' },
  { id: 'despesas_nutricao_silagem_de_milho', nome: 'Silagem De Milho' },
  { id: 'despesas_nutricao_silagem_de_sorgo', nome: 'Silagem De Sorgo' },
  { id: 'despesas_nutricao_sorgo', nome: 'Sorgo' },
  { id: 'despesas_nutricao_aditivo', nome: 'Aditivo' },
  { id: 'despesas_nutricao_nucleo', nome: 'Núcleo' },
  { id: 'despesas_nutricao_racao', nome: 'Ração' },
  { id: 'despesas_nutricao_sal_mineral', nome: 'Sal Mineral' },
  { id: 'despesas_nutricao_caroco_de_algodao', nome: 'Caroço De Algodão' },
  { id: 'despesas_nutricao_cevada', nome: 'Cevada' },
  { id: 'despesas_nutricao_farelo_de_trigo', nome: 'Farelo De Trigo' },
  { id: 'despesas_nutricao_volumoso', nome: 'Volumoso' },
  { id: 'despesas_nutricao_energeticos', nome: 'Energéticos' },
  { id: 'despesas_nutricao_concentrado', nome: 'Concentrado' },
  { id: 'despesas_nutricao_proteicos', nome: 'Proteicos' },
  { id: 'despesas_nutricao_suplementacao', nome: 'Suplementação' },
  { id: 'despesas_nutricao_soja', nome: 'Soja' },
  { id: 'despesas_nutricao_farelo_de_amendoim', nome: 'Farelo de Amendoim' },
  { type: 'separator', id: 'despesas_sanidade', nome: 'Sanidade' },
  { id: 'despesas_sanidade_vacinas', nome: 'Vacinas' },
  { id: 'despesas_sanidade_medicamentos', nome: 'Medicamentos' },
  { id: 'despesas_sanidade_seringas', nome: 'Seringas' },
  { id: 'despesas_sanidade_vermifugos', nome: 'Vermífugos' },
  { id: 'despesas_sanidade_pulvarizador_costal', nome: 'Pulvarizador Costal ' },
  {
    id: 'despesas_sanidade_macacao_luvas_e_oculos_de_seguranca',
    nome: 'Macacão, Luvas E Óculos De Segurança'
  },
  { id: 'despesas_sanidade_agulhas', nome: 'Agulhas' },
  { id: 'despesas_sanidade_caixa_de_isopor', nome: 'Caixa De Isopor' },
  { type: 'separator', id: 'despesas_reproducao', nome: 'Reprodução' },
  { id: 'despesas_reproducao_embrioes', nome: 'Embriões' },
  { id: 'despesas_reproducao_semens', nome: 'Sêmens' },
  { id: 'despesas_reproducao_implantes_de_progesterona', nome: 'Implantes De Progesterona' },
  { id: 'despesas_reproducao_medicamentos_e_hormonios', nome: 'Medicamentos E Hormônios' },
  { id: 'despesas_reproducao_aplicadores', nome: 'Aplicadores ' },
  { id: 'despesas_reproducao_luvas_baldes_e_assessorios', nome: 'Luvas, Baldes E Assessórios' },
  { id: 'despesas_reproducao_seringas_e_agulhas', nome: 'Seringas E Agulhas' },
  { id: 'despesas_reproducao_bastao_marcador', nome: 'Bastão Marcador' },
  { id: 'despesas_reproducao_adesivos', nome: 'Adesivos' },
  { id: 'despesas_reproducao_botijao_de_semen', nome: 'Botijão De Sêmen' },
  { id: 'despesas_reproducao_bainhas', nome: 'Bainhas' },
  { id: 'despesas_reproducao_descongelador_de_semen', nome: 'Descongelador De Sêmen' }
]

const Categorias = {
  financeiro_entrada: f_Entradas,
  financeiro_saida: f_Saidas
}
export { Categorias }
