import { ErrorBluetooth } from '../../../services/bluetooth/Error/ErrorBluetooth'
import { setObserverMensagemStatusConexao } from '../../../services/bluetooth/dispositivos/observer-dispositivos'
import { DispositivoConexao, DispositivoLowenergy, DispositivoSelecionado, OpcoesBuscarPorDispositivosLowEnergy, OpcoesConexaoBluetooth, OpcoesNotificacoesBluetoothLowEnergy, ResultadoConexaoBluetooth, ResultadoHabilitarBluetooth, StatusConexao } from '../../../services/bluetooth/interfaces/bluetooth-interface'
import { GlobalService } from '../../../services/global.service'
import { ApplicationRef, Injectable } from '@angular/core'
import { BleClient, ScanResult } from '@capacitor-community/bluetooth-le'
import { ActionSheetController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HelperLowEnergyService {
  constructor(
    private ref: ApplicationRef,
    private actionSheetCtrl: ActionSheetController,
    private globalCtrl: GlobalService
  ) {
  }

  emitStatusConexaoBastao(statusConexao: StatusConexao, dispositivoSelecionado?: DispositivoSelecionado): void {
    VastaRX.setState('dispositivo_conexao', {
      dispositivoSelecionado: dispositivoSelecionado || undefined,
      tipoDispositivo: 'bastao',
      statusConexao
    } as DispositivoConexao)
  }

  async isBluetoothLEHabilitado(): Promise<ResultadoHabilitarBluetooth> {
    try {
      const isBluetoothHabilitado = await BleClient.isEnabled()
      return { isEnabled: isBluetoothHabilitado }
    } catch (error) {
      console.error(error)
    }
  }

  async actionSheetSeletorDispositivo(opcoes: { devices: DispositivoLowenergy[] }): Promise<DispositivoLowenergy> {
    return new Promise(async (callback, reject) => {
      let opcaoSelecionada = false
      const { devices } = opcoes
      const buttons = []
      devices.map((device) => {
        buttons.push({
          text: `${device.name} ${device.deviceId}`,
          handler: () => {
            opcaoSelecionada = true
            callback(device)
          }
        })
      })
      buttons.push({
        text: 'Cancelar',
        role: 'cancel',
        icon: 'close'
      })
      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Selecionar Dispositivo',
        buttons
      })
      await actionSheet.present()
      await actionSheet.onDidDismiss()
      if (!opcaoSelecionada) {
        reject()
      }
    })
  }

  pluginConectarBalanca(opcoes: OpcoesConexaoBluetooth): Observable<ResultadoConexaoBluetooth> {
    return new Observable((observer) => {
      console.warn('conectando...')

      setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Conectando...' })

      BleClient.connect(opcoes.deviceId, (deviceId: string) => {
        // AO DESCONECTAR O DISPOSITIVO
        console.error('dispositivo desconectado')
        observer.error({ deviceId, isConnected: false, message: 'Dispositivo Desconectado' })
      })
        .then((_isConectado) => {
          // SUCESSO AO CONECTAR
          observer.next({ deviceId: opcoes.deviceId, isConnected: true })
        })
        .catch((error) => {
          // ERRO AO CONECTAR
          console.warn('Erro ao conectar dispositivo')
          console.error(error)
          observer.error({ deviceId: opcoes.deviceId, isConnected: false })
        })
    })
  }

  async pluginBuscarDispositivo(
    opcoes: OpcoesBuscarPorDispositivosLowEnergy
  ): Promise<{ devices: DispositivoLowenergy[] }> {
    return new Promise(async (resolve, reject) => {
      const dispositivos: DispositivoLowenergy[] = []
      let qtdBuscas = 0

      BleClient.requestLEScan({ services: opcoes?.services }, (result: ScanResult) => {
        // Retorna apenas o dispositivo associado ao service
        if (opcoes?.services) {
          BleClient.stopLEScan()
          resolve({ devices: [result.device] })
        } else {
          // Retorna lista de dispositivos encontrados
          qtdBuscas++

          dispositivos.push(result.device)

          if (qtdBuscas >= opcoes?.limiteDeBuscas) {
            BleClient.stopLEScan()
            resolve({ devices: dispositivos })
          }
        }
      })
    })
  }

  async getDispositivosConectados(
    opcoes: Omit<OpcoesBuscarPorDispositivosLowEnergy, 'limiteDeBuscas'>
  ): Promise<DispositivoLowenergy[]> {
    return new Promise(async (resolve, reject) => {
      const devices = await BleClient.getConnectedDevices(opcoes?.services)

      if (devices) {
        resolve(devices)
      } else {
        const errorBluetooth = new ErrorBluetooth('Erro ao buscas dispositivos conectados')
        reject(errorBluetooth)
      }
    })
  }

  pluginHabilitarNotificacoesBluetooth(): Observable<ResultadoHabilitarBluetooth> {
    return new Observable((observer) => {
      BleClient.startEnabledNotifications((isBluetoothHabilitado) => {
        if (!isBluetoothHabilitado) {
          console.error('Bluetooth desabilitado!')
          BleClient.stopEnabledNotifications()
          observer.next({ isEnabled: false })
          observer.complete()
          observer.unsubscribe()
        }
      })
    })
  }

  emitirPesoFormatado(pesoFormatado: string): void {
    this.globalCtrl.set('pesoNaBalanca', pesoFormatado || '0')
    VastaRX.setState('peso_balanca', pesoFormatado)
    this.ref.tick()
  }

  async buscarDispositivosPorEmPainel(
    opcoes: Omit<OpcoesBuscarPorDispositivosLowEnergy, 'limiteDeBuscas'>
  ): Promise<DispositivoLowenergy> {
    try {
      const device = await BleClient.requestDevice({ services: opcoes?.services })

      const dispositivosLowenergy: DispositivoLowenergy = {
        ...device
      }

      return dispositivosLowenergy
    } catch (error) {
      console.error(error)
    }
  }

  startNotifications(opcoes: OpcoesNotificacoesBluetoothLowEnergy): Observable<DataView> {
    return new Observable((observer) => {
      BleClient.startNotifications(opcoes.deviceId, opcoes.service, opcoes.characteristic, (result: DataView) => {
        observer.next(result)
      })
    })
  }
}
