import { DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { HttpClientModule } from '@angular/common/http'
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx'
import { ComponentsModule } from './components/components.module'
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx'
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx'

import { setAppInjector } from './utils'
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null
registerLocaleData(localePt, 'pt-br')

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot({ mode: 'md' }), AppRoutingModule, HttpClientModule, ComponentsModule, NgxMaskModule.forRoot(options)],
  providers: [
    AndroidPermissions,
    SQLite,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    BluetoothSerial,
    { provide: LOCALE_ID, useValue: 'pt-br' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' }
  ],
   bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector)
  }
}
