let Racas = {
  'aberdeen-angus': 'Aberdeen Angus',
  africander: 'Africander',
  ayrshire: 'Ayrshire',
  barzona: 'Barzona',
  beefalo: 'Beefalo',
  beefmaster: 'Beefmaster',
  'belgian-blue': 'Belgian Blue',
  'blond-d-aquitaine': 'Blond D Aquitaine',
  bonsmara: 'Bonsmara',
  braford: 'Braford',
  brahman: 'Brahman',
  brahmental: 'Brahmental',
  braler: 'Braler',
  brangus: 'Brangus',
  bubalino: 'Bubalino',
  'campino-red-pied': 'Campino Red Pied',
  canadienne: 'Canadienne',
  canchim: 'Canchim',
  caracu: 'Caracu',
  charbray: 'Charbray',
  charoles: 'Charolês',
  chianina: 'Chianina',
  'crioulo-lageano': 'Crioulo Lageano',
  devon: 'Devon',
  dexter: 'Dexter',
  'dimamarques-vermelho': 'Dimamarques Vermelho',
  'east-flenisgh-read-pied': 'East Flenisgh Read Pied',
  eringer: 'Eringer',
  flamenga: 'Flamenga',
  fribourg: 'Fribourg',
  frisia: 'Frisia',
  galloway: 'Galloway',
  gascone: 'Gascone',
  gelbvieh: 'Gelbvieh',
  'gir-leiteiro': 'Gir Leiteiro',
  'gir-mocho': 'Gir Mocho',
  gir: 'Gir',
  girolando: 'Girolando',
  gronninger: 'Gronninger',
  guernsey: 'Guernsey',
  guzera: 'Guzerá',
  guzolando: 'Guzolando',
  guzonel: 'Guzonel',
  hariana: 'Hariana',
  'hays-converter': 'Hays Converter',
  hereford: 'Hereford',
  highland: 'Highland Escocês',
  holandes: 'Holandês',
  holstein: 'Holstein',
  indubrasil: 'Indubrasil',
  jersey: 'Jersey',
  jersolando: 'Jersolando',
  kangaian: 'Kangaian',
  limousin: 'Limousin',
  'lincoln-red': 'Lincoln Red',
  luing: 'Luing',
  'maine-anjou': 'Maine-Anjou',
  marchigiana: 'Marchigiana',
  maremmana: 'Maremmana',
  mestico: 'Mestiço',
  'mocho-nacional': 'Mocho Nacional',
  mueserhineissel: 'Mueserhineissel',
  nelogir: 'Nelogir',
  nelore: 'Nelore',
  'nelore-de-chifre': 'Nelore de Chifre',
  'nelore-mocho': 'Nelore Mocho',
  'nelore-pintado': 'Nelore Pintado',
  nerolando: 'Nelorando',
  nguni: 'Nguni',
  normandos: 'Normandos',
  'norwegian-red': 'Norwegian Red',
  outro: 'Outro',
  pantaneiro: 'Pantaneiro',
  'pardo-holandes': 'Pardo Holandês',
  'pardo-suico': 'Pardo Suiço',
  parthenaise: 'Parthenaise',
  piemontes: 'Piemontês',
  pinzgauer: 'Pinzgauer',
  pitangueiras: 'Pitangueiras',
  puruna: 'Purunã',
  ranger: 'Ranger',
  'red-angus': 'Red Angus',
  'red-brangus': 'Red Brangus',
  'red-dane': 'Red Dane',
  'red-poll': 'Red Poll',
  romagnola: 'Romagnola',
  salers: 'Salers',
  'santa-gertrudis': 'Santa Gertrudis',
  senepol: 'Senepol',
  shorthorn: 'Shorthorn',
  simbras: 'Simbras',
  simbrasil: 'Simbrasil',
  simental: 'Simental',
  sindi: 'Sindi',
  sindolando: 'Sindolando',
  sinjer: 'Sinjer',
  'south-devon': 'South Devon',
  sussex: 'Sussex',
  tabanel: 'Tabanel',
  tabapua: 'Tabapuã',
  tarentaise: 'Tarentaise',
  'tasmanian-grey': 'Tasmanian Grey',
  'texas-longhorn': 'Texas Longhorn',
  tuli: 'Tuli',
  wagyu: 'Wagyu',
  'welsh-black': 'Welsh Black',
  'west-flemish-rer': 'West Flemish Rer',
  zebulando: 'Zebulando',

  jafarabadi: 'Jafarabadi',
  murrah: 'Murrah',
  mediterraneo: 'Mediterrâneo',
  carabao: 'Carabao'
}
let Categorias = {
  bezerro: 'Bezerro',
  'bezerro-mamando': 'Bezerro Mamando',
  garrote: 'Garrote',
  'garrote-em-engorda': 'Garrote Em engorda',
  'garrote-em-reproducao': 'Garrote Em reprodução',
  boi: 'Boi',
  'boi-em-engorda': 'Boi Em engorda',
  touro: 'Touro',
  bezerra: 'Bezerra',
  'bezerra-mamando': 'Bezerra Mamando',
  novilha: 'Novilha',
  'novilha-em-engorda': 'Novilha Em engorda',
  'novilha-em-reproducao': 'Novilha Em reprodução',
  vaca: 'Vaca',
  'vaca-em-engorda': 'Vaca Em engorda',
  'vaca-em-reproducao': 'Vaca Em reprodução',
  'vaca-leiteira': 'Vaca Leiteira'
}

const Pelagens = {
  Amarela: 'Amarela',
  'Amarela Alaranjada': 'Amarela Alaranjada',
  'Amarela Chitada': 'Amarela Chitada',
  'Amarela Gargantilha': 'Amarela Gargantilha',
  'Araçá de Preto': 'Araçá de Preto',
  'Araçá de Vermelho': 'Araçá de Vermelho',
  Baia: 'Baia',
  Branca: 'Branca',
  'Branca Pintada de Vermelha': 'Branca Pintada de Vermelha',
  'Branca Pintada de Preto': 'Branca Pintada de Preto',
  Brazina: 'Brazina',
  Café: 'Café',
  'Castanha Mamona': 'Castanha Mamona',
  'Castanho Esc. Mamona': 'Castanho Esc. Mamona',
  'Cast. Esc. Est. Garg. Bargada': 'Cast. Esc. Est. Garg. Bargada',
  'Castanha Escura': 'Castanha Escura',
  'Castanha Esc. Uniforme': 'Castanha Esc. Uniforme',
  'Castanha Clara': 'Castanha Clara',
  'Castanha Pintada de Branco': 'Castanha Pintada de Branco',
  'Castanho Claro': 'Castanho Claro',
  'Cast. Esc. Pint. de Branco': 'Cast. Esc. Pint. de Branco',
  'Chumbado Claro': 'Chumbado Claro',
  'Chitada de Amarelo': 'Chitada de Amarelo',
  'Chitada Clara': 'Chitada Clara',
  'Chitada de Vermelho': 'Chitada de Vermelho',
  Cinza: 'Cinza',
  'Cinza Avermelhada': 'Cinza Avermelhada',
  'Cinza Clara': 'Cinza Clara',
  'Cinza Escura': 'Cinza Escura',
  'Chita Claro': 'Chita Claro',
  Creme: 'Creme',
  Fumaça: 'Fumaça',
  Lixa: 'Lixa',
  'Mamona de Preta': 'Mamona de Preta',
  'Mamona Pintada de Preta': 'Mamona Pintada de Preta',
  Manchada: 'Manchada',
  'Malhada de Amarelo': 'Malhada de Amarelo',
  'Malhada de Preto': 'Malhada de Preto',
  'Malhada de Vermelho': 'Malhada de Vermelho',
  'Moura Clara': 'Moura Clara',
  'Moura Escura': 'Moura Escura',
  'Moura de Vermelho': 'Moura de Vermelho',
  Osca: 'Osca',
  Oliveira: 'Oliveira',
  'Oliveira Preta': 'Oliveira Preta',
  'Oliveira Vermelha': 'Oliveira Vermelha',
  'Pintada de Amarelo': 'Pintada de Amarelo',
  'Pintada de Preto': 'Pintada de Preto',
  'Pintada de Vermelho': 'Pintada de Vermelho',
  Preta: 'Preta',
  'Preta Pintada de Branco': 'Preta Pintada de Branco',
  'Preta Bargada': 'Preta Bargada',
  Vermelha: 'Vermelha',
  'Vermelha Chitada': 'Vermelha Chitada',
  'Vermelha Gargantilha': 'Vermelha Gargantilha',
  Zaina: 'Zaina',
  Rajada: 'Rajada',
  Outro: 'Outro'
}

export { Racas, Categorias, Pelagens }
