import { ProcedimentosSanitarios, TipoManejos } from ".";

type ManejosSelecionadosHash = {
  [key in TipoManejos]: string;
};

type ManejoPesagem = {
  tipo: string;
  finalidade: string;
  peso: number;
  gmd: number;
  observacao?: string
};

type ManejoOrdenha = {
  tipo: string;
  turno?: number;
  ordenha_1?: number;
  ordenha_2?: number;
  ordenha_3?: number;
};

type ManejoAssociacaoDeBrinco = {
  brinco: string;
  tipo: string;
};

type ManejoRegistrarMorte = {
  tipo: string;
  motivo: string;
  detalhes: string;
  fotos: string[];
  localizacao: {
    latitude: number;
    longitude: number;
  };
}

type ManejoMovimentacaoEntreLotes = {
  tipo: string;
  finalidade: string;
  nome_lote_destino: string;
  hash_lote_destino: string;
};

type ManejoMovimentacaoEntreAreas = {
  nome: string;
  tipo: string;
  finalidade: string;
  hash: string;
  icone: string;
  nome_area_destino: string;
  nome_area_atual?: string;
  hash_area_destino: string;
  checked: boolean;
  is_reprodutivo?: boolean;
};

type ManejoMovimentacaoEntreConfinamento = {
  tipo: string;
  finalidade: string;
  nome_lote_destino: string;
  hash_lote_destino: string;
};

type ManejoParto = {
  tipo: string;
  filhotes: FilhotesModel[];
};

type ManejoDiagnosticoGestacao = {
  tipo: string;
  diagnostico?: TiposDiagnosticos;
  periodo_gestacional: string;
  ovario_esquerdo?: string;
  ovario_direito?: string;
  tonus_uterino?: string;
  hash_exposicao?: string;
};

enum TiposDiagnosticos {
  positivo = 'positivo',
  negativo = 'negativo',
  vazia = ''
}

type OpcoesSegment =
  | 'positivo'
  | 'negativo'
  | 'perdido'
  | 'nao-perdido'

type DadosManejoDiagnoticosModel = {
  ovario_esquerdo?: string,
  ovario_direito?: string,
  tonus_uterino?: string,
  diagnostico: TiposDiagnosticos,
  periodoGestacional?: string,
  hashExposicao?: string
}

type FilhotesModel = {
  hash: string;
  brinco?: string;
  dadosAnimal?:any;
}

type ManejoDiagnosticoCio = {
  tipo: string;
  diagnostico?: TiposDiagnosticos;
};

type ManejoInseminacaoArtificial = {
  tipo: string;
  semen: string;
  bastao?: string;
  muco?: string;
  inseminador?: string;
};

type BoiOuSemenSelecionadoModel = {
  nome: string,
  doadora?: string,
  hash: string,
}

type ManejoMontaNatural = {
  tipo: string;
  touro: {
    hash: string,
    nome: string
  };
};

type ManejoDesmame = {
  tipo: string;
  desmame: string;
};

type ManejoImplanteProgesterona = {
  tipo: string;
  implantado: string;
};

type ManejoRemocaoImplante = {
  tipo: string;
  removido: string;
};

type ManejoAborto = {
  tipo: string;
  realizado: string;
};

type ManejoDescarte = {
  tipo: string;
  descarte: string
  descricao?: string
}

type ManejoCastracao = {
  tipo: string;
  castracao: string
  descricao?: string
}

type ManejoEscoreCorporal = {
  tipo: string;
  escore: string;
};

type ManejoAlteracaoNumero = {
  tipo: string;
  novo_numero: string;
};

type ManejoSanitario<T, U> = {
  nome: U;
  tipo: string;
  hash: string;
  procedimento: T
  procedimento_sanitario_realizado?: boolean
  descricao: string
}

type ManejoNutricional = {
  tipo: string;
  categoria: string;
  hash_animal?: string
  hash_area?: string
  hash_lote?: string
  id_estoque?: number
  nutricional_hash_estoque?: string
  nutricional_quantidade?: number
  nutricional_duracao?: number
  extra_quantidade?: string
  icone?: string;
  checked?: boolean;
}

type ManejoSanitarioEstoque = {
  tipo: string;
  categoria: string;
  hash_animal?: string
  hash_area?: string
  hash_lote?: string
  id_estoque?: number
  sanitario_hash_estoque?: string
  sanitario_quantidade?: number
  meta_unidade?: string
  extra_quantidade?: string
  icone?: string;
  checked?: boolean;
}

type ManejoPluviometria = {
  chuva_quantidade?: string
  chuva_hora?: string
  observacao?: string;
}

type ManejoAlturaCapim = {
  [key: string]: {
    altura_capim?: number
    hash_piquete?: string
    nome_piquete?: string
  }
}

type ManejoPastejoRotacionado = {
  hash_piquete?: string
  nome_piquete?: string
  observacao?: string
}

type ManejoEscoreFezes = {
  escore_fezes?: number
  observacao?: string
}

type InseminadorModel = {
  hash: string,
  nome: string
}

/**
 * Serve para exibir apenas os valores do "Union type".
 * Possui dois parâmetros, o primeiro é um type do tipo "union type" ex: 'type1' | 'type2' | 'type3', o segundo é um valor que faz parte do union type utilizado no primeiro parâmetro.
 * por exemplo: PickUnionType<ProcedimentosSanitarios, 'vermifugacao',
 */
export type PickUnionType<T, K extends T> = K

export {
  ManejosSelecionadosHash,
  ManejoPesagem,
  ManejoOrdenha,
  ManejoSanitarioEstoque,
  ManejoAssociacaoDeBrinco,
  ManejoRegistrarMorte,
  ManejoMovimentacaoEntreLotes,
  ManejoMovimentacaoEntreAreas,
  ManejoMovimentacaoEntreConfinamento,
  ManejoParto,
  FilhotesModel,
  ManejoDiagnosticoGestacao,
  TiposDiagnosticos,
  DadosManejoDiagnoticosModel,
  ManejoDiagnosticoCio,
  ManejoInseminacaoArtificial,
  BoiOuSemenSelecionadoModel,
  ManejoMontaNatural,
  ManejoDesmame,
  ManejoImplanteProgesterona,
  ManejoRemocaoImplante,
  ManejoAborto,
  ManejoDescarte,
  ManejoCastracao,
  ManejoEscoreCorporal,
  ManejoAlteracaoNumero,
  ManejoSanitario,
  ManejoNutricional,
  OpcoesSegment,
  ManejoPluviometria,
  ManejoAlturaCapim,
  ManejoPastejoRotacionado,
  ManejoEscoreFezes,
  InseminadorModel
}