import { Preferences } from '@capacitor/preferences'
import { BrincosJaUtilizadosService } from '../../../services/brincos-ja-utilizados.service'
import { AnimalDoManejo, ManejoAnimalModel } from '../../../utils/types'
import { ApplicationRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'manejo-alteracao-numero',
  templateUrl: './manejo-alteracao-numero.component.html',
  styleUrls: ['./manejo-alteracao-numero.component.scss']
})
export class ManejoAlteracaoNumeroComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel
  @Output() manejoChange = new EventEmitter()
  @Input() animal: AnimalDoManejo
  @Output() opcoes = new EventEmitter()

  public isBrincoExiste = false
  public timeoutBrinco = null
  public isTecladoNumerico = true

  constructor(private brincosJaUtilizadosCtrl: BrincosJaUtilizadosService, private ref: ApplicationRef) {}

  ngOnInit() {
    this.getTipoDoTeclado()

    if (this.manejo.novo_numero) {
      this.verificaNumeroExistente()
    }
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.verificaNumeroExistente()

    this.manejoChange.emit(this.manejo)
  }

  verificaNumeroExistente() {
    clearTimeout(this.timeoutBrinco)

    this.timeoutBrinco = setTimeout(async () => {
      this.isBrincoExiste = await this.brincosJaUtilizadosCtrl.numeroControleJaUtilizado({
        numeracao: this.manejo.novo_numero,
        hash: this.animal.hash
      })
    }, 500)
  }

  async getTipoDoTeclado(): Promise<void> {
    const { value: isTecladoNumerico } = await Preferences.get({ key: 'tipo-teclado-numerico' })
    if (isTecladoNumerico === '1') {
      this.isTecladoNumerico = true
    } else {
      this.isTecladoNumerico = false
    }
  }

  toggleTecladoNumerico(): void {
    this.isTecladoNumerico = !this.isTecladoNumerico
    console.warn('🚀 ~ toggleTecladoNumerico ~ this.isTecladoNumerico:', this.isTecladoNumerico)
    Preferences.set({ key: 'tipo-teclado-numerico', value: this.isTecladoNumerico ? '1' : '0' })
    this.ref.tick()
  }
}
