import { sqlFilter } from '../utils/sqlUtils'
import { IEstoqueNutricionalItemsQuery } from '../utils/types/manejo-nutricional-types'
import { Injectable } from '@angular/core'
import { BancoSQLiteService } from './banco-sqlite.service'

@Injectable({
  providedIn: 'root'
})
export class NutricionalService {
  constructor(private db: BancoSQLiteService) {}

  async getEstoquesNutricional(opcoes: {
    idPropriedade: number
    hash?: string
    hashs?: string[]
    tipo?: string
    dataPrecos?: string
    filtros?: IEstoqueNutricionalItemsQuery
  }): Promise<{ estoques: IEstoqueNutricionalItemsQuery[]; totais: { semens: number; embrioes: number; total: number } }> {
    const { idPropriedade, filtros } = opcoes

    const estoquesPromise = this.db.safeQuery({
      query: `
        WITH usos AS (
          SELECT SUM(nutricional.nutricional_quantidade) as qtd,
          nutricional.nutricional_hash_estoque as estoque
          FROM gestor_manejo_nutricional nutricional
              LEFT JOIN gestor_animais 
                  ON gestor_animais.status = 1 
                  AND nutricional.hash_animal = gestor_animais.hash
              LEFT JOIN gestor_lotes 
                  ON gestor_lotes.status = 1 
                  AND nutricional.hash_lote = gestor_lotes.hash
                LEFT JOIN gestor_areas 
                  ON gestor_areas.status = 1 
                  AND nutricional.hash_area = gestor_areas.hash 
          
          WHERE nutricional.status = 1
          AND nutricional.id_propriedade = {{idPropriedade}}
          AND (
              (
                nutricional.hash_animal IS NOT NULL AND gestor_animais.hash IS NOT NULL
            )
            OR
            (
                nutricional.hash_lote IS NOT NULL AND gestor_lotes.hash IS NOT NULL
            )
            OR
            (
                nutricional.hash_area IS NOT NULL AND gestor_areas.hash IS NOT NULL
            )
          
          )
          GROUP BY estoque
      )

      SELECT busca.*,
      busca.extra_quantidade * busca.ultimo_estoque_momentaneo as extra_valor_total
      FROM (
      
        SELECT item.id, item.titulo, item.hash, item.tipo,
        meta_tipo.valor AS meta_tipoDoEstoque,

        (
          SELECT COALESCE(positivo, 0) - COALESCE(negativo, 0) - COALESCE(usos.qtd, 0) FROM (
            SELECT 
            SUM( CASE WHEN movimentacao = "entrada" THEN qtd ELSE 0 END ) as positivo,
            SUM( CASE WHEN movimentacao = "saida" THEN qtd ELSE 0 END) + 
            COALESCE(
              ( 
                SELECT SUM(quantidade) 
                FROM gestor_estoque_lancamento_formulacao 
                WHERE status = 1 AND hash_item_nutricional = item.hash
              ), 0
            ) as negativo
            FROM gestor_estoque_lancamento
            WHERE gestor_estoque_lancamento.status = 1
            AND hash_estoque = item.hash
          ) as a
        ) AS extra_quantidade,

        (
          SELECT valor_medio_momentaneo 
          FROM gestor_estoque_lancamento
          WHERE gestor_estoque_lancamento.status = 1
          AND hash_estoque = item.hash
          AND movimentacao = 'entrada'
          ORDER BY data_lancamento DESC
          LIMIT 1
        ) AS ultimo_estoque_momentaneo,

        ${filtros.data_custo ? `
          (
            SELECT valor_medio_momentaneo 
            FROM gestor_estoque_lancamento
            WHERE gestor_estoque_lancamento.status = 1
            AND hash_estoque = item.hash
            AND movimentacao = 'entrada'
            AND data_lancamento <= '${filtros.data_custo}'
            ORDER BY data_lancamento DESC
            LIMIT 1
          ) AS extra_custo_na_data,
        ` : ''}

        (
          SELECT *
          FROM (
                SELECT data 
                    FROM gestor_manejo_nutricional
                    WHERE gestor_manejo_nutricional.status = 1
                    AND nutricional_hash_estoque = item.hash
                  UNION
                    SELECT data_lancamento as data
                    FROM gestor_estoque_lancamento
                    WHERE gestor_estoque_lancamento.status = 1
                    AND hash_estoque = item.hash

                  ORDER BY data
          ) a
          ORDER BY data DESC
          LIMIT 1
        ) AS extra_ultima_movimentacao
        
        FROM gestor_estoque item
        LEFT JOIN usos ON usos.estoque = item.hash
        LEFT JOIN gestor_estoque_metas meta_tipo ON meta_tipo.status = 1 AND meta_tipo.hash_estoque = item.hash AND meta_tipo.chave = 'tipo'

        
        WHERE item.status = 1
        AND item.tipo = 'nutricional'
        AND item.id_propriedade = ${idPropriedade}
        ${sqlFilter.safeSelectIn({ coluna: 'item.hash', tag: '{{hash}}', valor: filtros.hash })}
        ${sqlFilter.safeLike({ coluna: 'item.titulo', tag: '{{titulo}}', valor: filtros.titulo })}
        ${sqlFilter.safeEquals({ coluna: 'item.tipo', tag: '{{tipo}}', valor: filtros.tipo })}
        ${sqlFilter.safeEquals({ coluna: 'meta_tipo.valor', tag: '{{meta_tipoDoEstoque}}', valor: filtros.meta_tipoDoEstoque })}
        
        ) busca
        -- HAVING TRUE
        ${sqlFilter.safeNumberRange({ coluna: 'extra_quantidade', tag: '{{extra_quantidade}}', valor: filtros.extra_quantidade })}
        ${sqlFilter.safeDateRange({ coluna: 'extra_ultima_movimentacao', tag: '{{extra_ultima_movimentacao}}', valor: filtros.extra_ultima_movimentacao })}

        ${filtros.hash_not_in?.length ? `WHERE hash NOT IN ("${filtros.hash_not_in.join('", "')}")` : ''}

      ${sqlFilter.safeNumberRange({ coluna: 'extra_valor_total', tag: '{{extra_valor_total}}', valor: filtros.extra_valor_total as unknown as string })}
      ${sqlFilter.safeOrderBy({ coluna: filtros.order_by, direcao: filtros.order_direction, prefixo: true })}
    `,
      params: {
        ...filtros,
        idPropriedade
      }
    })

    const totaisPromise = this.db.safeQuery({
      query: `
      WITH usos AS (
        SELECT SUM(nutricional.nutricional_quantidade) as qtd,
        nutricional.nutricional_hash_estoque as estoque
        FROM gestor_manejo_nutricional nutricional
            LEFT JOIN gestor_animais 
                ON gestor_animais.status = 1 
                AND nutricional.hash_animal = gestor_animais.hash
              LEFT JOIN gestor_lotes 
                ON gestor_lotes.status = 1 
                AND nutricional.hash_lote = gestor_lotes.hash
              LEFT JOIN gestor_areas 
                ON gestor_areas.status = 1 
                AND nutricional.hash_area = gestor_areas.hash 
        
        WHERE nutricional.status = 1
        AND nutricional.id_propriedade = {{idPropriedade}}
        AND (
            (
              nutricional.hash_animal IS NOT NULL AND gestor_animais.hash IS NOT NULL
          )
          OR
          (
              nutricional.hash_lote IS NOT NULL AND gestor_lotes.hash IS NOT NULL
          )
          OR
          (
              nutricional.hash_area IS NOT NULL AND gestor_areas.hash IS NOT NULL
          )
        
        )
        GROUP BY estoque
    )

    SELECT COUNT(1) AS qtd FROM (
      SELECT item.id, 
      item.hash, item.tipo,
      meta_tipo.valor AS tipoDoEstoque,

      (
        SELECT COALESCE(positivo, 0) - COALESCE(negativo, 0) - COALESCE(usos.qtd, 0) FROM (
          SELECT 
          SUM( CASE WHEN movimentacao = "entrada" THEN qtd ELSE 0 END ) as positivo,
          SUM( CASE WHEN movimentacao = "saida" THEN qtd ELSE 0 END) as negativo
          FROM gestor_estoque_lancamento
          WHERE gestor_estoque_lancamento.status = 1
          AND hash_estoque = item.hash
        ) as a
      ) AS quantidade
      
      FROM gestor_estoque item
      LEFT JOIN usos ON usos.estoque = item.hash
      INNER JOIN gestor_estoque_metas meta_tipo ON meta_tipo.status = 1 AND meta_tipo.hash_estoque = item.hash AND meta_tipo.chave = 'tipo'
      
      WHERE item.status = 1
      AND item.tipo = 'nutricional'
      AND item.id_propriedade = {{idPropriedade}}
      ${sqlFilter.safeSelectIn({ coluna: 'item.hash', tag: '{{hash}}', valor: filtros.hash })}
      ${sqlFilter.safeEquals({ coluna: 'item.tipo', tag: '{{tipo}}', valor: filtros.tipo })}
    ) a
  `,
      params: {
        ...filtros,
        idPropriedade
      }
    })

    const [estoques, [totais]] = await Promise.all([estoquesPromise, totaisPromise])
    
    let quantidade_total = 0
    let valor_total = 0

    for (const estoque of estoques) {
      estoque.extra_custo_na_data = Number(estoque.extra_custo_na_data || '0')
      estoque.ultimo_estoque_momentaneo = Number(estoque.ultimo_estoque_momentaneo || '0')
      estoque.extra_quantidade = Number(estoque.extra_quantidade || '0')
      quantidade_total += Number(estoque.extra_quantidade || '0')
      valor_total += Number(estoque.extra_valor_total || '0')
    }

    totais.quantidade_total = quantidade_total
    totais.valor_total = valor_total

    return { estoques, totais }
  }
}
