import { ManejoAnimalModel } from '../../../utils/types'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'manejo-implante-remover',
  templateUrl: './manejo-implante-remover.component.html',
  styleUrls: ['./manejo-implante-remover.component.scss']
})
export class ManejoImplanteRemoverComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  getOpcaoSelecionada(opcao) {
    this.manejo.removido = opcao
    this.emit()
  }

  async abrirOpcoesDeManejo() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
