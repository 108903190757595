import { ApplicationRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSearchbar, ModalController } from '@ionic/angular';
import { AnimaisService } from '../../services/animais.service';
import { AnimalModel, IAnimalQuery, IPropsAnimal, OpcoesDismissFichaAnimal } from '../../utils/types';
import Utils from '../../utils/utils';
import { VastaRX } from '@vasta/rx';
import { FichaAnimalSingleComponent } from '../../components/ficha-animal-single/ficha-animal-single.component';
import { Preferences } from '@capacitor/preferences';
import { CadastrarAnimalComponent } from '../../components/cadastrar-animal/cadastrar-animal.component';
import { TiposManejosLista } from '../home/atalho-manejos';
import { ColunasAnimais } from '../../utils/configuracoes-padrao';
import { ConfigTabela } from '../../utils/types/tabela-colunas';
import { IOptionButtons } from '../../components/opcoes-botoes/opcoes-botoes.component';
import { DadosLinhaTabela } from '../../components/vasta-lista/tabela-types';
import { NovoFiltro, Ordenacao } from '../../components/vasta-lista/vasta-lista.component';
import { UtilsService } from '../../services/utils.service';
import { ConexoesBluetoothService } from '../../services/dispositivos-bluetooth/conexoes-bluetooth.service';

@Component({
  selector: 'app-seletor-animais',
  templateUrl: './seletor-animais.page.html',
  styleUrls: ['./seletor-animais.page.scss']
})
export class SeletorAnimaisPage implements OnInit {
  @Input() animaisSelecionados: string[] = [];

  @ViewChild('campoBusca', { static: false }) campoBusca: IonSearchbar;

  public ColunasAnimais = ColunasAnimais
  public config: ConfigTabela = {
    toolbar: true,
  }

  public campoBuscaElement: IonSearchbar;

  public filtrarSexo
  brincosOutrosManejos = {}
  brincosAssociadosNesteManejo = {}
  public brincoJaFoiAssociado = false;

  public animais: AnimalModel[] = [];

  public termoAnimais = '';
  public isCarregandoAnimais = true;
  public utils = Utils;

  public abaSelecionada = 'animais';
  public credencialAnimalRx: any;
  public credencialAnimal: any;
  public propriedadeAtual: any;

  public estaTelaFoiAbertaPorManejo: TiposManejosLista

  public isTecladoNumerico = true
  
  public novoFiltro: NovoFiltro = {
    situacao: ['vivo', 'novo']
  }
  public filtrosFixos: NovoFiltro = []
  public novaOrdenacao: Ordenacao = { coluna: 'numeracao', direcao: 'desc' }
  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  public optionsButtonsAnimais: IOptionButtons[] = [
    {
      titulo: 'Ver Ficha',
      icone: 'layers-sharp',
      acao: ({ original }: DadosLinhaTabela, date: string): void => {
        this.verFichaAnimal(original as unknown as AnimalModel);
      }
    },
    {
      titulo: 'Novo Manejo',
      icone: 'add-circle',
      acao: ({ original }: DadosLinhaTabela): void => {
        this.selecionarAnimal(original as unknown as AnimalModel)
      }
    },
    {
      titulo: 'Editar Animal',
      icone: 'create',
      acao: ({ original }: DadosLinhaTabela): void => {
        if (original.situacao === 'novo') {
          this.editarAnimalPendente(original as unknown as AnimalModel)
        } else {
          this.utilsCtrl.exibirToast('Não é possível editar animais que já foram enviados para aprovação')
        }
      }
    },
    {
      titulo: 'Remover Animal',
      icone: 'trash',
      acao: ({ original }: DadosLinhaTabela): void => {
        if (original.situacao === 'novo') {
          this.removerAnimalPendente(original as unknown as AnimalModel)
        } else {
          this.utilsCtrl.exibirToast('Não é possível remover animais que já foram enviados para aprovação')
        }
      }
    },
  ]

  constructor(
    private modalCtrl: ModalController,
    private animaisCtrl: AnimaisService,
    private alertCtrl: AlertController,
    private ref: ApplicationRef,
    private utilsCtrl: UtilsService,
    public conexoesBluetoothSeparadaCtrl: ConexoesBluetoothService,
  ) {
    this.credencialAnimalRx = VastaRX.getState('credencialAnimal', async (valor) => {
      if (this.estaTelaFoiAbertaPorManejo == "monta-natural") {
        return
      }

      this.credencialAnimal = valor;
      
      this.ref.tick()

      if (this.credencialAnimal) {
        this.leuBrinco()
      }
    })
  }

  async leuBrinco() {
    this.brincoJaFoiAssociado = this.brincosAssociadosNesteManejo?.[this.credencialAnimal] || this.brincosOutrosManejos?.[this.credencialAnimal]

    try {
      const animal: AnimalModel[] = await this.animaisCtrl.getAnimalByBrinco(this.credencialAnimal);

      if (animal.length === 1) {
        this.brincoJaFoiAssociado = true
        return this.selecionarAnimal(animal[0])
      }

      if (this.brincoJaFoiAssociado) {
        if (this.brincosAssociadosNesteManejo?.[this.credencialAnimal]) {
          const animal = await this.animaisCtrl.getAnimal(this.brincosAssociadosNesteManejo?.[this.credencialAnimal]);
          return this.selecionarAnimal(animal);
        }
        if (this.brincosOutrosManejos?.[this.credencialAnimal]) {
          const animal = await this.animaisCtrl.getAnimal(this.brincosOutrosManejos?.[this.credencialAnimal]);
          return this.selecionarAnimal(animal);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async ngOnInit() {
    this.propriedadeAtual = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value);
    this.credencialAnimal = null;
    this.isCarregandoAnimais = false;

    this.novoFiltro = this.utilsCtrl.filtroAnimais[this.propriedadeAtual.id] || {
      situacao: ['vivo', 'novo']
    }

    if ( this.filtrarSexo && !this.animaisSelecionados.length ) {
      this.novoFiltro.sexo = this.filtrarSexo
    }

    if (this.estaTelaFoiAbertaPorManejo === "monta-natural") {
      this.novoFiltro.sexo = 'macho'
      this.filtrosFixos.push('sexo')
    }

    await this.verificarSeExistemAnimaisNovosPendentes()

    this.getAnimais()

    this.getTipoDoTeclado()
  }

  async ionViewDidEnter() {
    setTimeout(() => {
      if (!this.conexoesBluetoothSeparadaCtrl.isBastaoConectado) {
        try {
          this.campoBusca.setFocus();
        } catch (error) {
          console.error(error);
        }
      }
    }, 1500)
  }

  toggleTecladoNumerico() {
    setTimeout(() => {
      this.campoBusca.setFocus();
    }, 500)

    this.isTecladoNumerico = !this.isTecladoNumerico
    Preferences.set({ key: 'tipo-teclado-numerico', value: this.isTecladoNumerico ? '1' : '0' })
  }

  async getTipoDoTeclado(): Promise<void> {
    const { value: isTecladoNumerico } = await Preferences.get({ key: 'tipo-teclado-numerico' })
    this.isTecladoNumerico = isTecladoNumerico === '1'
  }

  ngOnDestroy() {
    VastaRX.setState('credencialAnimal', null);
    VastaRX.unListen(this.credencialAnimalRx);
  }

  // Animais que foram cadastrados, mas não foram enviados para aprovação
  async verificarSeExistemAnimaisNovosPendentes(): Promise<void> {
    const animaisNovos: AnimalModel[] = await this.animaisCtrl.getAnimaisNovosPendentes({
      menosEssesAnimais: this.animaisSelecionados
    })

    if(animaisNovos.length && this.novoFiltro?.situacao?.length && !this.novoFiltro?.situacao?.includes('pendente')) {
      this.novoFiltro.situacao.push('pendente')
    }
  }

  zerarPaginate() {
    this.paginate.currentPage = 1
    this.paginate.offset = 0
  }

  async getAnimais() {
    this.utilsCtrl.filtroAnimais[this.propriedadeAtual.id] = this.novoFiltro
    this.isCarregandoAnimais = true

    let filtroAnimais: IAnimalQuery = {}

    filtroAnimais = {
      ...this.novoFiltro,
      query_limit: this.paginate.limit,
      query_offset: this.paginate.offset,
      order_by: this.novaOrdenacao.coluna,
      order_direction: this.novaOrdenacao.direcao,
      hash_not_in: (this.animaisSelecionados || []) as unknown as string
    }
    const { animais, quantidade } = await this.animaisCtrl.getAnimais({
      termo: this.termoAnimais,
      filtro: filtroAnimais,
    });

    this.animais = animais
    this.paginate.total = quantidade
    this.isCarregandoAnimais = false
    this.ref.tick()
  }

  async selecionarAnimal(animal: AnimalModel) {
    const propsAnimal: IPropsAnimal = {
      animal,
      credencialAnimal: this.brincoJaFoiAssociado ? null : this.credencialAnimal
    };

    VastaRX.setState('credencialAnimal', null);
    await this.modalCtrl.dismiss(propsAnimal);
  }

  async dismiss(dados = null) {
    await this.modalCtrl.dismiss(dados);
  }

  async abrirTelaDeCadastrarAnimal() {
    const modal = await this.modalCtrl.create({
      component: CadastrarAnimalComponent,
      cssClass: 'modal-narrow'
    });

    await modal.present();
    const { data: novoAnimalCadastrado } = await modal.onDidDismiss();
    if (novoAnimalCadastrado) {
      // this.termoAnimais = novoAnimalCadastrado.numeracao || 'Sem Número' || ''
      // if (!novoAnimalCadastrado.numeracao) {
      //   this.novaOrdenacao = { coluna: 'created_at', direcao: 'desc' }
      // }
      // this.getAnimais()
      this.selecionarAnimal(novoAnimalCadastrado);
    }
  }

  async verFichaAnimal(animal: AnimalModel, event?: Event) {
    event?.stopPropagation();
    const modal = await this.modalCtrl.create({
      component: FichaAnimalSingleComponent,
      componentProps: { hashDoAnimal: animal.hash, isAbertaPorSeletorDeAnimais: true }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss<OpcoesDismissFichaAnimal>()

    if(data?.iniciarNovoManejo) {
      this.selecionarAnimal(animal)
    } else if(data?.isAnimalRemovido) {
      this.getAnimais()
    }
  }

  async editarAnimalPendente(animal: AnimalModel) {
    const modal = await this.modalCtrl.create({
      component: CadastrarAnimalComponent,
      componentProps: {
        animalParaEditar: animal
      }
    });

    await modal.present();
    const { data: animalAtualizado } = await modal.onDidDismiss();

    if (animalAtualizado) {
      this.getAnimais()
    }
  }

  async removerAnimalPendente(animal: AnimalModel) {
    const alerta = await this.alertCtrl.create({
      header: 'Apagar Animal',
      message: 'Deseja realmente apagar este animal?',
      buttons: [
        'Cancelar',
        {
          text: 'Sim',
          handler: async () => {
            await this.animaisCtrl.removerAnimalPendente(animal)
            this.getAnimais()

            if(!this.animais.length) {
              this.getAnimais()
            }
          }
        }
      ]
    });
    await alerta.present();
  }
}
