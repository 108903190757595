import { commonEnv } from './environment.common'

const env = {
  production: false,
  name: 'stg',
  APICampo: 'https://southamerica-east1-fertili-staging.cloudfunctions.net/campo',
  API: 'https://southamerica-east1-fertili-staging.cloudfunctions.net/api'
}

export const environment = {
  ...commonEnv,
  ...env
}
