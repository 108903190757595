import { ArvoreGenealogica, DadoGenealogia } from '../../utils/types';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FichaAnimalSingleComponent } from '../ficha-animal-single/ficha-animal-single.component';

@Component({
  selector: 'genealogia-animal',
  templateUrl: './genealogia-animal.component.html',
  styleUrls: ['./genealogia-animal.component.scss'],
})
export class GenealogiaAnimalComponent implements OnInit {
  @Input() genealogia: ArvoreGenealogica

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  async verFichaAnimal(dadosAnimal: DadoGenealogia) {
    if (!dadosAnimal || dadosAnimal?.externo) return

    const modal = await this.modalCtrl.create({
      component: FichaAnimalSingleComponent,
      componentProps: { hashDoAnimal: dadosAnimal.hash, isAbertaPorSeletorDeAnimais: true }
    })

    await modal.present();
  }
}
