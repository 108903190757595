import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ManejoEscoreFezes } from '../../utils/types'

@Component({
  selector: 'manejo-escore-fezes',
  templateUrl: './manejo-escore-fezes.component.html',
  styleUrls: ['./manejo-escore-fezes.component.scss']
})
export class ManejoEscoreFezesComponent implements OnInit {
  @Input() manejo: ManejoEscoreFezes
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
