import { TiposManejosLista } from '../../pages/home/atalho-manejos'
import {
  ManejoAssociacaoDeBrinco,
  ManejoDiagnosticoGestacao,
  ManejoMovimentacaoEntreLotes,
  ManejoParto,
  ManejoPesagem,
  ManejoRegistrarMorte,
  ManejoDiagnosticoCio,
  ManejoInseminacaoArtificial,
  ManejoMontaNatural,
  ManejoDesmame
} from '../../utils/types'
import { PayloadCadastrarLoteModel } from '.'
import { AnimalModel } from './ficha-animal-single'
import {
  ManejoAborto,
  ManejoAlteracaoNumero,
  ManejoAlturaCapim,
  ManejoCastracao,
  ManejoDescarte,
  ManejoEscoreCorporal,
  ManejoEscoreFezes,
  ManejoImplanteProgesterona,
  ManejoMovimentacaoEntreAreas,
  ManejoNutricional,
  ManejoOrdenha,
  ManejoPastejoRotacionado,
  ManejoPluviometria,
  ManejoRemocaoImplante,
  ManejoSanitario,
  ManejoSanitarioEstoque,
} from './manejos-payload-types'

enum TipoManejos {
  pesagem = 'pesagem',
  associacaoDeBrinco = 'associacao-de-brinco',
  registrarMorte = 'registrar-morte',
  movimentacaoEntreLotes = 'movimentacao-entre-lotes',
  enviarParaConfinamento = 'enviar-para-confinamento',
  cadastrar_lote = 'cadastrar_lote',
  sanitario = 'sanitario',
  reprodutivo = 'reprodutivo',
  parto = 'parto',
  diagnosticoGestacao = 'diagnostico-gestacao',
  diagnosticoCio = 'diagnostico-cio',
  inseminacaoArtificial = 'inseminacao-artificial',
  montaNatural = 'monta-natural',
  desmame = 'desmame',
  implanteProgesterona = 'implante-progesterona',
  remocaoImplante = 'remocao-implante',
  aborto = 'aborto',
  escoreCorporal = 'escore-corporal',
  alteracaoNumero = 'alteracao-numero',
  descarte = 'descarte',
  castracao = 'castracao',
  nutricional = 'nutricional',
  ordenha = 'ordenha',
  sanitarioEstoque = 'sanitarioEstoque',
  movimentacaoEntreAreas = 'movimentacao-entre-areas',
}

export const ListaProcedimentosSanitarios = [
  'vermifugacao',
  'medicacao',
  'vacinacao',
  'procedimentos-veterinarios',
  'exames',
  'banho',
  'complexo-vitaminico',
  'tratamento-clinico',
  'antiparasitarios',
  'diagnostico',
  'outros',
] as const

// utilizar array de procedimentos sanitário e criar um Union type com os valores do array
export type ProcedimentosSanitarios = typeof ListaProcedimentosSanitarios[number]

type ManejoAnimalModel = ManejoPesagem &
  ManejoAssociacaoDeBrinco &
  ManejoRegistrarMorte &
  ManejoMovimentacaoEntreAreas &
  ManejoMovimentacaoEntreLotes &
  ManejoParto &
  ManejoDiagnosticoGestacao &
  ManejoDiagnosticoCio &
  ManejoInseminacaoArtificial &
  ManejoMontaNatural &
  ManejoDesmame &
  ManejoImplanteProgesterona &
  ManejoRemocaoImplante &
  ManejoAborto &
  ManejoDescarte &
  ManejoCastracao &
  ManejoEscoreCorporal &
  ManejoAlteracaoNumero &
  ManejoSanitario<ProcedimentosSanitarios, string> &
  ManejoNutricional &
  ManejoOrdenha &
  ManejoSanitarioEstoque

type ManejoLoteModel = ManejoMovimentacaoEntreAreas

type ManejoGeralModel = {
  tipo: 'animal' | 'lote' | 'area' | 'cadastro-lote' | 'cadastro-animais';
  hash: string;
  inicio: string;
  fim?: string;
  editado?: string;
  id_propriedade?: number;
  descricao?: string;
  observacao?: string;
  manejos?: ManejoModel[];
  animais?: AnimalDoManejo[];
  novos_animais?: AnimalModel[];
  novos_lotes?: PayloadCadastrarLoteModel[];
  novo_lote?: PayloadCadastrarLoteModel;
  created_at?: string;
  updated_at?: string;
  pesoTotal?: number

  lotes?: LoteDoManejo[];
  areas?: AreaDoManejo[];
  id_solicitacao?: number
};

type SolicitacaoManejosModel = {
  id: number;
  hash: string;
  inicio: string;
  fim: string;
  editado?: string;
  id_propriedade: number;
  descricao?: string;
  manejo: string;
  created_at?: string;
  updated_at?: string;
};

type ManejoModel = {
  nome?: string;
  hash?: string;
  finalidade?: string;
  tipo?: TiposManejosLista
  somente_sexo?: string

  nutricional_hash_estoque?: string;
  sanitario_hash_estoque?: string;
  descricao?: string;
  procedimento?: ProcedimentosSanitarios;
  procedimentos?: ProcedimentoObjeto[]
  sanitarioItensEstoque?: {
    sanitario_hash_estoque: string
    nome: string
    categoria: string
    meta_unidade: string
  }[]
  nutricionalItensEstoque?: {
    nutricional_hash_estoque: string
    nome: string
    categoria: string
  }[]
}

export type ProcedimentoObjeto = {
  hash: string
  procedimento: string
  descricao: string
}

export type LoteDoManejo = {
  hash?: string
  manejos?: any[]
  
  dados?: {
    sanitarioEstoque?: {
      [key: string]: {
        sanitario_quantidade?: number
      }
    }
    nutricional?: {
      [key: string]: {
        nutricional_quantidade?: number
        nutricional_duracao?: number
      }
    }
    ['escore-fezes']?: ManejoEscoreFezes
  }
}

export type AreaDoManejo = {
  hash?: string
  manejos?: any[]
  
  dados?: {
    sanitarioEstoque?: {
      [key: string]: {
        sanitario_quantidade?: number
      }
    }
    nutricional?: {
      [key: string]: {
        nutricional_quantidade?: number
        nutricional_duracao?: number
      }
    }
    pluviometria?: ManejoPluviometria
    ['altura-capim']?: ManejoAlturaCapim
    ['pastejo-rotacionado']?: ManejoPastejoRotacionado
  }
}

type AnimalDoManejo = {
  dadosAnimal?: any;
  hash: string;
  manejos?: ManejoAnimalModel[];
  nome?: string;
  numeracao?: string;
  dados: {
    pesagem?: {
      peso?: number;
      gmd?: number;
      finalidade?: string;
    }
    'diagnostico-cio'?: {
      diagnostico?: 'positivo' | 'negativo'
    }
    'diagnostico-gestacao'?: {
      diagnostico?: 'positivo' | 'negativo'
    }
    ordenha?: {
      turno?: string;
      ordenha_1?: number;
      ordenha_2?: number;
      ordenha_3?: number;
    }
    sanitario?: {
      [key: string]: {
        procedimento_sanitario_realizado?: boolean
      }
    }
    descarte?: {
      descarte?: boolean
    }
    desmame?: {
      desmame?: boolean
    }
    castracao?: {
      castracao?: boolean
    }
    aborto?: {
      realizado?: boolean
    }
    parto?: {
      filhotes?: {
        hash?: string
        brinco?: string
        dadosAnimal?: {
          [key: string]: any
        }
      }[]
    }
    sanitarioEstoque?: {
      [key: string]: {
        sanitario_quantidade?: number
      }
    }
    nutricional?: {
      [key: string]: {
        nutricional_quantidade?: number
        nutricional_duracao?: number
      }
    }
    'movimentacao-entre-lotes'?: {
      hash_lote_destino?: string
      nome_lote_destino?: string
    }
    'enviar-para-confinamento'?: {
      hash_lote_destino?: string
      nome_lote_destino?: string
    }
    'monta-natural'?: {
      touro?: {
        hash?: string
        nome?: string
      }
    }
    'escore-corporal'?: {
      escore?: number
    }
    'implante-progesterona'?: {
      implantado?: boolean
    }
    'remocao-implante'?: {
      removido?: boolean
    }
    'inseminacao-artificial'?: {
      semen?: string
    }
    'associacao-de-brinco'?: {
      brinco?: string
    }
    'alteracao-numero'?: {
      novo_numero?: string
    }
    ['registrar-morte']?: {
      motivo?: string,
      detalhes?: string,
      fotos?: string[],
      localizacao?: {
        latitude: number
        longitude: number
      }
     }
    aprumos?: {
      aprumos?: string
    }
    boca?: {
      boca?: string
    }
    casco?: {
      casco?: string
    }
    chanfro?: {
      chanfro?: string
    }
    frame?: {
      frame?: string
    }
    garupa?: {
      garupa?: string
    }
    ['insercao-cauda']?: {
      insercao_cauda?: string
    }
    ['linha-dorso']?: {
      linha_dorso?: string
    }
    musculosidade?: {
      musculosidade?: string
    }
    ossatura?: {
      ossatura?: string
    }
    pelagem?: {
      pelagem?: string
    }
    ['perimetro-escrotal']?: {
      perimetro_escrotal?: string
    }
    profundidade?: {
      profundidade?: string
    }
    racial?: {
      racial?: string
    }
    reproducao?: {
      reproducao?: string
    }
    temperamento?: {
      temperamento?: string
    }
    ['torcao-testicular']?: {
      torcao_testicular?: string
    }
    ubere?: {
      ubere?: string
    }
    umbigo?: {
      umbigo?: string
    }
    // [key: string]: any;
  }
  [key: string]: any;
};

type PesoAnimalModel = {
  ultimoPeso?: number;
  ultimoPesoData?: string;
  pesoDesmame?: number;
  pesoNascimento?: number;
};

type PayloadManejosPendente = {
  manejoSequencial: ManejoGeralModel;
  isEditandoManejos: boolean;
  idSolicitacao: number;
};

export {
  ManejoGeralModel,
  ManejoAnimalModel,
  ManejoLoteModel,
  ManejoModel,
  PayloadManejosPendente,
  AnimalDoManejo,
  PesoAnimalModel,
  SolicitacaoManejosModel,
  TipoManejos,
};
