import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BovinosListaComponent } from './animais/bovinos-lista/bovinos-lista.component';
import { FichaAnimalSingleComponent } from './ficha-animal-single/ficha-animal-single.component';
import { SeletorLoteSingleComponent } from './seletor-lote-single/seletor-lote-single.component';
import { ManejoPesagemComponent } from './manejo-pesagem/manejo-pesagem.component';
import { ListaVaziaFallbackComponent } from './lista-vazia-fallback/lista-vazia-fallback.component';
import { TagsComponent } from './tags/tags.component';
import { ManejoIdentificacaoEletronicaComponent } from './manejo-geral/manejo-identificacao-eletronica/manejo-identificacao-eletronica.component';
import { CadastrarAnimalComponent } from './cadastrar-animal/cadastrar-animal.component';
import { ListaRacasComponent } from './lista-racas/lista-racas.component';
import { ManejoRegistrarMorteComponent } from './manejo-geral/manejo-registrar-morte/manejo-registrar-morte.component';
import { ImagemAntesDeEnviarComponent } from './imagem-antes-de-enviar/imagem-antes-de-enviar.component';
import { ManejoMovimentacaoEntreLotesComponent } from './manejo-geral/manejo-movimentacao-entre-lotes/manejo-movimentacao-entre-lotes.component';
import { CadastrarLoteComponent } from './cadastrar-lote/cadastrar-lote.component';
import { ModalDispositivosBluetoothComponent } from './modal-dispositivos-bluetooth/modal-dispositivos-bluetooth.component';
import { SeletorAreasComponent } from './seletor-areas/seletor-areas.component';
import { DetalhesManejosComponent } from './detalhes-manejos/detalhes-manejos.component';
import { ManejosAnimalSingleComponent } from './manejos-animal-single/manejos-animal-single.component';
import { HeaderFichaAnimalComponent } from './header-ficha-animal/header-ficha-animal.component';
import { GuiaDeUsoComponent } from './guia-de-uso/guia-de-uso.component';
import {
  ManejoInseminacaoArtificialComponent,
  ManejoDiagnosticoGestacaoComponent,
  ManejoPartoComponent,
  ManejoMontaNaturalComponent,
  ManejoDesmameComponent,
  ManejoImplanteProgesteronaComponent,
  ManejoAbortoComponent,
  ManejoEscoreCorporalComponent,
  PopoverListaExposicoesComponent
} from './manejo-reprodutivo';
import { GenealogiaAnimalComponent } from './genealogia-animal/genealogia-animal.component';
import { ManejoSanitarioComponent } from './manejo-sanitario/manejo-sanitario.component'
import { PipesModule } from '../pipes/pipes.module';
import { ManejoNutricionalComponent } from './manejo-nutricional/manejo-nutricional.component';
import { ManejoDescarteComponent } from './manejo-descarte/manejo-descarte.component';
import { ManejoOrdenhaComponent } from './manejo-ordenha/manejo-ordenha.component';
import { ManejoCastracaoComponent } from './manejo-castracao/manejo-castracao.component';
import { ManejoSanitarioEstoqueComponent } from './manejo-sanitario-estoque/manejo-sanitario-estoque.component';
import { VastaListaComponent } from './vasta-lista/vasta-lista.component';
import { OpcoesBotoesComponent } from './opcoes-botoes/opcoes-botoes.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { VastaTabelaFiltrosComponent } from './vasta-tabela-filtros/vasta-tabela-filtros.component';
import { ScrollingModule } from '@angular/cdk/scrolling'
import { VastaInputComponent } from './vasta-input/vasta-input.component';
import { CtaButtonsComponent } from './cta-buttons/cta-buttons.component';
import { PaginateComponent } from './paginate/paginate.component';
import { NgxMaskModule } from 'ngx-mask';
import { IonicModule } from '@ionic/angular';
import { ManejoCioComponent } from './manejo-reprodutivo/manejo-cio/manejo-cio.component';
import { ManejoImplanteRemoverComponent } from './manejo-reprodutivo/manejo-implante-remover/manejo-implante-remover.component';
import { ManejoMovimentacaoConfinamentoComponent } from './manejo-geral/manejo-movimentacao-confinamento/manejo-movimentacao-confinamento.component';
import { PropriedadeNomeComponent } from './propriedade-nome/propriedade-nome.component';
import { ConexaoBluetoothComponent } from './conexao-bluetooth/conexao-bluetooth.component';
import { ManejoPerimetroEscrotalComponent } from './manejo-genetica/manejo-perimetro-escrotal/manejo-perimetro-escrotal.component';
import { ManejoFrameComponent } from './manejo-genetica/manejo-frame/manejo-frame.component';
import { ManejoGarupaComponent } from './manejo-genetica/manejo-garupa/manejo-garupa.component';
import { ManejoInsercaoCaudaComponent } from './manejo-genetica/manejo-insercao-cauda/manejo-insercao-cauda.component';
import { ManejoLinhaDorsoComponent } from './manejo-genetica/manejo-linha-dorso/manejo-linha-dorso.component';
import { ManejoMusculosidadeComponent } from './manejo-genetica/manejo-musculosidade/manejo-musculosidade.component';
import { ManejoOssaturaComponent } from './manejo-genetica/manejo-ossatura/manejo-ossatura.component';
import { ManejoPelagemComponent } from './manejo-genetica/manejo-pelagem/manejo-pelagem.component';
import { ManejoProfundidadeComponent } from './manejo-genetica/manejo-profundidade/manejo-profundidade.component';
import { ManejoRacialComponent } from './manejo-genetica/manejo-racial/manejo-racial.component';
import { ManejoReproducaoComponent } from './manejo-genetica/manejo-reproducao/manejo-reproducao.component';
import { ManejoTemperamentoComponent } from './manejo-genetica/manejo-temperamento/manejo-temperamento.component';
import { ManejoTorcaoTesticularComponent } from './manejo-genetica/manejo-torcao-testicular/manejo-torcao-testicular.component';
import { ManejoUbereComponent } from './manejo-genetica/manejo-ubere/manejo-ubere.component';
import { ManejoUmbigoComponent } from './manejo-genetica/manejo-umbigo/manejo-umbigo.component';
import { ManejoAprumosComponent } from './manejo-genetica/manejo-aprumos/manejo-aprumos.component';
import { ManejoBocaComponent } from './manejo-genetica/manejo-boca/manejo-boca.component';
import { ManejoCascoComponent } from './manejo-genetica/manejo-casco/manejo-casco.component';
import { ManejoChanfroComponent } from './manejo-genetica/manejo-chanfro/manejo-chanfro.component';
import { ManejoAlteracaoNumeroComponent } from './manejo-geral/manejo-alteracao-numero/manejo-alteracao-numero.component';
import { ManejoPerdaComponent } from './manejo-geral/manejo-perda/manejo-perda.component';
import { SegmentPersonalizadoComponent} from './segment-personalizado/segment-personalizado.component'
import { ManejoSecagemComponent } from './manejo-geral/manejo-secagem/manejo-secagem.component';
import { ManejoObservacaoComponent } from './manejo-geral/manejo-observacao/manejo-observacao.component';
import { ManejoMovimentacaoEntreAreasComponent } from './manejo-geral/manejo-movimentacao-entre-areas/manejo-movimentacao-entre-areas.component';
import { ManejoPluviometriaComponent } from './manejo-pluviometria/manejo-pluviometria.component';
import { ManejoAlturaCapimComponent } from './manejo-altura-capim/manejo-altura-capim.component';
import { ManejoPastejoRotacionadoComponent } from './manejo-pastejo-rotacionado/manejo-pastejo-rotacionado.component';
import { ManejoEscoreFezesComponent } from './manejo-escore-fezes/manejo-escore-fezes.component';
import { SeletorPessoasComponent } from './seletor-pessoas/seletor-pessoas.component';

@NgModule({
  declarations: [
    BovinosListaComponent,
    SeletorLoteSingleComponent,
    FichaAnimalSingleComponent,
    ManejoPesagemComponent,
    ManejoOrdenhaComponent,
    ListaVaziaFallbackComponent,
    ManejoIdentificacaoEletronicaComponent,
    TagsComponent,
    CadastrarAnimalComponent,
    ListaRacasComponent,
    ManejoRegistrarMorteComponent,
    ImagemAntesDeEnviarComponent,
    ManejoMovimentacaoEntreLotesComponent,
    ManejoMovimentacaoConfinamentoComponent,
    CadastrarLoteComponent,
    ModalDispositivosBluetoothComponent,
    SeletorAreasComponent,
    ManejoPartoComponent,
    ConexaoBluetoothComponent,
    ManejoDiagnosticoGestacaoComponent,
    ManejoCioComponent,
    SegmentPersonalizadoComponent,
    ManejoInseminacaoArtificialComponent,
    ManejoMontaNaturalComponent,
    ManejoDesmameComponent,
    ManejoImplanteProgesteronaComponent,
    ManejoImplanteRemoverComponent,
    ManejoAbortoComponent,
    ManejoEscoreCorporalComponent,
    ManejoAlteracaoNumeroComponent,
    DetalhesManejosComponent,
    ManejosAnimalSingleComponent,
    HeaderFichaAnimalComponent,
    GuiaDeUsoComponent,
    VastaListaComponent,
    OpcoesBotoesComponent,
    CalendarioComponent,
    VastaTabelaFiltrosComponent,
    VastaInputComponent,
    CtaButtonsComponent,
    PaginateComponent,
    GenealogiaAnimalComponent,
    ManejoSanitarioComponent,
    PopoverListaExposicoesComponent,
    ManejoNutricionalComponent,
    ManejoSanitarioEstoqueComponent,
    ManejoCastracaoComponent,
    PropriedadeNomeComponent,
    ManejoDescarteComponent,
    ManejoPerimetroEscrotalComponent,
    ManejoFrameComponent,
    ManejoGarupaComponent,
    ManejoInsercaoCaudaComponent,
    ManejoLinhaDorsoComponent,
    ManejoMusculosidadeComponent,
    ManejoOssaturaComponent,
    ManejoPelagemComponent,
    ManejoProfundidadeComponent,
    ManejoRacialComponent,
    ManejoReproducaoComponent,
    ManejoTemperamentoComponent,
    ManejoTorcaoTesticularComponent,
    ManejoUbereComponent,
    ManejoUmbigoComponent,
    ManejoAprumosComponent,
    ManejoBocaComponent,
    ManejoCascoComponent,
    ManejoChanfroComponent,
    ManejoPerdaComponent,
    ManejoSecagemComponent,
    ManejoObservacaoComponent,
    ManejoMovimentacaoEntreAreasComponent,
    ManejoPluviometriaComponent,
    ManejoAlturaCapimComponent,
    ManejoPastejoRotacionadoComponent,
    ManejoEscoreFezesComponent,
    SeletorPessoasComponent
  ],
  imports: [IonicModule.forRoot(), CommonModule, FormsModule, PipesModule, ScrollingModule, NgxMaskModule.forChild()],
  exports: [
    BovinosListaComponent,
    SeletorLoteSingleComponent,
    FichaAnimalSingleComponent,
    ManejoPesagemComponent,
    ManejoOrdenhaComponent,
    ListaVaziaFallbackComponent,
    TagsComponent,
    ManejoIdentificacaoEletronicaComponent,
    CadastrarAnimalComponent,
    ListaRacasComponent,
    ManejoRegistrarMorteComponent,
    ImagemAntesDeEnviarComponent,
    ManejoMovimentacaoEntreLotesComponent,
    ManejoMovimentacaoConfinamentoComponent,
    CadastrarLoteComponent,
    ModalDispositivosBluetoothComponent,
    SeletorAreasComponent,
    ManejoPartoComponent,
    ConexaoBluetoothComponent,
    ManejoDiagnosticoGestacaoComponent,
    ManejoCioComponent,
    SegmentPersonalizadoComponent,
    ManejoInseminacaoArtificialComponent,
    ManejoMontaNaturalComponent,
    ManejoDesmameComponent,
    ManejoImplanteProgesteronaComponent,
    ManejoImplanteRemoverComponent,
    ManejoAbortoComponent,
    ManejoEscoreCorporalComponent,
    ManejoAlteracaoNumeroComponent,
    DetalhesManejosComponent,
    ManejosAnimalSingleComponent,
    HeaderFichaAnimalComponent,
    GuiaDeUsoComponent,
    VastaListaComponent,
    OpcoesBotoesComponent,
    CalendarioComponent,
    VastaTabelaFiltrosComponent,
    VastaInputComponent,
    CtaButtonsComponent,
    PaginateComponent,
    GenealogiaAnimalComponent,
    ManejoSanitarioComponent,
    PopoverListaExposicoesComponent,
    ManejoNutricionalComponent,
    ManejoSanitarioEstoqueComponent,
    ManejoCastracaoComponent,
    PropriedadeNomeComponent,
    ManejoDescarteComponent,
    ManejoPerimetroEscrotalComponent,
    ManejoFrameComponent,
    ManejoGarupaComponent,
    ManejoInsercaoCaudaComponent,
    ManejoLinhaDorsoComponent,
    ManejoMusculosidadeComponent,
    ManejoOssaturaComponent,
    ManejoPelagemComponent,
    ManejoProfundidadeComponent,
    ManejoRacialComponent,
    ManejoReproducaoComponent,
    ManejoTemperamentoComponent,
    ManejoTorcaoTesticularComponent,
    ManejoUbereComponent,
    ManejoUmbigoComponent,
    ManejoAprumosComponent,
    ManejoBocaComponent,
    ManejoCascoComponent,
    ManejoChanfroComponent,
    ManejoPerdaComponent,
    ManejoSecagemComponent,
    ManejoObservacaoComponent,
    ManejoMovimentacaoEntreAreasComponent,
    ManejoPluviometriaComponent,
    ManejoAlturaCapimComponent,
    ManejoPastejoRotacionadoComponent,
    ManejoEscoreFezesComponent,
    SeletorPessoasComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
