import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AnimalModel, AnimalDoManejo } from '../../../utils/types';

@Component({
  selector: 'manejo-perimetro-escrotal',
  templateUrl: './manejo-perimetro-escrotal.component.html',
  styleUrls: ['./manejo-perimetro-escrotal.component.scss'],
})
export class ManejoPerimetroEscrotalComponent  implements OnInit {
  @Input() manejo: {
    perimetro_escrotal?: string
    observacao?: string
  }
  @Input('animal') animal: AnimalModel
  @Input() animalSelecionado: AnimalDoManejo
  @Output('manejoChange') manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {}

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.detectChanges()
    this.manejoChange.emit(this.manejo)
  }

  detectChanges() {
    this.cd.detectChanges()
  }
}
