import { ManejosPorTipo } from '../../pages/home/atalho-manejos'
import { EstoqueBiologicoService } from '../../services/estoque-biologico.service'
import { AnimalModel, AnimalDoManejo, ManejoModel } from '../../utils/types'
import Utils from '../../utils/utils'
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'manejos-animal-single',
  templateUrl: './manejos-animal-single.component.html',
  styleUrls: ['./manejos-animal-single.component.scss']
})
export class ManejosAnimalSingleComponent implements OnInit {
  @Input() animalDados: AnimalModel
  @Input() animal: AnimalDoManejo
  @Input() manejos: ManejoModel[]

  utils = Utils
  public listaDeSemens = []
  manejosPorTipo = ManejosPorTipo
  semenPorHash = {}

  labelsResultadosManejos = {
    'positivo': 'Positivo',
    'negativo': 'Negativo',
    'perdido': 'Confirmada'
  }

  constructor(private estoqueBiologicoCtrl: EstoqueBiologicoService) {}

  async ngOnInit() {
    this.listaDeSemens = await this.estoqueBiologicoCtrl.listarItems()
    this.listaDeSemens.forEach((semen) => {
      this.semenPorHash[semen.hash] = semen
    })
  }
}
