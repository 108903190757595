import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { Preferences } from '@capacitor/preferences';

@Component({
  selector: 'propriedade-nome',
  templateUrl: './propriedade-nome.component.html',
  styleUrls: ['./propriedade-nome.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropriedadeNomeComponent implements OnInit {
  @Input('tipo') tipo: '' | 'h1'
  public propriedadeNome: string

  constructor(
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      const propriedadeSelecionadaAux = (await Preferences.get({ key: 'propriedade-selecionada' })).value;
      const propriedade = JSON.parse(propriedadeSelecionadaAux);
      this.propriedadeNome = propriedade?.nome || ''
    } catch (e) {
      console.error('ERRO AO BUSCAR propriedade selecionada', e)
    }
  }
}
