import { Preferences } from '@capacitor/preferences';
import { LabelsEstoqueNutricionalTipo } from '../../../components/manejo-nutricional/nutricional-labels';
import { ColunasEstoqueNutricionalLista } from '../../../utils/configuracoes-padrao';
import { ManejoNutricional } from '../../../utils/types';
import { IEstoqueNutricionalItemsQuery } from '../../../utils/types/manejo-nutricional-types';
import Utils from '../../../utils/utils';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NutricionalService } from '../../../services/nutricao.service';

@Component({
  selector: 'app-estoque-nutricional',
  templateUrl: './estoque-nutricional.page.html',
  styleUrls: ['./estoque-nutricional.page.scss'],
})
export class EstoqueNutricionalPage implements OnInit {
  ColunasEstoqueNutricionalLista = ColunasEstoqueNutricionalLista
  estoqueNutricional: IEstoqueNutricionalItemsQuery[]
  estoquesSelecionadosPreviamente: ManejoNutricional[]
  hash_not_in: string[]
  utils = Utils
  termoBuscaEstoque: string
  labelsEstoqueNutricionalTipo = LabelsEstoqueNutricionalTipo
  estoquesSelecionados: { [keyId: number]: IEstoqueNutricionalItemsQuery } = {}

  constructor(
    private modalCtrl: ModalController,
    private nutricionalService: NutricionalService
  ) { }

  ngOnInit() {
    this.getEstoqueNutricional()
  }

  async getEstoqueNutricional(): Promise<void> {
    try {
      const propriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)

      const { estoques } = await this.nutricionalService.getEstoquesNutricional({
        idPropriedade: propriedade.id,
        filtros: {
          hash_not_in: this.hash_not_in,
          titulo: this.termoBuscaEstoque || '',
          order_by: 'extra_quantidade',
          order_direction: 'desc'
        }
      })

      this.estoqueNutricional = estoques
    } catch(error) {
      console.error(error)
    }
  }

  dismiss(value?: IEstoqueNutricionalItemsQuery[]) {
    this.modalCtrl.dismiss(value)
  }

  selecionarEstoque(estoque: IEstoqueNutricionalItemsQuery) {
    this.dismiss([estoque])
  }

  confirmarSelecaoDeEstoques() {
    const listaEstoques = Object.values(this.estoquesSelecionados)
    this.dismiss(listaEstoques)
  }
}
