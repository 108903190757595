import { environment } from "../../../environments/environment"

let APP_VERSION = environment.appVersion
const IS_PRODUCTION = environment.production
const URL_CLOUD_FUNCTONS = environment.API
const URL_CLOUD_FUNCTONS_CAMPO = environment.APICampo
const PATH = '/peao'
const JWTCode = environment.jwtCode

if (!IS_PRODUCTION) {
  const root = document.getElementsByTagName('html')[0]
  root.classList.add('dev')
  document.body.classList.add('dev')
}

if (environment.name !== 'prod') {
  APP_VERSION += '-' + environment.name
}

export {
  IS_PRODUCTION,
  URL_CLOUD_FUNCTONS,
  URL_CLOUD_FUNCTONS_CAMPO,
  PATH,
  JWTCode,
  APP_VERSION
}
