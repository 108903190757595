import { Injectable } from '@angular/core';
import { BancoSQLiteService } from './banco-sqlite.service';
import { Preferences } from '@capacitor/preferences';
import { NovoFiltro } from '../components/vasta-lista/vasta-lista.component';
import { sqlFilter } from '../utils/sqlUtils';


@Injectable({
    providedIn: 'root'
  })
  export class PessoasService {
    constructor(private db: BancoSQLiteService) {}

    async getPessoas(opcoes: {
        filtros?: NovoFiltro
      }): Promise<{ centros: any[]; totais: { total: number } }> {
        const { filtros } = opcoes || {}
        const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;

        const centrosPromise: Promise<any[]> = this.db.safeQuery({
          query: `
          SELECT pessoa.hash, pessoa.nome as nome, pessoa.slug, pessoa.descricao, pessoa.status,
          (CASE WHEN pai.nome IS NOT NULL THEN pai.slug ELSE pessoa.categoria_pai END) AS categoria_pai

          FROM gestor_categorias pessoa
          LEFT JOIN gestor_categorias pai ON pai.hash = pessoa.categoria_pai
          WHERE pessoa.status = 1
          AND pessoa.id_propriedade = {{idPropriedade}}
          AND (pessoa.slug NOT IN ("terceirizado","funcionario","fornecedor","cliente") OR pessoa.slug IS NULL)
          AND pessoa.modulo = 'pessoas'
          ${sqlFilter.safeLike({ coluna: 'pessoa.nome', tag: '{{nome}}', valor: filtros.nome })}
          ${sqlFilter.safeLike({ coluna: 'pessoa.descricao', tag: '{{descricao}}', valor: filtros.descricao })}

          group by pessoa.hash
          HAVING TRUE
          ${sqlFilter.safeLike({ coluna: 'pessoa.categoria_pai', tag: '{{categoria_pai}}', valor: filtros.categoria_pai })}

          ${sqlFilter.safeOrderBy({
            coluna: filtros.order_by == 'nome' ? 'pessoa.nome' : filtros.order_by || 'pessoa.nome',
            direcao: filtros.order_direction || 'asc',
            prefixo: true,
            tableCase: {
              descricao: 'pessoa'
            }
          })}

          ${sqlFilter.safeLimitOffset(filtros.query_limit, filtros.query_offset)}
        `,
          params: {
            idPropriedade,
            ...filtros
          }
        })

        const totaisPromise: Promise<[{ total: number }]> = this.db.safeQuery({
          query: `
          SELECT COUNT(*) as totais
          FROM gestor_categorias super
          WHERE status = 1
          AND id_propriedade = {{idPropriedade}}
          AND (slug NOT IN ("terceirizado","funcionario","fornecedor","cliente") OR slug IS NULL)
          AND modulo = 'pessoas'
        `,
          params: {
            idPropriedade
          }
        })

        const [centros, [totais]] = await Promise.all([centrosPromise, totaisPromise])

        return { centros, totais }
    }
}