import { ManejoAnimalModel } from '../../utils/types'
import { IEstoqueSanitarioItemsQuery } from '../../utils/types/manejo-sanitario-types'
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { ActionSheetController, ModalController } from '@ionic/angular'
import { labelsEstoqueFarmaciaTipo } from './sanitario-labels'
import { EstoqueFarmaciaPage } from '../../pages/manejos/estoque-farmacia/estoque-farmacia.page'

@Component({
  selector: 'manejo-sanitario-estoque',
  templateUrl: './manejo-sanitario-estoque.component.html',
  styleUrls: ['./manejo-sanitario-estoque.component.scss'],
})
export class ManejoSanitarioEstoqueComponent implements OnInit {
  @Input() manejo: { [key: string]: { sanitario_quantidade?: number} } = {};
  @Output() manejoChange = new EventEmitter<any>();
  
  @Output() opcoes = new EventEmitter<any>();

  @Input() itensEstoque: { sanitario_hash_estoque: string; nome: string; categoria: string; meta_unidade: string }[]
  @Output() itensEstoqueChange = new EventEmitter<{ sanitario_hash_estoque: string; nome: string; categoria: string}[]>()

  @Output() removerEstoque = new EventEmitter<ManejoAnimalModel>()

  @Input() quantidadeSelecionadas: {[key: string]: number[]} = {}
  @Output() quantidadeSelecionadasChange = new EventEmitter()

  labelsEstoqueSanitarioTipo = labelsEstoqueFarmaciaTipo

  constructor(
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
  ) { }

  ngOnInit() {
  }

  async adicionarManejoEmitindoManejosSelecionados() {
    const modal = await this.modalCtrl.create({
      component: EstoqueFarmaciaPage,
      cssClass: 'modal-narrow',
      componentProps: {
        hash_not_in: this.itensEstoque?.map(item => item?.sanitario_hash_estoque)
      }
    })

    await modal.present()

    const { data: estoquesSelecionados } = await modal.onDidDismiss<IEstoqueSanitarioItemsQuery[]>()

    console.log('estoquesSelecionados', estoquesSelecionados)

    if(estoquesSelecionados) {
      estoquesSelecionados.map(estoque => {
        this.itensEstoque = this.itensEstoque || []
        this.itensEstoque.push({
          sanitario_hash_estoque: estoque?.hash,
          nome: estoque?.titulo,
          categoria: estoque?.meta_tipoDoEstoque,
          meta_unidade: estoque?.meta_unidade,
        })
      })

      this.itensEstoqueChange.emit(this.itensEstoque)
      this.emit()
    }
  }

  fecharTeclado(event: KeyboardEvent) {
    let element = event.target as HTMLInputElement;
    element.blur();
  }

  async abrirOpcoesDoManejo() {
    this.opcoes.emit()
  }

  async abrirOpcoesDoEstoque(estoqueSelecionado: ManejoAnimalModel) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Opções do Manejo',
      buttons: [
        {
          text: 'Remover estoque de todos os animais',
          role: 'remover',
          icon: 'trash',
          handler: () => {
            this.removerEstoque.emit(estoqueSelecionado)
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          icon: 'close'
        }
      ]
    });
    await actionSheet.present();
  }

  atualizarOpcoesRapidas(valor, hash_estoque) {
      this.quantidadeSelecionadas[hash_estoque] = this.quantidadeSelecionadas[hash_estoque] || []
      if (!this.quantidadeSelecionadas[hash_estoque].includes(valor) && valor) {
        this.quantidadeSelecionadas[hash_estoque].unshift(valor)
        this.quantidadeSelecionadas[hash_estoque] = this.quantidadeSelecionadas[hash_estoque].slice(0, 5)
      }
  }
  
  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
