import { ColunasTabela } from "./types/tabela-colunas"

export const ColunasAnimais: ColunasTabela[] = [
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    bold: true,
    tamanho: 3,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Lote',
    chave: 'extra_lote',
    placeholder: 'Sem lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 3,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tipoFiltro: 'texto',
    tamanho: 3,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 3,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Peso',
    chave: 'pesagem_peso',
    unidade: 'Kg',
    format: 'peso',
    placeholder: 'Não pesado',
    tipoFiltro: 'rangeNumeros',
    tamanho: 3,
    classesExtra: 'mobile-line-break mobile-width-auto'
  },
  {
    titulo: 'Peso @',
    chave: 'pesagem_arroba',
    unidade: '@',
    placeholder: '',
    tipoFiltro: 'rangeNumeros',
    tamanho: 3,
    format: 'peso',
    classesExtra: 'mobile-width-auto',
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Pesagem',
    format: 'data',
    chave: 'pesagem_data',
    tipoFiltro: 'rangeDatas',
    tamanho: 3,
    classesExtra: 'mobile-font-smaller mobile-width-auto'
  },
  {
    titulo: 'GMD',
    chave: 'pesagem_gmd',
    unidade: 'kg',
    placeholder: '0',
    assinaturaPremium: true,
    tipoFiltro: 'rangeNumeros',
    tamanho: 3,
    format: 'gmd',
    classesExtra: 'mobile-hide-empty mobile-before-gmd-texto mobile-width-auto'
  },
  {
    titulo: 'Partos',
    chave: 'extra_partos_total',
    // format: "categoria",
    tipoFiltro: 'preenchidosMultiplos',
    labelFormat: 'labelsParto',
    tamanho: 3,
    classesExtra: 'mobile-line-break',
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'No lote desde',
    format: 'data',
    chave: 'data_manejo_lote',
    small: 'data_manejo_lote_dias_atras',
    tipoFiltro: 'rangeDatas',
    tamanho: 3,
    classesExtra: 'mobile-before-lote-desde mobile-font-smaller',
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Categoria',
    chave: 'extra_categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 3,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Raça',
    chave: 'extra_composicao_racial',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 3,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Nascimento',
    format: 'data',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    tamanho: 3,
  },
  {
    titulo: 'Situação',
    labelFormat: 'labelsSituacao',
    chave: 'situacao',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 3,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'S. Reprodutiva',
    labelFormat: 'labelsSituacaoReprodutiva',
    chave: 'situacao_reprodutiva',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: '',
    tamanho: 3,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'S. Sanitária',
    labelFormat: 'labelsSituacaoSanitaria',
    chave: 'situacao_sanitaria',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: '',
    tamanho: 3,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Aptidão',
    labelFormat: 'labelsAptidao',
    chave: 'aptidao',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: '',
    tamanho: 3,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Cria ao pé',
    chave: 'meta_cria_ao_pe',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 3,
    labelFormat: 'labelsCriaAoPe'
  },
  {
    titulo: 'Área',
    chave: 'extra_area',
    tipoFiltro: 'preenchidos',
    tamanho: 3,
  },
  {
    titulo: 'Observações',
    chave: 'observacao',
    tipoFiltro: 'texto',
    tamanho: 1,
  },
  {
    titulo: 'SISBOV',
    chave: 'meta_n_sisbov',
    tipoFiltro: 'texto',
    tamanho: 3,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Produtor de Origem',
    chave: 'extra_produtor_de_origem',
    tipoFiltro: 'texto',
    tamanho: 3,
    bloquearOrdenacao: true,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Pelagem',
    chave: 'meta_pelagem',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsPelagem',
    tamanho: 3,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Data de Cadastro',
    format: 'data',
    chave: 'created_at',
    tipoFiltro: 'rangeDatas',
    tamanho: 3,
    somente_exibir_se_filtrado: true,
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 3,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasCustoDoAnimal: ColunasTabela[] = [
  {
    titulo: 'Data',
    format: 'data',
    chave: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7,
  },
  {
    titulo: 'Manejo',
    chave: 'manejo',
    tamanho: 7,
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'tipoDeManejoNutricional',
    tamanho: 5,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
  },
  {
    titulo: 'Qtd',
    chave: 'qtd_consumido',
    // unidade: 'Kg',
    format: 'peso',
    placeholder: 'Não pesado',
    tipoFiltro: 'rangeNumeros',
    tamanho: 7,
  },
  // {
  //   titulo: 'Unidade',
  //   chave: 'unidade',
  //   // format: 'peso',
  //   // placeholder: 'Não pesado',
  //   // tipoFiltro: 'rangeNumeros',
  //   tamanho: 7,
  // },
  {
    titulo: 'Item',
    chave: 'item',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
  },
  {
    titulo: 'Duração',
    chave: 'duracao',
    tamanho: 7,
    unidade: 'dias',
    tipoFiltro: 'texto',
    placeholder: '',
  },
  {
    titulo: 'Valor Kg',
    chave: 'valor_kg',
    format: 'dinheiro',
    placeholder: 'Não informado',
    tamanho: 8,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Custo Total',
    chave: 'custo_total',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
]
export const ColunasAnimalSingleHistorico: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data',
    placeholder: '-',
    tipoFiltro: 'texto',
    format: 'data',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Ação',
    chave: 'acao',
    tamanho: 12,
    tipoFiltro: 'texto',
    placeholder: '-',
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Dados',
    chave: 'dados',
    placeholder: '-',
    tipoFiltro: 'texto',
    tamanho: 18,
    classesExtra: 'mobile-width-auto'
  }
]
export const ColunasAnimaisExternos: ColunasTabela[] = [
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 10,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Raça',
    chave: 'extra_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    bloquearOrdenacao: true,
    tamanho: 8
  },
  {
    titulo: 'Matriz (Mãe)',
    chave: 'matriz',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Reprodutor (Pai)',
    chave: 'reprodutor',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Pelagem',
    chave: 'meta_pelagem',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 10,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Registro Definitivo',
    chave: 'meta_registroDefinitivo',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 9,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Registro de Nascimento',
    chave: 'meta_registroDeNascimento',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Descrição',
    chave: 'descricao',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasAreas: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 3,
    bold: true,
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Lotes incluídos',
    chave: 'lotes_nomes',
    placeholder: 'Nenhum lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 2
  },
  {
    titulo: 'Tamanho',
    chave: 'tamanho',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não preenchido',
    unidade: 'ha',
    tamanho: 3
  },

  {
    titulo: 'Animais',
    chave: 'qtd_animais',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 3
  },
  {
    titulo: 'Piquete Ativo',
    chave: 'extra_piquete_ativo',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 3
  },
  {
    titulo: 'Piquetes',
    chave: 'extra_piquetes_qtd',
    placeholder: '0',
    format: 'int',
    tipoFiltro: 'rangeNumeros',
    tamanho: 3
  },
  {
    titulo: 'UA',
    chave: 'ua',
    placeholder: '0',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    tamanho: 3
  },
  {
    titulo: '% de ocupação',
    chave: 'tx_ocupacao',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '0',
    assinaturaPremium: true,
    unidade: '%',
    tamanho: 3
  },
  {
    titulo: '% de lotação',
    chave: 'tx_lotacao',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '0',
    assinaturaPremium: true,
    unidade: 'UA/ha',
    tamanho: 3
  },
  {
    titulo: 'Peso total',
    chave: 'total_peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 3
  },
  {
    titulo: 'Lot. Máxima',
    chave: 'max_lotacao',
    tipoFiltro: 'rangeNumeros',
    unidade: 'ua',
    placeholder: '0',
    tamanho: 3
  },
  {
    titulo: 'Tipo de Área',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'meta_tipo_area',
    labelFormat: 'labelsTipoDeArea',
    placeholder: 'Não preenchido',
    tamanho: 3
  },
  {
    titulo: 'Tipo de Capim',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'meta_tipo_capim',
    placeholder: 'Não preenchido',
    tamanho: 3
  },
  {
    titulo: 'Altura de Entrada do Capim',
    chave: 'meta_altura_entrada_capim',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não preenchido',
    tamanho: 3
  },
  {
    titulo: 'Altura de Saída do Capim',
    chave: 'meta_altura_saida_capim',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'N/A',
    tamanho: 3
  },
  {
    titulo: 'Data de Cadastro',
    chave: 'created_at',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 3
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    tipoFiltro: 'texto',
    placeholder: 'Não preenchido',
    tamanho: 3
  },
  // {
  //   titulo: 'Mais',
  //   chave: 'mais',
  //   tamanho: 2,
  //   fixaFinal: true,
  //   tipo: 'opcoes',
  //   centralizar: true,
  //   evento: 'opcoes',
  //   pointer: true
  // }
]
export const ColunasPropriedades: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    bold: true,
    tamanho: 1,
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Animais',
    chave: 'propriedade_animais',
    placeholder: 'Nenhum animal',
    tipoFiltro: 'texto',
    tamanho: 3
  },
  {
    titulo: 'Tipo de Criação',
    chave: 'criacao',
    tipoFiltro: 'texto',
    labelFormat: 'labelsFinalidade',
    placeholder: 'Sem criação',
    tamanho: 3
  },
  {
    titulo: 'Produção',
    chave: 'producao',
    tipoFiltro: 'texto',
    labelFormat: 'labelsAptidao',
    placeholder: 'Sem produção',
    tamanho: 3
  },
  {
    titulo: 'Proprietário',
    chave: 'proprietario',
    tipoFiltro: 'texto',
    placeholder: 'Não preenchido',
    tamanho: 3
  },
  {
    titulo: 'Cidade',
    chave: 'cidade',
    tipoFiltro: 'texto',
    placeholder: 'Sem ciadade',
    tamanho: 3
  },
  {
    titulo: 'Estado',
    chave: 'estado',
    tipoFiltro: 'texto',
    tamanhoMinimo: 7,
    placeholder: 'Sem estado'
  },
]
export const ColunasLotes: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    small: 'label_confinamento',
    tamanho: 3,
    classesExtra: 'mobile-bold mobile-width-two-thirds',
    bold: true,
  },
  {
    titulo: 'Animais',
    chave: 'qtd_animais',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 3,
    classesExtra: 'mobile-before-animais'
  },
  {
    titulo: 'Área',
    chave: 'nome_area',
    tipoFiltro: 'preenchidos',
    placeholder: 'Sem área',
    tamanho: 3,
    classesExtra: 'strong'
  },
  {
    titulo: 'Entrada na área',
    chave: 'data_entrada_area',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    placeholder: 'N/A',
    tamanho: 3,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Peso total',
    chave: 'total_peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 3,
    classesExtra: 'mobile-width-auto mobile-line-break'
  },
  {
    titulo: 'Peso médio',
    chave: 'peso_medio',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 3,
    classesExtra: 'mobile-width-auto mobile-line-break'
  },
  {
    titulo: 'Peso @',
    chave: 'peso_arroba',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '0',
    unidade: '@',
    tamanho: 3,
    classesExtra: 'mobile-width-auto mobile-hide-empty'
  },
  {
    titulo: 'GMD Médio',
    chave: 'gmd',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    assinaturaPremium: true,
    format: 'gmd',
    unidade: 'kg',
    tamanho: 3,
    classesExtras: 'mobile-width-auto mobile-hide-empty'
  },
  {
    titulo: 'Finalidade',
    chave: 'finalidade',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsFinalidade',
    tamanho: 3,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Sistema de Criação',
    chave: 'sistema_criacao',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsSistemaCriacao',
    tamanho: 3,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Aptidão',
    chave: 'aptidao',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsAptidao',
    tamanho: 3,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Confinamento',
    chave: 'confinamento',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 3,
    labelFormat: 'labelsCriaAoPe'
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 3
  },
  // {
  //   titulo: 'Mais',
  //   chave: 'mais',
  //   tamanho: 2,
  //   fixaFinal: true,
  //   tipo: 'opcoes',
  //   centralizar: true,
  //   evento: 'opcoes',
  //   pointer: true
  // }
]
export const OrdenhasLista: ColunasTabela[] = [
  {
    titulo: 'Dia',
    chave: 'dia',
    tipoFiltro: 'rangeNumeros',
    tamanho: 7,
    classesExtra: 'mobile-bold mobile-width-fourth'
  },
  {
    titulo: 'Vacas',
    chave: 'ordenhas',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 7,
    classesExtra: 'mobile-before-animais mobile-width-fourth'
  },
  {
    titulo: 'Total',
    chave: 'total',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 9,
    classesExtra: 'mobile-before-leite mobile-width-fourth'
  },
  {
    // titulo: "Diferença",
    chave: 'diffDirecao',
    tipo: 'icon-only',
    centralizar: true,
    tipoFiltro: 'preenchidos',
    tamanho: 1,
    iconeCase: {
      positivo: {
        class: 'arrow-up'
      },
      negativo: {
        class: 'arrow-down',
        color: '#d32f2f'
      }
    }
  },
  {
    titulo: 'Diferença',
    chave: 'diff',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '-',
    unidade: 'kg',
    tamanho: 9,
    classesExtra: 'mobile-width-auto'
  }
]
export const ColunasManejoReprodutivo: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-bold mobile-width-one-third'
  },
  {
    titulo: 'Manejo',
    chave: 'id_manejos',
    labelFormat: 'labelsManejosReprodutivos',
    tipoFiltro: 'preenchidos',
    placeholder: '',
    tamanho: 9,
    classesExtra: 'mobile-width-auto mobile-width-two-thirds mobile-manejos-titulos'
  },
  {
    chave: 'sexo_animal',
    tipo: 'icon-only',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Animal ou lote',
    chave: 'nome_lote',
    chaveFallback: 'nome_animal',
    placeholder: 'Não selecionado',
    tipoFiltro: 'texto',
    tamanho: 13
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Quantidade de animais',
    chave: 'qtd_animais',
    placeholder: '0',
    tipoFiltro: 'texto',
    tamanhoMinimo: 10,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasManejoSanitario: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-bold mobile-width-one-third'
  },
  {
    titulo: 'Manejo',
    chave: 'id_manejos',
    labelFormat: 'labelsManejosSanitarios',
    tipoFiltro: 'preenchidosSplit',
    placeholder: '',
    tamanho: 12,
    classesExtra: 'mobile-width-auto mobile-width-two-thirds mobile-manejos-titulos'
  },
  {
    // titulo: 'Sexo',
    chave: 'sexo_animal',
    tipo: 'icon-only',
    // tipoFiltro: "sexo",
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Animal ou lote',
    chave: 'nome_lote',
    chaveFallback: 'nome_animal',
    placeholder: 'Não selecionado',
    tipoFiltro: 'texto',
    tamanho: 13
  },
  {
    titulo: 'Quantidade de animais',
    chave: 'qtd_animais',
    placeholder: '0',
    tipoFiltro: 'texto',
    tamanhoMinimo: 12,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    tipoFiltro: 'texto',
    placeholder: '',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasEstoqueBiologicoLista: ColunasTabela[] = [
  {
    titulo: 'Tipo',
    chave: 'tipoDoEstoque',
    labelFormat: 'labelsEstoqueBiologicoLancamentoTipo',
    placeholder: 'Sem tipo',
    tamanho: 6,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Quantidade',
    chave: 'quantidade',
    placeholder: '0',
    tamanho: 7,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Touro',
    chave: 'touro_nome',
    placeholder: 'Sem touro',
    tamanho: 9,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Doadora',
    chave: 'doadora_nome',
    placeholder: 'Sem doadora',
    tamanho: 9,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Fornecedor',
    chave: 'fornecedor_nome',
    placeholder: '-',
    tamanho: 9,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    placeholder: '-',
    labelFormat: 'labelsRaca',
    bloquearOrdenacao: true,
    tamanho: 9,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Código',
    chave: 'codigo_no_rack',
    placeholder: 'Sem código',
    tamanho: 7,
    tipoFiltro: 'texto'
  },

  {
    titulo: 'Caneca',
    chave: 'caneca',
    placeholder: '-',
    tamanho: 7,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Botijão',
    chave: 'botijao',
    placeholder: '-',
    tamanhoMinimo: 7,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasEstoqueNutricionalLista: ColunasTabela[] = [
  {
    titulo: 'Título',
    chave: 'titulo',
    bold: true,
    placeholder: 'Sem título',
    tamanho: 10,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Tipo',
    chave: 'meta_tipoDoEstoque',
    labelFormat: 'labelsEstoqueNutricionalTipo',
    placeholder: 'Sem tipo',
    tamanho: 3,
    tipoFiltro: 'preenchidos'
  },
  {
    titulo: 'Valor',
    format: 'dinheiro',
    chave: 'extra_valor_total',
    placeholder: 'R$ 0,00',
    tamanho: 3,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Quantidade',
    chave: 'extra_quantidade',
    unidade: 'kg',
    placeholder: '0 kg',
    format: 'gmd',
    tamanho: 3,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Última Movimentação',
    chave: 'extra_ultima_movimentacao',
    placeholder: 'Sem movimentação',
    format: 'data',
    tamanho: 3,
    tipoFiltro: 'rangeDatas'
  },
]
export const ColunasEstoqueFarmaciaLista: ColunasTabela[] = [
  {
    titulo: 'Título',
    chave: 'titulo',
    placeholder: 'Sem título',
    bold: true,
    tamanho: 3,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Marca',
    chave: 'meta_marca',
    placeholder: '-',
    tamanho: 3,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Tipo',
    chave: 'meta_tipoDoEstoque',
    labelFormat: 'labelsEstoqueFarmaciaTipo',
    placeholder: 'Sem tipo',
    tamanho: 3,
    tipoFiltro: 'preenchidos'
  },
  {
    titulo: 'Valor',
    format: 'dinheiro',
    chave: 'extra_valor_total',
    placeholder: 'R$ 0,00',
    tamanho: 3,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Quantidade',
    chave: 'extra_quantidade',
    small: 'meta_unidade',
    // unidade: 'kg',
    placeholder: '0',
    format: 'gmd',
    tamanho: 3,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Última Movimentação',
    chave: 'extra_ultima_movimentacao',
    placeholder: 'Sem movimentação',
    format: 'data',
    tamanho: 3,
    tipoFiltro: 'rangeDatas'
  },
]
export const ColunasEstoqueNutricionalLancamentos: ColunasTabela[] = [
  {
    titulo: 'Movimentação',
    tituloHidden: true,
    chave: 'movimentacao',
    tipo: 'icon-only',
    labelFormat: 'labelsEstoqueLancamentoMovimentacao',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Data',
    chave: 'data_lancamento',
    placeholder: 'Sem data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Quantidade',
    chave: 'qtd',
    unidade: 'kg',
    format: 'gmd',
    placeholder: 'Sem qtd',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'labelsEstoqueNutricionalLancamentoTipo',
    placeholder: 'Sem tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Valor',
    chave: 'valor_total',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Valor / Kg',
    chave: 'valor_medio_momentaneo',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Descrição',
    chave: 'obs',
    placeholder: '-',
    tamanhoMinimo: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasEstoqueFarmaciaLancamentos: ColunasTabela[] = [
  {
    titulo: 'Movimentação',
    tituloHidden: true,
    chave: 'movimentacao',
    tipo: 'icon-only',
    labelFormat: 'labelsEstoqueLancamentoMovimentacao',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Data',
    chave: 'data_lancamento',
    placeholder: 'Sem data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Quantidade',
    chave: 'qtd',
    small: 'meta_unidade',
    format: 'gmd',
    placeholder: 'Sem qtd',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'labelsEstoqueNutricionalLancamentoTipo',
    placeholder: 'Sem tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Valor',
    chave: 'valor_total',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Valor Unitário',
    chave: 'valor_medio_momentaneo',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Descrição',
    chave: 'obs',
    placeholder: '-',
    tamanhoMinimo: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasCalendario: ColunasTabela[] = [
  {
    titulo: '',
    chave: 'icon',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      pesagem: {
        class: 'pesagem-controle',
        color: 'manejo-pesagem'
      },
      ordenha: {
        class: 'leiteiro',
        color: 'manejo-ordenha'
      },
      reprodutivo: {
        class: 'inseminacao',
        color: 'manejo-reprodutivo'
      },
      parto: {
        class: 'parto',
        color: 'manejo-reprodutivo'
      },
      desmame: {
        class: 'desmame',
        color: 'manejo-reprodutivo'
      },
      chuva: {
        class: 'rainy-sharp',
        color: 'manejo-chuva'
      },
      sanitario: {
        class: 'seringa',
        color: 'manejo-sanitario'
      },
      geral: {
        class: 'calendar',
        color: 'manejo-evento'
      },
      outros: {
        class: 'calendar',
        color: 'manejo-evento'
      },
      evento: {
        class: 'calendar',
        color: 'manejo-evento'
      }
    },
    tamanho: 1
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    tipoFiltro: 'texto',
    labelFormat: 'labelsCalendarioEvento',
    tamanho: 8
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    tipoFiltro: 'texto',
    labelFormat: 'labelsCalendarioEvento',
    tamanho: 8
  },
  {
    titulo: 'Hora',
    placeholder: '--:--',
    chave: 'hora',
    tipoFiltro: 'texto',
    tamanho: 4
  },
  {
    titulo: 'Título',
    chave: 'titulo',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Descrição',
    chave: 'observacao',
    tipoFiltro: 'texto',
    tamanhoMinimo: 14
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasEstoqueBiologicoLancamento: ColunasTabela[] = [
  {
    titulo: 'Movimentação',
    tituloHidden: true,
    chave: 'movimentacao',
    tipo: 'icon-only',
    labelFormat: 'labelsEstoqueLancamentoMovimentacao',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Data',
    chave: 'data_lancamento',
    placeholder: 'Sem data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Quantidade',
    chave: 'qtd',
    format: 'gmd',
    placeholder: 'Sem qtd',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'labelsEstoqueBiologicoLancamentoTipo',
    placeholder: 'Sem tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Valor',
    chave: 'valor_total',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanhoMinimo: 7
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
