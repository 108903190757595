import { BoiOuSemenSelecionadoModel, ManejoAnimalModel } from '../../../utils/types'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { SeletorAnimaisPage } from '../../../pages/seletor-animais/seletor-animais.page'

@Component({
  selector: 'manejo-monta-natural',
  templateUrl: './manejo-monta-natural.component.html',
  styleUrls: ['./manejo-monta-natural.component.scss']
})
export class ManejoMontaNaturalComponent implements OnInit {
  @Input() opcoesRapidasTourosMonta: BoiOuSemenSelecionadoModel[]
  @Output('opcoesRapidasTourosMontaChange') opcoesRapidasTourosMontaChange = new EventEmitter()
  @Input() manejo: ManejoAnimalModel
  @Output('manejoChange') manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor(private modalCtrl: ModalController) {}

  async ngOnInit() {}

  async abrirOpcoesDeManejo() {
    this.opcoes.emit()
  }

  async openModalListaTouro() {
    const modal = await this.modalCtrl.create({
      component: SeletorAnimaisPage,
      componentProps: {
        estaTelaFoiAbertaPorManejo: "monta-natural"
      }
    })
    await modal.present()

    const { data: objetoAnimal } = await modal.onDidDismiss()

    const touro = objetoAnimal?.animal

    if (touro) {
      this.manejo.touro = touro
      this.selecionarTouro(touro)
    }
  }

  limparManejo() {
    this.manejo.touro = null
    this.emit()
  }

  selecionarTouro(touro) {
    this.manejo.touro = {
      hash: touro.hash,
      nome: touro?.nome || touro?.numeracao
    }

    this.processaOpcoesRapidas(touro)

    this.emit()
  }

  processaOpcoesRapidas(touro) {
    const hashsPresentes = this.opcoesRapidasTourosMonta.map((touro) => touro.hash)
    touro.nome = touro?.nome || touro?.numeracao
    if (!hashsPresentes.includes(touro.hash)) {
      this.opcoesRapidasTourosMonta.unshift(touro)
    }
    this.opcoesRapidasTourosMonta = this.opcoesRapidasTourosMonta.slice(0, 5)
    this.opcoesRapidasTourosMontaChange.emit(this.opcoesRapidasTourosMonta)
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
