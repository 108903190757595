import { Component } from '@angular/core'

import { ModalController, Platform } from '@ionic/angular'
import { UsuarioService } from './services/usuario.service'
import { BancoSQLiteService } from './services/banco-sqlite.service'
import { Router } from '@angular/router'
import { Preferences } from '@capacitor/preferences'
import { SplashScreen } from '@capacitor/splash-screen'
import { GlobalService } from './services/global.service'
import * as moment from 'moment'
import { VastaRX } from '@vasta/rx'
import { Device } from '@capacitor/device'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private usuarioService: UsuarioService,
    public modalCtrl: ModalController,
    private bancoSQLite: BancoSQLiteService,
    private router: Router,
    private global: GlobalService,
  ) {
		moment.locale('pt-BR');
  }

  async ngOnInit() {
    const platform = (await Device.getInfo())
    this.global.set('manufacturer', platform.manufacturer)

    if(navigator.userAgent.match(/SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i))  {
      alert("it's Samsung default browser");
      // your code for Samsung goes here
    } else {
      alert("Not Samsung default browser");
    }

    const { value } = await Preferences.get({
      key: 'token'
    })
    if (value) {
      const { token } = JSON.parse(value)
      if (token) this.global.set('token', token)
      console.log({token});
    }

    await this.initializeApp()
  }

  async initializeApp() {
    await this.bancoSQLite.initializaPlugin()
    await this.verificaUsuario()
    await this.platform.ready()
    if (this.platform.is('capacitor')) {
      SplashScreen.hide()
    }
  }

  async verificaUsuario() {
    try {
      await this.usuarioService.atualizaUsuarioESincroniza()
      
      if(this.router.url !== '/ajustes') {
        await this.router.navigate(['/home'])
      }
    } catch (error) {
      
      VastaRX.setState('statusSync', {
        status: 'erro',
        mensagem: 'Erro ao verificar usuário'
      })

      console.log(error)
      // await alerta.dismiss()
    }
  }
}
