import { AnimalDoManejo } from '../../utils/types';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FichaAnimalSingleComponent } from '../ficha-animal-single/ficha-animal-single.component';

@Component({
  selector: 'header-ficha-animal',
  templateUrl: './header-ficha-animal.component.html',
  styleUrls: ['./header-ficha-animal.component.scss'],
})
export class HeaderFichaAnimalComponent implements OnInit {

  @Input() animal: AnimalDoManejo
  @Output('animalEditado') animalEditado = new EventEmitter<any>()

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    console.log('animal: ',this.animal);
  }

  async verFichaAnimal(hashDoAnimal: string, event: any) {
    event.stopPropagation();
    const modal = await this.modalCtrl.create({
      component: FichaAnimalSingleComponent,
      componentProps: { hashDoAnimal }
    });
    await modal.present();
    await modal.onWillDismiss();
    this.animalEditado.emit()
  }

}
