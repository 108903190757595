import { LoteModel, ManejoAnimalModel } from '../../../utils/types';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SeletorLoteSingleComponent } from '../../seletor-lote-single/seletor-lote-single.component';

@Component({
  selector: 'manejo-movimentacao-entre-lotes',
  templateUrl: './manejo-movimentacao-entre-lotes.component.html',
  styleUrls: ['./manejo-movimentacao-entre-lotes.component.scss'],
})
export class ManejoMovimentacaoEntreLotesComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel;
  @Output() manejoChange = new EventEmitter();
  @Input('opcoesRapidasLotes') opcoesRapidasLotes: LoteModel[];
  @Output() opcoesRapidasLotesChange = new EventEmitter();
  @Input('loteAtual') loteAtual: string;
  @Output() opcoes = new EventEmitter();

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  async openModalListaLote() {
    const modal = await this.modalCtrl.create({
      component: SeletorLoteSingleComponent,
      componentProps: {
        titulo: 'Selecionar Lote'
      },
      cssClass: 'modal-narrow'
    })

    await modal.present()
    const { data: lote } = await modal.onDidDismiss()
    if(lote) {
      this.selecionarLote(lote);
    }
  }

  limparManejo() {
    this.manejo.hash_lote_destino = null
    this.manejo.nome_lote_destino = null
    this.emit()
  }

  selecionarLote(lote: LoteModel) {
    this.manejo.hash_lote_destino = lote.hash
    this.manejo.nome_lote_destino = lote.nome
    this.processarOpcoesRapidasDeLotes(lote)
    this.emit()
  }

  processarOpcoesRapidasDeLotes(lote: LoteModel) {
    if(this.opcoesRapidasLotes?.length) {
      const loteJaExiste = this.opcoesRapidasLotes.find(l => l.hash === lote.hash)
      if(!loteJaExiste) {
        this.opcoesRapidasLotes.push(lote)
      }
    } else {
      this.opcoesRapidasLotes = [lote]
    }
  }

  async abrirOpcoesDeManejo() {
    this.opcoes.emit()
  }

  emit() {
    this.opcoesRapidasLotesChange.emit(this.opcoesRapidasLotes)
    this.manejoChange.emit(this.manejo)
  }
}
