import { ApplicationRef, Injectable } from '@angular/core'
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx'
import * as moment from 'moment'
import { Device } from '@capacitor/device'
import { VastaRX } from '@vasta/rx'

declare var openDatabase

@Injectable({
  providedIn: 'root'
})
export class BancoSQLiteService {
  private banco

  platform: string
  schemasTable
  objTabela

  constructor(private sqlite: SQLite, private ref: ApplicationRef) {}

  async initializaPlugin() {
    this.platform = (await Device.getInfo()).platform
    if (this.platform == 'web') this.inicializaBrowser()
    else await this.inicializaCapacitor()
  }

  private inicializaBrowser() {
    this.banco = openDatabase('fertiliDB', '1.5', 'O banco local oficial fertili', 20 * 1024 * 1024)
    this.schemas()
  }

  private async inicializaCapacitor() {
    try {
      const db = await this.sqlite.create({
        name: 'data.db',
        location: 'default'
      })
      this.banco = db
      this.schemas()
    } catch (error) {
      console.error('ERRO AO INICIALIZAR BANCO', error)
    }
  }

  private schemas() {
    VastaRX.setState('init_bancos', true)
    this.schemasTable = []
    let schemasObject = {
      brincos_utilizados: `
      (
        id INTEGER PRIMARY KEY NOT NULL,

        brincos TEXT,

        created_at VARCHAR( 25 ) NOT NULL,
        updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      _backup_manejos_pendentes: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        id_propriedade INTEGER NOT NULL,
        hash_manejo VARCHAR(25) NOT NULL,
        salvo INT(1),

        manejo TEXT,

        created_at VARCHAR( 25 ) NOT NULL
      )
      `,
      peao_solicitacoes: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash VARCHAR(25) NOT NULL UNIQUE,
        id_propriedade INTEGER NOT NULL,

        inicio VARCHAR( 255 ),
        fim VARCHAR( 255 ),
        editado INTEGER NOT NULL,
        manejo TEXT,

        status INTEGER DEFAULT 1,
        
        created_at VARCHAR( 25 ) NOT NULL,
        updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_animais: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
  id_propriedade INTEGER NOT NULL,

  hash_lote VARCHAR(25),
  data_manejo_lote VARCHAR( 255 ),
  nome VARCHAR( 255 ),
  data_nascimento VARCHAR( 255 ) NOT NULL,
  situacao VARCHAR( 255 ) NOT NULL,
  situacao_reprodutiva VARCHAR( 255 ),
  situacao_sanitaria VARCHAR( 255 ),
  situacao_produtiva VARCHAR( 255 ),
  raca VARCHAR( 255 ) NOT NULL,
  numeracao VARCHAR( 255 ),
  sexo VARCHAR( 5 ) NOT NULL,
  profile TEXT,
  tipo VARCHAR( 255 ) NOT NULL,
  id_produto INTEGER,
  observacao STRING,
  tipo_entrada STRING,
  aptidao STRING,

  status INTEGER NOT NULL,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_animal_metas: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

  hash_animal VARCHAR(25),
  chave VARCHAR( 255 ),
          valor VARCHAR( 255 ),

  status BOOLEAN,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_areas: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
  id_propriedade INTEGER NOT NULL,

  nome VARCHAR(255),
  tamanho FLOAT,
  max_lotacao INTEGER,
  observacao VARCHAR(255),
  coordenadas_centrais TEXT,
  area TEXT,
  status BOOLEAN,

  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
gestor_areas_metas: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
  id_propriedade INTEGER NOT NULL,

  hash_area VARCHAR(40),
  chave VARCHAR( 255 ),
  valor VARCHAR( 255 ),

  status BOOLEAN,

  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
gestor_areas_piquetes: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
  id_propriedade INTEGER NOT NULL,

  hash_area VARCHAR(255),
  nome VARCHAR(255),
  tamanho FLOAT,
  max_lotacao INTEGER,
  observacao VARCHAR(255),
  coordenadas_centrais TEXT,
  area TEXT,
  status BOOLEAN,

  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_lotes: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
  id_propriedade INTEGER NOT NULL,

  especie VARCHAR(255),
  data_entrada_area VARCHAR(25),
  gmd VARCHAR(255),
  producao_leiteira VARCHAR(255),
  hash_area VARCHAR(25),
  nome VARCHAR(255),
  sistema_criacao VARCHAR(255),
  aptidao VARCHAR(255),
  finalidade VARCHAR(255),
  observacao text,

  status BOOLEAN,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_pesagens: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
  hash_pesagem_pai VARCHAR(25) NOT NULL,
  id_propriedade INTEGER NOT NULL,

  especie VARCHAR(255),
  identificador VARCHAR(255),
  balanca INTEGER,
  hash_lote VARCHAR(25),
  gmd FLOAT NOT NULL,
  hash_animal VARCHAR(25),
  peso FLOAT NOT NULL,
  data_pesagem VARCHAR(50),
  observacao TEXT,

  status BOOLEAN,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_genealogia: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
  id_propriedade INTEGER NOT NULL,

  hash_animal VARCHAR(25) NOT NULL,
  hash_mae VARCHAR(25),
  hash_pai VARCHAR(25),
  hash_reprodutor_pai VARCHAR(25),
  hash_reprodutor_mae VARCHAR(25),
  cache TEXT,

  status INTEGER,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_reprodutores_externos: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
  id_propriedade INTEGER NOT NULL,

          especie VARCHAR(255),
  nome VARCHAR(255) NOT NULL,
  reprodutor VARCHAR(255),
  matriz VARCHAR(255),
  sexo VARCHAR(10),
  descricao VARCHAR(225),
  tipo VARCHAR(225),

  status INTEGER,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_leite: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

  hash_animal VARCHAR(25),
  data VARCHAR(255),
  especie VARCHAR(255),
  quantidade FLOAT,
  primeira_ordenha FLOAT,
  segunda_ordenha FLOAT,
  terceira_ordenha FLOAT,
          observacao STRING,

  status BOOLEAN,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      propriedades: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
          id_propriedade INTEGER,

  id_proprietario INTEGER,
          nome VARCHAR(255),
          localizacao STRING,
          telefone STRING,
          endereco VARCHAR (255),
          bairro VARCHAR (255),
          cidade VARCHAR (255),
          estado VARCHAR (255),
          pais VARCHAR (255),
          cep INTEGER,
          cnpj INTEGER,
          cpf INTEGER,
          numero_sisbov VARCHAR (255),
          ie VARCHAR (255),
          nrf VARCHAR (255),
          incra VARCHAR (255),

  status BOOLEAN,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_propriedades_metas: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          hash_propriedade VARCHAR(25),
  chave VARCHAR( 255 ),
          valor VARCHAR( 255 ),

  status BOOLEAN,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
      `,
      gestor_historico: `
(
  id INTEGER PRIMARY KEY NOT NULL,
  hash VARCHAR(25) NOT NULL UNIQUE,
  sync BOOLEAN,
  edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          hash_item VARCHAR(25),
  acao VARCHAR( 255 ),
          hash_local VARCHAR( 255 ),
          id_usuario INTEGER,
  data VARCHAR( 25 ) NOT NULL,
  dados TEXT,

  status BOOLEAN,
  created_at VARCHAR( 25 ) NOT NULL,
  updated_at VARCHAR( 25 ) NOT NULL
)
`,
      gestor_relacao_tripla: `
      (
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          contexto VARCHAR(255) NOT NULL,
          de VARCHAR(255) NOT NULL,
          para VARCHAR(255) NOT NULL,

          hash_contexto VARCHAR(255) NOT NULL,
          hash_de VARCHAR(255) NOT NULL,
  hash_para VARCHAR(255) NOT NULL,

          observacao VARCHAR(255),

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_procedimento: `
      (
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          tipo VARCHAR(255) NOT NULL,
          identificador VARCHAR(25),
          especificacao VARCHAR(255),

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_manejo: `
      (
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          tipo VARCHAR(255) NOT NULL,
          hash_lote VARCHAR(255) NOT NULL,
          observacao VARCHAR(255) NOT NULL,
          data VARCHAR(255) NOT NULL,

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_categorias: `
      (
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          modulo VARCHAR(255) NOT NULL,
          nome VARCHAR(255) NOT NULL,
          slug VARCHAR(255),
          descricao VARCHAR(255),
          categoria_pai VARCHAR(255),

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_agenda: `
      (
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          titulo VARCHAR(255) NOT NULL,
          hora VARCHAR(255),
          data VARCHAR(255) NOT NULL,
          observacao TEXT,
          situacao INTEGER NOT NULL,
          tipo VARCHAR(255),
          tipo_repeticao INTEGER DEFAULT 0 NOT NULL,

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_financeiro_contas: `
      (
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          titulo VARCHAR(255) NOT NULL,
          tipo VARCHAR(255),
          agencia VARCHAR(255),
          conta VARCHAR(255),
          is_padrao INTEGER DEFAULT 0 NOT NULL,
          descricao TEXT,

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_financeiro_transacoes: `
      (
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          id_usuario INTEGER NOT NULL,
          titulo VARCHAR(255) NOT NULL,
          descricao TEXT,
          tipo VARCHAR(255) NOT NULL,
          valor INTEGER NOT NULL,
          hash_conta VARCHAR(255),
          hash_conta_transferencia VARCHAR(255),
          tipo_repeticao INTEGER DEFAULT 0 NOT NULL,
          data VARCHAR(255) NOT NULL,
          realizado TINYINTEGER DEFAULT 0 NOT NULL,

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_controle_pluviometrico: `(
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          indice INTEGER NOT NULL,
          data VARCHAR(255) NOT NULL,
          hora VARCHAR(255),
          observacao VARCHAR(255),

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
          )
      `,
      gestor_manejo_sanitario_animais_e_lotes: `(
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          data VARCHAR(255) NOT NULL,
          hash_manejo	VARCHAR(255),
          hash_animal	VARCHAR(255),
          hash_lote	VARCHAR(255),
          hash_area	VARCHAR(255),
          animais_no_manejo	INTEGER,

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
          )
      `,
      gestor_estoque: `(
          id INTEGER PRIMARY KEY NOT NULL,
          hash VARCHAR(25) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade INTEGER NOT NULL,

          titulo VARCHAR(255),
          descricao VARCHAR(255),
          tipo VARCHAR(255),

          status INTEGER NOT NULL,
          created_at VARCHAR( 25 ) NOT NULL,
          updated_at VARCHAR( 25 ) NOT NULL
          )
      `,
      gestor_estoque_metas: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash VARCHAR(25) NOT NULL UNIQUE,
        sync BOOLEAN,
        edited BOOLEAN,
        id_propriedade INTEGER NOT NULL,

        hash_estoque VARCHAR(25),
        chave VARCHAR( 255 ),
        valor VARCHAR( 255 ),

        status BOOLEAN,
        created_at VARCHAR( 25 ) NOT NULL,
        updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_estoque_lancamento: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash VARCHAR(25) NOT NULL UNIQUE,
        sync BOOLEAN,
        edited BOOLEAN,
        id_propriedade INTEGER NOT NULL,

        hash_estoque VARCHAR(25),
        data_lancamento VARCHAR(255) NOT NULL,
        valor_medio_momentaneo VARCHAR( 255 ),
        movimentacao VARCHAR( 255 ),
        tipo VARCHAR( 255 ),
        qtd INTEGER,
        valor INTEGER,
        obs VARCHAR( 255 ),
        hash_financeiro VARCHAR( 255 ),

        status BOOLEAN,
        created_at VARCHAR( 25 ) NOT NULL,
        updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      gestor_estoque_lancamento_formulacao: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash VARCHAR(25) NOT NULL UNIQUE,
        sync BOOLEAN,
        edited BOOLEAN,
        id_propriedade INTEGER NOT NULL,

        hash_lancamento	VARCHAR(255),
        hash_estoque VARCHAR(255),
        hash_item_nutricional VARCHAR(255),
        percentual	DOUBLE(8,2),
        quantidade	DOUBLE(8,2),
        data_lancamento VARCHAR(255) NOT NULL,

        status BOOLEAN,
        created_at VARCHAR( 25 ) NOT NULL,
        updated_at VARCHAR( 25 ) NOT NULL
      )
      `,
      versao_schemas: `
      (
          id INTEGER PRIMARY KEY NOT NULL,
          tabela VARCHAR(255) NOT NULL,
          versao FLOAT NOT NULL
      )
      `,
      gestor_manejo_nutricional: `
        (
          id INTEGER PRIMARY KEY NOT NULL,
          hash varchar(255) NOT NULL UNIQUE,
          sync BOOLEAN,
          edited BOOLEAN,
          id_propriedade int NOT NULL,
          observacao varchar(255) DEFAULT NULL,
          data varchar(255) NOT NULL,
          responsavel_tecnico VARCHAR(255) NOT NULL,
          hash_animal VARCHAR(255) NOT NULL,
          hash_lote VARCHAR(255) NOT NULL,
          hash_area VARCHAR(255) NOT NULL,
          nutricional_hash_estoque VARCHAR(255) NOT NULL,
          nutricional_quantidade VARCHAR(255),
          nutricional_duracao int DEFAULT NULL,
          novo_status VARCHAR(255) NOT NULL,
          origem varchar(255) DEFAULT NULL,
          manejo_original varchar(255) DEFAULT NULL,
          status tinyint(1) NOT NULL,
          created_at varchar(255) NOT NULL,
          updated_at varchar(255) NOT NULL
        )
      `,
      gestor_manejo_reprodutivo_unitario: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash varchar(255) NOT NULL UNIQUE,
        sync BOOLEAN,
        edited BOOLEAN,
        id_propriedade int NOT NULL,
        procedimento VARCHAR(255),
        observacao  VARCHAR(255),
        data VARCHAR(255),
        responsavel_tecnico  VARCHAR(255),
        hash_animal VARCHAR(255),
        escore_corporal  VARCHAR(255),
        novo_status  VARCHAR(255),
        dg_resultado  VARCHAR(255),
        dg_periodo_gestacional  VARCHAR(255),
        dg_previsao_de_parto  VARCHAR(255),
        dg_hash_exposicao  VARCHAR(255),
        dg_ovario_direito  VARCHAR(255),
        dg_ovario_esquerdo  VARCHAR(255),
        dg_tonus_uterino VARCHAR(255),
        inseminacao_semen  VARCHAR(255),
        inseminacao_ovulo  VARCHAR(255),
        inseminacao_embriao  VARCHAR(255),
        cio_resultado  VARCHAR(255),
        cobertura_hash_reprodutor  VARCHAR(255),
        cobertura_hash_reprodutor_externo  VARCHAR(255),
        implante_aplica  VARCHAR(255),
        implante_remove  VARCHAR(255),
        desmame_detalhes  VARCHAR(255),
        aborto_detalhes  VARCHAR(255),
        castracao_detalhes  VARCHAR(255),
        parto_hash_cria1  VARCHAR(255),
        parto_hash_cria2  VARCHAR(255),
        parto_hash_cria3  VARCHAR(255),
        parto_intervalo  VARCHAR(255),
        origem  VARCHAR(255),
        manejo_original  VARCHAR(255),
        status tinyint(1) NOT NULL,
        created_at varchar(255) NOT NULL,
        updated_at varchar(255) NOT NULL
      )
      `,
      gestor_manejo_sanitario_unitario: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash varchar(255) NOT NULL UNIQUE,
        sync BOOLEAN,
        edited BOOLEAN,
        id_propriedade int NOT NULL,
        hash_procedimento  VARCHAR(255),
        procedimento  VARCHAR(255),
        descricao  VARCHAR(255),
        observacao  VARCHAR(255),
        data varchar(255) NOT NULL,
        responsavel_tecnico  VARCHAR(255),
        hash_animal varchar(255),
        hash_lote varchar(255),
        hash_area varchar(255),
        sanitario_hash_estoque VARCHAR(255),
        sanitario_quantidade VARCHAR(255),
        novo_status  VARCHAR(255),
        manejo_original  VARCHAR(255),
        origem  VARCHAR(255),
        status tinyint(1) NOT NULL,
        created_at varchar(255) NOT NULL,
        updated_at varchar(255) NOT NULL
      )
      `,
      gestor_manejo_geral_unitario: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash varchar(255) NOT NULL UNIQUE,
        sync BOOLEAN,
        edited BOOLEAN,
        id_propriedade int NOT NULL,
        procedimento varchar(255) NOT NULL,
        observacao varchar(255) DEFAULT NULL,
        data varchar(255) NOT NULL,
        responsavel_tecnico varchar(255) NOT NULL,
        hash_animal varchar(255) NOT NULL,
        hash_confinamento varchar(255) NOT NULL,
        hash_lote varchar(255) NOT NULL,
        sem_lote varchar(2) NOT NULL,
        numero_novo varchar(255) NOT NULL,
        brinco_eletronico VARCHAR(255),
        perda_observacoes VARCHAR(255),
        morte_causa VARCHAR(255),
        morte_observacoes VARCHAR(255),
        morte_imagem_1 VARCHAR(255),
        morte_imagem_2 VARCHAR(255),
        morte_imagem_3 VARCHAR(255),
        morte_imagem_4 VARCHAR(255),
        morte_imagem_5 VARCHAR(255),
        morte_latitude VARCHAR(255),
        morte_longitude VARCHAR(255),
        transf_propriedade_id bigint NOT NULL,
        transf_propriedade_novo_hash VARCHAR(255),
        venda_hash_transacao VARCHAR(255),
        novo_status VARCHAR(255),
        origem VARCHAR(255),
        manejo_original VARCHAR(255),
        status tinyint(1) NOT NULL,
        created_at VARCHAR(255),
        updated_at VARCHAR(255)
      )
      `,
      gestor_manejo_lote: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash varchar(255) NOT NULL UNIQUE,
        edited BOOLEAN,
        sync BOOLEAN,
        id_propriedade int NOT NULL,
        procedimento VARCHAR(255),
        observacao VARCHAR(255),
        data VARCHAR(255),
        responsavel_tecnico  VARCHAR(255),
        hash_lote VARCHAR(255),
        hash_area_destino VARCHAR(255),
        sem_area varchar(2) NOT NULL,
        novo_status VARCHAR(255),
        origem VARCHAR(255),
        status tinyint(1) NOT NULL,
        created_at VARCHAR(255),
        updated_at VARCHAR(255)
      )
      `,
      gestor_manejo_area: `
      (
        id INTEGER PRIMARY KEY NOT NULL,
        hash varchar(255) NOT NULL UNIQUE,
        edited BOOLEAN,
        sync BOOLEAN,
        id_propriedade int NOT NULL,
        procedimento VARCHAR(255),
        observacao  VARCHAR(255),
        data VARCHAR(255),
        responsavel_tecnico VARCHAR(255),
        hash_area VARCHAR(255),
        movimentacao_hash_lote VARCHAR(255),
        chuva_quantidade VARCHAR(255),
        chuva_hora VARCHAR(255),
        novo_status VARCHAR(255),
        origem VARCHAR(255),
        manejo_original VARCHAR(255),
        status tinyint(1) NOT NULL,
        created_at VARCHAR(255),
        updated_at VARCHAR(255)
      )
      `
    }
    for (let tabela in schemasObject)
      this.schemasTable.push(`CREATE TABLE IF NOT EXISTS ${tabela} ${schemasObject[tabela]} `)
    for (let sql of this.schemasTable) this.run(sql)
    if (this.schemasTable.length) this.indexar()
  }

  private async indexar() {
    const queries = [
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_animais ON gestor_animais (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_lotes ON gestor_lotes (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_animal_metas ON gestor_animal_metas (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_areas ON gestor_areas (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_areas_metas ON gestor_areas_metas (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_areas_piquetes ON gestor_areas_piquetes (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_pesagens ON gestor_pesagens (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_genealogia ON gestor_genealogia (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_reprodutores_externos ON gestor_reprodutores_externos (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_estoque ON gestor_estoque (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_estoque_metas ON gestor_estoque_metas (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_estoque_lancamento ON gestor_estoque_lancamento (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_estoque_lancamento_formulacao ON gestor_estoque_lancamento_formulacao (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_leite ON gestor_leite (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_propriedades_metas ON gestor_propriedades_metas (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_historico ON gestor_historico (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_procedimento ON gestor_procedimento (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_manejo ON gestor_manejo (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_categorias ON gestor_categorias (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_relacao_tripla ON gestor_relacao_tripla (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_agenda ON gestor_agenda (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_contas ON gestor_financeiro_contas (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_financeiro_transacoes ON gestor_financeiro_transacoes (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_manejo_nutricional ON gestor_manejo_nutricional (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_manejo_reprodutivo_unitario ON gestor_manejo_reprodutivo_unitario (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_manejo_sanitario_unitario ON gestor_manejo_sanitario_unitario (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_manejo_sanitario_animais_e_lotes ON gestor_manejo_sanitario_animais_e_lotes (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_manejo_geral_unitario ON gestor_manejo_geral_unitario (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_manejo_lote ON gestor_manejo_lote (hash);',
      'CREATE UNIQUE INDEX IF NOT EXISTS hash_gestor_manejo_area ON gestor_manejo_area (hash);',
      'CREATE INDEX IF NOT EXISTS hash_item_gestor_historico ON gestor_historico (hash_item);',
      'CREATE INDEX IF NOT EXISTS hash_local_gestor_historico ON gestor_historico (hash_local);',
      'CREATE INDEX IF NOT EXISTS hash_gestor_transacao ON gestor_financeiro_transacoes (hash_conta_transferencia);',
      'CREATE INDEX IF NOT EXISTS hash_animal_gestor_pesagens ON gestor_pesagens (hash_animal);',
      'CREATE INDEX IF NOT EXISTS hash_lote_gestor_pesagens ON gestor_pesagens (hash_lote);',
      'CREATE INDEX IF NOT EXISTS hash_animal_gestor_animal_metas ON gestor_animal_metas (hash_animal);',
      'CREATE INDEX IF NOT EXISTS hash_lote_gestor_animais ON gestor_animais (hash_lote);',
      'CREATE INDEX IF NOT EXISTS hash_animal_gestor_genealogia ON gestor_genealogia (hash_animal);',
      'CREATE INDEX IF NOT EXISTS hash_area_gestor_lotes ON gestor_lotes (hash_area);',
      'CREATE INDEX IF NOT EXISTS hash_animal_gestor_leite ON gestor_leite (hash_animal);',
      'CREATE INDEX IF NOT EXISTS id_propriedade_gestor_propriedades_metas ON gestor_propriedades_metas (id_propriedade);',
      'CREATE INDEX IF NOT EXISTS hash_contexto_gestor_relacao_tripla ON gestor_relacao_tripla (hash_contexto);',
      'CREATE INDEX IF NOT EXISTS hash_de_gestor_relacao_tripla ON gestor_relacao_tripla (hash_para);',
      'CREATE INDEX IF NOT EXISTS hash_para_gestor_relacao_tripla ON gestor_relacao_tripla (hash_de);',
      'CREATE INDEX IF NOT EXISTS hash_gestor_financeiro_transacoes_conta ON gestor_financeiro_transacoes (hash_conta);'
    ]
    for (let query of queries) {
      await this.run({ query: query })
    }
  }

  async run(params: { query?; dados?; ignorarInsert? } | string): Promise<any[]> {
    return new Promise((resolve) => {
      let query = ''
      let dados = null
      let ignorarInsert = false

      if (typeof params == 'string') query = params
      else {
        query = params.query
        dados = params.dados
        ignorarInsert = params.ignorarInsert
      }

      this.consultaComParametros(
        query,
        dados,
        (data: any[]) => {
          resolve(data)
        },
        (erro) => {
          resolve(undefined)
        },
        ignorarInsert
      )
    })
  }

  private consultaComParametros(query, parametros = null, retorno = null, erro = null, ignorarInsert = false) {
    if (this.platform == 'web') {
      this.banco.transaction(
        (tx) => {
          tx.executeSql(query, parametros, (tx, ret) => {
            var lista = []
            if (ret['rows']) {
              for (var a = 0; a < ret['rows'].length; a++) {
                lista.push(ret['rows'].item(a))
              }
              if (retorno) retorno(lista)
            } else {
              if (ret['insertId']) {
                if (retorno) retorno(ret)
              } else if (retorno) retorno(lista)
            }
          })
        },
        (retornoErro) => console.error(retornoErro, query)
      )
    } else {
      this.banco
        .executeSql(query, parametros, (ret) => {
          var lista = []
          if (ret['rows']) {
            for (var a = 0; a < ret['rows'].length; a++) {
              lista.push(ret['rows'].item(a))
            }
            if (retorno) retorno(lista)
          } else {
            if (ret['insertId']) {
              if (retorno) retorno(ret)
            } else {
              if (retorno) retorno(lista)
            }
          }
        })
        .catch((retornoErro) => {
          console.error('ERRO NA CONSULTA SQL', retornoErro, 'QUERRY: ', query)
          if (erro) erro(retornoErro)
        })
    }
  }

  public getFormatedDate(): string {
    return moment().utc().format('YYYY-MM-DD HH:mm:ss')
  }

  public safeQuery = async (conf: {
    query: string
    params: { [key: string]: any }
    logQuery?: boolean
  }): Promise<any> => {
    let { query, params, logQuery } = conf
    const queryOriginal = query
    const paramsEncontrados = [...query['matchAll'](/{{(.*?)}}/g)].map((match) => match[1])
    const paramsMysql = []
    for (const param of paramsEncontrados) {
      const isLike = !!query.replace(`{{${param}}}`, 'IS LIKE').includes('LIKE IS LIKE')

      query = query.replace(`{{${param}}}`, '?')

      const valor = isLike ? `%${params[param]}%` : params[param]

      paramsMysql.push(valor)
    }

    if (logQuery) {
      console.log('query',queryOriginal)
      console.log('paramsMysql',paramsMysql)
    }

    return await this.run({ query, dados: paramsMysql })
  }
}
