import { ErrorBluetooth } from '../../bluetooth/Error/ErrorBluetooth'
import {
  BluetoothConexaoDispositivoIsolado,
  DispositivoConexao,
  DispositivoSelecionado,
  DispositivoSerial,
  OpcoesBuscarPorDispositivos,
  OpcoesConexaoBluetooth,
  OpcoesConfiguracoesBluetooth,
  ResultadoConexaoBluetooth,
  ResultadoHabilitarBluetooth,
  ResultadoScanBluetooth,
  StatusConexao,
} from '../../bluetooth/interfaces/bluetooth-interface'
import { UtilsService } from '../../utils.service'
import { ApplicationRef, Injectable } from '@angular/core'
import { VastaRX } from '@vasta/rx'
import { Observable, from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { Balancas, ValorNomeDispositivos } from '../../../components/modal-dispositivos-bluetooth/dispositivos-bluetooth.data'
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx'
import { setObserverMensagemStatusConexao } from '../../bluetooth/dispositivos/observer-dispositivos'
import { GlobalService } from '../../global.service'
import { BluetoothSerialModificadoBalanca } from '../../bluetooth/serial/plugin-serial/plugin-serial-modificado/balanca/bluetooth-serial-modificado-balanca.service'
import Utils from '../../../utils/utils'
import { BluetoothHelperService } from '../_helpers/helper-classic.service'

@Injectable({
  providedIn: 'root'
})
export class BalancaAnimallTagAT60 implements BluetoothConexaoDispositivoIsolado {
  private idAparelho = ValorNomeDispositivos.balancas.AT60
  private dadosAparelho = Balancas.find((balanca) => balanca.value === this.idAparelho)
  private balancaClassic: DispositivoSelecionado = {
    name: this.idAparelho,
    nome_modelo: this.dadosAparelho.nome,
    id: null
  }

  private utils = Utils
  private qtdTentativasConexaoBalancaQueDeramErrado = 0
  private mensagemOrientandoAReiniciarBalanca =
    'Conexão com a balança perdida, se o erro de conexão persistir, por favor reinicie a balança'
  private mensagemErroPerdaDeConexaoDoBalanca = 'Conexão com a balança perdida, tente conecta-lo novamente'

  public interval: any

  ultimoPesoCompleto = ''
  valorPesoAcumulado = ''

  constructor(
    private utilsCtrl: UtilsService,
    private bluetoothModule: BluetoothSerial,
    private bluetoothHelper: BluetoothHelperService,
    private globalCtrl: GlobalService,
    private bluetoothBalanca: BluetoothSerialModificadoBalanca,
    private ref: ApplicationRef,
  ) {}

  async estabelecerConexao(): Promise<void> {
    try {
      const dispositivoConexao: DispositivoConexao = {
        tipoDispositivo: 'balanca',
        statusConexao: 'conectando'
      }

      VastaRX.setState('dispositivo_conexao', dispositivoConexao)

      const balancaEncontrada = await this.buscarDispositivo({ nomeDispositivo: 'AT60' })
      console.warn('🚀 ~ estabelecerConexao ~ balancaEncontrada:', balancaEncontrada)
      const resultadoConexao = await this.estabelecerConexaoComBalanca({ deviceId: balancaEncontrada.id })
      console.warn('🚀 ~ estabelecerConexao ~ resultadoConexao:', resultadoConexao)
      this.balancaClassic.id = balancaEncontrada.id
      
      if(resultadoConexao.isConnected) {
        
        const dispositivoConexao: DispositivoConexao = {
          dispositivoSelecionado: this.balancaClassic,
          tipoDispositivo: 'balanca',
          statusConexao: 'conectado'
        }
        
        VastaRX.setState('dispositivo_conexao', dispositivoConexao)
        
        await this.receberDadosDaBalanca(balancaEncontrada)
      }
    } catch (error) {
      const dispositivoConexao: DispositivoConexao = {
        tipoDispositivo: 'balanca',
        statusConexao: 'erro-conexao'
      }
      VastaRX.setState('dispositivo_conexao', dispositivoConexao)

      if (error instanceof ErrorBluetooth) {
        this.utilsCtrl.exibirToast(error.message, this.utils.tempoDeExibicaoDeMensagemDeErro(error.message))
      }
    }
  }

  private async receberDadosDaBalanca(device: DispositivoSerial): Promise<void> {
    console.warn('🚀 ~ receberDadosDaBalanca:', device)
    console.warn('Receber dados da balança')

    setObserverMensagemStatusConexao({
      mensagemStatusConexao: 'Pronto para receber dados da balança',
      isEsconderMensagem: true
    })

    this.saidaDeDadosBalanca().subscribe((peso: number) => {
      if (peso || peso === 0) {
        this.emitirValorFormatadoDeBalancas(peso)
      }
    })
  }

  private emitirValorFormatadoDeBalancas(peso: number): void {
    const valorPeso = String(peso)
    this.globalCtrl.set('pesoNaBalanca', valorPeso);
    VastaRX.setState('peso_balanca', valorPeso);
    this.ref.tick();
  }

  saidaDeDadosBalanca(): Observable<any> {
    const comandoPesoInstantaneo = this.bluetoothHelper.getComandoBalanca('0')
    return new Observable((observer) => {
      this.interval = setInterval(() => {
        try {
          const reader = from(this.bluetoothBalanca.write(comandoPesoInstantaneo))
            .pipe(mergeMap(() => this.bluetoothBalanca.subscribeRawData()))

          reader.subscribe(data => {
            const bytes = new Uint8Array(data);
            const [_cinco, peso_1, peso_2, _sinal, _estabilizado] = bytes
            const pesoRetornado = (( peso_2 * 256 ) + peso_1) / 10
            console.log(pesoRetornado);
            observer.next(pesoRetornado);
          })
        } catch(error) {
          console.error(error)
          observer.error(error)
        }
      }, 500)
    })
  }

  getDadosBalancaFormatado(bytes: any): String {
    let pesoRetornado = this.binarioToString(bytes);
    console.warn('🚀 ~ pesoRetornado:', pesoRetornado)
    // Remover todos os caracteres, exceto números
    
    this.valorPesoAcumulado += pesoRetornado
    
    if (this.valorPesoAcumulado.includes(';E;') || this.valorPesoAcumulado.includes(';I;')) {
      this.ultimoPesoCompleto = this.valorPesoAcumulado.replace(/[^\d.]/g, '').trim();
      this.valorPesoAcumulado = ''
    }

    return this.ultimoPesoCompleto
  }

  binarioToString(bytes) {
    return String.fromCharCode.apply(String, bytes);
  }

  async estabelecerConexaoComBalanca(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    try {
      const dispositivoVerificado = await this.isBalancaConectada({ deviceId: opcoes.deviceId })
      
      if(dispositivoVerificado?.isConnected) {
        await this.pluginDesconectarBalanca({ deviceId: opcoes.deviceId })
        // ir para o catch dessa função, para assim, conectar com a balança
        throw new ErrorBluetooth('Balança desconectada para conecta-la novamente')
      }
    } catch(error) {
      console.error(error)
      // Tentar conectar com balança, dentro do limite de tempo estabelecido
      const limiteDeTempoParaTentarConectar = 15; // segundos
      
      const response = await Promise.race<ResultadoConexaoBluetooth | boolean>([
        this.conectarBalanca({ deviceId: opcoes.deviceId }),
        this.tempoLimiteParaRequisicao({ delayPorSegundos: limiteDeTempoParaTentarConectar })
      ]);

      if(typeof response === 'boolean') {
        console.warn('Tempo limite para conectar balança ultrapassado')

        let mensagemErro = this.getMensagemDeErro()

        return {
          deviceId: opcoes.deviceId,
          isConnected: false,
          message: mensagemErro
        }
      }

      return response
    }
  }

  async conectarBalanca(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    const [isBluetoothHabilitado, errorBluetoothDesabilitado] = await this.isBluetoothHabilitado()

    if(isBluetoothHabilitado) {
      return new Promise(async (resolve, reject) => {
        
        const conexaoBalanca = this.pluginConectarBalanca({ deviceId: opcoes.deviceId })

        conexaoBalanca.subscribe(
          (success: ResultadoConexaoBluetooth) => {
            if(success.isConnected) {
              console.warn('sucesso ao conectar balança: ', success)
              success.message = 'Balança conectada'
              const { isConnected, deviceId,  message } = success

              this.emitirResultadoConexaoBalanca({
                deviceId,
                isConnected, 
                message
              })

              this.emitirValorFormatadoDeBalancas(0)

              resolve({ isConnected, deviceId, message })
            }
          },
          (fail: ResultadoConexaoBluetooth) => {
            console.error('Conexão perdida!! ', fail)

            const { isConnected, deviceId } = fail

            let mensagemErro = this.getMensagemDeErro()

            this.emitirResultadoConexaoBalanca({
              isConnected, 
              deviceId,
              message: mensagemErro
            })

            const erroBluetooth = new ErrorBluetooth(mensagemErro)
            reject(erroBluetooth)
          }
        )
      })
    }

    if(errorBluetoothDesabilitado) {
      console.error(errorBluetoothDesabilitado)

      if(errorBluetoothDesabilitado instanceof ErrorBluetooth) {
        this.utilsCtrl.exibirToast(errorBluetoothDesabilitado.message)
      }
    }
  }

  pluginConectarBalanca(opcoes: OpcoesConexaoBluetooth): Observable<ResultadoConexaoBluetooth> {
    console.warn('🚀 ~ pluginConectarBalanca ~ opcoes:', opcoes)
    return new Observable((observer) => {
      console.warn('conectando balança...')

      setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Conectando Balança...' })

      this.bluetoothBalanca.connect({ deviceId: opcoes.deviceId }).subscribe(success => {
        console.warn('🚀 ~ this.bluetoothBalanca.connect ~ success:', success)
        const resultadoConexao: ResultadoConexaoBluetooth = {
          isConnected: true,
          deviceId: opcoes.deviceId
        }

        observer.next(resultadoConexao)
      }, _error => {
        const resultadoConexao: ResultadoConexaoBluetooth = {
          isConnected: false,
          deviceId: opcoes.deviceId
        }

        observer.error(resultadoConexao)
      })
    })
  }

  private emitirResultadoConexaoBalanca(opcoes: ResultadoConexaoBluetooth): void {
    this.utilsCtrl.exibirToast(opcoes.message);
    this.globalCtrl.set('balanca_conectada', opcoes.isConnected ? this.balancaClassic : null)
    
    const statusConexao: StatusConexao = opcoes?.statusConexao
      ? opcoes?.statusConexao
      : opcoes.isConnected
      ? 'conectado'
      : 'desconectado'

    const dispositivoConexao: DispositivoConexao = {
      dispositivoSelecionado: this.balancaClassic,
      statusConexao,
      tipoDispositivo: 'balanca',
    }

    VastaRX.setState('dispositivo_conexao', dispositivoConexao)
    setObserverMensagemStatusConexao({ mensagemStatusConexao: opcoes?.message, isEsconderMensagem: !opcoes.isConnected  })

    if(!opcoes.isConnected) {
      this.pararDeReceberDadosDoDispositivo()
      this.qtdTentativasConexaoBalancaQueDeramErrado++
    } else {
      this.qtdTentativasConexaoBalancaQueDeramErrado = 0
    }

    console.warn('this.qtdTentativasConexaoBalancaQueDeramErrado: ',this.qtdTentativasConexaoBalancaQueDeramErrado);
  }

  pararDeReceberDadosDoDispositivo(): void {
    clearInterval(this.interval)
  }

  async desconectarDispositivo(options: OpcoesConexaoBluetooth): Promise<void> {
    try {
      const dispositivo = await this.pluginDesconectarBalanca({ deviceId: options.deviceId })

      if(!dispositivo.isConnected) {
        const { isConnected, deviceId, message } = dispositivo

        this.emitirResultadoConexaoBalanca({
          isConnected,
          deviceId,
          message,
          statusConexao: 'desconectado'
        })
      }
    } catch(error) {
      console.error(error)
    }
  }
  
  async pluginDesconectarBalanca(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    return new Promise((resolve, reject) => {
      this.bluetoothBalanca.disconnect().subscribe(isDisconnected => {
        console.warn('balança desconectada com sucesso!: ', isDisconnected)

          const resultadoConexao: ResultadoConexaoBluetooth = {
            isConnected: false,
            deviceId: opcoes.deviceId,
            message: 'Balança Desconectada'
          }

          resolve(resultadoConexao)
      }, error => {
        console.error('falha ao desconectar balança: ', error)
        const erroBluetooth = new ErrorBluetooth('Erro ao desconectar dispositivo')
        reject(erroBluetooth)
      })
    })
  }

  async isBalancaConectada(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    return new Promise((resolve, reject) => {
      this.bluetoothBalanca.isConnected().subscribe(isConnect => {
        const resultadoConexao: ResultadoConexaoBluetooth = {
          isConnected: true,
          deviceId: opcoes.deviceId,
          message: 'Dispositivo Conectado'
        }

        resolve(resultadoConexao)
      }, notConnected => {
        console.error('Balança não está conectado')
        
        const resultadoConexao: ResultadoConexaoBluetooth = {
          isConnected: false,
          deviceId: opcoes.deviceId,
          message: 'Dispositivo não conectado'
        }

        reject(resultadoConexao)
      })
    })
  }

  async buscarDispositivo({ nomeDispositivo: nomeBalanca }: OpcoesBuscarPorDispositivos): Promise<DispositivoSerial> {
    try {
      const [resultadoBluetooth] = await this.isBluetoothHabilitado()

      if (resultadoBluetooth.isEnabled) {
        /* PAREADOS */
        const balancaPareadaEncontrada = await this.procurarBalancaNosDispositivosPareados({ nomeBalanca })
        if (balancaPareadaEncontrada) return balancaPareadaEncontrada

        /* NÃO PAREADOS */
        const balancaNaoPareadaEncontrada = await this.procurarBalancaNosDispositivosNaoPareados({ nomeBalanca })
        if (balancaNaoPareadaEncontrada) return balancaNaoPareadaEncontrada

        /* se o balança não foi encontrado, abrir configurações para parear manualmente */
        await this.abrirConfiguracoesDoCelularParaParearManualmente()
      } else {
        throw new ErrorBluetooth('Bluetooth não está habilitado')
      }
    } catch (error) {
      console.error(error)

      if (error instanceof ErrorBluetooth) {
        setObserverMensagemStatusConexao({
          mensagemStatusConexao: error.message,
          isEsconderMensagem: true
        })
      }
    }
  }

  async isBluetoothHabilitado(): Promise<[ResultadoHabilitarBluetooth, ErrorBluetooth?]> {
    try {
      const isEnabled = await this.bluetoothModule.isEnabled()

      return [{ isEnabled: !!isEnabled }]
    } catch (error) {
      console.error('erro ao habilitar bluetooth: ', error)
      const erroBluetooth = new ErrorBluetooth('O bluetooth está desabilitado')
      return [null, erroBluetooth]
    }
  }

  async procurarBalancaNosDispositivosPareados(opcoes: { nomeBalanca: string }): Promise<DispositivoSerial> {
    const dispositivosPareados = await this.procurarDispositivosPareados()
    console.warn('dispositivosPareados: ',dispositivosPareados)

    if (!dispositivosPareados?.devices?.length) return null

    const dispositivoEncontrado = dispositivosPareados.devices.find((device) =>
      device.name.startsWith(opcoes.nomeBalanca)
    )
    return dispositivoEncontrado
  }

  async procurarDispositivosPareados(): Promise<ResultadoScanBluetooth<DispositivoSerial>> {
    console.warn('Procurando dispositivos pareados')
    setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Procurando dispositivos pareados...' })

    const devices = await this.bluetoothModule.list()
    console.warn('dispositivos pareados: ', devices)
    if (devices) {
      return { devices }
    } else {
      const erroBluetooth = new ErrorBluetooth('Erro ao encontrar dispositivos pareados')
      throw erroBluetooth
    }
  }

  async procurarBalancaNosDispositivosNaoPareados(opcoes: { nomeBalanca: string }): Promise<DispositivoSerial> {
    const dispositivosNaoPareados = await Promise.race([
      this.procurarDispositivosNaoPareados(),
      this.tempoLimiteParaRequisicao({ delayPorSegundos: 30 })
    ])

    if (typeof dispositivosNaoPareados === 'boolean') return null

    const dispositivoEncontrado = dispositivosNaoPareados.devices?.find((device) =>
      device?.name?.startsWith(opcoes.nomeBalanca)
    )
    return dispositivoEncontrado
  }

  async procurarDispositivosNaoPareados(): Promise<ResultadoScanBluetooth<DispositivoSerial>> {
    console.warn('Procurando dispositivos não pareados')
    setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Procurando dispositivos não pareados...' })

    const devices: DispositivoSerial[] = await this.bluetoothModule.discoverUnpaired()

    console.warn('Dispositivos não pareados: ', devices)

    if (!devices) {
      const erroBluetooth = new ErrorBluetooth('Erro ao encontrar dispositivos não pareados')
      throw erroBluetooth
    }

    if (!devices?.length) {
      console.warn('Não foi encontrado nenhum dispositivo não pareado')
    }

    return { devices }
  }

  private tempoLimiteParaRequisicao(opcoes: { delayPorSegundos: number }): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let timeout = setTimeout(() => {
        clearTimeout(timeout)
        resolve(true)
      }, opcoes.delayPorSegundos * 1000)
    })
  }

  private async abrirConfiguracoesDoCelularParaParearManualmente(): Promise<void> {
    this.globalCtrl.set('dispositivo-selecionado-com-erro-pareamento-local', this.balancaClassic)

    setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Abrindo configurações para parear dispositivo' })

    setTimeout(async () => {
      const [responseConfiguracoes, error] = await this.abrirConfiguracoesBluetooth()

      if (responseConfiguracoes.isAberto) {
        console.warn('Configurações de bluetooth aberta')
      }

      if (error) {
        throw error
      }
    }, 2000)
  }

  async abrirConfiguracoesBluetooth(): Promise<[OpcoesConfiguracoesBluetooth, ErrorBluetooth?]> {
    try {
      await this.bluetoothModule.showBluetoothSettings()
      return [{ isAberto: true }]
    } catch (error) {
      const erroBluetooth = new ErrorBluetooth('Erro ao abrir configurações de bluetooth')
      return [null, erroBluetooth]
    }
  }

  private getMensagemDeErro(): string {
    let mensagemErro: string

    if (this.qtdTentativasConexaoBalancaQueDeramErrado >= 2) {
      mensagemErro = this.mensagemOrientandoAReiniciarBalanca
    } else {
      mensagemErro = this.mensagemErroPerdaDeConexaoDoBalanca
    }

    return mensagemErro
  }
}
