export const labelsManejosReprodutivos = {
  castracao: 'Castração',
  aborto: 'Aborto',
  escore: 'Escore Corporal',
  'monta-natural': 'Monta Natural',
  inseminacao: 'Inseminação',
  dg: 'Diagnóstico de Gestação',
  parto: 'Parto',
  desmame: 'Desmame',
  cio: 'Cio',
  implante: 'Implante de Progesterona',
  'implante-remocao': 'Remoção de Implante'
}

export const labelsSituacaoReprodutiva = {
  vazia: 'Vazia',
  cio: 'Cio',
  inseminada: 'Inseminada',
  coberta: 'Coberta',
  prenhe: 'Prenhe',
  implantada: 'Implantada',
  solteira: 'Solteira',
  castrada: 'Castrada',
  touro: 'Touro',
  rufiao: 'Rufião',
  castrado: 'Castrado',
  inteiro: 'Inteiro',
  'cio-detectado': 'Cio Detectado',
  'cio-nao-detectado': 'Cio Não Detectado',
  'implante-removido': 'Implante Removido'
}