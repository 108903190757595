import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class BuscarService {
  listaCompleta = []

  constructor() {}

  setListaCompleta(lista: any) {
    this.listaCompleta = lista
  }

  zeraListaCompleta() {
    this.listaCompleta = []
  }

  searchAnimal(termo = '') {
    let filtradosPorNome
    let listaFiltrada = []
    if (!termo) listaFiltrada = this.listaCompleta
    else {
      filtradosPorNome = this.listaCompleta.filter(
        (animalFiltradoNome) =>
          animalFiltradoNome.nome && animalFiltradoNome.nome.toLowerCase().includes(termo.toLowerCase())
      )
      filtradosPorNome.forEach((animal) => {
        if (!listaFiltrada.includes(animal)) {
          listaFiltrada.push(animal)
        }
      })
    }
    return listaFiltrada
  }

  searchTouro(termo = '') {
    let listaFiltrada: any
    if (!termo) listaFiltrada = this.listaCompleta
    else {
      listaFiltrada = this.listaCompleta.filter((itemDaLista) =>
        this.verificarSeInclui(itemDaLista?.touro, termo)
      )
    }
    return listaFiltrada
  }

  searchOpcoesGeral(termo = '', chave: string) {
    if (!termo) {
      return this.listaCompleta;
    }
    
    return this.listaCompleta.filter(itemDaLista => {
      const item = itemDaLista[chave]
      return this.verificarSeInclui(item, termo);
    });
  }

  searchOpcoes(termo = '') {
    let listaFiltrada: any
    if (!termo) listaFiltrada = this.listaCompleta
    else {
      listaFiltrada = this.listaCompleta.filter((itemDaLista) =>
        this.verificarSeInclui(itemDaLista?.nome || itemDaLista?.tipo || itemDaLista, termo)
      )
    }
    return listaFiltrada
  }

  verificarSeInclui(texto?: string, termo?: string) {
    return texto?.toLowerCase().includes(termo?.toLowerCase())
  }
}
