import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { ApiService } from '../api';
import { AnimaisService } from '../animais.service';
import { BancoSQLiteService } from '../banco-sqlite.service';

import Utils from '../../utils/utils';
import {
  AnimalModel,
  AnimalDoManejo,
  ManejoGeralModel,
  PayloadCadastrarLoteModel,
  PayloadManejosPendente,
  SolicitacaoManejosModel,
  TipoManejoModel,
  PayloadCadastrarAreaModel,
} from '../../utils/types';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ManejoService {
  public utils = Utils;

  constructor(
    private db: BancoSQLiteService,
    private animaisCtrl: AnimaisService,
    private api: ApiService,
    public modalCtrl: ModalController,
  ) {}

  async getManejosDoAnimalSingle(hashDoAnimal: string, tipo: TipoManejoModel) {
    let response: any;
    if (tipo === 'reprodutivo' || tipo === 'sanitario')
      response = await this.buscarManejosReprodutivoOuSanitario({ hash_animal: hashDoAnimal, tipo });
    else if (tipo === 'pesagem')
      response = await this.animaisCtrl.buscarPesagensAnimal({ hash_animal: hashDoAnimal });
    else if (tipo === 'geral') response = await this.animaisCtrl.historicoAnimal(hashDoAnimal);
    else response = [];
    return response;
  }

  async sincronizarAnimais(solicitacoesDeManejos: SolicitacaoManejosModel[]) {
    try {
      const response = await this.api.postAuth('/aprovacao', solicitacoesDeManejos[0].manejo);
      return response;
    } catch (error) {
      throw error;
    }
  }
  
  async postTodosOsManejos(opcoes: { animais: AnimalModel[], lotes: PayloadCadastrarLoteModel[], areas: PayloadCadastrarAreaModel[], manejos: ManejoGeralModel[], idPropriedade?: number }) {
    try {
      opcoes.idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;
      const response = await this.api.postAuth('/aprovacoes', opcoes);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async salvarAlteracoesDoManejoSequencial(manejoSequencialPendente: PayloadManejosPendente) {
    await Preferences.set({
      key: 'alteracoes-manejos-pendentes',
      value: JSON.stringify(manejoSequencialPendente)
    });

    // insert em um sqlite novo
    try {
      const { manejoSequencial: { hash, id_propriedade: idPropriedade } } = manejoSequencialPendente;

      this.db.safeQuery({
        query: `
          INSERT INTO _backup_manejos_pendentes (
            hash_manejo,
            manejo,
            salvo,
            id_propriedade,
            created_at
          ) VALUES (
            {{hash}},
            {{manejo}},
            0,
            {{idPropriedade}},
            {{created_at}}
          )
        `,
        params: {
          hash,
          manejo: JSON.stringify(manejoSequencialPendente),
          idPropriedade,
          created_at: moment().format('YYYY-MM-DD HH:mm:ss')
        }
      });
    } catch (e) {
      console.error('Erro ao salvar backup do manejo no storage', e)
    }
  }

  async existeManejoPendente(): Promise<number> {
    const { id: idPropriedade } = (await this.getPropriedadeSelecionada()) || {};

    const backups = await this.db.safeQuery({
      query: `
        SELECT *
        FROM _backup_manejos_pendentes
        WHERE salvo <> 1
        AND id_propriedade = {{idPropriedade}}
        GROUP BY hash_manejo
      `,
      params: {
        idPropriedade
      }
    })

    return backups.length
  }

  async getBackupsUnicos(): Promise<string[]> {

    const backups = await this.db.run(`
      SELECT MAX(id) as max_id, *
      FROM _backup_manejos_pendentes
      GROUP BY hash_manejo
      ORDER BY id DESC
      LIMIT 3
    `);

    return backups
  }

  async getTodosOsBackups(): Promise<string[]> {

    const backups = await this.db.run(`
      SELECT *
      FROM _backup_manejos_pendentes
      ORDER BY id DESC 
      LIMIT 50
    `);

    return backups
  }

  async getManejosPendentes(): Promise<{ id: number, hash_manejo: string, manejo: string, created_at: string }[]> {
    const { id: idPropriedade } = (await this.getPropriedadeSelecionada()) || { id: 0 };

    const backups = await this.db.safeQuery({
      query: `
        SELECT MAX(id) as max_id, *
        FROM _backup_manejos_pendentes
        WHERE salvo <> 1
        ${idPropriedade ? 'AND id_propriedade = {{idPropriedade}}' : ''}
        GROUP BY hash_manejo
        ORDER BY id DESC
      `,
      params: {
        idPropriedade
      }
    })

    return backups
  }

  async limparAlteracoesPendentesDoManejoSequencial() {
    await Preferences.remove({
      key: 'alteracoes-manejos-pendentes'
    });
  }

  async getPropriedadeSelecionada() {
    try {
      const propriedadeSelecionadaAux = (await Preferences.get({ key: 'propriedade-selecionada' })).value;
      return JSON.parse(propriedadeSelecionadaAux);
    } catch (e) {
      console.error('ERRO AO BUSCAR propriedade selecionada', e)
      return {}
    }
  }

  verificarSeExisteAlgumManejoBrinco(animais: AnimalDoManejo[]) {
    let qtdManejoBrinco = 0;

    for (let animal of animais) {
      for (let manejo of animal.manejos) {
        if (manejo.tipo === "associacao-de-brinco") qtdManejoBrinco++;
      }
    }

    if (qtdManejoBrinco === 0) return false;
    else return true;
  }

  verificarSeExisteApenasUmManejoPesagem(animais: AnimalDoManejo[]) {
    let qtdManejoPesagem = 0;

    for (let animal of animais) {
      for (let manejo of animal.manejos) {
        if (manejo.tipo === "pesagem") qtdManejoPesagem++;
      }
    }

    if (qtdManejoPesagem === 1) return true;
    else return false;
  }

  async buscarManejosReprodutivoOuSanitario({
    tipo = '',
    quantidade = 30,
    offset = 0,
    inicio = '',
    fim = '',
    buscar = '',
    hash_animal = ''
  }) {
    let consulta = `
			SELECT gestor_manejo.*,
			gestor_lotes.nome as nome_lote,
			(
				SELECT COUNT(DISTINCT gestor_relacao_tripla.hash_de)
				FROM gestor_relacao_tripla
					WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
					AND gestor_relacao_tripla.de = 'gestor_animais'
					AND gestor_relacao_tripla.para = 'gestor_procedimento'
					AND gestor_relacao_tripla.status = 1
			) as qtd_animais,
            CASE
            WHEN (
                    (
                        SELECT COUNT(DISTINCT gestor_relacao_tripla.hash_de)
                        FROM gestor_relacao_tripla
                            WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
                            AND gestor_relacao_tripla.de = 'gestor_animais'
                            AND gestor_relacao_tripla.para = 'gestor_procedimento'
                            AND gestor_relacao_tripla.status = 1
                    )
                ) = 1
            THEN (
                SELECT gestor_animais.nome || "_-_" || gestor_animais.numeracao
			    	FROM gestor_relacao_tripla
                    INNER JOIN gestor_animais ON gestor_animais.hash = gestor_relacao_tripla.hash_de
                    WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
		    			AND gestor_relacao_tripla.de = 'gestor_animais'
			    		AND gestor_relacao_tripla.para = 'gestor_procedimento'
				    	AND gestor_relacao_tripla.status = 1
                        LIMIT 1
                )
            END as nome_animal,
            CASE WHEN gestor_manejo.hash_lote = ''
            THEN (
                SELECT gestor_animais.sexo
			    	FROM gestor_relacao_tripla
                    INNER JOIN gestor_animais ON gestor_animais.hash = gestor_relacao_tripla.hash_de
                    WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
		    			AND gestor_relacao_tripla.de = 'gestor_animais'
			    		AND gestor_relacao_tripla.para = 'gestor_procedimento'
				    	AND gestor_relacao_tripla.status = 1
                        LIMIT 1
                )
            END as sexo_animal,
			(
				SELECT COUNT(gestor_relacao_tripla.hash_de)
				FROM gestor_relacao_tripla
						INNER JOIN gestor_procedimento
						ON gestor_procedimento.hash = gestor_relacao_tripla.hash_de
						AND gestor_procedimento.status = 1
					WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
					AND gestor_relacao_tripla.de = 'gestor_procedimento'
					AND gestor_relacao_tripla.para = 'gestor_manejo'
					AND gestor_relacao_tripla.status = 1
            ) as qtd_manejos,
            (
				SELECT GROUP_CONCAT(gestor_procedimento.identificador, ',')
				FROM gestor_relacao_tripla
						INNER JOIN gestor_procedimento
						ON gestor_procedimento.hash = gestor_relacao_tripla.hash_de
						AND gestor_procedimento.status = 1
                        AND gestor_procedimento.identificador <> ''
					WHERE gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
					AND gestor_relacao_tripla.de = 'gestor_procedimento'
					AND gestor_relacao_tripla.para = 'gestor_manejo'
                    AND gestor_relacao_tripla.status = 1
			) as id_manejos,
			( SELECT COUNT(*) FROM (
				SELECT manejoInner.*
				FROM gestor_manejo manejoInner
					${
            buscar
              ? `
						INNER JOIN gestor_relacao_tripla
							ON gestor_relacao_tripla.hash_contexto = manejoInner.hash
							AND gestor_relacao_tripla.de = 'gestor_animais'
							AND gestor_relacao_tripla.para = 'gestor_procedimento'
							AND gestor_relacao_tripla.status = 1
						INNER JOIN gestor_animais animais2
							ON animais2.hash = gestor_relacao_tripla.hash_de
							AND (animais2.nome LIKE '%${buscar}%' OR animais2.numeracao LIKE '%${buscar}%')
					`
              : ''
          }
				WHERE manejoInner.status = 1
				AND manejoInner.id_propriedade = ${JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id}
				AND manejoInner.tipo = '${tipo}'
				${inicio ? ' AND manejoInner.data >= "' + inicio + '"' : ''}
				${fim ? ' AND manejoInner.data <= "' + fim + '"' : ''}
				GROUP BY manejoInner.hash
			) ) AS contador
			FROM gestor_manejo
				LEFT JOIN gestor_lotes ON gestor_lotes.hash = gestor_manejo.hash_lote

				${
          hash_animal
            ? `

					INNER JOIN gestor_relacao_tripla AS relacaoComProcedimento
					ON relacaoComProcedimento.hash_para = gestor_manejo.hash
					AND relacaoComProcedimento.status = 1

					INNER JOIN gestor_relacao_tripla AS relacaoAnimalProcedimento
					ON relacaoAnimalProcedimento.hash_para = relacaoComProcedimento.hash_de
					AND relacaoAnimalProcedimento.hash_de = '${hash_animal}'
					AND relacaoAnimalProcedimento.status = 1
					AND relacaoAnimalProcedimento.contexto = 'gestor_manejo'
				`
            : ''
        }

				${
          buscar
            ? `
					INNER JOIN gestor_relacao_tripla
						ON gestor_relacao_tripla.hash_contexto = gestor_manejo.hash
						AND gestor_relacao_tripla.de = 'gestor_animais'
						AND gestor_relacao_tripla.para = 'gestor_procedimento'
						AND gestor_relacao_tripla.status = 1
					INNER JOIN gestor_animais animais2
						ON animais2.hash = gestor_relacao_tripla.hash_de
						AND (animais2.nome LIKE '%${buscar}%' OR animais2.numeracao LIKE '%${buscar}%')
				`
            : ''
        }
            WHERE gestor_manejo.status = 1
            AND gestor_manejo.id_propriedade = ${
              JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id
            }
            AND gestor_manejo.tipo = '${tipo}'
				${inicio ? ' AND data >= "' + inicio + '"' : ''}
                ${fim ? ' AND data <= "' + fim + '"' : ''}
			GROUP BY gestor_manejo.hash
			ORDER BY gestor_manejo.data DESC, gestor_manejo.created_at DESC
			LIMIT ${quantidade}
			OFFSET ${offset}
		`;

    let manejos: any = await this.db.run(consulta);
    if (hash_animal && manejos.length) {
      manejos = await this.buscarDetalhesDosManejos(manejos, hash_animal);
    }

    for (const manejo of manejos) {
      if (!manejo.nome_animal) continue;
      manejo.nome_animal = manejo.nome_animal
        .split('_-_')
        .filter((nome) => {
          return !!nome;
        })
        .join(' - ');
    }

    return manejos;
  }

  async verificarNovasTabelas(): Promise<boolean> {
    let isTabelasVazia = false
    
    const gestorManejoLote: any = (await this.db.run({
      query: `
        SELECT COUNT(1) as qtd FROM gestor_manejo_lote
      `
    }))[0].qtd;
    
    const gestorManejoArea: any = (await this.db.run({
      query: `
        SELECT COUNT(1) as qtd FROM gestor_manejo_area
      `
    }))[0].qtd;

    const gestorManejoGeralUnitario: any = (await this.db.run({
      query: `
        SELECT COUNT(1) as qtd FROM gestor_manejo_geral_unitario
      `
    }))[0].qtd;

    const gestorManejoSanitarioUnitario: any = (await this.db.run({
      query: `
        SELECT COUNT(1) as qtd FROM gestor_manejo_sanitario_unitario
      `
    }))[0].qtd;

    const gestorManejoReprodutivoUnitario: any = (await this.db.run({
      query: `
        SELECT COUNT(1) as qtd FROM gestor_manejo_reprodutivo_unitario
      `
    }))[0].qtd;

    const gestorManejoNutricional: any = (await this.db.run({
      query: `
        SELECT COUNT(1) as qtd FROM gestor_manejo_nutricional
      `
    }))[0].qtd;

    if(
      !gestorManejoLote &&
      !gestorManejoArea && 
      !gestorManejoGeralUnitario && 
      !gestorManejoSanitarioUnitario &&
      !gestorManejoReprodutivoUnitario &&
      !gestorManejoNutricional
    ) {
      isTabelasVazia = true
    } else {
      isTabelasVazia = false
    }

    return isTabelasVazia
  }

  async buscarDetalhesDosManejos(manejos, hash_animal) {
    // inseminação: reprodutor
    // cobertura: reprodutor

    for (let manejo of manejos) {
      let valores = [];

      let status: any = await this.db.run({
        query: `
				SELECT gestor_relacao_tripla.observacao as valor,
				gestor_relacao_tripla.contexto as contexto,
				gestor_manejo.data as data
				FROM gestor_relacao_tripla
					INNER JOIN gestor_procedimento
						ON gestor_procedimento.hash = gestor_relacao_tripla.hash_para
						AND gestor_procedimento.status = 1
					INNER JOIN gestor_manejo
						ON gestor_manejo.hash = gestor_relacao_tripla.hash_contexto
						AND gestor_procedimento.status = 1
				WHERE gestor_relacao_tripla.status = 1
				AND gestor_relacao_tripla.contexto IN ('toque', 'cio', 'desmame', 'parto_filhote', 'inseminacao_externo', 'inseminacao_interno', 'cobertura_externo', 'cobertura_interno')
				AND gestor_relacao_tripla.hash_contexto = '${manejo.hash}'
				AND gestor_relacao_tripla.de = 'gestor_animais'
				AND gestor_relacao_tripla.para = 'gestor_procedimento'
				AND gestor_relacao_tripla.hash_de = '${hash_animal}'
			`
      });

      if (!status.length) continue;

      for (let statusSingle of status) {
        if (statusSingle.contexto == 'desmame' || statusSingle.contexto == 'parto_filhote') {
          let animalComPeso: any = await this.db.run({
            query: `
					SELECT gestor_animais.*,
					(SELECT peso FROM gestor_pesagens WHERE hash_animal = gestor_animais.hash AND data_pesagem = '${statusSingle.data}' AND gestor_pesagens.status = 1) as peso
					FROM gestor_animais
					WHERE gestor_animais.hash = '${statusSingle.valor}'
				`
          });

          if (animalComPeso.length) {
            for (let animalComPesoSingle of animalComPeso) {
              valores.push({
                titulo: 'Cria',
                conteudo: animalComPesoSingle.numeracao || animalComPesoSingle.nome || 'Sem Identificação'
              });

              if (animalComPesoSingle.peso) {
                valores.push({
                  titulo: '',
                  conteudo: animalComPesoSingle.peso + ' kg'
                });
              }
            }
          }
        }

        if (['inseminacao_externo', 'cobertura_externo'].indexOf(statusSingle.contexto) > -1) {
          let animalComPeso: any = await this.db.run({
            query: `
					SELECT gestor_reprodutores_externos.*
					FROM gestor_reprodutores_externos
					WHERE gestor_reprodutores_externos.hash = '${statusSingle.valor}'
				`
          });

          if (animalComPeso.length) {
            valores.push({
              titulo: 'Rep.',
              conteudo: animalComPeso[0].nome
            });
          }
        }

        if (['inseminacao_interno', 'cobertura_interno'].indexOf(statusSingle.contexto) > -1) {
          let animalComPeso: any = await this.db.run({
            query: `
					SELECT gestor_animais.*
					FROM gestor_animais
					WHERE gestor_animais.hash = '${statusSingle.valor}'
				`
          });

          if (animalComPeso.length) {
            valores.push({
              titulo: 'Rep.',
              conteudo: animalComPeso[0].numeracao || animalComPeso[0].nome || 'Sem Identificação'
            });
          }
        }
      }

      let tradutor = {
        vazia: 'Negativo',
        cheia: 'Positivo',
        cio: 'Positivo'
      };

      if (!valores.length) {
        valores.push({
          titulo: '',
          conteudo: tradutor[status[0].valor] || status[0].valor
        });
      }

      manejo['detalhes'] = valores;
      let detalhes_resumo = '';
      valores.map((valor, index) => {
        detalhes_resumo += `${index > 0 ? ', ' : ''}${valor.titulo} ${valor.conteudo}`;
      });
      manejo['detalhes_resumo'] = detalhes_resumo;
    }
    return manejos;
  }
}
