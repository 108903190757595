import { OpcoesSegment } from '../../utils/types';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'segment-personalizado',
  templateUrl: './segment-personalizado.component.html',
  styleUrls: ['./segment-personalizado.component.scss']
})
export class SegmentPersonalizadoComponent implements OnInit {
  @Input() valor: string;
  @Output() valorChange = new EventEmitter();
  @Input('opcoes') opcoes = [
    { nome: 'POSITIVO', valor: 'positivo' },
    { nome: 'NEGATIVO', valor: 'negativo' },
  ]

  constructor() {}

  async ngOnInit() {
  }

  selecionarOpcao(opcao: OpcoesSegment) {
    this.valor = opcao
    this.valorChange.emit(this.valor);
  }
}
