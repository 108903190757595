
export const ManejosPorId = {
	11: {
		order: 0,
		slug: 'cio',
		texto: 'Cio',
		id_pai: null,
		sexo: 'femea',
	},
	2: {
		order: 1,
		slug: 'cobertura',
		texto: 'Cobertura',
		id_pai: null,
		sexo: 'femea',
	},
	4: {
		order: 2,
		slug: 'inseminacao',
		texto: 'Inseminação',
		id_pai: null,
		sexo: 'femea',
	},
	5: {
		order: 3,
		slug: 'toque',
		texto: 'Toque/Ultrassom',
		id_pai: null,
		sexo: 'femea',
	},
	6: {
		order: 4,
		slug: 'parto',
		texto: 'Parto',
		id_pai: null,
		sexo: 'femea',
		single: true,
	},
	7: {
		order: 5,
		slug: 'desmame',
		texto: 'Desmame',
		id_pai: null,
		sexo: 'femea',
	},
	1: {
		order: 6,
		slug: 'aborto',
		texto: 'Aborto',
		id_pai: null,
		sexo: 'femea',
		single: true,
		valor: 'Realizado'
	},
	0: {
		order: 7,
		slug: 'castracao',
		texto: 'Castração',
		id_pai: null
	},
	3: {
		order: 8,
		slug: 'coleta-semen',
		texto: 'Coleta de sêmen',
		id_pai: null,
		hidden: true,
		sexo: 'macho',
	},
	8: {
		slug: 'entouramento',
		texto: 'Entouramento',
		id_pai: null,
		hidden: true,
		sexo: 'femea',
	},
	9: {
		slug: 'in-vitro',
		texto: 'In vitro',
		id_pai: null,
		hidden: true
	},
	10: {
		slug: 'transferencia-embriao',
		texto: 'Transferência de embrião',
		id_pai: null,
		hidden: true
	},
	12: {
		slug: 'implante-progesterona',
		texto: 'Aplicação de Implante',
		id_pai: null,
		hidden: true,
		valor: 'Realizado'
	},
	13: {
		slug: 'remocao-implante',
		texto: 'Remoção de Implante',
		id_pai: null,
		hidden: true,
		valor: 'Realizado'
	},
}
