/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, TemplateRef, ContentChild, ChangeDetectorRef } from '@angular/core'
import { ViewChild, ElementRef, ViewEncapsulation, SimpleChanges } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { tabelaFormat } from './format'
import { DadosLinhaTabela, StatusTabela } from './tabela-types'
import { IOptionButtons, OpcoesBotoesComponent } from '../opcoes-botoes/opcoes-botoes.component'
import * as moment from 'moment'
import { CalendarioComponent } from '../calendario/calendario.component'
import { VastaTabelaFiltrosComponent } from '../vasta-tabela-filtros/vasta-tabela-filtros.component'
import { ColunasTabela, ConfigTabela, Conta, Contas } from '../../utils/types/tabela-colunas'
import { ILote } from '../../utils/types'
import { IArea } from '../../utils/types/areas.interface'
import { SeletorLoteSingleComponent } from '../seletor-lote-single/seletor-lote-single.component'
import { SeletorAreasComponent } from '../seletor-areas/seletor-areas.component'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { LotesService } from '../../services/lotes.service'

export type NovoFiltro = { [key: string]: string | number | any }
export type TabelaFiltroExtra = {
  chave: string
  titulo: string
  tipo: 'animal' | 'lista' | 'data'
  lista?: { chave: string; valor: string }[]
  icone?: string
  valor?: string
  obrigatorio?: boolean
  acao?: (e: Event, filtro: TabelaFiltroExtra) => Promise<void> | void
}
export type Ordenacao = { coluna: string; direcao: 'asc' | 'desc' }
export type Paginacao = { limit: number; offset: number; total: number; currentPage: number }

@Component({
  selector: 'vasta-lista',
  templateUrl: './vasta-lista.component.html',
  styleUrls: ['./vasta-lista.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VastaListaComponent implements OnInit, OnChanges {
  @Input() status: StatusTabela = 'carregando'
  @ViewChild('s2') s2: ElementRef
  @ViewChild('tabela') tabela: ElementRef
  @Input() config: ConfigTabela = {}
  @Input() labels: ColunasTabela[] = []
  @Input() labelsId: string
  @Input() optionsSelecionados: IOptionButtons[]
  @Output() optionsSelecionadosEmit = new EventEmitter()
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  @Input('paginate') paginate: { limit: number; offset: number; total: number; currentPage?: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  @Output() paginateChange = new EventEmitter()
  @Input('optionsButtons') optionsButtons: IOptionButtons[]

  /*
   * ---------------- OLD -------------------- *
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() campos: Array<Record<string, string | number | boolean | any>> = []
  @Input() imprimir: number
  @Input() conta: Conta
  @Input() contas: Contas
  @Input() hashsSelecionados = []
  @Output() hashsSelecionadosChange: EventEmitter<string[]> = new EventEmitter()
  @Output() selecionarTodos: EventEmitter<boolean> = new EventEmitter()
  @Output() eventos: EventEmitter<{ acao: string; evento?: string; linha: DadosLinhaTabela; date?: string }> =
    new EventEmitter()
  @Output() selecionado: EventEmitter<DadosLinhaTabela> = new EventEmitter()
  @Output() periodo: EventEmitter<{ inicial: string; final: string } | void> = new EventEmitter()

  @Output() retentar: EventEmitter<boolean> = new EventEmitter()

  @Output('toggleChange') toggleChange: EventEmitter<DadosLinhaTabela> = new EventEmitter()

  @ContentChild('innerComponents') innerComponentsTmpl: TemplateRef<any>

  filtrosSelecionados = []

  excluidasPadrao: string[] = []
  controleNomeNumero: string
  exibirNBrinco: number | boolean
  atualizandoColunas: boolean = false

  camposFormatados: DadosLinhaTabela[] = []
  dadosBackup = []
  dadosBackupFormatados = [] // todos os campos, na ordem que veio, formatados
  dadosBackupFormatadosFiltrados = []
  exibindoTotais = false
  labelsChaveValor = {}
  tableFormat = tabelaFormat

  scrollandoManualmente = false

  public dadosSelecionados = []
  // Filtros

  @Input() novoFiltro: NovoFiltro = {}
  @Output() novoFiltroChange: EventEmitter<{ [key: string]: string | number }> = new EventEmitter()

  @Input() filtrosFixos: string[] = []

  @Input() filtrosExtras: TabelaFiltroExtra[] = []

  @Input() novaOrdenacao: Ordenacao
  @Output() novaOrdenacaoChange: EventEmitter<Ordenacao> = new EventEmitter()

  @Input() opcoesFiltros: { [key: string]: { [key: string]: string } } = {}

  public filtrosDisponiveis: boolean = false
  @Input() lotes: ILote[] = []
  @Input() areas: IArea[] = []

  public format = tabelaFormat

  public colunaCheckBoxMobile: ColunasTabela = {}

  constructor(
    private popoverCtrl: PopoverController,
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private lotesCtrl: LotesService,
  ) {}

  ngOnInit(): void {
    if (this.config?.selecao?.ativar && !this.labels.find((col) => col.tipo === 'checkbox')) {
      this.labels.unshift({
        tipo: 'checkbox',
        chave: 'checkbox',
      })
    }
    if (!this.config?.selecao?.ativar && this.labels.find((col) => col.tipo === 'checkbox')) {
      this.labels = this.labels.filter((col) => col.tipo !== 'checkbox')
    }

    this.colunaCheckBoxMobile = this.labels.find((col) => col.tipo === 'checkbox')
    
    const temLotes = this.labels.find((col) => ['hash_lote', 'extra_lote'].includes(col.chave))
    if (temLotes) {
      this.buscarTodosOsLotes()
    }
  }
  
  async buscarTodosOsLotes(): Promise<void> {
    this.lotes = <any[]>await this.lotesCtrl.getLotes({})
  }
  
  ngOnChanges(alteracoes: SimpleChanges): void {
    if (alteracoes['campos'] && alteracoes.campos.currentValue) {
      this.dadosBackup = alteracoes.campos.currentValue
      this.formataCampos(alteracoes.campos.currentValue)
    }
    if (alteracoes['status'] && alteracoes.status.currentValue == 'sucesso') {
      if (this.campos.length == 0) this.formataCampos([])
    }
    if (alteracoes['hashsSelecionados']) {
      this.hashsSelecionados = alteracoes.hashsSelecionados.currentValue
      this.tick()
    }
    if (alteracoes['paginate']) {
      this.paginate = alteracoes.paginate.currentValue
      this.tick()
    }

    if (this.labelsId) {
      this.verificarColunasOcultadasPorPadrao()
    }
  }

  formataCampos(novosCampos = null): void {
    let camposAutilizar = novosCampos || this.campos

    if (this.config.excluirColunas && this.config.excluirColunas.length)
      this.labels = this.labels.filter((label) => !this.config.excluirColunas.includes(label.chave))

    if (this.config.ocultarColunas && this.config.ocultarColunas.length)
      this.labels = this.labels.filter((label) => !this.config.ocultarColunas.includes(label.chave))

    if (this.excluidasPadrao && this.excluidasPadrao.length)
      this.labels = this.labels.filter((label) => !this.excluidasPadrao.includes(label.chave))

    if (this.config.ordenacaoColunas && this.config.ordenacaoColunas.length) {
      const labelsOrdenados = []
      const colunaMais = []
      let novosCampos = []
      try {
        novosCampos = this.labels.filter(a => !this.config.ordenacaoColunas.includes(a.chave) && !this.config.ocultarColunas?.includes(a.chave))
      } catch (e) {
        console.warn('🚀 ~ formataCampos ~ e:', e)
      }
      this.config.ordenacaoColunas.map(chave => {
        const coluna = this.labels.find(a => a.chave == chave)
        if (coluna) {
          if (coluna.chave == 'mais') {
            colunaMais.push(coluna)
          } else {
            labelsOrdenados.push(coluna)
          }
        }
      })
      this.labels = [...labelsOrdenados, ...novosCampos, ...colunaMais]
    }

    let camposFormatados = []

    const labelsPorChaves = {}
    let order = 0
    this.labels.map((label) => {
      label['order'] = order++
      labelsPorChaves[label.chave || label.tipo] = label
    })

    const labelsOrdenados = Object.keys(labelsPorChaves)

    this.filtrosDisponiveis = this.labels.some((l) => !!l.tipoFiltro)

    this.labelsChaveValor = labelsPorChaves

    if (camposAutilizar?.length) {
      for (const linha of camposAutilizar) {
        const novaLinha = {}
        const novaLinhaOrdenada = {
          original: linha,
          formatado: {},
          centro: []
        }

        for (const chave of labelsOrdenados) {
          const valorOriginal = linha[chave]
          const novaColuna = {
            valorOriginal,
            class: '',
            titulo: '',
            chave
          }

          const colunaConfig = labelsPorChaves[chave]
          novaColuna.titulo = colunaConfig.titulo || ''
          let novoValor = ''

          if (colunaConfig) {
            if (colunaConfig.tamanho) {
              novaColuna['tamanho'] = colunaConfig.tamanho
              novaColuna['class'] += `coluna-${colunaConfig.tamanho} `
            }

            if (colunaConfig.tamanhoMinimo) {
              novaColuna['tamanhoMinimo'] = colunaConfig.tamanhoMinimo
              novaColuna['class'] += `coluna-min-${colunaConfig.tamanhoMinimo} `
            }

            if (colunaConfig.pointer) {
              novaColuna['class'] += 'pointer '
            }

            if (colunaConfig.centralizar) {
              novaColuna['centralizar'] = true
              novaColuna['class'] += 'centralizar '
            }

            if (colunaConfig.fixaFinal) {
              novaColuna['fixaFinal'] = true
              novaColuna['class'] += 'fixa-final '
            }

            if (colunaConfig.tipo) {
              novaColuna['tipo'] = colunaConfig.tipo
              novaColuna['class'] += `${colunaConfig.tipo} `
            }

            if (colunaConfig.evento) {
              novaColuna['evento'] = colunaConfig.evento
            }

            if (colunaConfig.bold) {
              novaColuna['bold'] = colunaConfig.bold
            }

            if (colunaConfig.class) {
              novaColuna['class'] += `${colunaConfig.class} `
            }

            if (colunaConfig.small && linha[colunaConfig.small] && valorOriginal) {
              novaColuna['small'] = linha[colunaConfig.small]
            }

            if (colunaConfig.unidade && valorOriginal) {
              novaColuna['unidade'] = colunaConfig.unidade
            }

            if (colunaConfig.format && valorOriginal) {
              novoValor = tabelaFormat[colunaConfig.format](valorOriginal)
            }

            if (
              colunaConfig.labelFormat &&
              valorOriginal &&
              colunaConfig.tipo !== 'icon-only' &&
              colunaConfig.tipo !== 'toggle'
            ) {
              if (tabelaFormat[colunaConfig.labelFormat]) {
                novoValor = tabelaFormat[colunaConfig.labelFormat](valorOriginal)
              }
            }

            if (colunaConfig.tipo == 'icone' || colunaConfig.tipo == 'icon-only') {
              if (colunaConfig.iconeCase) {
                if (colunaConfig.iconeCase['null'] && !valorOriginal) {
                  novaColuna['icone'] = colunaConfig.iconeCase['null'].class
                  novaColuna['color'] = colunaConfig.iconeCase['null'].color
                } else {
                  if (colunaConfig.iconeCase['notNull']) {
                    novaColuna['icone'] = colunaConfig.iconeCase['notNull'].class
                    novaColuna['color'] = colunaConfig.iconeCase['notNull'].color
                  } else {
                    novaColuna['icone'] = colunaConfig.iconeCase[valorOriginal]?.class
                    novaColuna['color'] = colunaConfig.iconeCase[valorOriginal]?.color
                  }
                }
              }
              if (!novaColuna['icone'] && (!colunaConfig.iconeCase || !colunaConfig.iconeCase[valorOriginal]))
                novaColuna['icone'] = colunaConfig.icone || valorOriginal
            }

            if (colunaConfig.tipo == 'toggle') {
              novaColuna['toggle'] = true
            }

            if (!novoValor && colunaConfig.chaveFallback && linha[colunaConfig.chaveFallback]) {
              novoValor = linha[colunaConfig.chaveFallback]
            }

            if (
              !novoValor &&
              valorOriginal &&
              !(novaColuna['icone'] && colunaConfig.tipo == 'icon-only') &&
              !novaColuna['toggle']
            ) {
              novoValor = valorOriginal
            }

            if (colunaConfig.placeholder && !novoValor) {
              novaColuna['placeholder'] = colunaConfig.placeholder
            }

            if (colunaConfig.classesExtra) {
              novaColuna['classesExtra'] = colunaConfig.classesExtra
            }
          } else {
            novoValor = valorOriginal
          }

          novaColuna['valor'] = novoValor

          if (colunaConfig.somente_exibir_se_filtrado && !(this.novoFiltro[chave] || this.novaOrdenacao.coluna == chave)) {
            novaColuna['valor'] = null
          }
          
          novaLinhaOrdenada.formatado[chave] = novoValor || valorOriginal

          novaLinhaOrdenada.centro.push(novaColuna)
          novaLinha[chave] = novaColuna
        }

        camposFormatados.push(novaLinhaOrdenada)
      }
    }

    this.dadosBackupFormatados = camposFormatados

    this.dadosBackupFormatadosFiltrados = this.dadosBackupFormatados
    this.camposFormatados = this.dadosBackupFormatados

    const de = Number(moment().format('x'))

    this.tick()

    setTimeout(() => {
      const ate = Number(moment().format('x'))
      console.log('Tempo: ', ate - de)
    }, 10)
  }

  tickTimeout = null
  tick(): void {
    clearTimeout(this.tickTimeout)

    this.tickTimeout = setTimeout(() => {
      this.cd.detectChanges()
    }, 200)
  }

  async clickColuna(
    isHeader: boolean,
    coluna: ColunasTabela,
    linha?: DadosLinhaTabela,
    ev?: Event,
    rightClick = false,
    stopPropagation = false
  ): Promise<void> {
    if (stopPropagation) ev.stopPropagation()
    if (linha?.original?.skeleton) return

    if (coluna.tipo == 'checkbox' && this.campos.length) {
      if (isHeader) {
        // marca todos
        if (this.hashsSelecionados.includes(this.dadosBackupFormatadosFiltrados[0].original.hash)) {
          this.hashsSelecionados = []
        } else {
          // busca todos da api
          const todosOsDados = await this.selecionarTodasAsPaginas()
          const todosOsHashs = todosOsDados.map((item) => {
            return item.hash
          })

          this.hashsSelecionados = [...this.hashsSelecionados, ...todosOsHashs]
          this.hashsSelecionados = [...new Set(this.hashsSelecionados)]

          this.dadosSelecionados = [...todosOsDados]
        }

        this.hashsSelecionadosChange.emit(this.hashsSelecionados)
        this.selecionarTodos.emit(!!this.hashsSelecionados.length)
      } else {
        if (this.hashsSelecionados.includes(linha.original.hash)) {
          const index = this.hashsSelecionados.indexOf(linha.original.hash)
          this.hashsSelecionados.splice(index, 1)
        } else {
          this.hashsSelecionados.push(linha.original.hash)
        }

        // this.dadosSelecionados = this.dadosBackup.filter((item) => {
        //   return this.hashsSelecionados.includes(item.hash)
        // })

        this.hashsSelecionadosChange.emit(this.hashsSelecionados)

        document
          .querySelectorAll(`.linha[hash='${linha.original.hash}']`)
          .forEach((e) => e.classList.toggle('selecionado'))
      }
      this.tick()
    } else {
      if (isHeader) {
        if (!coluna.bloquearOrdenacao) {
          if (this.novaOrdenacao?.coluna == coluna.chave) {
            this.novaOrdenacao.direcao = this.novaOrdenacao.direcao == 'asc' ? 'desc' : 'asc'
          } else {
            if (this.novaOrdenacao) {
              this.novaOrdenacao.coluna = coluna.chave
              this.novaOrdenacao.direcao = this.novaOrdenacao.direcao || 'asc'
            } else return
          }
          this.novoFiltroChange.emit(this.novoFiltro)
        }
      } else {
        if (coluna.tipo == 'toggle') {
          const hash = linha.original.hash
          document.querySelectorAll(`.linha[hash='${hash}'] .toggle-elemento`)[0].classList.toggle('ativo')
          ev.stopPropagation()

          this.toggleChange.emit(linha)
          return
        }

        if (coluna.evento) {
          if (this.optionsButtons) {
            const options = this.optionsButtons.filter(button => (
              !['Editar Animal', 'Remover Animal'].includes(button.titulo) || 
              (
                ['Editar Animal', 'Remover Animal'].includes(button.titulo)
                && ['novo', 'pendente'].includes(String(linha.original.situacao))
              )
            ))
            const popover = await this.popoverCtrl.create({
              component: OpcoesBotoesComponent,
              componentProps: { 
                options
              },
              event: ev,
              reference: rightClick ? 'event' : 'trigger',
              dismissOnSelect: false
            })

            await popover.present()

            const { data } = await popover.onDidDismiss()
            if (data) {
              if (data.acao) {
                data.acao(linha, data.date)
              } else this.eventos.emit({ acao: data.chave, linha, date: data?.date })
            }
          }
        } else {
          console.log('linha: ', linha)
          this.selecionado.emit(linha)
        }
      }
    }
  }

  async selecionarTodasAsPaginas(): Promise<Array<Record<string, string | number | boolean | any>>> {
    let todosOsDados = null
    if (this.paginate?.total > this.campos.length && this.config.buscaDados) {
      // const loading = await this.utilsCtrl.showLoading('circular', 'Buscando dados...')
      const { dados } = await this.config.buscaDados()
      // this.utilsCtrl.dismissLoading(loading)
      if (dados) {
        todosOsDados = dados
      }
    } else {
      todosOsDados = this.campos
    }
    return todosOsDados
  }

  async showPopoverOpcoes(ev: Event): Promise<void> {
    if (!this.optionsSelecionados?.length) return

    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options: this.optionsSelecionados },
      event: ev,
      reference: 'trigger',
      dismissOnSelect: false,
      cssClass: 'popoverOpcoesBotoes'
    })

    await popover.present()

    const { data } = await popover.onDidDismiss()
    const opcaoSelecionada = data as IOptionButtons

    if (opcaoSelecionada) {
      if (opcaoSelecionada.acao) {
        opcaoSelecionada.acao({ hashsSelecionados: this.hashsSelecionados, dadosSelecionados: this.dadosSelecionados, date: data?.date })
      }
    }
  }

  setPaginacao(novaPaginacao: { limit: number; offset: number; total: number; currentPage: number }): void {
    this.paginate = novaPaginacao
    this.paginateChange.emit(this.paginate)
  }

  scrollTop() {
    this.virtualScroll.scrollToIndex(0)
  }

  onRightClick(event: PointerEvent, linha: DadosLinhaTabela): void {
    event.preventDefault()
    let coluna = linha.centro ? linha.centro[linha.centro.length - 1] : null

    if (coluna && coluna.evento == 'opcoes') this.clickColuna(false, coluna, linha, event, true)
  }

  emiteRetentativa(): void {
    this.retentar.emit(true)
  }

  // Novos filtros

  removerFiltroExtra(event: Event, filtro: TabelaFiltroExtra): void {
    event.stopPropagation()
    filtro.valor = ''
    delete this.novoFiltro[filtro.chave]
    this.novoFiltroChange.emit(this.novoFiltro)
  }

  removerNovoFiltro(event: Event, chave: string): void {
    event.stopPropagation()
    if (this.paginate.offset !== 0) {
      this.paginate.offset = 0
      this.paginate.currentPage = 1
    }

    delete this.novoFiltro[chave]
    this.novoFiltroChange.emit(this.novoFiltro)
  }

  async openPopoverFiltros(event: PointerEvent, coluna?: ColunasTabela, ordenacao = false, inlineProp = false): Promise<void> {
    event.stopPropagation()

    if (this.filtrosFixos.includes(coluna?.chave)) return

    if (coluna?.tipoFiltro === 'rangeDatas' && !ordenacao) {
      let tooLowOnTheScreen = false
      if ((window.innerHeight - event.clientY) < 500) {
        tooLowOnTheScreen = true
      }

      const popover = await this.popoverCtrl.create({
        component: CalendarioComponent,
        componentProps: { range: true, titulo: coluna.titulo },
        event: tooLowOnTheScreen ? undefined : event,
        reference: tooLowOnTheScreen ? 'trigger' : undefined,
        cssClass: 'popover-calendario'
      })

      await popover.present()
      const { data } = await popover.onWillDismiss()
      if (data) {
        this.novoFiltro[coluna.chave] = [data.de.iso, data.ate.iso]

        if (this.paginate.offset !== 0) {
          this.paginate.offset = 0
          this.paginate.currentPage = 1
        }

        this.novoFiltroChange.emit(this.novoFiltro)
      }

      this.popoverCtrl.dismiss()

      return
    }

    if (
      ['extra_lote', 'lotes_nomes', 'extra_lote_matriz', 'extra_hash_lote_label'].includes(coluna?.chave) &&
      !ordenacao
    ) {
      const modal = await this.modalCtrl.create({
        component: SeletorLoteSingleComponent,
        componentProps: {
          isFiltroMultiplos: true,
          // multiplosLotesSelecionados
        },
        cssClass: 'modal-narrow'
      })

      await modal.present()
      const { data } = await modal.onWillDismiss<{ lotesSelecionados: ILote[] }>();
      if (data) {
        this.lotes = data.lotesSelecionados
        console.warn('🚀 ~ openPopoverFiltros ~ this.lotes:', this.lotes)

        if (this.paginate.offset !== 0) {
          this.paginate.offset = 0
          this.paginate.currentPage = 1
        }

        this.novoFiltro[coluna.chave] = data.lotesSelecionados.map((lote) => lote.hash)
        this.novoFiltroChange.emit(this.novoFiltro)
      }

      return
    }

    if (['extra_area', 'nome_area'].includes(coluna?.chave) && !ordenacao) {
      const modal = await this.modalCtrl.create({
        component: SeletorAreasComponent,
        componentProps: { multiplos: true },
        cssClass: 'modal-narrow'
      })

      await modal.present()
      const { data } = await modal.onWillDismiss()
      if (data) {
        this.areas = [data]

        if (this.paginate.offset !== 0) {
          this.paginate.offset = 0
          this.paginate.currentPage = 1
        }

        this.novoFiltro[coluna.chave] = [data].map((area) => area.hash)
        this.novoFiltroChange.emit(this.novoFiltro)
      }

      return
    }

    const popover = await this.popoverCtrl.create({
      component: VastaTabelaFiltrosComponent,
      componentProps: {
        colunas: this.labels,
        filtros: this.novoFiltro,
        coluna,
        eventRef: event,
        ordenacao,
        inlineProp,
        opcoesExtrasFiltros: this.opcoesFiltros,
        filtrosFixos: this.filtrosFixos,
      },
      event,
      reference: 'trigger'
    })

    await popover.present()

    const { data } = await popover.onWillDismiss()
    if (data) {
      if (data.colunaOrdenacao) {
        this.novaOrdenacao.coluna = data.colunaOrdenacao.chave
      }

      if (data.lotes) {
        this.lotes = data.lotes
      }

      if (data.areas) {
        this.areas = [...this.areas, ...data.areas]
      }

      if (this.paginate.offset !== 0) {
        this.paginate.offset = 0
        this.paginate.currentPage = 1
      }

      this.novoFiltroChange.emit(this.novoFiltro)
    }
  }

  async executaAcaoFiltroExtra(ev: Event, filtro: TabelaFiltroExtra): Promise<void> {
    if (filtro.acao) {
      filtro.acao(ev, filtro)
    }
  }

  formatarData(data: string | number): string {
    const [de, ate] = String(data).split(',')
    if (moment(de).isValid() && moment(ate).isValid()) {
      if (moment(de).format('YYYY') == moment(ate).format('YYYY')) {
        return `${moment(de).format('DD/MM')} a ${moment(ate).format('DD/MM/YYYY')}`
      } else {
        return `${moment(de).format('DD/MM/YYYY')} a ${moment(ate).format('DD/MM/YYYY')}`
      }
    } else {
      return 'Selecionar Data'
    }
  }

  formatarLabel(labelFormat: string, valor: string | string[] | number, colunaChave?: string): string {
    try {
      let valorFormatado = ''
      if (this.format[labelFormat]) {
        valorFormatado = String(valor)
          .split(',')
          .map((item) => this.format[labelFormat](item))
          .join(', ')
        return valorFormatado
      } else {
        if (typeof valor === 'object') {
          valorFormatado = valor.map((item) => this.opcoesFiltros[colunaChave][item]).join(',')
        } else {
          valorFormatado = this.opcoesFiltros[colunaChave]?.[valor]
        }
      }

      return valorFormatado
    } catch (error) {
      throw error
    }
  }

  formatarLabelLote(hashs: string[]): string {
    const labelLote = this.lotes
      .filter((lote) => hashs.includes(lote?.hash))
      .map((lote) => lote.nome)
      .join(',')

    return labelLote
  }

  formatarLabelArea(hashs: string[]): string {
    const labelArea = this.areas
      .filter((area) => hashs.includes(area?.hash))
      .map((area) => area.nome)
      .join(',')

    return labelArea
  }

  mudarDirecaoOrdenacao(event: Event): void {
    event.stopPropagation()
    this.novaOrdenacao.direcao = this.novaOrdenacao.direcao === 'asc' ? 'desc' : 'asc'
    this.novoFiltroChange.emit(this.novoFiltro)
  }

  keyLength(objeto: { [key: string]: string }): number {
    return Object.keys(objeto).length
  }

  verificarConfiguracaoSalva(configSalva: ColunasTabela[]): void {
    const remanescentes = configSalva.map((col) => {
      if (col.chave === 'meta_nbrinco' && this.exibirNBrinco == 0) return col.chave

      if (col.chave === 'numeracao' && this.controleNomeNumero === 'nome') return col.chave

      if (col.chave === 'nome' && this.controleNomeNumero === 'numero' && this.labelsId === 'colunas_animais')
        return col.chave
    })

    this.config.excluirColunas = remanescentes

    this.formataCampos(this.dadosBackup)
    this.tick()
  }

  verificarColunasOcultadasPorPadrao(): void {
    this.excluidasPadrao = this.labels.map((col) => {
      if (col.exibida === false) {
        return col.chave
      }

      if (col.chave === 'meta_nbrinco' && this.exibirNBrinco == 0) return col.chave

      if (col.chave === 'numeracao' && this.controleNomeNumero === 'nome') return col.chave

      if (col.chave === 'nome' && this.controleNomeNumero === 'numero' && this.labelsId === 'colunas_animais')
        return col.chave
    })

    this.formataCampos(this.dadosBackup)
  }
}
