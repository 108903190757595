import { Component, Input, OnInit } from '@angular/core'
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular'
import { AnimaisService } from '../../services/animais.service'
import { GenealogiaService } from '../../services/genealogia.service'
import { ManejoService } from '../../services/manejo/manejo.service'
import {
  AnimalModel,
  ArvoreGenealogica,
  FilhoFichaModel,
  GenealogiaFichaModel,
  ListaManejoReprodutivo,
  ManejoFichaModel,
  OpcoesDismissFichaAnimal,
  PesoAnimalModel,
  TableGestorManejoSanitarioUnitario,
  TipoManejoModel
} from '../../utils/types'
import Utils from '../../utils/utils'
import * as moment from 'moment'
import { ManejosPorId } from '../../services/manejo/manejos-por-id'
import { CadastrarAnimalComponent } from '../cadastrar-animal/cadastrar-animal.component'
import { SolicitacaoService } from '../../services/solicitacao.service'
import { ManejoSanitarioService } from '../../services/manejo/manejo-sanitario.service'
import { labelsSituacoesSanitarias } from '../../utils/manejoSanitario'
import { ManejoReprodutivoService } from '../../services/manejo/manejo-reprodutivo.service'
import { labelsManejosReprodutivos, labelsSituacaoReprodutiva, Racas } from '../../utils'

@Component({
  selector: 'app-ficha-animal-single',
  templateUrl: './ficha-animal-single.component.html',
  styleUrls: ['./ficha-animal-single.component.scss']
})
export class FichaAnimalSingleComponent implements OnInit {
  public utils = Utils

  @Input() hashDoAnimal: string
  @Input() isAbertaPorSeletorDeAnimais: boolean

  public objetoRacas = Racas

  public labelsSituacoesSanitarias = labelsSituacoesSanitarias
  public labelsManejosReprodutivos = labelsManejosReprodutivos
  public situacoesReprodutivas = labelsSituacaoReprodutiva

  public manejosPorId = ManejosPorId;
  public animal: AnimalModel
  public manejos: ManejoFichaModel[] = []
  public manejoSelecionado: TipoManejoModel = 'geral'
  public peso: PesoAnimalModel
  public listaDePesagens;
  public genealogia: GenealogiaFichaModel
  public arvoreGenealogica: ArvoreGenealogica
  public manejosSanitarios: TableGestorManejoSanitarioUnitario[]
  public manejosReprodutivos: ListaManejoReprodutivo[]
  public carregandoGenealogia = true
  public filhos: FilhoFichaModel[]
  public ultimasVacinas: any
  public hoje = moment().format('YYYY-MM-DD')
  public tipoBovinoTitulo = ''
  public listaManejosReprodutivos;
  public listaDataDeNascimentoFilhotes = {}
  public isListaDataDeNascimentoFilhotes: boolean;
  public isAnimalEditado = false

  constructor(
    private modalCtrl: ModalController,
    private animaisCtrl: AnimaisService,
    private manejoCtrl: ManejoService,
    private genealogiaCtrl: GenealogiaService,
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private solicitacaoService: SolicitacaoService,
    private manejoSanitarioService: ManejoSanitarioService,
    private manejoReprodutivoService: ManejoReprodutivoService
  ) {}

  async ngOnInit() {
    await this.getAnimalSingle()
    await this.getManejos()
    await this.getPesos()
    await this.getUltimoPeso()
    await this.getPesoDesmame()
    await this.getPesoNascimento()
    this.processarTituloDaPagina()
    await this.getManejosSanitario()
    await this.getManejosReprodutivos()
    
    this.filhos = await this.genealogiaCtrl.getFilhos(this.animal.hash)
    this.genealogia = await this.genealogiaCtrl.getGenealogia(this.animal.hash)
    this.arvoreGenealogica = await this.genealogiaCtrl.getGenealogiaAnimal({ hash_animal: this.animal.hash }) 
    
    this.ultimasVacinas = await this.animaisCtrl.buscaVacinas(this.animal.hash)

    this.listaManejosReprodutivos = await this.getReproducao() 
    await this.getDataDeNascimentoDeTodosFilhotes()
  }

  async getDataDeNascimentoDeTodosFilhotes() {
    for(const filhote of this.filhos) {
      const filhoteAnimalCompleto = await this.animaisCtrl.getAnimal(filhote.hash_animal);
      this.listaDataDeNascimentoFilhotes[filhote.hash_animal] = filhoteAnimalCompleto.data_nascimento;
    }

    if(!Object.values(this.listaDataDeNascimentoFilhotes).length || Object.values(this.listaDataDeNascimentoFilhotes)[0] === undefined) {
      this.isListaDataDeNascimentoFilhotes = false;
    } else {
      this.isListaDataDeNascimentoFilhotes = true;
    }
  }

  formataDetalhesResumoDeReprodutivo(manejoReprodutivo) {
    if(manejoReprodutivo?.detalhes_resumo?.includes(',')) {
      const formatado = manejoReprodutivo.detalhes_resumo.split(',');
      return formatado[0];
    } else {
      return manejoReprodutivo.detalhes_resumo;
    }
  }

  async getDataNascimento(filhoProp) {
    const cache = JSON.parse(filhoProp.cache);
    const filhote = await this.animaisCtrl.getAnimal(cache.hash);
    return filhote.data_nascimento;
  }

  async getManejosSanitario() {
    const { manejos: manejosSanitarios } = await this.manejoSanitarioService.getManejos({ hash_animal: this.animal.hash })
    this.manejosSanitarios = manejosSanitarios
  }

  async getManejosReprodutivos() {
    const { manejos: manejosReprodutivos } = await this.manejoReprodutivoService.getManejos({ hash_animal: this.animal.hash })
    this.manejosReprodutivos = manejosReprodutivos
  }

  processarTituloDaPagina() {
    this.tipoBovinoTitulo = ''
    const datasIdades = {
      dataGarrote: moment().subtract(12, 'months').format('YYYY-MM-DD'),
      dataNovilha: moment().subtract(12, 'months').format('YYYY-MM-DD'),
      dataBoi: moment().subtract(24, 'months').format('YYYY-MM-DD'),
      dataVaca: moment().subtract(24, 'months').format('YYYY-MM-DD')
    }

    const nascimento = this.animal.data_nascimento
    const sexo = this.animal.sexo

    if (nascimento < datasIdades.dataVaca && sexo === 'femea') this.tipoBovinoTitulo = 'Vaca'
    else if (nascimento < datasIdades.dataBoi && sexo === 'macho') this.tipoBovinoTitulo = 'Boi'
    else if (nascimento < datasIdades.dataNovilha && sexo === 'femea') this.tipoBovinoTitulo = 'Novilha'
    else if (nascimento < datasIdades.dataGarrote && sexo === 'macho') this.tipoBovinoTitulo = 'Garrote'
    else if (sexo == 'femea') this.tipoBovinoTitulo = 'Bezerra'
    else if (sexo == 'macho') this.tipoBovinoTitulo = 'Bezerro'
  }

  async getAnimalSingle() {
    const animal = await this.animaisCtrl.getAnimal(this.hashDoAnimal)
    this.animal = animal
  }

  async getPesos() {
    this.listaDePesagens = await this.manejoCtrl.getManejosDoAnimalSingle(this.hashDoAnimal, 'pesagem');
    console.log('manejoPesos: ',this.listaDePesagens);
  }

  async getReproducao() {
    return await this.manejoCtrl.getManejosDoAnimalSingle(this.hashDoAnimal, 'reprodutivo')
  }

  isPrimeiroItemDaListaDePesagens(pesagem) {
    if(this.listaDePesagens[0].id === pesagem.id) return true;
    else return false;
  }

  isPrimeiroItemDaListaDeReprodutivos(manejoReprodutivo) {
    if(this.listaManejosReprodutivos[0].id === manejoReprodutivo.id) return true;
    else return false;
  }

  isPrimeiroFilhoDaLista(filho) {
    if(this.filhos[0].id === filho.id) return true;
    else return false;
  }

  async getManejos() {
    this.manejos = await this.manejoCtrl.getManejosDoAnimalSingle(this.hashDoAnimal, this.manejoSelecionado)
  }

  async alterarTipoDeManejoSelecionado(tipo: TipoManejoModel) {
    this.manejoSelecionado = tipo
    await this.getManejos()
  }

  async getUltimoPeso() {
    this.peso = await this.animaisCtrl.getUltimoPeso(this.animal)
  }

  async getPesoDesmame() {
    const response = await this.animaisCtrl.getPesoDesmame(this.animal)
    this.peso = { ...this.peso, pesoDesmame: response }
  }

  async getPesoNascimento() {
    const response = await this.animaisCtrl.getPesoNascimento(this.animal)
    this.peso = { ...this.peso, pesoNascimento: response }
  }

  getNomeManejo(idManejo: string) {
    if (idManejo.length > 1) {
      const idsManejo = idManejo.split(',')
      const manejos = idsManejo.map(
        (idManejoInterno: string) => this.utils.manejosObjetoDeTraducao[idManejoInterno]?.texto
      )
      return manejos
    }
    return [this.utils.manejosObjetoDeTraducao[idManejo]?.texto || idManejo]
  }

  pesoIsValido() {
    return (
      typeof this.peso?.ultimoPeso !== 'undefined' ||
      typeof this.peso?.pesoDesmame !== 'undefined' ||
      typeof this.peso?.pesoNascimento !== 'undefined'
    )
  }

  verificarStatusGmd(gmd: number) {
    if(gmd <= 0.550 || !gmd) {
      return 'vermelho'
    } else if(gmd > 550 && gmd <= 700) {
      return 'amarelo'
    } else if(gmd > 0.700) {
      return 'verde'
    }
  }

  async abrirOpcoesDoAnimal() {
    let listaDeOpcoes = [
      {
        text: 'Novo Manejo',
        icon: 'add-circle',
        role: 'novo-manejo',
        handler: () => {
          this.iniciarNovoManejo();
          actionSheetOpcoesAnimal.dismiss();
        }
      },
      {
        text: 'Editar Animal',
        icon: 'create',
        role: 'animais-pendentes',
        handler: () => {
          this.editarAnimalPendente();
          actionSheetOpcoesAnimal.dismiss();
        }
      },
      {
        text: 'Remover Animal',
        icon: 'trash',
        role: 'animais-pendentes',
        handler: () => {
          this.removerAnimalPendente();
          actionSheetOpcoesAnimal.dismiss();
        }
      },
      {
        text: 'Cancelar',
        role: 'Cancelar'
      }
    ]

    if(!this.isAbertaPorSeletorDeAnimais) {
      listaDeOpcoes.shift()
    }
    
    const actionSheetOpcoesAnimal = await this.actionSheetCtrl.create({
      header: 'Selecione Alguma Opção',
      buttons: listaDeOpcoes
    })

    await actionSheetOpcoesAnimal.present()
  }

  iniciarNovoManejo() {
    this.dismiss({ iniciarNovoManejo: true })
  }

  async editarAnimalPendente() {
    const modal = await this.modalCtrl.create({
      component: CadastrarAnimalComponent,
      componentProps: {
        animalParaEditar: this.animal
      }
    });

    await modal.present();
    const { data: animalAtualizado } = await modal.onDidDismiss<AnimalModel>();
    
    if (animalAtualizado) {
      this.hashDoAnimal = animalAtualizado.hash
      this.isAnimalEditado = true
      await this.getAnimalSingle()
    }
  }

  async removerAnimalPendente() {
    const alerta = await this.alertCtrl.create({
      header: 'Apagar Animal',
      message: 'Deseja realmente apagar este animal?',
      buttons: [
        'Cancelar',
        {
          text: 'Sim',
          handler: async () => {
            await this.animaisCtrl.removerAnimalPendente(this.animal)
            this.dismiss({ isAnimalRemovido: true, animal: this.animal })
          }
        }
      ]
    });
    await alerta.present();
  }

  async dismiss(opcoes?: OpcoesDismissFichaAnimal) {
    if(!opcoes) {
      opcoes = {
        animal: this.animal,
        isAnimalEditado: this.isAnimalEditado
      }
    }
    await this.modalCtrl.dismiss(opcoes)
  }

  totalDePartos() {
    const meta_partos = isNaN(Number(this.animal?.meta_partos)) ? 0 : Number(this.animal?.meta_partos)
    const meta_partos_cache = isNaN(Number(this.animal?.meta_partos_cache)) ? 0 : Number(this.animal?.meta_partos_cache)
    return meta_partos + meta_partos_cache
  }
}
