import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { VastaRX } from '@vasta/rx'
import { AnimalModel, IAnimalQuery, ManejoAnimalModel } from '../../../utils/types'
import { AnimaisService } from '../../../services/animais.service'
import { UtilsService } from '../../../services/utils.service'
import { ConexoesBluetoothService } from '../../../services/dispositivos-bluetooth/conexoes-bluetooth.service'

@Component({
  selector: 'manejo-identificacao-eletronica',
  templateUrl: './manejo-identificacao-eletronica.component.html',
  styleUrls: ['./manejo-identificacao-eletronica.component.scss']
})
export class ManejoIdentificacaoEletronicaComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel;
  @Output() manejoChange = new EventEmitter();
  @Output() opcoes = new EventEmitter();

  @Input() animal: IAnimalQuery
  @Input() brincosOutrosManejos: {[key: string]: string} = {}
  @Input() brincosDesteManejo: {[key: string]: string} = {}

  @Output() removerAtribuicaoDeBrinco = new EventEmitter()

  public credencialAnimalRx: any
  public credencialCancelarLeituraRx: any

  isAguardandoLeituraBrinco = false

  constructor(
    public conexoesBluetoothSeparadaCtrl: ConexoesBluetoothService,
    private animaisCtrl: AnimaisService,
    private utilsCtrl: UtilsService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.credencialAnimalRx = VastaRX.getState('credencialAnimal', async (valorCredencial) => {
      if ( this.isAguardandoLeituraBrinco && valorCredencial ) {
        await this.associarCredencialAoManejoBrinco(valorCredencial)
      }

      this.cd.detectChanges()
    })
    this.credencialCancelarLeituraRx = VastaRX.getState('cancelarLeitura', async (cancelar) => {
      if(cancelar) {
        this.isAguardandoLeituraBrinco = false
        this.cd.detectChanges()
      }
    })
  }

  ngOnDestroy() {
    VastaRX.unListen(this.credencialAnimalRx)
    VastaRX.unListen(this.credencialCancelarLeituraRx)
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  removerBrinco() {
    this.manejo.brinco = null
    this.emit()
  }

  atribuirBrinco() {
    this.isAguardandoLeituraBrinco = !this.isAguardandoLeituraBrinco
  }

  async salvarBrincoPorInput() {
    const isBrincoJaUtilizado = await this.verificarSeBrincoJaEstaSendoUtilizado(this.manejo.brinco)

    if (isBrincoJaUtilizado) {
      this.isAguardandoLeituraBrinco = false
      this.manejo.brinco = ''
      this.emit()
    } else {
      this.isAguardandoLeituraBrinco = false
      VastaRX.unListen(this.credencialAnimalRx)
      this.associarCredencialAoManejoBrinco(this.manejo.brinco)
    }
  }

  async associarCredencialAoManejoBrinco(valorCredencial: string) {
    let brincoJaFoiAssociado = await this.verificarSeBrincoJaEstaSendoUtilizado(valorCredencial)

    if (!brincoJaFoiAssociado && valorCredencial) {
      this.manejo.brinco = valorCredencial;
      this.isAguardandoLeituraBrinco = false;
      
      this.detectChanges();
      VastaRX.setState('credencialAnimal', null);

      this.emit()
    }
  }

  detectChanges() {
    this.cd.detectChanges();
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }

  async verificarSeBrincoJaEstaSendoUtilizado(valorCredencial: string) {
    let brincoJaFoiAssociado = !!this.brincosOutrosManejos?.[valorCredencial] || this.brincosDesteManejo?.[valorCredencial]

    if (brincoJaFoiAssociado) {
      this.utilsCtrl.exibirToast('Atenção, esse brinco já foi associado a outro animal', 2000, 'top')
      return brincoJaFoiAssociado
    }
    
    const animal: AnimalModel[] = await this.animaisCtrl.getAnimalByBrinco(valorCredencial)

    if (animal.length === 1) {
      brincoJaFoiAssociado = true;
    }

    if (brincoJaFoiAssociado) {
      this.utilsCtrl.exibirToast('Atenção, esse brinco já foi associado a outro animal', 2000, 'top')
    }

    return brincoJaFoiAssociado;
  }
}
