import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ManejoPluviometria } from '../../utils/types'
import * as moment from 'moment'

@Component({
  selector: 'manejo-pluviometria',
  templateUrl: './manejo-pluviometria.component.html',
  styleUrls: ['./manejo-pluviometria.component.scss']
})
export class ManejoPluviometriaComponent implements OnInit {
  @Input() manejo: ManejoPluviometria
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter<unknown>()

  constructor() {}

  ngOnInit() {}

  emit(): void {
    this.manejoChange.emit(this.manejo)
  }

  alterouVolume() {
    if (!this.manejo.chuva_hora) {
      this.manejo.chuva_hora = moment().local().format('HH:mm')
    }
    this.emit()
  }

  async abrirOpcoesDoManejo() {
    this.opcoes.emit()
  }

  fecharTeclado(event: KeyboardEvent) {
    let element = event.target as HTMLInputElement
    element.blur()
  }
}
