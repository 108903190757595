import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GuiaPassoAPasso } from '../modal-dispositivos-bluetooth/dispositivos-bluetooth.data';

@Component({
  selector: 'app-guia-de-uso',
  templateUrl: './guia-de-uso.component.html',
  styleUrls: ['./guia-de-uso.component.scss'],
})
export class GuiaDeUsoComponent implements OnInit {
  public titulo: string
  public passosParaUso: GuiaPassoAPasso[];

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
