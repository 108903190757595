import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IArea, IAreaPiquete } from '../../utils/types/areas.interface'
import { ManejoAlturaCapim } from '../../utils/types'
import { AreasService } from '../../services/areas.service'

@Component({
  selector: 'manejo-altura-capim',
  templateUrl: './manejo-altura-capim.component.html',
  styleUrls: ['./manejo-altura-capim.component.scss']
})
export class ManejoAlturaCapimComponent implements OnInit {
  @Input() manejo: ManejoAlturaCapim
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter<unknown>()

  @Input() area: IArea

  @Input() piquetesAssociados: { [hashArea: string]: IAreaPiquete[] } = {}
  @Output() piquetesAssociadosChange = new EventEmitter()

  constructor(private areasCtrl: AreasService) {}

  ngOnInit(): void {
    this.getPiquetes()
  }

  async getPiquetes() {
    const piquetes = await this.areasCtrl.getPiquetes({ hashArea: this.area?.hash })
    piquetes.map((piquete) => {
      this.piquetesAssociados[this.area.hash] = this.piquetesAssociados[this.area.hash] || []
      this.piquetesAssociados[this.area.hash].push(piquete)
      this.manejo = this.manejo || {}
      this.manejo[piquete.hash] = this.manejo[piquete.hash] || {}
      this.manejo[piquete.hash].nome_piquete = piquete.nome
    })
  }

  emit(): void {
    this.manejoChange.emit(this.manejo)
  }

  async abrirOpcoesDoManejo(): Promise<void> {
    this.opcoes.emit()
  }
}
