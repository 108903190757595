import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OpcoesSegment } from '../../../utils/types';

@Component({
  selector: 'manejo-secagem',
  templateUrl: './manejo-secagem.component.html',
  styleUrls: ['./manejo-secagem.component.scss'],
})
export class ManejoSecagemComponent  implements OnInit {
  @Input() manejo: { secagem?: string; observacao?: string }
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor() { }

  ngOnInit() {}

  getOpcaoSelecionada(opcao: OpcoesSegment) {
    this.manejo.secagem = opcao
    this.emit()
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}