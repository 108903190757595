import { AnimalDoManejo } from '../utils/types';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BancoSQLiteService } from './banco-sqlite.service';

export type EstoqueBiologicoModel = {
  botijao: string
  caneca: string
  codigo_no_rack: string
  composicaoRacialTouro: string
  doadora: string
  doadora_externa: string
  doadora_nome: string
  fornecedor_customizado: string
  fornecedor_legado: string
  fornecedor_nome: string
  fornecedor_predefinido: string
  hash: string
  quantidade: number
  raca: string
  sexado: string
  tipo: string
  tipoDoEstoque: string
  tipo_de_palheta: string
  touro: string
  touro_externo: string
  touro_nome: string
}

@Injectable({
  providedIn: 'root'
})
export class EstoqueBiologicoService {
  constructor(private db: BancoSQLiteService, ) {}

  async getListaDeSemensSelecionados(animais: AnimalDoManejo[]) {
    const semens = await this.listarItems()
    console.warn('🚀 ~ getListaDeSemensSelecionados ~ semens:', semens)
    let selecaoRapidaDeSemens: EstoqueBiologicoModel[] = [];
    for (const animal of animais) {
      if (animal?.dados?.['inseminacao-artificial']?.semen) {
        const hash = animal?.dados?.['inseminacao-artificial']?.semen
        const semenSalvo = semens.find(semen => semen.hash === hash)
        if(!semenSalvo) continue
        if(!selecaoRapidaDeSemens.find(semenSelecaoRapida => semenSelecaoRapida.hash === semenSalvo.hash)) {
          selecaoRapidaDeSemens.push(semenSalvo);
        }
      }
    }
    return selecaoRapidaDeSemens;
  }

  async listarItems(hash = ''): Promise<EstoqueBiologicoModel[]> {
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;

    const listaConsulta = <any>await this.db.run(`
      WITH usos AS (
        SELECT COUNT(1) as qtd,
        CASE 
            WHEN COALESCE(inseminacao_embriao, '') = '' THEN inseminacao_semen 
            ELSE inseminacao_embriao 
        END as estoque
        FROM gestor_manejo_reprodutivo_unitario inseminacao
            INNER JOIN gestor_animais 
                ON gestor_animais.status = 1 
                AND inseminacao.hash_animal = gestor_animais.hash 
        WHERE inseminacao.status = 1
        AND inseminacao.procedimento = 'inseminacao'
        AND (
            COALESCE(inseminacao.inseminacao_embriao, '') <> ''
            OR COALESCE(inseminacao.inseminacao_semen, '') <> ''
        )
        AND inseminacao.id_propriedade = ${idPropriedade}
        GROUP BY estoque
    )
    
    SELECT item.id, item.hash, item.tipo,
    meta_botijao.valor AS botijao,
    meta_caneca.valor AS caneca,
    meta_tipo.valor AS tipoDoEstoque,
    meta_rack.valor AS codigo_no_rack,
    meta_sexado.valor AS sexado,
    meta_tipo_de_palheta.valor AS tipo_de_palheta,
    
    
    fornecedor.nome AS fornecedor_legado,
    touro.hash AS touro,
    touro_externo.hash AS touro_externo,
    doadora.hash AS doadora,
    doadora_externa.hash as doadora_externa,
    CASE 
      WHEN touro.raca IS NOT NULL THEN touro.raca
      ELSE NULL END as raca,
    meta_composicaoRacial.valor as composicaoRacialTouro,
    CASE 
      WHEN doadora.numeracao IS NOT NULL THEN doadora.numeracao
      WHEN doadora.nome IS NOT NULL THEN doadora.nome
      WHEN doadora_externa.nome IS NOT NULL THEN doadora_externa.nome 
    ELSE NULL END as doadora_nome,
    CASE 
      WHEN touro.numeracao IS NOT NULL THEN touro.numeracao
      WHEN touro.nome IS NOT NULL THEN touro.nome
      WHEN touro_externo.nome IS NOT NULL THEN touro_externo.nome 
    ELSE NULL END as touro_nome,
    CASE 
      WHEN meta_fornecedor_predefinido.valor IS NOT NULL THEN meta_fornecedor_predefinido.valor
      WHEN meta_fornecedor_customizado.valor IS NOT NULL THEN 'Outro'
      WHEN fornecedor.nome IS NOT NULL THEN 'Outro'
    ELSE NULL END as fornecedor_predefinido,
    CASE 
      WHEN meta_fornecedor_customizado.valor IS NOT NULL THEN meta_fornecedor_customizado.valor
      WHEN fornecedor.nome IS NOT NULL THEN fornecedor.nome
    ELSE NULL END as fornecedor_customizado,
    CASE 
      WHEN meta_fornecedor_predefinido.valor IS NOT NULL AND meta_fornecedor_predefinido.valor <> 'outro' THEN meta_fornecedor_predefinido.valor
      WHEN meta_fornecedor_customizado.valor IS NOT NULL THEN meta_fornecedor_customizado.valor
      WHEN fornecedor.nome IS NOT NULL THEN fornecedor.nome
    ELSE NULL END as fornecedor_nome,

    (
      SELECT COALESCE(positivo, 0) - COALESCE(negativo, 0) - COALESCE(usos.qtd, 0) FROM (
        SELECT 
        SUM( CASE WHEN movimentacao = "entrada" THEN qtd ELSE 0 END ) as positivo,
        SUM( CASE WHEN movimentacao = "saida" THEN qtd ELSE 0 END) as negativo
        FROM gestor_estoque_lancamento
        WHERE gestor_estoque_lancamento.status = 1
        AND hash_estoque = item.hash
      ) as a
    ) AS quantidade
    
    FROM gestor_estoque item
    LEFT JOIN usos ON usos.estoque = item.hash
    INNER JOIN gestor_estoque_metas meta_tipo ON meta_tipo.status = 1 AND meta_tipo.hash_estoque = item.hash AND meta_tipo.chave = 'tipo'
    LEFT JOIN gestor_estoque_metas meta_botijao ON meta_botijao.status = 1 AND meta_botijao.hash_estoque = item.hash AND meta_botijao.chave = 'botijao'
    LEFT JOIN gestor_estoque_metas meta_caneca ON meta_caneca.status = 1 AND meta_caneca.hash_estoque = item.hash AND meta_caneca.chave = 'caneca'
    LEFT JOIN gestor_estoque_metas meta_rack ON meta_rack.status = 1 AND meta_rack.hash_estoque = item.hash AND meta_rack.chave = 'codigo_no_rack'
    LEFT JOIN gestor_estoque_metas meta_sexado ON meta_sexado.status = 1 AND meta_sexado.hash_estoque = item.hash AND meta_sexado.chave = 'sexado'
    LEFT JOIN gestor_estoque_metas meta_tipo_de_palheta ON meta_tipo_de_palheta.status = 1 AND meta_tipo_de_palheta.hash_estoque = item.hash AND meta_tipo_de_palheta.chave = 'tipo_de_palheta'
    LEFT JOIN gestor_estoque_metas meta_fornecedor_predefinido ON meta_fornecedor_predefinido.status = 1 AND meta_fornecedor_predefinido.hash_estoque = item.hash AND meta_fornecedor_predefinido.chave = 'fornecedor_predefinido'
    LEFT JOIN gestor_estoque_metas meta_fornecedor_customizado ON meta_fornecedor_customizado.status = 1 AND meta_fornecedor_customizado.hash_estoque = item.hash AND meta_fornecedor_customizado.chave = 'fornecedor_customizado'
    LEFT JOIN gestor_estoque_metas meta_fornecedor ON meta_fornecedor.status = 1 AND meta_fornecedor.hash_estoque = item.hash AND meta_fornecedor.chave = 'fornecedor'
      LEFT JOIN gestor_categorias fornecedor ON fornecedor.hash = meta_fornecedor.valor AND fornecedor.status = 1 
    LEFT JOIN gestor_estoque_metas meta_touro ON meta_touro.status = 1 AND meta_touro.hash_estoque = item.hash AND meta_touro.chave = 'touro'
      LEFT JOIN gestor_animais touro ON touro.hash = meta_touro.valor AND touro.status = 1
    LEFT JOIN gestor_estoque_metas meta_touro_externo ON meta_touro_externo.status = 1 AND meta_touro_externo.hash_estoque = item.hash AND meta_touro_externo.chave = 'touro_externo'
      LEFT JOIN gestor_reprodutores_externos touro_externo ON touro_externo.hash = meta_touro_externo.valor AND touro_externo.status = 1
        LEFT JOIN gestor_animal_metas meta_composicaoRacial ON meta_composicaoRacial.status = 1 AND meta_composicaoRacial.hash_animal = touro_externo.hash AND meta_composicaoRacial.chave = 'composicaoRacial'
    LEFT JOIN gestor_estoque_metas meta_doadora ON meta_doadora.status = 1 AND meta_doadora.hash_estoque = item.hash AND meta_doadora.chave = 'doadora'
      LEFT JOIN gestor_animais doadora ON doadora.hash = meta_doadora.valor AND doadora.status = 1
    LEFT JOIN gestor_estoque_metas meta_doadora_externa ON meta_doadora_externa.status = 1 AND meta_doadora_externa.hash_estoque = item.hash AND meta_doadora_externa.chave = 'doadora_externa'
      LEFT JOIN gestor_reprodutores_externos doadora_externa ON doadora_externa.hash = meta_doadora_externa.valor AND doadora_externa.status = 1

    
      WHERE item.status = 1
      AND item.tipo = 'biologico'
      AND item.id_propriedade = ${idPropriedade}
      ${hash ? `AND item.hash = "${hash}"` : ''}

      ORDER BY quantidade DESC
    `);

    return listaConsulta
  }
}
