import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { BancoSQLiteService } from '../../../services/banco-sqlite.service'

@Component({
  selector: 'app-animais-single',
  templateUrl: './animais-single.page.html',
  styleUrls: ['./animais-single.page.scss']
})
export class AnimaisSinglePage implements OnInit {
  @Input('animal') animal

  constructor(public modalCtrl: ModalController, private sql: BancoSQLiteService) {}

  ngOnInit() {
    this.buscaDados()
  }

  async buscaDados() {
    let animalDetalhado = await this.sql.run(`
    SELECT gestor_animais.*, 
    gestor_lotes.aptidao AS aptidao_lote, 
    gestor_lotes.finalidade AS finalidade_lote, 
    gestor_areas.nome AS nome_area 
    FROM (gestor_animais LEFT JOIN gestor_lotes ON gestor_animais.hash_lote = gestor_lotes.hash 
      LEFT JOIN gestor_areas ON gestor_lotes.hash_area = gestor_areas.hash) 
    WHERE gestor_animais.hash = '${this.animal.hash}'
    ORDER BY id DESC LIMIT 1
    `)
    this.animal = animalDetalhado[0]
  }

  dismiss() {
    this.modalCtrl.dismiss()
  }
}
