import { Injectable } from '@angular/core'
import { AnimalModel, ManejoGeralModel, PayloadCadastrarAreaModel, PayloadCadastrarLoteModel } from '../utils/types'
import { AnimaisService } from './animais.service'
import { BancoSQLiteService } from './banco-sqlite.service'
import { ImagemService } from './imagem.service'
import * as moment from 'moment'
import { LotesService } from './lotes.service'
import { AreasService } from './areas.service'
import { Preferences } from '@capacitor/preferences'

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoService {
  constructor(
    private sql: BancoSQLiteService,
    private animaisCtrl: AnimaisService,
    private imagemCtrl: ImagemService,
    private lotesCtrl: LotesService,
    private areasCtrl: AreasService,
  ) { }

  async buscarSolicitacoesFormatadas(): Promise<{
    manejos: ManejoGeralModel[]
    totais: {
      animaisManejados: number
      pesoTotal: number
      pesoMedio: number
      gmdMedio: number
    },
    animais: AnimalModel[],
    lotes: PayloadCadastrarLoteModel[],
    areas: PayloadCadastrarAreaModel[]
  }> {
    await this.sql.run(`
      DELETE FROM peao_solicitacoes WHERE manejo LIKE '%"tipo":"cadastro-lote"%'
    `)

    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' }))?.value)?.id || 0

    let manejos = await this.sql.run(`
      SELECT * 
      FROM peao_solicitacoes
      WHERE ( status = 1 OR status IS NULL )
      AND id_propriedade = ${idPropriedade}
      ORDER BY id DESC
    `)

    let manejosFormatado = []
    manejos.forEach((manejo) => {
      try {
        const manejoFormatado = JSON.parse(manejo.manejo) as unknown as ManejoGeralModel
        if (manejoFormatado?.animais?.[0]?.manejos) {
          this.converterManejosParaFormatoNovo(manejoFormatado)
        }

        manejoFormatado.id_solicitacao = manejo.id

        manejosFormatado.push(manejoFormatado)
      } catch (e) {

      }
    })

    const totais = {
      animaisManejados: 0,
      pesoTotal: 0,
      pesoMedio: 0,
      gmdMedio: 0
    }


    let pesoTotal = 0
    let qtdAnimais = 0

    manejosFormatado.map((manejo) => {
      let total = 0
      let qtdPesados = 0
      let somaGMD = 0
      let qtdGMD = 0
      if (manejo?.animais) {
        for (const animal of manejo?.animais) {
          const peso = Number(animal?.dados?.pesagem?.peso || 0)
          if (peso) {
            total += peso
            qtdPesados++            
          }
          const gmd = Number(animal?.dados?.pesagem?.gmd || 0)
          if (gmd) {
            somaGMD += gmd  
            qtdGMD++
          }
        }
        manejo.pesoTotal = Number(total)
        if (qtdPesados > 1) {
          manejo.pesoMedio = Number(total) / qtdPesados
        }
        if (qtdGMD) {
          manejo.gmdMedio = Number(somaGMD) / qtdGMD
        }
      }

      pesoTotal += manejo?.pesoTotal || 0
      qtdAnimais += manejo?.animais?.length || 0
    })

    totais.animaisManejados = qtdAnimais
    totais.pesoTotal = pesoTotal
    totais.pesoMedio = pesoTotal / qtdAnimais

    
    const lotes = await this.lotesCtrl.getLotes({
      filtros: {
        sync: 0
      }
    })
    
    const animais = await this.animaisCtrl.getAnimaisNovosPendentes({
      situacao: 'novo'
    })
    
    const areas = await this.areasCtrl.getAreas({
      filtros: {
        sync: 0
      }
    })

    return {
      manejos: manejosFormatado,
      totais,
      animais,
      lotes,
      areas
    }
  }

  converterManejosParaFormatoNovo(manejoFormatado: ManejoGeralModel) {
    let { animais, manejos } = manejoFormatado
    for (let animal of animais) {
      if (!animal.dados) {
        animal.dados = {}
        animal['manejos'].map(manejo => {
          const tipo = manejo.tipo

          if(tipo == 'sanitario') {
            animal.dados[tipo] = animal.dados[tipo] || {}
            animal.dados.sanitario[manejo.hash] = { procedimento_sanitario_realizado: manejo.procedimento_sanitario_realizado }
          } else if (tipo == 'nutricional') {
            animal.dados[tipo] = animal.dados[tipo] || {}
            animal.dados.nutricional[manejo.nutricional_hash_estoque] = { 
              nutricional_duracao: manejo.nutricional_duracao,
              nutricional_quantidade: manejo.nutricional_quantidade 
            }
          } else if (tipo == 'sanitarioEstoque') {
            animal.dados[tipo] = animal.dados[tipo] || {}
            animal.dados.sanitarioEstoque[manejo.sanitario_hash_estoque] = { sanitario_quantidade: manejo.sanitario_quantidade }
          } else {
            delete manejo.icone
            delete manejo.nome
            delete manejo.tipo
            animal.dados[tipo] = manejo
          }
        })
        delete animal.manejos
      }
    }

    const manejosSanitarios = manejos.filter(manejo => manejo.tipo == 'sanitario' && !manejo.procedimentos)
    manejos = manejos.filter(manejo => !(manejo.tipo == 'sanitario' && !manejo.procedimentos))

    if (manejosSanitarios.length) {
      const procedimentos = []
      manejosSanitarios.map(procedimento => {
        if(procedimento.hash) {
          procedimentos.push({
            hash: procedimento.hash,
            procedimento: procedimento.nome,
            descricao: procedimento.descricao,
          })
        }
      })
      manejos.push({
        tipo: 'sanitario',
        procedimentos
      })
    }

    const manejosNutricionais = manejos.filter(manejo => manejo.tipo === 'nutricional' && !manejo.nutricionalItensEstoque)
    manejos = manejos.filter(manejo => !(manejo.tipo === 'nutricional' && !manejo.nutricionalItensEstoque))

    if (manejosNutricionais.length) {
      const nutricionalItensEstoque = []
      manejosNutricionais.map(itemEstoque => {
        if(itemEstoque.nutricional_hash_estoque) {
          nutricionalItensEstoque.push({
            categoria: itemEstoque.descricao,
            nome: itemEstoque.nome,
            nutricional_hash_estoque: itemEstoque.hash,
          })
        }
      })
      manejos.push({
        tipo: 'nutricional',
        nutricionalItensEstoque
      })
    }

    const manejosSanitarioEstoque = manejos.filter(manejo => manejo.tipo === 'sanitarioEstoque' && !manejo.sanitarioItensEstoque)
    manejos = manejos.filter(manejo => !(manejo.tipo === 'sanitarioEstoque' && !manejo.sanitarioItensEstoque))

    if (manejosSanitarioEstoque.length) {
      const sanitarioItensEstoque = []
      manejosSanitarioEstoque.map(itemEstoque => {
        if(itemEstoque.sanitario_hash_estoque) {
          sanitarioItensEstoque.push({
            sanitario_hash_estoque: itemEstoque.sanitario_hash_estoque,
            nome: itemEstoque.nome,
            categoria: itemEstoque['categoria'],
            meta_unidade: itemEstoque['meta_unidade']
          })
        }
      })
      manejos.push({
        tipo: 'sanitarioEstoque',
        sanitarioItensEstoque
      })
    }

    manejoFormatado.manejos = manejos
  }

  async atualizarSolicitacaoComFotosFormatadas(opcoes: { manejos: ManejoGeralModel[] }) {
    const { manejos } = opcoes

    for (const manejoSequencial of manejos) {
      if (manejoSequencial?.animais?.some(animal => animal?.dados?.['registrar-morte']?.fotos?.length)) {
        for (const animal of manejoSequencial?.animais) {
          const fotos = animal?.dados?.['registrar-morte']?.fotos
          if(fotos?.length) {
            const fotosFormatada = await Promise.all(fotos.map(async foto => {
              const [linkFoto, error] = await this.imagemCtrl.enviarImagem({ tipo: null, imagem: foto })
              if (error) {
                console.warn('🚀 ~ fotosFormatada ~ error:', error)
                throw error
              }
              return linkFoto.url
            }))

            console.warn('🚀 ~ fotosFormatada ~ fotosFormatada:', fotosFormatada)

            animal.dados['registrar-morte'].fotos = fotosFormatada
          }
        }
      }
    }
  }

  async buscarQtdSolicitacoes() {
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' }))?.value)?.id

    let manejos = await this.sql.run(`
      SELECT COUNT (*) as qtd
      FROM peao_solicitacoes
      WHERE status = 1
      AND id_propriedade = ${idPropriedade}
    `)

    const animaisNovosPendentes = await this.animaisCtrl.getAnimaisNovosPendentes({
      situacao: 'novo' 
    })

    const lotesNovosPendentes = await this.lotesCtrl.getLotes({
      filtros: {
        sync: 0
      }
    })

    const areasNovasPendentes = await this.areasCtrl.getAreas({
      filtros: {
        sync: 0
      }
    })

    return Number(manejos[0].qtd) + animaisNovosPendentes.length + lotesNovosPendentes.length + areasNovasPendentes.length
  }
  
  async marcarBackupsComoSalvos(hash: string): Promise<void> {
    await this.sql.run({
      query: `
        DELETE FROM peao_solicitacoes WHERE hash = '${hash}'
      `
    })

    this.sql.safeQuery({
      query: `
        UPDATE _backup_manejos_pendentes
        SET salvo = 1
        WHERE hash_manejo = {{hash_manejo}}
      `,
      params: {
        hash_manejo: hash
      }
    });

    this.sql.safeQuery({
      query: `
        DELETE FROM _backup_manejos_pendentes
        WHERE created_at < {{mes_passado}}
        AND salvo = 1
      `,
      params: {
        mes_passado: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
        hash_manejo: hash
      }
    });
  }

  async criarSolicitacao(manejoSequencial: ManejoGeralModel) {
    await this.marcarBackupsComoSalvos(manejoSequencial.hash)

    return await this.sql.run({
      query: `
        INSERT INTO peao_solicitacoes (
          hash,
          id_propriedade,
          inicio,
          fim,
          editado,
          manejo,
          created_at,
          updated_at
        )
        VALUES (
          '${manejoSequencial.hash}',
          ${manejoSequencial.id_propriedade},
          '${manejoSequencial.inicio}',
          '${manejoSequencial.fim}',
          ${manejoSequencial.editado ? 1 : 0},
          '${JSON.stringify(manejoSequencial)}',
          '${this.sql.getFormatedDate()}',
          '${this.sql.getFormatedDate()}'
        )
      `
    })
  }

  async editarSolicitacaoComManejoSequencial(manejoSequencial: ManejoGeralModel, idSolicitacao: number) {
    // await this.marcarBackupsComoSalvos(manejoSequencial.hash)

    return await this.sql.run(
      `
        INSERT OR REPLACE INTO peao_solicitacoes (
          hash,
          id_propriedade,
          inicio,
          fim,
          editado,
          manejo,
          created_at,
          updated_at
        )
        VALUES (
          '${manejoSequencial.hash}',
          ${manejoSequencial.id_propriedade},
          '${manejoSequencial.inicio}',
          '${manejoSequencial.fim}',
          ${manejoSequencial.editado ? 1 : 0},
          '${JSON.stringify(manejoSequencial)}',
          '${this.sql.getFormatedDate()}',
          '${this.sql.getFormatedDate()}'
        )
      `
    )
  }

  async removerSolicitacaoDeManejo(solicitacaoId: number) {
    let manejos = await this.sql.run(`
      UPDATE peao_solicitacoes SET status = 0 WHERE id = ${solicitacaoId}
    `)
    return manejos
  }

  async marcarSolicitacaoComoEnviada(solicitacaoId: number) {
    let manejos = await this.sql.run(`
      UPDATE peao_solicitacoes SET status = 2 WHERE id = ${solicitacaoId}
    `)
    return manejos
  }
}
