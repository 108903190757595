import { ManejoAnimalModel } from '../../utils/types'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'manejo-castracao',
  templateUrl: './manejo-castracao.component.html',
  styleUrls: ['./manejo-castracao.component.scss']
})
export class ManejoCastracaoComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  getOpcaoSelecionada(opcao: '1' | '0') {
    this.manejo.castracao = opcao
    this.emit()
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
