import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {

  @Input("animal") animal: any = {}

	constructor() {}

	ngOnInit() {}

	exibirUltimosOitoNumeros(brinco: string) {
		return brinco .slice(-8);
	}

	exibeNome(slug: any) {
		const nomes = {
			"estaNoPe": 'Ao Pé',
			"lactacao": 'Lactante',
			"em-reproducao": "Em Reprodução",
			"em-engorda": "Em Engorda",
			"vivo": "Vivo",
			"perdido": "Perdido",
			"morto": "Morto",
			"vendido": "Vendido",
			"abatido": "Abatido",
			"natimorto": "Natimorto",
			"doente": "Doente",
			"em-tratamento": "Tratamento",
			"saudavel": "Saudável",
			"cheia": "Prenhe",
			"solteira": "Solteira",
			"vazia": "Vazia",
			"abortou": "Abortada",
			"inseminacao": "Inseminada",
			"cobertura": "Coberta",
			"castrado": "Castrado",
			"rufiao": "Rufião",
			"touro": "Touro",
			"cio": "Cio",
			"inteiro": "Inteiro",
		};
		return nomes[slug] || slug;
	}

}
