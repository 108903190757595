export const labelsSituacoesSanitarias = {
  medicacao: 'Medicação',
  vacinacao: 'Vacinação',
  vermifugacao: 'Vermifugação',
  'procedimentos-veterinarios': 'Procedimentos Veterinários',
  exames: 'Exames',
  banho: 'Banho',
  'complexo-vitaminico': 'Complexo Vitaminico',
  'tratamento-clinico': 'Tratamento Clínico',
  antiparasitarios: 'Antiparasitários',
  outros: 'Outros',
  diagnostico: 'Diagnóstico',
  aftosa: 'Aftosa',
  brucelose: 'Brucelose',
  sanitarioEstoque: 'Item do Estoque'
}