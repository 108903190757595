import { sqlFilter } from '../utils/sqlUtils'
import { IEstoqueNutricionalItemsQuery } from '../utils/types/manejo-nutricional-types'
import { Injectable } from '@angular/core'
import { BancoSQLiteService } from './banco-sqlite.service'

@Injectable({
  providedIn: 'root'
})
export class SanitarioService {
  constructor(private db: BancoSQLiteService) {}

  async getEstoquesSanitarios(opcoes: {
    idPropriedade: number
    hash?: string
    hashs?: string[]
    tipo?: string
    dataPrecos?: string
    filtros?: IEstoqueNutricionalItemsQuery
  }): Promise<{ estoques: IEstoqueNutricionalItemsQuery[]; totais: { semens: number; embrioes: number; total: number } }> {
    const { idPropriedade, filtros } = opcoes

    const estoquesPromise = this.db.safeQuery({
      query: `
      WITH usos AS (
        SELECT 
        SUM(sanitario.sanitario_quantidade) as qtd,
        sanitario.sanitario_hash_estoque as estoque
        FROM gestor_manejo_sanitario_unitario sanitario
          --  INNER JOIN gestor_animais 
          --      ON gestor_animais.status = 1 
          --      AND sanitario.hash_animal = gestor_animais.hash 
        WHERE sanitario.status = 1
        AND sanitario.sanitario_hash_estoque IS NOT NULL
        AND COALESCE(sanitario.sanitario_hash_estoque, '') <> ''
        AND sanitario.id_propriedade = {{idPropriedade}}
        GROUP BY estoque
    )

      SELECT busca.*,
      busca.extra_quantidade * busca.ultimo_estoque_momentaneo as extra_valor_total
      FROM (
      
        SELECT item.id, item.titulo, item.hash, item.tipo,
        meta_tipo.valor AS meta_tipoDoEstoque,
        meta_unidade.valor AS meta_unidade,
        meta_marca.valor AS meta_marca,

        (
          SELECT COALESCE(positivo, 0) - COALESCE(negativo, 0) - COALESCE(usos.qtd, 0) FROM (
            SELECT 
            SUM( CASE WHEN movimentacao = "entrada" THEN qtd ELSE 0 END ) as positivo,
            SUM( CASE WHEN movimentacao = "saida" THEN qtd ELSE 0 END) as negativo
            FROM gestor_estoque_lancamento
            WHERE gestor_estoque_lancamento.status = 1
            AND hash_estoque = item.hash
          ) as a
        ) AS extra_quantidade,

        (
          SELECT valor_medio_momentaneo 
          FROM gestor_estoque_lancamento
          WHERE gestor_estoque_lancamento.status = 1
          AND hash_estoque = item.hash
          AND movimentacao = 'entrada'
          ORDER BY data_lancamento DESC
          LIMIT 1
        ) AS ultimo_estoque_momentaneo,

        ${filtros.data_custo ? `
          (
            SELECT valor_medio_momentaneo 
            FROM gestor_estoque_lancamento
            WHERE gestor_estoque_lancamento.status = 1
            AND hash_estoque = item.hash
            AND movimentacao = 'entrada'
            AND data_lancamento <= '${filtros.data_custo}'
            ORDER BY data_lancamento DESC
            LIMIT 1
          ) AS extra_custo_na_data,
        ` : ''}

        (
          SELECT * FROM (
            SELECT data_lancamento as data
            FROM gestor_estoque_lancamento
            WHERE gestor_estoque_lancamento.status = 1
            AND hash_estoque = item.hash
            ORDER BY data_lancamento DESC
            LIMIT 1
          ) a
          ORDER BY data DESC
          LIMIT 1
        ) AS extra_ultima_movimentacao
        
        FROM gestor_estoque item
        LEFT JOIN usos ON usos.estoque = item.hash
        LEFT JOIN gestor_estoque_metas meta_tipo ON meta_tipo.status = 1 AND meta_tipo.hash_estoque = item.hash AND meta_tipo.chave = 'tipo'
        LEFT JOIN gestor_estoque_metas meta_unidade ON meta_unidade.status = 1 AND meta_unidade.hash_estoque = item.hash AND meta_unidade.chave = 'unidade'
        LEFT JOIN gestor_estoque_metas meta_marca ON meta_marca.status = 1 AND meta_marca.hash_estoque = item.hash AND meta_marca.chave = 'marca'

        
        WHERE item.status = 1
        AND item.tipo = 'farmacia'
        AND item.id_propriedade = ${idPropriedade}
        ${sqlFilter.safeSelectIn({ coluna: 'item.hash', tag: '{{hash}}', valor: filtros.hash })}
        ${sqlFilter.safeLike({ coluna: 'item.titulo', tag: '{{titulo}}', valor: filtros.titulo })}
        ${sqlFilter.safeEquals({ coluna: 'item.tipo', tag: '{{tipo}}', valor: filtros.tipo })}
        ${sqlFilter.safeEquals({ coluna: 'meta_tipo.valor', tag: '{{meta_tipoDoEstoque}}', valor: filtros.meta_tipoDoEstoque })}
        ${filtros.hash_not_in && filtros.hash_not_in.length ? sqlFilter.safeSelectNotIn({
          coluna: 'item.hash',
          tag: '{{hash_not_in}}',
          valor: filtros.hash_not_in
        }) : ''}
        
        ) busca
        WHERE TRUE
        ${sqlFilter.safeNumberRange({ coluna: 'extra_quantidade', tag: '{{extra_quantidade}}', valor: filtros.extra_quantidade })}
        ${sqlFilter.safeDateRange({ coluna: 'extra_ultima_movimentacao', tag: '{{extra_ultima_movimentacao}}', valor: filtros.extra_ultima_movimentacao })}
        ${sqlFilter.safeNumberRange({ coluna: 'extra_valor_total', tag: '{{extra_valor_total}}', valor: filtros.extra_valor_total as unknown as string })}
        ${sqlFilter.safeOrderBy({ coluna: filtros.order_by, direcao: filtros.order_direction, prefixo: true })}
    `,
      params: {
        ...filtros,
        idPropriedade
      }
    })

    const totaisPromise = this.db.safeQuery({
      query: `
      SELECT COUNT(1) AS qtd FROM (
        SELECT item.id, 
        item.hash, item.tipo,
        meta_tipo.valor AS tipoDoEstoque,
  
        (
          SELECT COALESCE(positivo, 0) - COALESCE(negativo, 0) FROM (
            SELECT 
            SUM( CASE WHEN movimentacao = "entrada" THEN qtd ELSE 0 END ) as positivo,
            SUM( CASE WHEN movimentacao = "saida" THEN qtd ELSE 0 END) as negativo
            FROM gestor_estoque_lancamento
            WHERE gestor_estoque_lancamento.status = 1
            AND hash_estoque = item.hash
          ) as a
        ) AS quantidade
        
        FROM gestor_estoque item
        INNER JOIN gestor_estoque_metas meta_tipo ON meta_tipo.status = 1 AND meta_tipo.hash_estoque = item.hash AND meta_tipo.chave = 'tipo'
        
        WHERE item.status = 1
        AND item.tipo = 'farmacia'
        AND item.id_propriedade = {{idPropriedade}}
        ${sqlFilter.safeSelectIn({ coluna: 'item.hash', tag: '{{hash}}', valor: filtros.hash })}
        ${sqlFilter.safeEquals({ coluna: 'item.tipo', tag: '{{tipo}}', valor: filtros.tipo })}
      ) a
  `,
      params: {
        ...filtros,
        idPropriedade
      }
    })

    const [estoques, [totais]] = await Promise.all([estoquesPromise, totaisPromise])
    
    let quantidade_total = 0
    let valor_total = 0

    for (const estoque of estoques) {
      estoque.extra_custo_na_data = Number(estoque.extra_custo_na_data || '0')
      estoque.ultimo_estoque_momentaneo = Number(estoque.ultimo_estoque_momentaneo || '0')
      estoque.extra_quantidade = Number(estoque.extra_quantidade || '0')
      quantidade_total += Number(estoque.extra_quantidade || '0')
      valor_total += Number(estoque.extra_valor_total || '0')
    }

    totais.quantidade_total = quantidade_total
    totais.valor_total = valor_total

    return { estoques, totais }
  }
}
