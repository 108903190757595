import { ExecutarCordovaBluetoothSerial, OpcoesConexaoBluetooth } from "../../../../../../services/bluetooth/interfaces/bluetooth-interface";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

declare var cordova: any;

const serviceBluetooth = 'BluetoothSerial2' as const

@Injectable({
  providedIn: 'root'
})
export class BluetoothSerialModificadoLeitor {
  connect(opcoes: OpcoesConexaoBluetooth): Observable<any> {
    return this.executarCordovaBluetooth({ metodo: 'connect', argumento: opcoes.deviceId })
  } 
  
  disconnect(): Observable<string> {
    return this.executarCordovaBluetooth({ metodo: 'disconnect'})
  } 

  isConnected(): Observable<string> {
    return this.executarCordovaBluetooth({ metodo: 'isConnected' })
  }

  write(data: any): Observable<any> {
    const stringToArrayBuffer = function(str) {
      let ret = new Uint8Array(str.length);
      for (let i = 0; i < str.length; i++) {
        ret[i] = str.charCodeAt(i);
      }
      return ret.buffer;
    };

    // convert to ArrayBuffer
    if (typeof data === 'string') {
      data = stringToArrayBuffer(data);
    } else if (data instanceof Array) {
      // assuming array of interger
      data = new Uint8Array(data).buffer;
    } else if (data instanceof Uint8Array) {
      data = data.buffer;
    }
    
    return this.executarCordovaBluetooth({ metodo: 'write', argumento: data })
  }

  readUntil(delimiter: string): Observable<string> {
    return this.executarCordovaBluetooth({ metodo: 'readUntil', argumento: delimiter })
  }

  subscribeRawData(): Observable<any> {
    const successWrapper = (data: any) => {
      if (typeof data === 'number') {
        let a = new Uint8Array(1);
        a[0] = data;
        data = a.buffer;
      }
      return data
    };

    return this.executarCordovaBluetooth({ metodo: 'subscribeRaw' }).pipe(map(successWrapper))
  }

  /**
   * @description A partir do plugin bluetooth serial duplicado, executar métodos do bluetooth direto do cordova, selecionando o método que será executado
   * @returns Retorna um tipo génerico "T", o tipo será definido assim que chamar a função.
   * @example this.executarCordovaBluetoothSerial<boolean>(opcoes)
   */
  private executarCordovaBluetooth(opcoes: ExecutarCordovaBluetoothSerial): Observable<string> {
    return new Observable((observer) => {
      cordova.exec(
        function(success: string) {
          observer.next(success)
        },
        function(error: string) {
          observer.error(error)
          observer.complete()
          observer.unsubscribe()
        },
        serviceBluetooth,
        opcoes.metodo,
        [opcoes.argumento]
      );
    })
  } 
}