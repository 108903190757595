import { Injectable } from '@angular/core'
import { ApiService } from '../api'
import { BancoSQLiteService } from '../banco-sqlite.service'
import { GlobalService } from '../global.service'
import { tabelas } from './tabelas'
import { Preferences } from '@capacitor/preferences'
import { VastaRX } from '@vasta/rx'
@Injectable({
  providedIn: 'root'
})
export class SincronizadorService {
  constructor(public global: GlobalService, private sql: BancoSQLiteService, private api: ApiService) {}

  async buscaServidorQtdDataPendenteBaixar() {
    let horaDump = await this.getHoraDump()
    let usuario = this.global.get('usuario')
    let propriedades: any = usuario['propriedades']

    const propriedadesIds = propriedades.map((propriedade) => propriedade.id)

    VastaRX.setState('statusSync', {
      mensagem: 'Verificando downloads pendentes'
    })

    try {
      const dump = await this.api.getAuth(
        `/ultima-sincronizacao?hora=${horaDump}&propriedades=${JSON.stringify(propriedadesIds)}`
      )
      VastaRX.setState('statusSync', {
        status: 'sincronizado',
        mensagem: dump.status === 'sincronizado' ? 'Sincronizado' : 'Downloads pendentes verificados'
      })
      return dump.status === 'sincronizado' ? 'sincronizado' : 'download_pendente'
    } catch (err) {
      VastaRX.setState('statusSync', {
        status: 'erro',
        mensagem: 'Erro ao buscar alterações'
      })
      throw err
    }
  }

  async verificarSeTabelaTemColuna(tabela: string, coluna: string): Promise<boolean> {
    const colunasTabela = await this.sql.run({
      query: `
        SELECT sql FROM sqlite_master
        WHERE tbl_name = '${tabela}' AND type = 'table'
      `
    })
    const colunasFormatada = colunasTabela[0].sql.replace(`([^"]+)" (?!\()`) as string
    const inclueColuna = colunasFormatada.includes(coluna)

    return inclueColuna
  }

  async realizarSincronizacao(): Promise<void> {
    if (!this.global.get('usuario')) return
    let hasItensABaixar: any = await this.buscaServidorQtdDataPendenteBaixar()
    if (hasItensABaixar === 'download_pendente') await this.sincronizarSalvarDados()
  }

  async sincronizarSalvarDados(is_login = false, callback = console.log, propriedadesSolicitadas = []) {
    const isTabelasNovasVazia = this.global.get('novas-tabelas-vazias')

    return new Promise(async (resolve, reject) => {
      callback({ status: 'baixando', mensagem: 'Buscando dados' })

      VastaRX.setState('statusSync', {
        status: 'baixando',
        mensagem: 'Buscando dados'
      })

      if ( !(await this.verificarSeTabelaTemColuna('peao_solicitacoes', 'status'))) {
        await this.sql.run({
          query: `ALTER TABLE peao_solicitacoes ADD COLUMN status INTEGER DEFAULT 1`
        })
      }

      if(!(await this.verificarSeTabelaTemColuna('gestor_estoque_lancamento', 'valor_medio_momentaneo'))) {
        await this.sql.run({
          query: `
            ALTER TABLE gestor_estoque_lancamento ADD COLUMN valor_medio_momentaneo VARCHAR( 255 )
          `
        })
      }
      
      if(!(await this.verificarSeTabelaTemColuna('gestor_manejo_sanitario_unitario', 'sanitario_quantidade'))) {
        await this.sql.run({
          query: `
            DROP TABLE gestor_manejo_sanitario_unitario 
          `
        })
        location.reload()
      }

      let horaDump = is_login ? null : (await this.getHoraDump()) || null

      console.log({ horaDump })

      if (propriedadesSolicitadas && propriedadesSolicitadas.length)
        horaDump = '0&propriedades=' + JSON.stringify(propriedadesSolicitadas)

      try {
        const { last_sync, url } = await this.api.getAuth(`/sync?versao=2${horaDump ? `&timestamp=${horaDump}` : ''}${isTabelasNovasVazia ? '&manejos_todos=1' : ''}`, false, true)

        if (url == 'synced') {
          VastaRX.setState('statusSync', {
            status: 'sincronizado',
            mensagem: `Sincronizado`
          })
          callback({ status: 'sincronizado', mensagem: 'Sincronizado', done: true })
          resolve(true)
          return
        }

        const dump = await this.api.getArquivoStorage(
          url,
          async (percentual) => {
            callback('percentual', percentual + '%')
            callback({ status: 'percentual', mensagem: percentual + '% sincronizado', percentual: percentual })

            VastaRX.setState('statusSync', {
              status: 'percentual',
              mensagem: percentual + '% sincronizado',
              percentual: percentual
            })
          }
        )
        this.setHoraDump(last_sync)
        callback({ status: 'baixado', mensagem: 'Preparando para salvar' })

        VastaRX.setState('statusSync', {
          status: 'baixado',
          mensagem: 'Preparando para salvar'
        })

        const consultasInsercao = []

        for (let id_propriedade in dump) {
          let propriedade = dump[id_propriedade]

          for (let tabela in propriedade) {
            let dadosTabela = propriedade[tabela]
            var dadosTabelaLimitado = []

            var i,
              j,
              chunk = 400

            for (i = 0, j = dadosTabela.length; i < j; i += chunk)
              dadosTabelaLimitado.push(dadosTabela.slice(i, i + chunk))

            for (let dados of dadosTabelaLimitado) {
              if(!tabelas[tabela]) {
                continue
              }

              var frase = `INSERT OR REPLACE INTO ${tabela} ( ${tabelas[tabela].join(', ')}) VALUES`

              let inserts = []
              dados.forEach((item) => {
                let insertSingle = `(`
                for (let chave of tabelas[tabela]) {
                  if (chave === 'sync') insertSingle += `1,`
                  else if (chave === 'edited') insertSingle += `0,`
                  else if (chave === 'status') insertSingle += `${Number(item.status) === 1 ? 1 : 0},`
                  else if (chave === 'realizado') insertSingle += `${Number(item.realizado) === 1 ? 1 : 0},`
                  else if (chave === 'is_padrao') insertSingle += `${Number(item.is_padrao) === 1 ? 1 : 0},`
                  else insertSingle += `'${this.escapeSingleQuotes(`${item[chave] || ''}`)}',`
                }
                inserts.push(`${insertSingle.slice(0, -1)})`)
              })
              frase += inserts.join(', ')

              if (inserts.length) consultasInsercao.push(frase)
            }
          }
        }
        if (consultasInsercao.length) {
          let percentualInserts = 0
          let qtdInserts = consultasInsercao.length
          let percentualInsertUnico = 100 / qtdInserts
          for (const insert of consultasInsercao) {
            try {
              await this.sql.run({ query: insert.replace(/\x00/g, ""), ignorarInsert: true })
            } catch (e) {
              console.error(e)
            }
            percentualInserts += percentualInsertUnico
            callback({ status: 'salvando', mensagem: `${percentualInserts.toFixed(1)}% Salvo` })

            VastaRX.setState('statusSync', {
              status: 'salvando',
              mensagem: `${percentualInserts.toFixed(1)}% Salvo`
            })
          }
        }

        await this.verificarAnimaisReprovados()

        VastaRX.setState('statusSync', {
          status: 'sincronizado',
          mensagem: `Sincronizado`
        })
        callback({ status: 'sincronizado', mensagem: 'Sincronizado', done: true })
        resolve(true)
      } catch (error) {
        VastaRX.setState('statusSync', {
          status: 'erro',
          mensagem: 'Erro ao baixar dados'
        })
        reject({ status: 'erro', mensagem: 'Erro ao baixar dados', done: true })
      }
    })
  }

  escapeSingleQuotes(str: string): string {
    return str.replace(/'/g, "''");
  }

  async verificarAnimaisReprovados() {
    const resultadoQuery = await this.sql.run(`SELECT hash_animal FROM gestor_animal_metas WHERE chave = 'reprovado' AND status = 1`);
    const hashsReprovados = resultadoQuery.map(a => a.hash_animal)
    await this.sql.run(`UPDATE gestor_animais SET status = 0 WHERE hash IN("${hashsReprovados.join('", "')}")`)
  }

  async setHoraDump(valor = null) {
    await Preferences.set({ key: 'fertili-dump-time', value: valor || this.sql.getFormatedDate() })
  }

  async getHoraDump() {
    return (await Preferences.get({ key: 'fertili-dump-time' })).value || 0
  }
}
