import { ApplicationRef, Component, Input, OnInit } from '@angular/core'
import { AlertController, ModalController, NavParams } from '@ionic/angular'
import { UtilsService } from '../../services/utils.service'
import { ListaRacasComponent } from '../lista-racas/lista-racas.component'
import { SeletorLoteSingleComponent } from '../seletor-lote-single/seletor-lote-single.component'
import { LoteModel, IPayloadNovoAnimal, IPreencherAnimalModel, AnimalModel } from '../../utils/types'
import { AnimaisService } from '../../services/animais.service'
import Utils from '../../utils/utils'
import { Preferences } from '@capacitor/preferences'
import { BancoSQLiteService } from '../../services/banco-sqlite.service'
import { Pelagens, Racas } from '../../utils'
import { GlobalService } from '../../services/global.service'
import { BrincosJaUtilizadosService } from '../../services/brincos-ja-utilizados.service'
import { LotesService } from '../../services/lotes.service'
import * as moment from 'moment'
// import { SeletorAnimaisComponent } from '../seletor-animais/seletor-animais.component'
 
@Component({
  selector: 'app-cadastrar-animal',
  templateUrl: './cadastrar-animal.component.html',
  styleUrls: ['./cadastrar-animal.component.scss']
})
export class CadastrarAnimalComponent implements OnInit {
  @Input() animalParaEditar: AnimalModel

  // public propriedadeSelecionada: any
  public isTecladoNumerico = true

  public animalPeso: string;
  public animal: IPreencherAnimalModel = {
    id_propriedade: 0,
    nome: '',
    hash: '',
    sexo: 'macho',
    hash_lote: '',
    numeracao: '',
    tipo_entrada: 'nascimento',
    observacao: '',
    aptidao: 'corte',
    data_nascimento: '',
    raca: {
      slug: null,
      nome: null
    },
    genealogia_numero_pai: '',
    genealogia_numero_mae: '',
    pesagens: {
      peso_desmame: '',
      peso_nascimento: 30
    },
    meta_data_desmame: '',
    meta_n_sisbov: '',
    meta_registroDeNascimento: '',
    meta_dataRegistroDeNascimento: '',
    meta_registroDefinitivo: '',
    meta_dataRegistroDefinitivo: '',
    meta_pelagem: ''
  }

  public pelagensDisponiveis: { value: string; label: string }[] = Object.keys(Pelagens).map(pelagem => {
    return {
      value: pelagem,
      label: pelagem
    }
  })

  public reprodutorSelecionado: AnimalModel

  private hoje = moment().format('YYYY-MM-DD')

  public lote: LoteModel = {
    hash: '',
    nome: '',
  }
  public utils = Utils
  public isCarregando = false
  public propriedadeAtual = {
    nome: '',
    id: 0,
    metas: {
      raca_principal: ''
    }
  }

  public timeoutBrinco = null
  public isBrincoExiste = false

  public tipoManejo: string;
  public isCadastrandoFilhote = false;
  public isEditandoFilhote = false;

  public listaDeLotes: any[];

  public plataforma: 'ios' | 'android' | 'web'

  public isCadastroAvancado = false

  numeroMae = ''

  constructor(
    private modalCtrl: ModalController,
    private ref: ApplicationRef,
    private utilsCtrl: UtilsService,
    private sql: BancoSQLiteService,
    private alertCtrl: AlertController,
    private global: GlobalService,
    private animaisCtrl: AnimaisService,
    private brincosJaUtilizadosCtrl: BrincosJaUtilizadosService,
    private navParams: NavParams,
    private globalCtrl: GlobalService,
    private lotesCtrl: LotesService,
    private db: BancoSQLiteService
  ) {}

  async ngOnInit() {
    this.propriedadeAtual = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value)
    this.animal.id_propriedade = this.propriedadeAtual?.id

    this.animal.sexo = this.global.get('animal_sexo') || this.animal.sexo
    this.animal.aptidao = this.global.get('animal_aptidao') || this.animal.aptidao
    this.animal.tipo_entrada = this.global.get('animal_entrada') || this.animal.tipo_entrada
    
    this.propriedadeAtual.metas = { raca_principal: '' }
    let propriedadesMetas: any = await this.sql.run({
      query: `SELECT * FROM gestor_propriedades_metas WHERE id_propriedade = ${this.propriedadeAtual?.id} AND status = 1`
    })
    propriedadesMetas.map((meta) => {
      this.propriedadeAtual['metas'][meta.chave] = meta.valor == 'null' ? null : meta.valor
    })
    console.log({ propriedadesMetas })

    propriedadesMetas.map((meta) => {
      if (meta.chave === 'peso_padrao_ao_nascer') {
        if (Number(meta.valor) === 0 || !isNaN(meta.valor)) {
          this.animal.pesagens.peso_nascimento = Number(meta.valor)
        }
      }
    })

    if (this.propriedadeAtual.metas?.raca_principal)
      this.animal.raca = {
        slug: this.propriedadeAtual.metas?.raca_principal,
        nome: Racas[this.propriedadeAtual.metas?.raca_principal]
      }

    this.listaDeLotes = <any[]>await this.lotesCtrl.getLotes()

    this.carregarInformacoesDoUltimoCadastro()

    await this.getAtalhoProps();

    this.plataforma = this.globalCtrl.get('plataforma')

    await this.getTipoDoTeclado()

    if(this.animalParaEditar) {
     await this.preencherAnimalParaAtualizar()
     this.ref.tick()
    }

    this.ref.tick()
  }

  tick(event?) {
    if (event?.detail?.value) {
      this.ref.tick()
    }
  }

  toggleCadastroAvancado(isMarcado: boolean) {
    this.isCadastroAvancado = isMarcado
  }

  // async openModalListaReprodutores(filtro: 'reprodutor' | 'matriz') {
  //   const modal = await this.modalCtrl.create({
  //     component: SeletorAnimaisComponent,
  //     componentProps: {
  //       isFiltroAnimaisFemeas: filtro === 'matriz',
  //       isFiltroAnimaisMacho: filtro === 'reprodutor'
  //     }
  //   })

  //   await modal.present()

  //   const { data: objetoAnimal } = await modal.onDidDismiss()

  //   const animal: AnimalModel = objetoAnimal?.animal
  //   this.reprodutorSelecionado = animal

  //   if(filtro === 'reprodutor') {
  //     this.animal.genealogia_nome_pai = animal?.nome
  //     this.animal.genealogia_numero_pai = animal?.numeracao
  //   } else {
  //     this.animal.genealogia_nome_mae = animal?.nome
  //     this.animal.genealogia_numero_mae = animal?.numeracao
  //   }
  // }

  setPelagemSelecionada(event: { detail: { value: string } }) {
    this.animal.meta_pelagem = event.detail.value
  }

  carregarInformacoesDoUltimoCadastro() {
    const racaAnimalSalva = this.global.get('animal_entrada')

    if(racaAnimalSalva) {
      this.animal.raca.slug = racaAnimalSalva?.slug
      this.animal.raca.nome = racaAnimalSalva?.nome
    }

    const animalLoteSalvo = this.global.get('animal_lote')

    if(animalLoteSalvo) {
      this.lote.hash = animalLoteSalvo?.hash
      this.lote.nome = animalLoteSalvo?.nome
    }

    this.animal.data_nascimento = this.global.get('animal_data_nascimento') || this.hoje
  }

  salvarUltimaDataPreenchida() {
    this.global.set('animal_data_nascimento', this.animal.data_nascimento)
  }

  toggleTecladoNumerico(): void {
    this.isTecladoNumerico = !this.isTecladoNumerico
    console.warn('🚀 ~ toggleTecladoNumerico ~ this.isTecladoNumerico:', this.isTecladoNumerico)
    Preferences.set({ key: 'tipo-teclado-numerico', value: this.isTecladoNumerico ? '1' : '0' })
    this.ref.tick()
  }

  async getTipoDoTeclado(): Promise<void> {
    const { value: isTecladoNumerico } = await Preferences.get({ key: 'tipo-teclado-numerico' })
    if(isTecladoNumerico === '1') {
      this.isTecladoNumerico = true
    } else {
      this.isTecladoNumerico = false
    }
  }

  async getAtalhoProps() {
    const propsManejo = (await this.navParams.get('propsManejo'));

    if(propsManejo) {
      const { tipoManejo, animal, filhote } = propsManejo; 

      this.tipoManejo = tipoManejo;

      if(this.tipoManejo === 'parto') {
        if(filhote) {
          this.isEditandoFilhote = true;

          const raca = {
            nome: Racas[filhote.raca] || filhote.raca,
            slug: filhote.raca
          }

          const loteDoFilhote = this.listaDeLotes?.filter(lote => lote.hash === filhote.hash_lote)[0];

          this.lote = {
            nome: loteDoFilhote?.nome,
            hash: filhote?.hash
          }

          this.animal = { ...filhote, raca }

        } else {
          this.isCadastrandoFilhote = true;
          const dataHoje = new Date().toISOString().slice(0, 10);
          this.animal.data_nascimento = dataHoje;
  
          this.animal.raca.nome = Racas[animal.raca] || animal.raca
          this.animal.raca.slug = animal.raca
  
          this.lote.nome = animal.nome_lote;
          this.lote.hash = animal.hash_lote;
          this.numeroMae = animal.numeracao;
        }
      }
    }
  }

  async openModalListaLote() {
    const modal = await this.modalCtrl.create({
      component: SeletorLoteSingleComponent
    })

    await modal.present()
    const { data: lote } = await modal.onDidDismiss()
    if(lote) {
      this.lote.hash = lote?.hash
      this.lote.nome = lote?.nome
      this.global.set('animal_lote', { hash: lote?.hash, nome: lote?.nome  })
    }
  }

  async openModalListaRacas() {
    const modal = await this.modalCtrl.create({
      component: ListaRacasComponent
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    this.animal.raca.slug = data.racaKey
    this.animal.raca.nome = data.racaValue
    this.global.set('animal_entrada', { slug: data.racaKey, nome: data.racaValue  })
  }

  dismiss(novoAnimalCadastrado?) {
    this.modalCtrl.dismiss(novoAnimalCadastrado)
  }

  alteraBrinco() {
    clearTimeout(this.timeoutBrinco)
    this.timeoutBrinco = setTimeout(async () => {
      this.isBrincoExiste = await this.brincosJaUtilizadosCtrl.numeroControleJaUtilizado(this.animal)
    }, 500)
  }

  async cadastrarOuEditarAnimal() {
    await this.validarCampos()

    if(this.numeroMae && !this.animal.numeracao) {
      this.animal.numeracao = this.numeroMae;
    }

    const payloadAnimal: IPayloadNovoAnimal = {
      ...this.animal,
      raca: this.animal.raca.slug,
      hash: !this.isEditandoFilhote ? this.utils.gerarHash() : this.animal.hash,
      hash_lote: this.lote.hash
    }

    try {
      this.isCarregando = true
      !this.isEditandoFilhote && await this.animaisCtrl.criarNovoAnimal(payloadAnimal)
      this.dismiss(payloadAnimal)
      
      if(this.isEditandoFilhote) this.utilsCtrl.exibirToast('Animal atualizado com sucesso')
      else this.utilsCtrl.exibirToast('Animal Cadastrado com sucesso')
      
    } catch (error) {
      console.error(error)
    } finally {
      this.isCarregando = false
    }
  }

  async preencherAnimalParaAtualizar() {
    this.animal = {
      aptidao: this.animalParaEditar.aptidao,
      data_nascimento: this.animalParaEditar.data_nascimento,
      numeracao: this.animalParaEditar.numeracao,
      observacao: this.animalParaEditar.observacao,
      raca: {
        slug: this.animalParaEditar.raca,
        nome: this.animalParaEditar.raca
      },
      sexo: this.animalParaEditar.sexo,
      pesagens: {
        peso_nascimento: this.animalParaEditar.ultimo_peso,
      },
      nome: this.animalParaEditar.nome,
      hash_lote: this.animalParaEditar.hash_lote,
      tipo_entrada: this.animalParaEditar.tipo_entrada,
      hash: this.animalParaEditar.hash,
      id_propriedade: this.animalParaEditar.id_propriedade,
    }

    const metas = await this.db.run({ query: 
      `SELECT * 
      FROM gestor_animal_metas 
      WHERE hash_animal = '${this.animalParaEditar.hash}'
      AND status = 1
    `})

    metas.forEach(meta => {
      meta.chave = 'meta_' + meta.chave
      this.animal[meta.chave] = meta.valor
    })
  }

  async atualizarAnimalPendente() {
    if(this.animalParaEditar) {
      try {
        this.isCarregando = true

        await this.validarCampos()
        
        const payloadAnimal: IPayloadNovoAnimal = {
          ...this.animal,
          raca: this.animal.raca.slug,
          situacao: 'novo',
          created_at: this.animalParaEditar.created_at
        }

        await this.animaisCtrl.atualizarAnimalPendente(payloadAnimal)
        
        this.dismiss(payloadAnimal)
        
        this.utilsCtrl.exibirToast('Animal atualizado com sucesso')
        
      } catch (error) {
        console.error(error)
      } finally {
        this.isCarregando = false
      }
    }
  }

  async salvarAlteracoes() {
    if(!this.animalParaEditar) {
      await this.cadastrarOuEditarAnimal()
    }

    if(this.animalParaEditar) {
      await this.atualizarAnimalPendente()
    }
  }

  fecharTeclado(event: KeyboardEvent) {
    let element = event.target as HTMLInputElement;
    element.blur();
  }

  async validarCampos(): Promise<void> {
    if (!this.animal?.numeracao.trim()?.length && !this.numeroMae) {
      await this.confirmaAntesDeContinuar({ mensagem: 'O número é obrigatório' })
    }
    if (this.isBrincoExiste) {
      await this.confirmaAntesDeContinuar({ mensagem: 'Brinco já cadastrado' })
    }
    if (!this.animal.aptidao?.length) {
      await this.confirmaAntesDeContinuar({ mensagem: 'O campo de aptidão é obrigatório' })
    }
    if (!this.animal.data_nascimento?.length) {
      await this.confirmaAntesDeContinuar({ mensagem: 'A data de nascimento é obrigatório' })
    }
    if (!this.animal.raca?.nome?.length) {
      await this.confirmaAntesDeContinuar({ mensagem: 'A raça é obrigatório' })
    }
  }

  async confirmaAntesDeContinuar(opcoes: { mensagem: string }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const { mensagem } = opcoes
      const alert = await this.alertCtrl.create({
        header: mensagem,
        message: 'Continuar mesmo assim?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              reject()
            }
          },
          {
            text: 'Continuar',
            handler: () => {
              resolve(null)
            }
          }
        ]
      })
      alert.present()
    })
  }

  marcarSexoComo(valor) {
    this.global.set('animal_sexo', valor)
    this.animal.sexo = valor
  }

  marcarAptidaoComo(valor) {
    this.global.set('animal_aptidao', valor)
    this.animal.aptidao = valor
  }

  marcarEntradaComo(valor) {
    this.global.set('animal_entrada', valor)
    this.animal.tipo_entrada = valor
  }
}
