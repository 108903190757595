import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Preferences } from '@capacitor/preferences'

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit {

  constructor(private router: Router) { }

  async ngOnInit() {
    this.atualizarRotaParaLoginOuHome()
  }

  async atualizarRotaParaLoginOuHome() {
    const { value } = await Preferences.get({
      key: 'token'
    })

    if(!value) {
      await this.router.navigate(['/login'])
    } else {
      await this.router.navigate(['/home'])
    }
  }
}
