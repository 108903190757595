import { Injectable } from '@angular/core'
import { ArvoreGenealogica, FilhoFichaModel } from '../utils/types'
import { BancoSQLiteService } from './banco-sqlite.service'
import { GlobalService } from './global.service'

@Injectable({
  providedIn: 'root'
})
export class GenealogiaService {
  constructor(private db: BancoSQLiteService, private global: GlobalService) {}

  async getGenealogiaAnimal(opcoes: { hash_animal: string }): Promise<ArvoreGenealogica> {
    const { hash_animal } = opcoes

    function preencheGenealogia(prefixo = '', dados) {
      if (dados) {
        const { hash_pai, hash_mae, hash_reprodutor_pai, hash_reprodutor_mae } = dados

        if (hash_pai || hash_reprodutor_pai) {
          genealogia[`${prefixo}pai`] = {
            hash: hash_pai || hash_reprodutor_pai,
            externo: hash_reprodutor_pai ? 1 : 0
          }
        }

        if (hash_mae || hash_reprodutor_mae) {
          genealogia[`${prefixo}mae`] = {
            hash: hash_mae || hash_reprodutor_mae,
            externo: hash_reprodutor_mae ? 1 : 0
          }
        }
      }
    }

    const genealogia: ArvoreGenealogica = {}

    genealogia.crias = (
      await this.db.run(`
      SELECT hash, nome, numeracao, sexo, data_nascimento
      FROM gestor_animais
      WHERE gestor_animais.hash IN (
        SELECT hash_animal
        FROM gestor_genealogia
        WHERE (
          hash_pai = '${hash_animal}' OR 
          hash_mae = '${hash_animal}' OR 
          hash_reprodutor_pai = '${hash_animal}' OR 
          hash_reprodutor_mae = '${hash_animal}'
        )
        AND status = 1
      )
      AND gestor_animais.status = 1
    `)
    ).map((cria) => {
      return {
        hash: cria.hash,
        nome: cria.nome || '',
        numeracao: cria.numeracao || '',
        sexo: cria.sexo,
        data_nascimento: cria.data_nascimento
      }
    })

    const [genealogiaPropria] = await this.db.run(`
        SELECT *
        FROM gestor_genealogia
        WHERE hash_animal = '${hash_animal}'
        AND status = 1
    `)

    if (!genealogiaPropria) return genealogia

    const { hash_pai, hash_mae } = genealogiaPropria

    preencheGenealogia('', genealogiaPropria)

    const genealogiaAvos = await this.db.run(`
      SELECT *
      FROM gestor_genealogia
      WHERE hash_animal IN ('${hash_pai}', '${hash_mae}')
      AND hash_animal IS NOT NULL AND hash_animal <> ''
      AND status = 1
    `)

    const genealogiaPai = genealogiaAvos.filter((g) => g.hash_animal == hash_pai)[0]
    const genealogiaMae = genealogiaAvos.filter((g) => g.hash_animal == hash_mae)[0]

    if (genealogiaPai || genealogiaMae) {
      if (genealogiaPai) preencheGenealogia('pai_', genealogiaPai)
      if (genealogiaMae) preencheGenealogia('mae_', genealogiaMae)

      const hashsBisavos = [
        genealogia?.pai_pai?.hash,
        genealogia?.pai_mae?.hash,
        genealogia?.mae_pai?.hash,
        genealogia?.mae_mae?.hash
      ].filter(Boolean)

      const genealogiaBisavos = await this.db.run(`
      SELECT *
      FROM gestor_genealogia
      WHERE hash_animal IN ('${hashsBisavos.join("','")}')
      AND hash_animal IS NOT NULL AND hash_animal <> ''
      AND status = 1
    `)

      const genealogiaPaiPai = genealogiaBisavos.filter((g) => g.hash_animal == genealogia?.pai_pai?.hash)[0]
      const genealogiaPaiMae = genealogiaBisavos.filter((g) => g.hash_animal == genealogia?.pai_mae?.hash)[0]
      const genealogiaMaePai = genealogiaBisavos.filter((g) => g.hash_animal == genealogia?.mae_pai?.hash)[0]
      const genealogiaMaeMae = genealogiaBisavos.filter((g) => g.hash_animal == genealogia?.mae_mae?.hash)[0]

      preencheGenealogia('pai_pai_', genealogiaPaiPai)
      preencheGenealogia('pai_mae_', genealogiaPaiMae)
      preencheGenealogia('mae_pai_', genealogiaMaePai)
      preencheGenealogia('mae_mae_', genealogiaMaeMae)
    }

    const hashsTodos = Object.values(genealogia as unknown)
      .map((g) => {
        if (g.hash) return g.hash
      })
      .filter(Boolean)

    const dadosAnimaisGenealogia = await this.db.run(`
      SELECT hash, nome, numeracao
      FROM gestor_animais
      WHERE hash IN ('${hashsTodos.join("','")}')
      AND status = 1
    `)

    const dadosAnimaisExternosGenealogia = await this.db.run(`
      SELECT hash, nome, reprodutor, matriz
      FROM gestor_reprodutores_externos
      WHERE hash IN ('${hashsTodos.join("','")}')
      AND status = 1
    `)

    for (const animal of dadosAnimaisGenealogia) {
      for (const i in genealogia) {
        if (genealogia[i].hash == animal.hash && !genealogia[i].externo) {
          genealogia[i].nome = animal.nome || ''
          genealogia[i].numeracao = animal.numeracao || ''
        }
      }
    }

    for (const animal of dadosAnimaisExternosGenealogia) {
      for (const i in genealogia) {
        if (genealogia[i].hash == animal.hash && genealogia[i].externo) {
          genealogia[i].nome = animal.nome || ''
          genealogia[i + '_pai'] = {
            nome: animal.reprodutor || '',
            externo: true
          }
          genealogia[i + '_mae'] = {
            nome: animal.matriz || '',
            externo: true
          }
        }
      }
    }

    return genealogia
  }

  public async getGenealogia(hash_animal) {
    const reprodutor = <any[]>await this.db.run(`
      SELECT 
      mae.nome as mae_nome, mae.numeracao as mae_numeracao, 
      pai.nome as pai_nome, pai.numeracao as pai_numeracao
      FROM gestor_genealogia genealogia 
      LEFT JOIN gestor_animais pai ON pai.hash = genealogia.hash_pai AND pai.status = 1
      LEFT JOIN gestor_animais mae ON mae.hash = genealogia.hash_mae AND mae.status = 1
      WHERE genealogia.status = 1
      AND genealogia.hash_animal = "${hash_animal}"
      LIMIT 1`)
    if (reprodutor.length) return reprodutor[0]
    else return null
  }

  public async getAnimalGenealogia(hash) {
    let animal = <any[]>await this.db.run(`
      SELECT gestor_animais.*,
      gestor_lotes.nome as nome_lote,
      gestor_lotes.finalidade as finalidade_lote,
      gestor_lotes.aptidao as aptidao_lote,
      gestor_areas.nome as nome_area,
      CASE WHEN (SELECT MAX (edited) FROM gestor_animal_metas WHERE hash_animal = gestor_animais.hash) OR gestor_animais.edited THEN 1 ELSE 0 END AS edited
      FROM gestor_animais
      LEFT JOIN gestor_lotes ON gestor_animais.hash_lote = gestor_lotes.hash
      LEFT JOIN gestor_areas ON gestor_areas.hash = gestor_lotes.hash_area
      WHERE gestor_animais.hash = '${hash}' 
      AND gestor_animais.status = 1
    `)
    if (animal.length == 0) return false

    animal = animal[0]

    const metas = <any[]>(
      await this.db.run(
        `SELECT *  FROM gestor_animal_metas WHERE hash_animal = '` + hash + `' AND gestor_animal_metas.status = 1`
      )
    )
    var animal_metas = {}
    for (let meta of metas) animal_metas[meta.chave] = meta.valor
    animal['metas'] = animal_metas

    return animal
  }

  public async getReprodutor(hash_reprodutor) {
    const reprodutor = <any[]>(
      await this.db.run(` SELECT * FROM gestor_reprodutores_externos WHERE hash = '${hash_reprodutor}' `)
    )
    if (reprodutor.length) return reprodutor[0]
    else return null
  }

  iterar(pos, array, done, iterator) {
    if (pos < array.length) {
      iterator(pos, array, (retorno) => {
        pos++
        this.iterar(pos, array, done, iterator)
      })
    } else {
      done(pos, array)
    }
  }

  public getFilhos(hash_animal): Promise<FilhoFichaModel[]> {
    return new Promise(async (resolve) => {
      const resultado = <any[]>await this.db.run(`
        SELECT * FROM gestor_genealogia
        WHERE hash_pai = '${hash_animal}'
        OR hash_mae = '${hash_animal}'
        OR hash_reprodutor_pai = '${hash_animal}'
        OR hash_reprodutor_mae = '${hash_animal}'`)
      this.iterar(
        0,
        resultado,
        () => {
          resolve(resultado)
        },
        async (pos, array, next) => {
          const retorno = await this.getAnimalGenealogia(resultado[pos].hash_animal)
          resultado[pos]['nome'] = retorno['nome']
          resultado[pos]['numeracao'] = retorno['numeracao']
          resultado[pos]['sexo'] = retorno['sexo']
          next()
        }
      )
    })
  }
}
