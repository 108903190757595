import { AnimalModel } from '../utils/types';
import { Injectable } from '@angular/core';
import { AnimaisService } from './animais.service';
import { BancoSQLiteService } from './banco-sqlite.service';

@Injectable({
  providedIn: 'root'
})
export class EstoqueService {

  constructor(private db: BancoSQLiteService, private animaisCtrl: AnimaisService) { }

  async getTouroPorEstoqueBiologico(hash_estoque: string): Promise<AnimalModel> {
    const estoqueBiologico = await this.db.run({
      query: `
        SELECT *
        FROM gestor_estoque_metas
        WHERE hash_estoque = '${hash_estoque}'
      `
    });

    let touro: AnimalModel

    for(const estoque of estoqueBiologico) {
      if(estoque.chave === 'touro' && estoque.valor) {
        const hashTouro = estoque.valor
        touro = await this.animaisCtrl.getAnimal(hashTouro)
      }

      if(estoque.chave === 'touro_externo' && estoque.valor) {
        const hashTouro = estoque.valor
        touro = await this.animaisCtrl.getReprodutorExterno(hashTouro)
      }
    }

    return touro
  }
}
