import { Categorias as CategoriasFinancas } from '../../utils/financas-categorias'
import { Categorias, Pelagens, Racas } from "../../utils"
import { TiposAreaObject } from "../../utils/tipos-area"
import { TiposCapimObject } from "../../utils/tipos-capim"

const labelsSexo = {
  macho: 'Macho',
  femea: 'Fêmea'
}

const labelsMeses = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro'
}

const labelsRaca = { 'P.O.': 'P.O.', ...Racas }

const labelsExposicao = {
  'inseminacao-1': 'IATF 1',
  'inseminacao-2': 'IATF 2',
  'inseminacao-3': 'IATF 3',
  'inseminacao-4': 'IATF 4',
  'inseminacao-5': 'IATF 5',
  'inseminacao-6': 'IATF 6',
  repasse: 'Repasse',
  'monta-natural': 'Monta Natural'
}

const labelsExposicaoTipo = {
  embriao: 'Embrião',
  semen: 'Sêmen'
}

const labelsExposicaoDG = {
  perda: 'Perda Embrionária',
  positivo: 'Positivo',
  negativo: 'Negativo'
}

const labelsManejoRealizado = {
  realizado: 'Realizado',
  'nao-realizado': 'Não Realizado'
}

const labelsCategoria = Categorias

const labelsCategoriaAnimal = {
  bezerras: 'Bezerra',
  bezerros: 'Bezerro',
  novilhas_8_12: 'Novilhas 8-12',
  garrotes_8_12: 'Garrotes 8-12',
  novilhas_13_24: 'Novilhas 13-24',
  garrotes_13_24: 'Garrotes 13-24',
  bois_25_36: 'Boi 25-36',
  bois_36_mais: 'Boi 36+',
  vaca_24_mais: 'Vaca 24+',
  nuliparas: 'Nulípara',
  primiparas: 'Primípara',
  secundiparas: 'Secundípara',
  multiparas: 'Multípara',
  reprodutores: 'Reprodutor'
}

const labelsSituacao = {
  vivo: 'Vivo',
  novo: 'Novo',
  pendente: 'Pendente',
  perdido: 'Perdido',
  morto: 'Morto',
  vendido: 'Vendido',
  movido: 'Movido',
  abatido: 'Abatido',
  natimorto: 'Natimorto',
}

const labelsSituacaoReprodutiva = {
  vazia: 'Vazia',
  cio: 'Cio',
  inseminada: 'Inseminada',
  coberta: 'Coberta',
  prenhe: 'Prenhe',
  implantada: 'Implantada',
  solteira: 'Solteira',
  castrada: 'Castrada',
  touro: 'Touro',
  rufiao: 'Rufião',
  castrado: 'Castrado',
  inteiro: 'Inteiro',
  descarte: 'Descarte',
  'cio-detectado': 'Cio Detectado',
  'cio-nao-detectado': 'Cio Não Detectado',
  'implante-removido': 'Implante Removido'
}

const labelsSituacaoProdutiva = {
  reproducao: 'Em Reprodução',
  engorda: 'Em Engorda',
  seca: 'Seca'
}

const labelsPelagem = {
  ...Pelagens
}

const labelsSituacaoSanitaria = {
  saudavel: 'Saudável',
  doente: 'Doente',
  'em-tratamento': 'Em Tratamento'
}

const tipoDeManejoNutricional = {
  animal: 'Animal',
  lote: 'Lote',
  area: 'Área'
}

const labelsTipoDeCapimArea = TiposCapimObject

const labelsTipoDeArea = TiposAreaObject

const labelsManejosSanitarios = {
  medicacao: 'Medicacao',
  vacinacao: 'Vacinacao',
  vermifugacao: 'Vermifugacao',
  'procedimentos-veterinarios': 'Procedimentos Veterinarios',
  exames: 'Exames',
  banho: 'Banho',
  'complexo-vitaminico': 'Complexo Vitaminico',
  'tratamento-clinico': 'Tratamento Clinico',
  antiparasitarios: 'Antiparasitarios',
  outros: 'Outros',
  diagnostico: 'Diagnostico',
  aftosa: 'Aftosa',
  brucelose: 'Brucelose'
}

const labelsFinalidade = {
  cria: 'Cria',
  recria: 'Recria',
  engorda: 'Engorda',
  'ciclo-completo': 'Ciclo Completo'
}

const labelsSistemaCriacao = {
  extensivo: 'Extensivo',
  intensivo: 'Intensivo',
  'semi-intensivo': 'Semi-Intensivo'
}

const labelsAptidao = {
  corte: 'Corte',
  leite: 'Leite',
  dupla: 'Dupla Aptidão'
}

const labelsParto = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10
}

const labelsCriaAoPe = {
  0: 'Não',
  1: 'Sim'
}

const labelsManejosReprodutivos = {
  castracao: 'Castração',
  aborto: 'Aborto',
  escore: 'Escore Corporal',
  'monta-natural': 'Monta Natural',
  inseminacao: 'Inseminação',
  dg: 'Diagnóstico de Gestação',
  parto: 'Parto',
  desmame: 'Desmame',
  descarte: 'Descarte',
  cio: 'Cio',
  implante: 'Implante de Progesterona',
  'implante-remocao': 'Remoção de Implante'
}

const labelsExposicoes = {
  'monta-natural': 'Monta Natural',
  inseminacao: 'Inseminação'
}

const labelsCategoriasFinancasLegado = {
  'entrada-0': 'Recebimento',
  'entrada-1': 'Salário',
  'entrada-2': 'Pró-labore',
  'entrada-3': '13 Salário',
  'entrada-4': 'Alimentação Animal',
  'entrada-5': 'Aluguel de Espaço',
  'entrada-6': 'Venda de Animal',
  'entrada-7': 'Venda de Sêmen',
  'entrada-8': 'Venda de Cobertura',
  'entrada-9': 'Venda de Embrião',
  'entrada-10': 'Venda de Leite',
  'entrada-11': 'Aluguéis',
  'entrada-12': 'Aluguel de Pastagens',
  'entrada-13': 'Água',
  'entrada-14': 'Arrendamento de Áreas',
  'entrada-15': 'Aluguel de Pastagens',
  'entrada-16': 'Consultoria',
  'entrada-17': 'Matéria Prima',
  'entrada-18': 'Outros',
  'saida-1': 'Investimento',
  'saida-2': 'Salário',
  'saida-3': 'Pró-labore',
  'saida-4': '13 Salário',
  'saida-5': 'Adiantamento Salarial',
  'saida-6': 'Férias',
  'saida-7': 'Recisão de Funcionários',
  'saida-8': 'Exames Médicos dos Funcionários',
  'saida-9': 'Contratação de Terceiros',
  'saida-10': 'Contratação de Serviços Gerais',
  'saida-11': 'Contratação de Serviços Veterinários',
  'saida-12': 'Contratação de Serviços Agronômicos',
  'saida-13': 'Aquisição de Máquinas/Equipamentos',
  'saida-14': 'Manutenção de Máquinas/Equipamentos',
  'saida-15': 'Imposto',
  'saida-16': 'Seguro',
  'saida-17': 'Serviços Financeiros',
  'saida-18': 'Tarifa Bancária',
  'saida-19': 'Gastos Esporádicos',
  'saida-20': 'Compra Material Sanitário',
  'saida-21': 'Compra de Vacina',
  'saida-22': 'Compra de Remédio',
  'saida-23': 'Aquisição de Animal',
  'saida-24': 'Aquisição de Sêmen',
  'saida-25': 'Aquisição de Cobertura',
  'saida-26': 'Aquisição de Embrião',
  'saida-27': 'Aquisição de Leite',
  'saida-28': 'Aquisição de Óvulos',
  'saida-29': 'Alimentação Animal',
  'saida-30': 'Juros e Despesas Bancárias',
  'saida-31': 'Água',
  'saida-32': 'Energia Elétrica',
  'saida-33': 'Aluguéis',
  'saida-34': 'Telefone Fixo',
  'saida-35': 'Telefone Celular',
  'saida-36': 'Internet',
  'saida-37': 'Reforma de Instalações',
  'saida-38': 'Construção de Instalações',
  'saida-39': 'Armazenamento',
  'saida-40': 'Combustíveis',
  'saida-41': 'Arrendamento de Áreas',
  'saida-42': 'Aluguel de Pastagens',
  'saida-43': 'Segurança',
  'saida-44': 'Cartão de Crédito',
  'saida-45': 'Material de Escritório',
  'saida-46': 'Gás',
  'saida-47': 'Consultoria',
  'saida-48': 'Marketing/Propaganda',
  'saida-49': 'Assessoria Jurídica',
  'saida-50': 'Consórcios',
  'saida-51': 'Limpeza',
  'saida-52': 'Comissões',
  'saida-53': 'Matéria Prima',
  'saida-54': 'Outros'
}

const labelsCategoriasFinancas = {
  ...labelsCategoriasFinancasLegado,
  ...[...CategoriasFinancas.financeiro_entrada, ...CategoriasFinancas.financeiro_saida].reduce((acc, item) => {
    acc[item.id] = item.nome
    return acc
  }, {})
}

const labelsFormasDePagamentoFinancas = {
  'boleto': 'Boleto',
  'cartao_credito': 'Cartão de Crédito',
  'cartao_debito': 'Cartão de Débito',
  'cheque': 'Cheque',
  'deposito': 'Depósito',
  'dinheiro': 'Dinheiro',
  'transferencia': 'Transferência',
  'debito_em_conta': 'Débito em Conta',
  'debito_em_folha': 'Débito em Folha',
  'debito_automatico': 'Débito Automático',
  'pix': 'Pix',
  'credito_na_loja': 'Crédito na Loja'
}

const labelsTipoSaida = {
  'boi-vivo': 'Boi Vivo',
  abate: 'Abate'
}

const labelsTiposFinancas = {
  entrada: 'Entrada',
  saida: 'Saída',
  transferencia: 'Transferência'
}

const labelsRealizado = {
  0: 'Em Aberto',
  1: 'Pago'
}

const labelsCausaMorte = {
  acidente: 'Acidente',
  'ataque-de-onca': 'Ataque de Onça',
  eletrocussao: 'Eletrocussão',
  'enroscado-na-cerca': 'Enroscado na cerca',
  'complicacoes-no-parto': 'Complicações no parto',
  indefinida: 'Indefinida',
  infeccao: 'Infecção',
  intoxicacao: 'Intoxicação',
  'picada-de-cobra': 'Picada de cobra',
  tristeza: 'Tristeza Parasitária',
  natimorto: 'Natimorto',
  verminose: 'Verminose',
  abatido: 'Abatido',
  outros: 'Outro'
}

const labelsLancamentoTipo = {
  manejo: 'Manejo',
  compra: 'Compra',
  coleta: 'Coleta',
  outra: 'Outra',
  venda: 'Venda',
  uso: 'Uso'
}

const labelsCalendarioEvento = {
  sanitario: 'Sanitário',
  ordenha: 'Ordenha',
  pesagem: 'Pesagem',
  reprodutivo: 'Reprodutivo',
  desmame: 'Desmame',
  chuva: 'Chuva',
  parto: 'Parto',
  geral: 'Geral',
  evento: 'Evento',
  padrao: 'Padrão',
  outros: '-'
}

const labelsPessoa = {
  funcionario: 'Funcionário',
  fornecedor: 'Fornecedor',
  cliente: 'Cliente',
  proprietario: 'Proprietário',
  terceirizado: 'Terceirizado',
  frigorifico: 'Frigorífico'
}

const labelsEstoqueLancamentoMovimentacao = {
  entrada: 'Entrada',
  saida: 'Saída'
}

const labelsEstoqueBiologicoLancamentoTipo = {
  semen: 'Sêmen',
  embriao: 'Embrião',
  'embriao-fiv': 'Embrião FIV',
  'embriao-te': 'Embrião TE',
  compra: 'Compra',
  coleta: 'Coleta',
  outra: 'Outra',
  manejo: 'Manejo',
  venda: 'Venda',
  desperdicio: 'Desperdício',
  perda: 'Perda',
  uso: 'Uso'
}

const labelsEstoqueNutricionalLancamentoTipo = {
  compra: 'Compra',
  coleta: 'Coleta',
  producao: 'Produção',
  outra: 'Outra',
  manejo: 'Manejo',
  venda: 'Venda',
  desperdicio: 'Desperdício',
  perda: 'Perda',
  uso: 'Uso'
}

const labelsEstoqueNutricionalTipo = {
  'aditivos': 'Aditivos',
  'minerais': 'Minerais',
  'nucleo': 'Núcleo',
  'proteinado': 'Proteinado',
  'proteicos-energetico': 'Proteico Energético',
  'racao': 'Ração',
  'sal-mineral': 'Sal Mineral',
  'vitaminas': 'Vitaminas',
  'volumosos': 'Volumosos',
}

const labelsEstoqueFarmaciaTipo = {
  'agulha': 'Agulha',
  'analgesico': 'Analgésico',
  'anestesico': 'Anestésico',
  'anti-helmintico': 'Anti-helmíntico',
  'anti-hemorragico': 'Anti-hemorrágico',
  'anti-inflamatorio': 'Anti-inflamatório',
  'anti-inflamatorio-esteroidal': 'Anti-inflamatório Esteroidal',
  'anti-inflamatorio-nao-esteroidal-aine': 'Anti-inflamatório Não Esteroidal (AINE)',
  'anti-viral': 'Anti-Viral',
  'antibacteriano': 'Antibacteriano',
  'antibiotico': 'Antibiótico',
  'anticoagulante': 'Anticoagulante',
  'anticoccidiano': 'Anticoccidiano',
  'antidiarreico': 'Antidiarreico',
  'antiinfeccioso': 'Antiinfeccioso',
  'antimicrobiano': 'Antimicrobiano',
  'antiparasitario': 'Antiparasitário',
  'antisseptico': 'Antisséptico',
  'antitoxico': 'Antitóxico',
  'brinco-mosquicida': 'Brinco Mosquicida',
  'carrapaticida': 'Carrapaticida',
  'diuretico': 'Diurético',
  'ectocida': 'Ectocida',
  'ectoparasiticida': 'Ectoparasiticida',
  'endectocida': 'Endectocida',
  'endectoparasiticida': 'Endectoparasiticida',
  'endoparasiticida': 'Endoparasiticida',
  'equipamentos-diversos': 'Equipamentos diversos',
  'hormonio': 'Hormônio',
  'larvicida': 'Larvicida',
  'lubrificante-mineral': 'Lubrificante mineral',
  'luvas': 'Luvas',
  'material-cirurgico': 'Material cirúrgico',
  'modificador-organico': 'Modificador orgânico',
  'outros-materiais-cirurgicos': 'Outros materiais cirúrgicos',
  'outros-medicamentos': 'Outros medicamentos',
  'reproducao': 'Reprodução',
  'sedativo': 'Sedativo',
  'selante': 'Selante',
  'seringa': 'Seringa',
  'soro-hidratante': 'Soro Hidratante',
  'suplemento': 'Suplemento',
  'vacina': 'Vacina',
  'vermifugo': 'Vermífugo',
  'vidraria': 'Vidraria',
  'vitamina': 'Vitamina',
}

export type labelsFiltrosDisponiveis =
  | 'labelsSexo'
  | 'labelsRaca'
  | 'labelsExposicao'
  | 'labelsExposicaoTipo'
  | 'labelsExposicaoDG'
  | 'labelsManejoRealizado'
  | 'labelsCategoriaAnimal'
  | 'labelsCategoria'
  | 'labelsSituacao'
  | 'labelsSituacaoReprodutiva'
  | 'labelsSituacaoProdutiva'
  | 'labelsPelagem'
  | 'labelsSituacaoSanitaria'
  | 'tipoDeManejoNutricional'
  | 'labelsManejosSanitarios'
  | 'labelsTipoDeCapimArea'
  | 'labelsTipoDeArea'
  | 'labelsFinalidade'
  | 'labelsSistemaCriacao'
  | 'labelsAptidao'
  | 'labelsParto'
  | 'labelsCriaAoPe'
  | 'labelsManejosReprodutivos'
  | 'labelsExposicoes'
  | 'labelsCategoriasFinancas'
  | 'labelsFormasDePagamentoFinancas'
  | 'labelsTipoSaida'
  | 'labelsTiposFinancas'
  | 'labelsRealizado'
  | 'labelsCausaMorte'
  | 'labelsLancamentoTipo'
  | 'labelsCalendarioEvento'
  | 'labelsPessoa'
  | 'labelsEstoqueLancamentoMovimentacao'
  | 'labelsEstoqueBiologicoLancamentoTipo'
  | 'labelsEstoqueNutricionalLancamentoTipo'
  | 'labelsEstoqueNutricionalTipo'
  | 'labelsEstoqueFarmaciaTipo'

export const opcoesFiltros = {
  labelsSexo,
  labelsMeses,
  labelsRaca,
  labelsExposicao,
  labelsExposicaoTipo,
  labelsExposicaoDG,
  labelsManejoRealizado,
  labelsCategoria,
  labelsCategoriaAnimal,
  labelsSituacao,
  labelsSituacaoReprodutiva,
  labelsSituacaoProdutiva,
  labelsPelagem,
  labelsSituacaoSanitaria,
  tipoDeManejoNutricional,
  labelsFinalidade,
  labelsSistemaCriacao,
  labelsAptidao,
  labelsParto,
  labelsCriaAoPe,
  labelsManejosReprodutivos,
  labelsExposicoes,
  labelsManejosSanitarios,
  labelsTipoDeCapimArea,
  labelsTipoDeArea,
  labelsCategoriasFinancas,
  labelsFormasDePagamentoFinancas,
  labelsTipoSaida,
  labelsTiposFinancas,
  labelsRealizado,
  labelsCausaMorte,
  labelsLancamentoTipo,
  labelsCalendarioEvento,
  labelsPessoa,
  labelsEstoqueLancamentoMovimentacao,
  labelsEstoqueBiologicoLancamentoTipo,
  labelsEstoqueNutricionalLancamentoTipo,
  labelsEstoqueNutricionalTipo,
  labelsEstoqueFarmaciaTipo
}
