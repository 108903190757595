import {
  Leitores,
  ValorNomeDispositivos
} from '../../../components/modal-dispositivos-bluetooth/dispositivos-bluetooth.data'
import { ErrorBluetooth } from '../../../services/bluetooth/Error/ErrorBluetooth'
import {
  BluetoothConexaoDispositivoIsolado,
  DispositivoConexao,
  DispositivoSelecionado,
  DispositivoSerial,
  OpcoesBuscarPorDispositivos,
  OpcoesConexaoBluetooth,
  OpcoesConfiguracoesBluetooth,
  OpcoesNotificacoesBluetoothSerial,
  ResultadoConexaoBluetooth,
  ResultadoHabilitarBluetooth,
  ResultadoScanBluetooth,
  StatusConexao
} from '../../../services/bluetooth/interfaces/bluetooth-interface'
import { BluetoothSerialModificadoLeitor } from '../../../services/bluetooth/serial/plugin-serial/plugin-serial-modificado/leitor/bluetooth-serial-modificado-leitor.service'
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx'
import { Injectable } from '@angular/core'
import { setObserverMensagemStatusConexao } from '../../../services/bluetooth/dispositivos/observer-dispositivos'
import { GlobalService } from '../../../services/global.service'
import { VastaRX } from '@vasta/rx'
import { UtilsService } from '../../../services/utils.service'
import Utils from '../../../utils/utils'
import { from, Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LeitorAcoresSmartReaderService implements BluetoothConexaoDispositivoIsolado {
  private idAparelho = ValorNomeDispositivos.leitores.SMARTREADER
  private dadosAparelho = Leitores.find((leitor) => leitor.value === this.idAparelho)
  private leitorSmartReader: DispositivoSelecionado = {
    name: this.idAparelho,
    nome_modelo: this.dadosAparelho.nome,
    id: null
  }
  private qtdTentativasConexaoLeitorQueDeramErrado = 0

  private mensagemErroPerdaDeConexaoDoLeitor = 'Conexão com o leitor perdida, tente conecta-lo novamente'
  private mensagemOrientandoAReiniciarLeitor =
    'Conexão com o leitor perdida, se o erro de conexão persistir, por favor reinicie o leitor'

  private utils = Utils
  private valorBrincoAcumulado = ''

  constructor(
    private bluetoothModule: BluetoothSerial,
    private globalCtrl: GlobalService,
    private bluetoothLeitor: BluetoothSerialModificadoLeitor,
    private utilsCtrl: UtilsService
  ) {}

  async estabelecerConexao(): Promise<void> {
    console.log('estabelecerConexao SMARTREADER')

    try {
      const dispositivoConexao: DispositivoConexao = {
        tipoDispositivo: 'bastao',
        statusConexao: 'conectando'
      }
      VastaRX.setState('dispositivo_conexao', dispositivoConexao)

      const leitorEncontrado = await this.buscarDispositivo({ nomeDispositivo: this.leitorSmartReader.name })
      const resultadoConexao = await this.estabelecerConexaoComLeitor({ deviceId: leitorEncontrado.id })

      this.leitorSmartReader.id = leitorEncontrado.id

      if (resultadoConexao.isConnected) {
        const dispositivoConexao: DispositivoConexao = {
          dispositivoSelecionado: this.leitorSmartReader,
          tipoDispositivo: 'bastao',
          statusConexao: 'conectado'
        }

        VastaRX.setState('dispositivo_conexao', dispositivoConexao)

        await this.receberDadosDoLeitor(leitorEncontrado)
      } else if (!resultadoConexao.isConnected) {
        throw new ErrorBluetooth(resultadoConexao?.message)
      }
    } catch (error) {
      console.error(error)
      const dispositivoConexao: DispositivoConexao = {
        tipoDispositivo: 'bastao',
        statusConexao: 'erro-conexao'
      }
      VastaRX.setState('dispositivo_conexao', dispositivoConexao)

      if (error instanceof ErrorBluetooth) {
        this.utilsCtrl.exibirToast(error.message, this.utils.tempoDeExibicaoDeMensagemDeErro(error.message))
      }
    }
  }

  private async receberDadosDoLeitor(device: DispositivoSerial): Promise<void> {
    const opcoesNotificacoes: OpcoesNotificacoesBluetoothSerial = {
      delimiter: '\n',
      deviceId: device.id,
      message: ''
    }

    console.warn('Receber dados do leitor')

    setObserverMensagemStatusConexao({
      mensagemStatusConexao: 'Pronto para receber dados do leitor',
      isEsconderMensagem: true
    })

    this.escreverPortaSerialLeitor(opcoesNotificacoes).subscribe((data: string) => {
      if (data) {
        const valorProcessado = this.concatenarValorDoBrinco(data)
        const brincoFormatado = this.formatarValorDoBrinco(device, valorProcessado)

        console.warn('brincoFormatado ', brincoFormatado)
        VastaRX.setState('credencialAnimal', brincoFormatado)
      }
    })
  }

  private concatenarValorDoBrinco(valorBrinco: string, delimiter = '\n'): string {
    this.valorBrincoAcumulado += valorBrinco

    if (this.valorBrincoAcumulado.includes(delimiter)) {
      const retorno = `${this.valorBrincoAcumulado}`.trim()
      this.valorBrincoAcumulado = ''
      return retorno
    }
  }

  private formatarValorDoBrinco(_: DispositivoSerial, brinco: string): string {
    return brinco?.replace(' ', '')?.replace('\r\n', '')
  }

  escreverPortaSerialLeitor(opcoes: OpcoesNotificacoesBluetoothSerial): Observable<string> {
    return new Observable((observer) => {
      const reader = from(this.bluetoothLeitor.write(opcoes.message))
        .pipe(mergeMap(() => this.bluetoothLeitor.subscribeRawData()))
        .pipe(mergeMap(() => this.bluetoothLeitor.readUntil(opcoes.delimiter)))

      reader.subscribe((data: string) => {
        if (data?.length) {
          observer.next(data)
        }
      })
    })
  }

  async buscarDispositivo({ nomeDispositivo: nomeLeitor }: OpcoesBuscarPorDispositivos): Promise<DispositivoSerial> {
    console.log('buscarDispositivo SMARTREADER')
    try {
      const [resultadoBluetooth] = await this.isBluetoothHabilitado()

      if (resultadoBluetooth.isEnabled) {
        /* PAREADOS */
        const leitorPareadoEncontrado = await this.procurarLeitorNosDispositivosPareados({ nomeLeitor })
        if (leitorPareadoEncontrado) return leitorPareadoEncontrado

        /* NÃO PAREADOS */
        const leitorNaoPareadoEncontrado = await this.procurarLeitorNosDispositivosNaoPareados({ nomeLeitor })
        if (leitorNaoPareadoEncontrado) return leitorNaoPareadoEncontrado

        /* se o leitor não foi encontrado, abrir configurações para parear manualmente */
        await this.abrirConfiguracoesDoCelularParaParearManualmente()
      } else {
        throw new ErrorBluetooth('Bluetooth não está habilitado')
      }
    } catch (error) {
      console.error(error)

      if (error instanceof ErrorBluetooth) {
        setObserverMensagemStatusConexao({
          mensagemStatusConexao: error.message,
          isEsconderMensagem: true
        })
      }
    }
  }

  private async conectarLeitor(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    const [isBluetoothHabilitado, errorBluetoothDesabilitado] = await this.isBluetoothHabilitado()

    if (isBluetoothHabilitado) {
      return new Promise(async (resolve, reject) => {
        const conexaoLeitor = this.pluginConectarLeitor({ deviceId: opcoes.deviceId })

        conexaoLeitor.subscribe(
          (success: ResultadoConexaoBluetooth) => {
            if (success.isConnected) {
              console.warn('sucesso ao conectar: ', success)
              success.message = 'Leitor Conectado'

              const { isConnected, deviceId, message } = success

              this.emitirResultadoConexaoLeitor({
                message,
                deviceId,
                isConnected
              })

              resolve({ isConnected, deviceId, message })
            }
          },
          (fail: ResultadoConexaoBluetooth) => {
            console.error('Conexão perdida!! ', fail)

            const { isConnected, deviceId } = fail

            const mensagemErro = this.getMensagemDeErro()

            this.emitirResultadoConexaoLeitor({
              message: mensagemErro,
              deviceId,
              isConnected
            })

            const erroBluetooth = new ErrorBluetooth(mensagemErro)
            reject(erroBluetooth)
          }
        )
      })
    }

    if (errorBluetoothDesabilitado) {
      console.error(errorBluetoothDesabilitado)

      if (errorBluetoothDesabilitado instanceof ErrorBluetooth) {
        this.utilsCtrl.exibirToast(errorBluetoothDesabilitado.message)
      }
    }
  }

  pluginConectarLeitor(opcoes: OpcoesConexaoBluetooth): Observable<ResultadoConexaoBluetooth> {
    return new Observable((observer) => {
      console.warn('conectando leitor SMARTREADER...')

      setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Conectando Leitor...' })

      this.bluetoothLeitor.connect({ deviceId: opcoes.deviceId }).subscribe(
        (success) => {
          console.log('bluetoothLeitor success:', success)
          const resultadoConexao: ResultadoConexaoBluetooth = {
            isConnected: true,
            deviceId: opcoes.deviceId
          }

          observer.next(resultadoConexao)
        },
        (error) => {
          console.log('ERROR conexão', error)
          const resultadoConexao: ResultadoConexaoBluetooth = {
            isConnected: false,
            deviceId: opcoes.deviceId
          }

          observer.error(resultadoConexao)
        }
      )
    })
  }

  private emitirResultadoConexaoLeitor(opcoes: ResultadoConexaoBluetooth): void {
    this.utilsCtrl.exibirToast(opcoes.message)
    this.globalCtrl.set('isBastaoConectado', opcoes.isConnected)

    const statusConexao: StatusConexao = opcoes?.statusConexao
      ? opcoes?.statusConexao
      : opcoes.isConnected
      ? 'conectado'
      : 'desconectado'

    const dispositivoConexao: DispositivoConexao = {
      dispositivoSelecionado: this.leitorSmartReader,
      statusConexao,
      tipoDispositivo: 'bastao'
    }

    VastaRX.setState('dispositivo_conexao', dispositivoConexao)

    setObserverMensagemStatusConexao({
      mensagemStatusConexao: opcoes?.message,
      isEsconderMensagem: !opcoes.isConnected
    })

    if (!opcoes.isConnected) {
      this.qtdTentativasConexaoLeitorQueDeramErrado++
    } else {
      this.qtdTentativasConexaoLeitorQueDeramErrado = 0
    }

    console.warn('this.qtdTentativasConexaoLeitorQueDeramErrado: ', this.qtdTentativasConexaoLeitorQueDeramErrado)
  }

  async estabelecerConexaoComLeitor(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    console.log('estabelecerConexaoComLeitor SMARTREADER')
    try {
      const dispositivoVerificado = await this.isLeitorConectado({ deviceId: opcoes.deviceId })
      console.warn('conexão do dispositivo Verificado: ', dispositivoVerificado)

      if (dispositivoVerificado?.isConnected) {
        await this.pluginDesconectarLeitor({ deviceId: opcoes.deviceId })
        // ir para o catch dessa função, para assim, conectar com o leitor
        throw new ErrorBluetooth('Leitor desconectado para conecta-lo novamente')
      }
    } catch (error) {
      console.error(error)
      // Tentar conectar com leitor, dentro do limite de tempo estabelecido
      const limiteDeTempoParaTentarConectar = 15 // segundos

      const response = await Promise.race<ResultadoConexaoBluetooth | boolean>([
        this.conectarLeitor({ deviceId: opcoes.deviceId }),
        this.tempoLimiteParaRequisicao({ delayPorSegundos: limiteDeTempoParaTentarConectar })
      ])

      if (typeof response === 'boolean') {
        console.warn('Tempo limite para conectar leitor ultrapassado')

        const mensagemErro = this.getMensagemDeErro()
        this.qtdTentativasConexaoLeitorQueDeramErrado++

        return {
          deviceId: opcoes.deviceId,
          isConnected: false,
          message: mensagemErro
        }
      }

      return response
    }
  }

  async desconectarDispositivo(options: OpcoesConexaoBluetooth): Promise<void> {
    try {
      const dispositivo = await this.pluginDesconectarLeitor({ deviceId: options.deviceId })

      if (!dispositivo.isConnected) {
        const { isConnected, deviceId, message } = dispositivo

        this.emitirResultadoConexaoLeitor({
          isConnected,
          deviceId,
          message,
          statusConexao: 'desconectado'
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  async pluginDesconectarLeitor(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    return new Promise((resolve, reject) => {
      this.bluetoothLeitor.disconnect().subscribe(
        (isDisconnected) => {
          console.warn('leitor desconectado com sucesso!: ', isDisconnected)

          const resultadoConexao: ResultadoConexaoBluetooth = {
            isConnected: false,
            deviceId: opcoes.deviceId,
            message: 'Leitor Desconectado'
          }

          resolve(resultadoConexao)
        },
        (error) => {
          console.error('falha ao desconectar leitor: ', error)
          const erroBluetooth = new ErrorBluetooth('Erro ao desconectar dispositivo')
          reject(erroBluetooth)
        }
      )
    })
  }

  async isLeitorConectado(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    return new Promise((resolve, reject) => {
      this.bluetoothLeitor.isConnected().subscribe(
        (isConnect) => {
          console.warn('is leitor conectado: ', isConnect)

          const resultadoConexao: ResultadoConexaoBluetooth = {
            isConnected: true,
            deviceId: opcoes.deviceId,
            message: 'Dispositivo Conectado'
          }

          resolve(resultadoConexao)
        },
        (error) => {
          console.error('Leitor não está conectado')

          const resultadoConexao: ResultadoConexaoBluetooth = {
            isConnected: false,
            deviceId: opcoes.deviceId,
            message: 'Dispositivo não conectado'
          }

          console.log('resultadoConexao: ', resultadoConexao)

          reject(resultadoConexao)
        }
      )
    })
  }

  async isBluetoothHabilitado(): Promise<[ResultadoHabilitarBluetooth, ErrorBluetooth?]> {
    try {
      const isEnabled = await this.bluetoothModule.isEnabled()

      return [{ isEnabled: !!isEnabled }]
    } catch (error) {
      console.error('erro ao habilitar bluetooth: ', error)
      const erroBluetooth = new ErrorBluetooth('O bluetooth está desabilitado')
      return [null, erroBluetooth]
    }
  }

  async procurarDispositivosPareados(): Promise<ResultadoScanBluetooth<DispositivoSerial>> {
    console.warn('Procurando dispositivos pareados')
    setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Procurando dispositivos pareados...' })

    const devices = await this.bluetoothModule.list()
    console.warn('dispositivos pareados: ', devices)
    if (devices) {
      return { devices }
    } else {
      const erroBluetooth = new ErrorBluetooth('Erro ao encontrar dispositivos pareados')
      throw erroBluetooth
    }
  }

  async procurarLeitorNosDispositivosPareados(opcoes: { nomeLeitor: string }): Promise<DispositivoSerial> {
    const dispositivosPareados = await this.procurarDispositivosPareados()
    if (!dispositivosPareados?.devices?.length) return null

    const dispositivoEncontrado = dispositivosPareados.devices.find((device) =>
      device.name.startsWith(opcoes.nomeLeitor)
    )
    return dispositivoEncontrado
  }

  async procurarLeitorNosDispositivosNaoPareados(opcoes: { nomeLeitor: string }): Promise<DispositivoSerial> {
    const dispositivosNaoPareados = await Promise.race([
      this.procurarDispositivosNaoPareados(),
      this.tempoLimiteParaRequisicao({ delayPorSegundos: 30 })
    ])

    if (typeof dispositivosNaoPareados === 'boolean') return null

    const dispositivoEncontrado = dispositivosNaoPareados.devices?.find((device) =>
      device?.name?.startsWith(opcoes.nomeLeitor)
    )
    return dispositivoEncontrado
  }

  async procurarDispositivosNaoPareados(): Promise<ResultadoScanBluetooth<DispositivoSerial>> {
    console.warn('Procurando dispositivos não pareados')
    setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Procurando dispositivos não pareados...' })

    const devices: DispositivoSerial[] = await this.bluetoothModule.discoverUnpaired()

    console.warn('Dispositivos não pareados: ', devices)

    if (!devices) {
      const erroBluetooth = new ErrorBluetooth('Erro ao encontrar dispositivos não pareados')
      throw erroBluetooth
    }

    if (!devices?.length) {
      console.warn('Não foi encontrado nenhum dispositivo não pareado')
    }

    return { devices }
  }

  private tempoLimiteParaRequisicao(opcoes: { delayPorSegundos: number }): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let timeout = setTimeout(() => {
        clearTimeout(timeout)
        resolve(true)
      }, opcoes.delayPorSegundos * 1000)
    })
  }

  private async abrirConfiguracoesDoCelularParaParearManualmente(): Promise<void> {
    this.globalCtrl.set('dispositivo-selecionado-com-erro-pareamento-local', this.leitorSmartReader)

    setObserverMensagemStatusConexao({ mensagemStatusConexao: 'Abrindo configurações para parear dispositivo' })

    setTimeout(async () => {
      const [responseConfiguracoes, error] = await this.abrirConfiguracoesBluetooth()

      if (responseConfiguracoes.isAberto) {
        console.warn('Configurações de bluetooth aberta')
      }

      if (error) {
        throw error
      }
    }, 2000)
  }

  async abrirConfiguracoesBluetooth(): Promise<[OpcoesConfiguracoesBluetooth, ErrorBluetooth?]> {
    try {
      await this.bluetoothModule.showBluetoothSettings()
      return [{ isAberto: true }]
    } catch (error) {
      const erroBluetooth = new ErrorBluetooth('Erro ao abrir configurações de bluetooth')
      return [null, erroBluetooth]
    }
  }

  private getMensagemDeErro(): string {
    let mensagemErro: string

    if (this.qtdTentativasConexaoLeitorQueDeramErrado >= 2) {
      mensagemErro = this.mensagemOrientandoAReiniciarLeitor
    } else {
      mensagemErro = this.mensagemErroPerdaDeConexaoDoLeitor
    }

    return mensagemErro
  }





  // limparInformacaoSobreTempoDeConexaoDoLeitor(isApenasResetarTempo = false) {
  //   const value = this.globalCtrl.get('horario_que_leitor_foi_conectado');
  //   const horarioQueLeitorFoiConectado: number = Number(value)   
    
  //   if(horarioQueLeitorFoiConectado) {
  //     if(isApenasResetarTempo) {
  //       this.salvarHorarioEmQueBastaoFoiConectado()
  //     } else {
  //       this.globalCtrl.set('horario_que_leitor_foi_conectado', null);
  //     }
  //   }
  // }

  // async salvarHorarioEmQueBastaoFoiConectado() {
  //   const horarioQueLeitorFoiConectado = new Date().getTime()
  //   this.globalCtrl.set('horario_que_leitor_foi_conectado', String(horarioQueLeitorFoiConectado));
  // }


  // async calcularAQuantoTempoOLeitorEstaConectadoEDesconectarSeTerPassadoDoTempoLimite(dispositivo) {
  //   const valorGenericoDoDispositivo

  //   if(['SmartReader '].includes(valorGenericoDoDispositivo)) {
  //     const isBastaoConnected = this.globalCtrl.get('isBastaoConectado')

  //     this.tempoLimiteDeInatividadeEmMinutos = 3

  //     const value = this.globalCtrl.get('horario_que_leitor_foi_conectado');
  //     const horarioQueLeitorFoiConectado: number = Number(value)
      
  //     if(horarioQueLeitorFoiConectado && dispositivo) {
  //       const tempoAtual = new Date().getTime()
  //       const tempoTotalDeConexaoEmSegundos = (tempoAtual - horarioQueLeitorFoiConectado) / 1000
  //       const tempoTotalDeConexaoEmMinutos = Math.floor(tempoTotalDeConexaoEmSegundos / 60)
        
  //       if(tempoTotalDeConexaoEmMinutos >= this.tempoLimiteDeInatividadeEmMinutos && isBastaoConnected) {
  //         return await this.desconectarDispositivo()
  //       } 
  //     } else {
  //       await this.salvarHorarioEmQueBastaoFoiConectado()
  //     }
  //   }
  // }
}
