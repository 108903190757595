import { ApplicationRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { IonContent, ModalController } from '@ionic/angular'
import { AnimaisSinglePage } from '../../../pages/animais/animais-single/animais-single.page'

@Component({
  selector: 'app-bovinos-lista',
  templateUrl: './bovinos-lista.component.html',
  styleUrls: ['./bovinos-lista.component.scss']
})
export class BovinosListaComponent implements OnInit {
  @ViewChild('content', { static: true }) content: IonContent

  @Input('animaisHash') animaisHash: any[]
  @Input('animaisSelecionaveis') animais: any[]
  @Input('animaisNoManejo') animaisNoManejo: any[]
  @Input('multiplos') multiplos = false
  @Output('animaisEmiter') animaisEmiter = new EventEmitter<any>()
  @Output('irParaAnimal') irParaAnimal = new EventEmitter<any>()

  event: any
  page = 1
  animaisSelecionados = []
  animaisTotais = []
  hashsJaSelecionadas = []
  animaisParciais = []
  animaisExibidos

  constructor(public modalCtrl: ModalController, public ref: ApplicationRef) {}

  ngOnInit() {
    this.setaAnimais()
    if (!this.multiplos)
      this.animaisTotais = this.animaisTotais.filter((animal) => !this.animaisSelecionados.includes(animal))
    if (this.multiplos) this.marcaAnimaisSelecionados()
    this.divideLista()
    this.emiteNovos()
  }

  loadData(event) {
    this.event = event
    setTimeout(() => {
      if (this.animaisExibidos && this.animaisExibidos.length !== this.animaisTotais.length) {
        this.animaisExibidos = this.animaisExibidos.concat(this.animaisParciais[this.page])
        this.page++
      }
      event.target.complete()
      if (!this.animaisExibidos || this.animaisExibidos.length >= this.animaisTotais.length) {
        event.target.disabled = true
      }
    }, 100)
  }

  divideLista() {
    this.animaisParciais = []
    let i,
      j,
      pedaco = 40
    for (i = 0, j = this.animaisTotais.length; i < j; i += pedaco) {
      this.animaisParciais.push(this.animaisTotais.slice(i, i + pedaco))
    }
    this.animaisExibidos = this.animaisParciais[0]
    if (this.content) this.content.scrollToTop
  }

  ngOnChanges(changes) {
    this.event?.target.disabled ? (this.event.target.disabled = false) : ''
    if (changes.animais) this.animaisTotais = changes.animais.currentValue
    this.divideLista()
    this.page = 1
  }

  marcaAnimaisSelecionados() {
    this.animaisTotais.forEach((animal, index) => {
      if (this.hashsJaSelecionadas.includes(animal.hash)) {
        animal.checked = true
      } else animal.checked = false
    })
  }

  setaAnimais() {
    this.animaisNoManejo.forEach((animal) => {
      this.hashsJaSelecionadas.push(animal.hash)
      this.animaisSelecionados.push(animal)
    })
    this.animaisTotais = this.animais
  }

  abreAnimalNoManejo(animal, ev) {
    if (ev) ev.stopPropagation()
    if (this.multiplos && this.animaisNoManejo.includes(animal)) {
      this.irParaAnimal.emit(animal)
    }
  }

  selecionaAnimal(animal, ev) {
    if (ev) ev.stopPropagation()
    if (this.multiplos) {
      let desmarcando = false
      this.animaisSelecionados.forEach((ani, index) => {
        if (ani.hash == animal.hash) {
          desmarcando = true
          this.animaisSelecionados.splice(index, 1)
        }
      })
      if (!desmarcando) {
        this.animaisSelecionados.push(animal)
        this.hashsJaSelecionadas.push(animal.hash)
      }
      this.emiteNovos()
    } else {
      this.animaisEmiter.emit(animal)
    }
  }

  async mostraFicha(animal, e) {
    e.stopPropagation()
    const modal = this.modalCtrl.create({
      component: AnimaisSinglePage,
      cssClass: 'modal-pequeno',
      componentProps: {
        animal: animal
      }
    })
    ;(await modal).present()
  }

  emiteNovos() {
    this.animaisEmiter.emit(this.animaisSelecionados)
  }
}
