import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { TabsPage } from './pages/tabs/tabs.page'

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/entrar/entrar.module').then((m) => m.EntrarPageModule)
  },
  {
    path: '',
    component: TabsPage,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule)
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'bovino-contador',
        loadChildren: () => import('./pages/ferramentas/contador/contador.module').then((m) => m.ContadorPageModule)
      },
      {
        path: 'backups-manejos',
        loadChildren: () => import('./pages/manejos-backups/manejos-backups.module').then((m) => m.ManejosBackupsPageModule)
      },
      {
        path: 'backups-manejos-sem-processamento',
        loadChildren: () => import('./pages/manejos-backups-sem-processamento/manejos-backups-sem-processamento.module').then((m) => m.ManejosBackupsSemProcessamentoPageModule)
      },
      {
        path: 'animais-selecionar',
        loadChildren: () =>
          import('./pages/animais/animais-selecionar/animais-selecionar.module').then((m) => m.AnimaisSelecionarPageModule)
      },
      {
        path: 'manejo-sequencial',
        loadChildren: () =>
          import('./pages/manejos/manejo-sequencial/manejo-sequencial.module').then((m) => m.ManejosPageModule)
      },
      {
        path: 'manejo-sequencial-lote',
        loadChildren: () =>
          import('./pages/manejos/manejo-sequencial-lote/manejo-sequencial-lote.module').then((m) => m.ManejoSequencialLotesPageModule)
      },
      {
        path: 'manejo-sequencial-area',
        loadChildren: () =>
          import('./pages/manejos/manejo-sequencial-area/manejo-sequencial-area.module').then((m) => m.ManejoSequencialAreasPageModule)
      },
      {
        path: 'manejo-selecionar',
        loadChildren: () =>
          import('./pages/manejos/manejo-selecionar/manejo-selecionar.module').then((m) => m.ManejoSelecionarPageModule)
      },
      {
        path: 'animais-single',
        loadChildren: () =>
          import('./pages/animais/animais-single/animais-single.module').then((m) => m.AnimaisSinglePageModule)
      },
      {
        path: 'propriedades-selecionar',
        loadChildren: () =>
          import('./pages/propriedades/propriedades-selecionar/propriedades-selecionar.module').then(
            (m) => m.PropriedadesSelecionarPageModule
          )
      },
      {
        path: 'revisar-manejos',
        loadChildren: () =>
          import('./pages/manejos/revisar-solicitacao-manejos/revisar-manejos.module').then(
            (m) => m.RevisarManejosPageModule
          )
      },
      {
        path: 'solicitacao-manejos-pendentes',
        loadChildren: () =>
          import('./pages/manejos/solicitacao-manejos-pendentes/solicitacao-manejos-pendentes.module').then(
            (m) => m.SolicitacaoManejosPendentesPageModule
          )
      },
      {
        path: 'manejo-animais-selecionados',
        loadChildren: () =>
          import('./pages/manejos/manejo-animais-selecionados/manejo-animais-selecionados.module').then(
            (m) => m.ManejoAnimaisSelecionadosPageModule
          )
      },
      {
        path: 'manejo-lotes-selecionados',
        loadChildren: () =>
          import('./pages/manejos/manejo-lotes-selecionados/manejo-lotes-selecionados.module').then(
            (m) => m.ManejoLotesSelecionadosPageModule
          )
      },
      {
        path: 'manejo-areas-selecionadas',
        loadChildren: () =>
          import('./pages/manejos/manejo-areas-selecionadas/manejo-areas-selecionadas.module').then(
            (m) => m.ManejoAreasSelecionadasPageModule
          )
      },
      {
        path: 'estoque-nutricional',
        loadChildren: () => import('./pages/manejos/estoque-nutricional/estoque-nutricional.module').then( m => m.EstoqueNutricionalPageModule)
      },
      {
        path: 'estoque-farmacia',
        loadChildren: () => import('./pages/manejos/estoque-farmacia/estoque-farmacia.module').then( m => m.EstoqueFarmaciaPageModule)
      },
      {
        path: 'ajustes',
        loadChildren: () => import('./pages/ajustes/ajustes.module').then( m => m.AjustesPageModule)
      },
      {
        path: 'versionamento',
        loadChildren: () => import('./pages/versionamento/versionamento.module').then( m => m.VersionamentoPageModule)
      },
      {
        path: 'seletor-animais',
        loadChildren: () => import('./pages/seletor-animais/seletor-animais.module').then( m => m.SeletorAnimaisPageModule)
      },
      {
        path: 'modal-cadastrar-area',
        loadChildren: () => import('./pages/modal-cadastrar-area/modal-cadastrar-area.module').then( m => m.ModalCadastrarAreaPageModule)
      },
      {
        path: 'seletor-semen',
        loadChildren: () => import('./pages/seletor-semen/seletor-semen.module').then( m => m.SeletorSemenPageModule)
      }
    ]
  },
  {
    path: 'sqldebugger',
    loadChildren: () => import('./sqldebugger/sqldebugger.module').then( m => m.SQLDebuggerPageModule)
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
