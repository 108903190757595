import { ManejoAnimalModel } from '../../../utils/types'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'manejo-escore-corporal',
  templateUrl: './manejo-escore-corporal.component.html',
  styleUrls: ['./manejo-escore-corporal.component.scss']
})
export class ManejoEscoreCorporalComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel
  @Output() manejoChange = new EventEmitter()
  @Output() opcoes = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
