import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public var: any = {}

  constructor() {}

  set(key, value, callback = null) {
    this.var[key] = value
    if (callback) callback(true)
    return true
  }

  get(key, callback = null) {
    if (callback) callback(this.var[key])
    return this.var[key]
  }

  cleanup() {
    this.var = {}
  }
}
