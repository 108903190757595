import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'lista-vazia-fallback',
  templateUrl: './lista-vazia-fallback.component.html',
  styleUrls: ['./lista-vazia-fallback.component.scss']
})
export class ListaVaziaFallbackComponent implements OnInit {
  @Input() texto: string = 'texto'
  @Input() icone: string

  constructor() {}

  ngOnInit() {}
}
