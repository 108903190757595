import { Injectable } from '@angular/core'
import { ApiService } from './api'
import { BancoSQLiteService } from './banco-sqlite.service'
import { GlobalService } from './global.service'
import { SincronizadorService } from './sincronizador'
import { Preferences } from '@capacitor/preferences'
import { VastaRX } from '@vasta/rx'

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  public id_proprietario: number

  constructor(
    private global: GlobalService,
    private api: ApiService,
    private sql: BancoSQLiteService,
    private sincronizador: SincronizadorService
  ) {}

  async setUsuario(usuario) {
    await Preferences.set({
      key: 'user',
      value: JSON.stringify({
        usuario: usuario
      })
    })
    this.global.set('usuario', usuario)
  }

  async getUsuario() {
    const retorno = await Preferences.get({ key: 'user' })
    if (retorno.value) {
      const usuario = JSON.parse(retorno.value).usuario
      return usuario
    } else {
      return false
    }
  }

  async getPermissaoManejosReprodutivosAvancados() {
    const usuario = await this.getUsuario()
    if (usuario) {
      return Boolean(Number(usuario.metadados.manejos_reprodutivos_avancados))
    } else {
      return false
    }
  }

  async getManejosPermitidosUsuario() {
    const usuario = await this.getUsuario()
    if (usuario) {
      return usuario.metadados.manejos_permitidos
    } else {
      return []
    }
  }

  async getToken() {
    const retorno = await Preferences.get({ key: 'token' })
    if (retorno.value) {
      const token = JSON.parse(retorno.value).token
      return token
    } else {
      return false
    }
  }

  async salvarUsuarioGlobal() {
    const retorno = await Preferences.get({ key: 'user' })
    if (retorno.value) {
      this.global.set('usuario', JSON.parse(retorno.value).usuario)
    } else {
      //TODO: abrir modal de login
    }
  }

  async salvarTokenGlobal() {
    const retorno = await Preferences.get({ key: 'token' })
    if (retorno.value) {
      this.global.set('token', JSON.parse(retorno.value).token)
    }
  }

  async atualizaUsuario(): Promise<void> {
    try {
      const { peao, propriedades } = await this.api.getAuth('/usuario')
      const usuario = { ...peao, propriedades }
      usuario['data_verificacao'] = this.sql.getFormatedDate()
      VastaRX.setState('atualizouUsuario', true)
      setTimeout(() => {
        VastaRX.setState('atualizouUsuario', false)
      }, 100)
      await this.setUsuario(usuario)
    } catch (error) {
      console.error({ error })
      throw error
    }
  }

  async atualizaUsuarioESincroniza(): Promise<void> {
    try {
      const { peao, propriedades } = await this.api.getAuth('/usuario')
      const usuario = { ...peao, propriedades }
      usuario['data_verificacao'] = this.sql.getFormatedDate()
      VastaRX.setState('atualizouUsuario', true)
      setTimeout(() => {
        VastaRX.setState('atualizouUsuario', false)
      }, 100)
      await this.setUsuario(usuario)
      await this.sincronizador.realizarSincronizacao()
    } catch (error) {
      console.error({ error })
      throw error
    }
  }
}
