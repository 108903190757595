import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ConexoesBluetoothService } from '../../services/dispositivos-bluetooth/conexoes-bluetooth.service'
import { DispositivoConexao, TipoDispositivo } from '../../services/bluetooth/interfaces/bluetooth-interface';
import { ModalDispositivosBluetoothComponent } from '../modal-dispositivos-bluetooth/modal-dispositivos-bluetooth.component';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { VastaRX } from '@vasta/rx';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'conexao-bluetooth',
  templateUrl: './conexao-bluetooth.component.html',
  styleUrls: ['./conexao-bluetooth.component.scss']
})
export class ConexaoBluetoothComponent implements OnInit {

  public isConectandoDispositivo = false;

  public dispositivoConexaoRX: any
  public bastaoConectadoRX: any

  constructor(
    public conexoesBluetoothSeparadaCtrl: ConexoesBluetoothService,
    private modalCtrl: ModalController,
    private cd: ChangeDetectorRef,
    private utilsCtrl: UtilsService,
    private actionSheetController: ActionSheetController
  ) { }

  ngOnInit() {
    console.log('init bluetooth')
    /**
     * Vasta RX utilizado apenas nos services de conexões bluetooth isolados
     * Escuta sempre quando altera o status de conexão do dispositivo, quando um dispositivo é conectado ou desconectado
     */
    this.dispositivoConexaoRX = VastaRX.getState('dispositivo_conexao', (dispositivoConexao: DispositivoConexao) => {
      if (dispositivoConexao) {
        console.warn('dispositivoConexao: ',dispositivoConexao)
        
        const { dispositivoSelecionado, tipoDispositivo, statusConexao } = dispositivoConexao
        
        switch (statusConexao) {
          case 'conectando': 
            this.isConectandoDispositivo = true
            break
          case 'conectado': 
            if(tipoDispositivo === 'bastao') {
              this.conexoesBluetoothSeparadaCtrl.bastaoSelecionado = dispositivoSelecionado;
              this.conexoesBluetoothSeparadaCtrl.isBastaoConectado = true;
            } else if(tipoDispositivo === 'balanca') {
              this.conexoesBluetoothSeparadaCtrl.balancaSelecionada = dispositivoSelecionado
            }

            this.isConectandoDispositivo = false
            break
          case 'desconectado':
            if(tipoDispositivo === 'bastao') {
              this.conexoesBluetoothSeparadaCtrl.bastaoSelecionado = null
            } else if(tipoDispositivo === 'balanca') {
              this.conexoesBluetoothSeparadaCtrl.balancaSelecionada = null
              VastaRX.setState('peso_balanca', null)
            }
          case 'erro-conexao':
            if(tipoDispositivo === 'bastao') {
              this.conexoesBluetoothSeparadaCtrl.bastaoSelecionado = null

              this.utilsCtrl.exibirToast('Falha ao conectar bastão', 3000)
            } else if(tipoDispositivo === 'balanca') {
              this.conexoesBluetoothSeparadaCtrl.balancaSelecionada = null
              VastaRX.setState('peso_balanca', null)

              this.utilsCtrl.exibirToast('Falha ao conectar balança', 3000)
            }
          default:
            this.isConectandoDispositivo = false
            break
        }
      }

      this.cd.detectChanges()
    })

    this.bastaoConectadoRX = VastaRX.getState('bastao_conectado', async (bastao: any) => {
      if (bastao) {
        this.conexoesBluetoothSeparadaCtrl.bastaoSelecionado = bastao;
        this.conexoesBluetoothSeparadaCtrl.isBastaoConectado = true;
      }
    });
  }

  ngOnDestroy() {
    console.warn('🚀 ~ ngOnDestroy')

    VastaRX.unListen(this.dispositivoConexaoRX)
    VastaRX.unListen(this.bastaoConectadoRX)
  }

  async abrirEscolhaDeTipoDeDispositivo() {
    const botaoBalancaConectada = {
      text: 'Desconectar balança ' + this.conexoesBluetoothSeparadaCtrl.balancaSelecionada?.nome_modelo,
      icon: 'scale-outline',
      class: 'balanca-conectada',
      handler: () => this.conexoesBluetoothSeparadaCtrl.desconectarDispositivo(
        this.conexoesBluetoothSeparadaCtrl.balancaSelecionada.name,
        this.conexoesBluetoothSeparadaCtrl.balancaSelecionada.id
      )
    }

    const botaoBalancaDesconectada = {
      text: 'Balança',
      icon: 'scale-outline',
      class: 'balanca-conectada',
      handler: () => this.abrirModalBluetooth('balanca')
    }
    
    const botaoLeitorConectado = {
      text: 'Desconectar leitor ' + this.conexoesBluetoothSeparadaCtrl.bastaoSelecionado?.nome_modelo,
      icon: 'radio-outline',
      handler: () => this.conexoesBluetoothSeparadaCtrl.desconectarDispositivo(this.conexoesBluetoothSeparadaCtrl.bastaoSelecionado.name, this.conexoesBluetoothSeparadaCtrl.bastaoSelecionado.id)
    }

    const botaoLeitorDesconectado = {
      text: 'Leitor de Brinco',
      icon: 'radio-outline',
      handler: () => this.abrirModalBluetooth('bastao')
    }

    const actionSheet = await this.actionSheetController.create({
      header: 'Tipo do Dispositivo',
      buttons: [
        this.conexoesBluetoothSeparadaCtrl.bastaoSelecionado ? botaoLeitorConectado : botaoLeitorDesconectado,
        this.conexoesBluetoothSeparadaCtrl.balancaSelecionada ? botaoBalancaConectada : botaoBalancaDesconectada,
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });

    await actionSheet.present();
  }

  async abrirModalBluetooth(tipoDispositivo: TipoDispositivo) {
    const modal = await this.modalCtrl.create({
      component: ModalDispositivosBluetoothComponent,
      componentProps: {
        tipoDispositivo
      },
      cssClass: 'modal-narrow'
    });

    await modal.present();
  }
}
