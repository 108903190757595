export const labelsEstoqueFarmaciaTipo = {
  agulha: 'Agulha',
  analgesico: 'Analgésico',
  anestesico: 'Anestésico',
  'anti-helmintico': 'Anti-helmíntico',
  'anti-hemorragico': 'Anti-hemorrágico',
  'anti-inflamatorio': 'Anti-inflamatório',
  'anti-inflamatorio-esteroidal': 'Anti-inflamatório Esteroidal',
  'anti-inflamatorio-nao-esteroidal-aine': 'Anti-inflamatório Não Esteroidal (AINE)',
  'anti-viral': 'Anti-Viral',
  antibacteriano: 'Antibacteriano',
  antibiotico: 'Antibiótico',
  anticoagulante: 'Anticoagulante',
  anticoccidiano: 'Anticoccidiano',
  antidiarreico: 'Antidiarreico',
  antiinfeccioso: 'Antiinfeccioso',
  antimicrobiano: 'Antimicrobiano',
  antiparasitario: 'Antiparasitário',
  antisseptico: 'Antisséptico',
  antitoxico: 'Antitóxico',
  'brinco-mosquicida': 'Brinco Mosquicida',
  carrapaticida: 'Carrapaticida',
  diuretico: 'Diurético',
  ectocida: 'Ectocida',
  ectoparasiticida: 'Ectoparasiticida',
  endectocida: 'Endectocida',
  endectoparasiticida: 'Endectoparasiticida',
  endoparasiticida: 'Endoparasiticida',
  'equipamentos-diversos': 'Equipamentos diversos',
  hormonio: 'Hormônio',
  larvicida: 'Larvicida',
  'lubrificante-mineral': 'Lubrificante mineral',
  luvas: 'Luvas',
  'material-cirurgico': 'Material cirúrgico',
  'modificador-organico': 'Modificador orgânico',
  'outros-materiais-cirurgicos': 'Outros materiais cirúrgicos',
  'outros-medicamentos': 'Outros medicamentos',
  reproducao: 'Reprodução',
  sedativo: 'Sedativo',
  selante: 'Selante',
  seringa: 'Seringa',
  'soro-hidratante': 'Soro Hidratante',
  suplemento: 'Suplemento',
  vacina: 'Vacina',
  vermifugo: 'Vermífugo',
  vidraria: 'Vidraria',
  vitamina: 'Vitamina'
}
