import { ManejoAnimalModel } from '../../utils/types';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'manejo-descarte',
  templateUrl: './manejo-descarte.component.html',
  styleUrls: ['./manejo-descarte.component.scss'],
})
export class ManejoDescarteComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel;
  @Output() manejoChange = new EventEmitter();
  @Output() opcoes = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getOpcaoSelecionada(opcao: '1' | '0') {
    this.manejo.descarte = opcao;
    this.emit()
  }

  async openActionSheet() {
    this.opcoes.emit()
  }

  emit() {
    this.manejoChange.emit(this.manejo)
  }
}
