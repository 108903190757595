import { PayloadCadastrarLoteModel } from '../utils/types'
import { Injectable } from '@angular/core'
import { Preferences } from '@capacitor/preferences'
import { BancoSQLiteService } from './banco-sqlite.service'
import { GlobalService } from './global.service'
import { NovoFiltro, Ordenacao, Paginacao } from '../components/vasta-lista/vasta-lista.component'
import { sqlFilter } from '../utils/sqlUtils'

type PayloadLoteModel = {
  aptidao: string
  created_at: string
  data_entrada_area: string
  edited: number
  especie: string
  finalidade: string
  gmd: any
  hash: string
  hash_area: string
  id: number
  id_propriedade: number
  nome: string
  producao_leiteira: any
  sistema_criacao: string
  status: number
  sync: number
  updated_at: string
}

type PayloadMetaConfinamnetoLote = {
  chave: string
  created_at: string
  edited: number
  hash: string
  hash_propriedade: string
  id: number
  id_propriedade: number
  status: number
  sync: number
  updated_at: string
  valor: string
}

@Injectable({
  providedIn: 'root'
})
export class LotesService {
  constructor(private db: BancoSQLiteService, private global: GlobalService) {}

  async criarLote(payloadLote: PayloadCadastrarLoteModel) {
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;

    await this.db.run({
      query: `
        INSERT INTO gestor_lotes (
          hash,
					sync,
					edited,
					id_propriedade, 
					hash_area, 
					aptidao, 
          nome,
          data_entrada_area, 
					finalidade, 
          sistema_criacao,
          especie,
					status, 
					created_at, 
					updated_at
        )
        VALUES (
          '${payloadLote.hash}',
          0,
          0,
          ${idPropriedade},
          '${payloadLote.area_hash}',
          '${payloadLote.aptidao}',
          '${payloadLote.nome}',
          '${this.db.getFormatedDate()}',
          '${payloadLote.finalidade}',
          '${payloadLote.sistema_criacao}',
          'bovino',
          1,
          '${this.db.getFormatedDate()}',
          '${this.db.getFormatedDate()}'
        )
      `
    });

    await this.salvarMetaConfinamento(payloadLote, idPropriedade)
  }

  async salvarMetaConfinamento(payloadLote: PayloadCadastrarLoteModel, idPropriedade: number) {
     let frase = `
      INSERT INTO gestor_propriedades_metas (
        hash,
        sync,
        edited,
        id_propriedade,
        
        hash_propriedade,
        chave,
        valor,
        
        status,
        created_at,
        updated_at
      ) VALUES
    `;
     let inserts = [];
     inserts.push(`(
		  '${payloadLote.hash}:confinamento',
			0,
			'${1}',
			'${idPropriedade}',
			'${idPropriedade}', 
			'${payloadLote.hash}:confinamento',
			'${payloadLote.confinamento}', 
			1, 
			'${this.db.getFormatedDate()}', 
			'${this.db.getFormatedDate()}')`);
     frase += inserts.join(',');
     if (inserts.length) return await this.db.run({ query: frase });
  }

  async getLotes(opcoes?: {
    filtros?: NovoFiltro
    ordenacao?: Ordenacao
    paginacao?: Paginacao
    termo?: string
    hashs?: string[]
    hashNotIn?: string[]
  }) {
    const { termo, hashs, hashNotIn, filtros, ordenacao, paginacao } = opcoes || {}

    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;

    const {
      safeSelectIn,
      safeEquals,
      safeSelectNotIn,
      safeLike,
      safeNumberRange,
      safeDateRange,
      safeOrderBy,
    } = sqlFilter

    return await this.db.safeQuery({
      query: `
      WITH datas_pesos AS (
          SELECT MAX(data_pesagem) as data_pesagem, hash_animal
          FROM gestor_pesagens
          WHERE gestor_pesagens.status = 1
          AND gestor_pesagens.peso <> ''
          AND gestor_pesagens.peso IS NOT NULL
          AND gestor_pesagens.peso > 0
          AND gestor_pesagens.id_propriedade = {{idPropriedade}}
          GROUP BY hash_animal
      ),
      
      pesagens AS (
          SELECT
          gestor_pesagens.hash_animal,
          SUM(gestor_pesagens.peso) as peso_total,
          AVG(gestor_pesagens.peso) as peso_medio,
          SUM(gestor_pesagens.peso) / 30 as peso_arroba,
          AVG(CASE WHEN gestor_pesagens.gmd IS NOT NULL AND gestor_pesagens.gmd > 0 THEN gestor_pesagens.gmd ELSE NULL END) gmd,
          gestor_lotes.hash as hash_lote
          FROM gestor_pesagens
              INNER JOIN gestor_animais ON gestor_animais.status = 1 AND gestor_animais.hash = gestor_pesagens.hash_animal 
                AND gestor_animais.situacao = 'vivo'
                LEFT JOIN gestor_lotes ON gestor_lotes.hash = gestor_animais.hash_lote AND gestor_lotes.status = 1
              INNER JOIN datas_pesos ON datas_pesos.hash_animal = gestor_pesagens.hash_animal
              AND datas_pesos.data_pesagem = gestor_pesagens.data_pesagem
          WHERE gestor_pesagens.status = 1
          AND gestor_pesagens.id_propriedade = {{idPropriedade}}
          GROUP BY gestor_lotes.hash
      ),
      
      animais AS (
        SELECT COUNT(1) as qtd,
        gestor_lotes.id_propriedade,
        COALESCE(gestor_lotes.hash, 'sem_lote') as hash_lote,
        pesagens.peso_total,
        pesagens.peso_medio,
        pesagens.peso_arroba,
        pesagens.gmd
        FROM gestor_animais
            LEFT JOIN gestor_lotes ON gestor_lotes.hash = gestor_animais.hash_lote AND gestor_lotes.status = 1
              AND gestor_lotes.id_propriedade = {{idPropriedade}}
            LEFT JOIN pesagens ON pesagens.hash_lote = gestor_animais.hash_lote
        WHERE gestor_animais.status = 1
        AND gestor_animais.situacao = 'vivo'
        AND gestor_animais.id_propriedade = {{idPropriedade}}
        ${sqlFilter.safeNotNull({coluna: 'gestor_lotes.hash', valor: filtros?.hash ? 'true' : ''})}
        GROUP BY gestor_lotes.hash
      )

      SELECT gestor_lotes.hash as hash,
        gestor_lotes.id_propriedade,
        gestor_lotes.data_entrada_area,
        gestor_lotes.hash_area,
        gestor_lotes.nome,
        gestor_lotes.sistema_criacao,
        gestor_lotes.aptidao,
        gestor_lotes.finalidade,
        -- gestor_lotes.observacao,
        gestor_lotes.status,
        gestor_lotes.created_at,
        gestor_lotes.updated_at,
        
        gestor_propriedades_metas.valor as confinamento,
        
        animais.qtd as qtd_animais,
        animais.peso_total as total_peso,
        animais.peso_arroba as peso_arroba,
        animais.peso_medio as peso_medio,
        animais.gmd as gmd,
        
        gestor_areas.nome as nome_area
        FROM gestor_lotes
        LEFT JOIN gestor_propriedades_metas 
          ON gestor_propriedades_metas.hash = gestor_lotes.hash || ':confinamento'
            LEFT JOIN animais ON animais.hash_lote = gestor_lotes.hash
        LEFT JOIN gestor_areas 
            ON gestor_lotes.hash_area = gestor_areas.hash
        WHERE gestor_lotes.status = 1
        AND gestor_lotes.id_propriedade = {{idPropriedade}}

      ${termo ? `AND ( gestor_lotes.nome LIKE '%${termo}%' )` : ``}
      ${hashs && hashs.length ? `AND ( gestor_lotes.hash IN ("${hashs.join('","')}") )` : ``}
      ${hashNotIn && hashNotIn.length ? `AND ( gestor_lotes.hash NOT IN ("${hashNotIn.join('","')}") )` : ``}


      ${filtros?.hash_not_in?.length ? safeSelectNotIn({ coluna: 'gestor_lotes.hash', tag: '{{hash_not_in}}', valor: filtros?.hash_not_in }) : ''}
      ${safeSelectIn({ coluna: 'gestor_lotes.hash', tag: '{{hash}}', valor: filtros?.hash })}
      ${safeLike({ coluna: 'gestor_lotes.nome', tag: '{{nome}}', valor: filtros?.nome })}
      ${safeNumberRange({ coluna: 'animais.qtd', tag: '{{qtd_animais}}', valor: filtros?.qtd_animais })}
      ${safeNumberRange({ coluna: 'animais.peso_total', tag: '{{peso_total}}', valor: filtros?.total_peso })}
      ${safeNumberRange({ coluna: 'animais.gmd', tag: '{{gmd}}', valor: filtros?.gmd as unknown as string })}
      ${safeNumberRange({ coluna: 'animais.peso_arroba', tag: '{{peso_arroba}}', valor: filtros?.peso_arroba })}
      ${safeNumberRange({ coluna: 'animais.peso_medio', tag: '{{peso_medio}}', valor: filtros?.peso_medio })}
      ${safeLike({ coluna: 'gestor_lotes.finalidade', tag: '{{finalidade}}', valor: filtros?.finalidade })}
      ${safeLike({
        coluna: 'gestor_lotes.sistema_criacao',
        tag: '{{sistema_criacao}}',
        valor: filtros?.sistema_criacao
      })}
      ${safeLike({ coluna: 'gestor_lotes.aptidao', tag: '{{aptidao}}', valor: filtros?.aptidao })}
      ${safeLike({ coluna: 'gestor_lotes.sync', tag: '{{sync}}', valor: filtros?.sync })}
      ${safeSelectIn({ coluna: 'gestor_lotes.hash_area', tag: '{{nome_area}}', valor: filtros?.nome_area })}
      ${safeSelectIn({ coluna: 'gestor_lotes.hash_area', tag: '{{hash_area}}', valor: filtros?.hash_area })}
      ${safeDateRange({
        coluna: 'data_entrada_area',
        tag: '{{data_entrada_area}}',
        valor: filtros?.data_entrada_area
      })}
      ${safeDateRange({ coluna: 'gestor_lotes.created_at', tag: '{{created_at}}', valor: filtros?.created_at })}
      ${safeDateRange({ coluna: 'gestor_lotes.updated_at', tag: '{{updated_at}}', valor: filtros?.updated_at })}
      ${safeLike({ coluna: 'gestor_lotes.observacao', tag: '{{observacao}}', valor: filtros?.observacao})}
			${safeEquals({ coluna: 'confinamento', tag: '{{confinamento}}', valor: filtros?.confinamento })}

      GROUP BY gestor_lotes.hash

        
      ${safeOrderBy({
        coluna: ordenacao?.coluna,
        direcao: ordenacao?.direcao,
        prefixo: true,
        tableCase: {
          nome: 'gestor_lotes'
        }
      })}
      `,
      params: {
        idPropriedade,
        ...(filtros || {}),
        ...(ordenacao || {}),
        ...(paginacao || {}),
      }
    });
  }

  async getMetasConfinamentos() {
    const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id;
    console.log('idPropriedade: ',idPropriedade);
    
    return await this.db.run({
      query: `SELECT * FROM gestor_propriedades_metas WHERE id_propriedade = ${idPropriedade} AND status = 1`
    })
  }


  async marcarLotesComoSincronizados() {
    try {
      const idPropriedade = JSON.parse((await Preferences.get({ key: 'propriedade-selecionada' })).value).id
      await this.db.run({
        query: `
          UPDATE gestor_lotes
          SET sync = 1
          WHERE id_propriedade = ${idPropriedade}
          AND sync = 0
        `
      })
    } catch(error) {
      console.error(error);
    }
  }
}