import {
  DispositivosComServicesSeparados,
  ServicoPorNomeDispositivo
} from '../../components/modal-dispositivos-bluetooth/dispositivos-bluetooth.data'
import { Injectable } from '@angular/core'
import { DispositivoSelecionado, TipoBluetooth } from '../../services/bluetooth/interfaces/bluetooth-interface'
import { GlobalService } from '../../services/global.service'
import { App } from '@capacitor/app'
import { BleClient } from '@capacitor-community/bluetooth-le'
import { Device } from '@capacitor/device'
import { LeitorAllFlexRS420Service } from './leitores/leitor-cl-allflex-rs420.service'
import { LeitorAllFlexAWR250Service } from './leitores/leitor-le-allflex-awr250.service'
import { LeitorAcoresSmartReaderService } from './leitores/leitor-cl-acores-smartreader.service'
import { LeitorTruTestSRS2Service } from './leitores/leitor-cl-trutest-srs2.service'
import { LeitorTruTestXRS2Service } from './leitores/leitor-cl-trutest-xrs2.service'
import { LeitorSigmaciaBT05Service } from './leitores/leitor-cl-sigmacia-bt05.service'
import { LeitorAllTagsAT05Service } from './leitores/leitor-cl-alltags-at05.service'
import { BalancaTruTestId5000Service } from './balancas/balanca-cl-trutest-id5000.service'
import { BalancaIcv3Service } from './balancas/balanca-cl-sigmacia-icv3.service'
import { BalancaTruTestS2Service } from './balancas/balanca-le-trutest-s2.service'
import { BalancaSigmaciaIcvService } from './balancas/balanca-cl-sigmacia-icv.service.service'
import { BalancaAcoresBPB085ioTService } from './balancas/balanca-le-acores-bpb085-iot.service'
import { BalancaAcoresHeavyDutyService } from './balancas/balanca-cl-acores-heavy-duty.service'
import { BalancaPrixMGR4000CampoService } from './balancas/balanca-le-prix-mgr4000campo.service'
import { BalancaGallagherW0Service } from './balancas/balanca-le-gallagher-w-0.service'
import { BalancaTruTestS3Service } from './balancas/balanca-le-trutest-s3.service'
import { BalancaTruTestEW7IService } from './balancas/balanca-cl-trutest-ew7i.service'
import { BalancaPrixMGRCampoService } from './balancas/balanca-le-prix-mgrcampo.service'
import { BalancaTruTestJR5000Service } from './balancas/balanca-cl-trutest-jr5000.service'
import { BalancaTruTestXR5000Service } from './balancas/balanca-cl-trutest-xr5000.service'
import { BalancaValfranVFPlusService } from './balancas/balanca-cl-valfran-vf-plus.service'
import { BalancaCoimmaKM3PlusService } from './balancas/balanca-cl-coimma-km3plus.service'
import { LeitorTruTestXRSService } from './leitores/leitor-cl-trutest-xrs.service'
import { BalancaAcoresHDEasyService } from './balancas/balanca-cl-acores-hd-easy.service'
import { BalancaLEPesentiPese2090Service } from './balancas/balanca-le-pesenti-pese-2090.service'
import { BalancaAnimallTagAT55 } from './balancas/balanca-cl-animalltag-at55.service'
import { BalancaAnimallTagAT60 } from './balancas/balanca-cl-animalltag-at60.service'
import { VastaRX } from '@vasta/rx'

export type DispositivoBluetoothGenerico = {
  address?: string;
  class?: number;
  id?: string;
  deviceId?: string;
  nome_modelo?: string;
  tipoBluetooth?: TipoBluetooth
  valor_generico?: string
  name?: DispositivosComServicesSeparados;
}

@Injectable({
  providedIn: 'root'
})
export class ConexoesBluetoothService {

  public isBastaoConectado: boolean;
  public balancaSelecionada: DispositivoBluetoothGenerico
  public bastaoSelecionado: DispositivoBluetoothGenerico
  public mensagemDeStatusBluetooth: string = ''

  constructor(
    private rs420Service: LeitorAllFlexRS420Service,
    private balancaEW7IService: BalancaTruTestEW7IService,
    private balancaId5000Service: BalancaTruTestId5000Service,
    private balancaJR5000Service: BalancaTruTestJR5000Service,
    private balancaXR5000Service: BalancaTruTestXR5000Service,
    private balancaHeavyDutyService: BalancaAcoresHeavyDutyService,
    private balancaHDEasyService: BalancaAcoresHDEasyService,
    private balancaAT55: BalancaAnimallTagAT55,
    private balancaAT60: BalancaAnimallTagAT60,
    private balancaIcv3Service: BalancaIcv3Service,
    private balancaSigmaciaIcvService: BalancaSigmaciaIcvService,
    private balancaS2: BalancaTruTestS2Service,
    private balancaS3: BalancaTruTestS3Service,
    private balancaW0: BalancaGallagherW0Service,
    private balancaMGR4000Campo: BalancaPrixMGR4000CampoService,
    private balancaMGRCampo: BalancaPrixMGRCampoService,
    private balancaAcoresIOT: BalancaAcoresBPB085ioTService,
    private leitorAllTagsAt05: LeitorAllTagsAT05Service,
    private leitorAwr250Service: LeitorAllFlexAWR250Service,
    private balancaValfranVFPlus: BalancaValfranVFPlusService,
    private balancaCoimmaKM3Plus: BalancaCoimmaKM3PlusService,
    private balancaLePese2090: BalancaLEPesentiPese2090Service,
    private leitorSmarReader: LeitorAcoresSmartReaderService,
    private leitorTruTestXRSService: LeitorTruTestXRSService,
    private leitorTruTestSRS2Service: LeitorTruTestSRS2Service,
    private leitorTruTestXRS2Service: LeitorTruTestXRS2Service,
    private leitorSigmaciaBT05: LeitorSigmaciaBT05Service,
    private globalCtrl: GlobalService
  ) {
    this.initBluetooth()
    this.observarDispositivosEmparelhadosExternamente()

    VastaRX.getState('observer_mensagem_status_conexao', (options) => {
      if(!options) return

      if (options?.mensagemStatusConexao && !options?.isEsconderMensagem) {
        this.mensagemDeStatusBluetooth = options.mensagemStatusConexao

      } else if(options.isEsconderMensagem) {
        if(options.mensagemStatusConexao) {
          this.mensagemDeStatusBluetooth = options.mensagemStatusConexao
        }

        setTimeout(() => {
          this.mensagemDeStatusBluetooth = null
        }, 3000)
      }
    })
  }

  async initBluetooth(): Promise<void> {
    const info = await Device.getInfo()
    console.log('initBluetooth', info.platform)
    if (info.platform) {
      const plataforma: 'android' | 'ios' | 'web' = info.platform
    
      if (plataforma !== 'web') {
        await BleClient.initialize()
      }
      if (plataforma == 'android') {
        BleClient.enable()
      }
    }
  }

  servicos: ServicoPorNomeDispositivo = {
    RS420_: this.rs420Service,
    EziWeigh: this.balancaEW7IService,
    ID5000: this.balancaId5000Service,
    JR5000: this.balancaJR5000Service,
    XR5000: this.balancaXR5000Service,
    ACR_HD_: this.balancaHeavyDutyService,
    ACR_HD_EASY: this.balancaHDEasyService,
    AT55: this.balancaAT55,
    AT60: this.balancaAT60,
    "XRS": this.leitorTruTestXRSService,
    "SRS2": this.leitorTruTestSRS2Service,
    "XRS2": this.leitorTruTestXRS2Service,
    BPB085: this.balancaAcoresIOT,
    BTBOV3000: this.balancaIcv3Service,
    SIGMACIA_ICV: this.balancaSigmaciaIcvService,
    "S2 ": this.balancaS2,
    "S3 ": this.balancaS3,
    "W-0-": this.balancaW0,
    "MGR": this.balancaMGR4000Campo,
    "CSB": this.balancaMGRCampo,
    AWR250_: this.leitorAwr250Service,
    VALFRAN: this.balancaValfranVFPlus,
    ALLTAGS: this.leitorAllTagsAt05,
    'SmartReader ': this.leitorSmarReader,
    'BT-05 ': this.leitorSigmaciaBT05,
    'Pesenti BLE': this.balancaLePese2090,
    'KM3-PLUS': this.balancaCoimmaKM3Plus
  }

  async conectarDispositivo(nomeServico: DispositivosComServicesSeparados): Promise<void> {
    await this.servicos[nomeServico].estabelecerConexao()
  }

  async desconectarDispositivo(nomeServico: DispositivosComServicesSeparados, idDispositivo: string): Promise<void> {
    await this.servicos[nomeServico].desconectarDispositivo({ deviceId: idDispositivo })
  }

  observarDispositivosEmparelhadosExternamente(): void {
    App.addListener('appStateChange', async (data) => {
      if (data.isActive) {
        await this.tentarConectarDispositivoNovamente()
      }
    })
  }

  async tentarConectarDispositivoNovamente(): Promise<void> {
    const dispositivoComErroNoPareamento: DispositivoSelecionado = this.globalCtrl.get(
      'dispositivo-selecionado-com-erro-pareamento-local'
    )

    if (dispositivoComErroNoPareamento?.name) { 
      await this.conectarDispositivo(dispositivoComErroNoPareamento.name)
    }
  }
}
