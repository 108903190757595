import { Injectable } from '@angular/core'
import { LoadingController, ToastController } from '@ionic/angular'
import { NovoFiltro } from '../components/vasta-lista/vasta-lista.component'

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  filtroAnimais: {[key: number]: NovoFiltro} = {}

  constructor(
    private toastCtrl: ToastController,
    private loaderCtrl: LoadingController
  ) {}

  public async mostrarLoader(message) {
    const loaderCtrl = await this.loaderCtrl.create({ message })

    return loaderCtrl
  }

  async exibirToast(message: string, duration = 1500, position: 'top' | 'bottom' = 'top') {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      position,
      buttons: [
        {
          side: 'end',
          icon: 'close',
        }
      ]
    })
    await toast.present()
  }
}
