import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-imagem-antes-de-enviar',
  templateUrl: './imagem-antes-de-enviar.component.html',
  styleUrls: ['./imagem-antes-de-enviar.component.scss']
})
export class ImagemAntesDeEnviarComponent implements OnInit {
  public base64Imagem: any

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    console.log('base64imagem: ', this.base64Imagem)
  }

  adicionarImagem() {
    this.dismiss({
      base64Imagem: this.base64Imagem
    })
  }

  dismiss(value?: any) {
    this.modalCtrl.dismiss(value)
  }
}
