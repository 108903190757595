export { BovinosListaComponent } from './animais/bovinos-lista/bovinos-lista.component'
export { FichaAnimalSingleComponent } from './ficha-animal-single/ficha-animal-single.component'
export { SeletorLoteSingleComponent } from './seletor-lote-single/seletor-lote-single.component'
export { ManejoPesagemComponent } from './manejo-pesagem/manejo-pesagem.component'
export { ListaVaziaFallbackComponent } from './lista-vazia-fallback/lista-vazia-fallback.component'
export { TagsComponent } from './tags/tags.component'
export { ManejoIdentificacaoEletronicaComponent } from './manejo-geral/manejo-identificacao-eletronica/manejo-identificacao-eletronica.component'
export { CadastrarAnimalComponent } from './cadastrar-animal/cadastrar-animal.component'
export { ListaRacasComponent } from './lista-racas/lista-racas.component'
export { ManejoRegistrarMorteComponent } from './manejo-geral/manejo-registrar-morte/manejo-registrar-morte.component'
export { ImagemAntesDeEnviarComponent } from './imagem-antes-de-enviar/imagem-antes-de-enviar.component'
export { ModalDispositivosBluetoothComponent } from './modal-dispositivos-bluetooth/modal-dispositivos-bluetooth.component'
export { CadastrarLoteComponent } from './cadastrar-lote/cadastrar-lote.component'
export { ManejoInseminacaoArtificialComponent } from './manejo-reprodutivo/manejo-inseminacao-artificial/manejo-inseminacao-artificial.component'
