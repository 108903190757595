import { AreaSelecionadaModel, ManejoAnimalModel } from '../../../utils/types';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SeletorAreasComponent } from '../../seletor-areas/seletor-areas.component';

@Component({
  selector: 'manejo-movimentacao-entre-areas',
  templateUrl: './manejo-movimentacao-entre-areas.component.html',
  styleUrls: ['./manejo-movimentacao-entre-areas.component.scss'],
})
export class ManejoMovimentacaoEntreAreasComponent implements OnInit {
  @Input() manejo: ManejoAnimalModel;
  @Output() manejoChange = new EventEmitter();
  @Input('opcoesRapidasAreas') opcoesRapidasAreas: AreaSelecionadaModel[];
  @Output() opcoesRapidasAreasChange = new EventEmitter();
  @Input('areaAtual') areaAtual: string;
  @Output() opcoes = new EventEmitter();

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  async openModalListaArea() {
    const modal = await this.modalCtrl.create({
      component: SeletorAreasComponent,
      componentProps: {
      },
      cssClass: 'modal-narrow'
    })

    await modal.present()
    const { data: area } = await modal.onDidDismiss()
    if(area) {
      this.selecionarArea(area);
    }
  }

  limparManejo() {
    this.manejo.hash_area_destino = null
    this.manejo.nome_area_destino = null
    this.emit()
  }

  selecionarArea(area: AreaSelecionadaModel) {
    this.manejo.hash_area_destino = area.hash
    this.manejo.nome_area_destino = area.nome
    this.processarOpcoesRapidasDeAreas(area)
    this.emit()
  }

  processarOpcoesRapidasDeAreas(area: AreaSelecionadaModel) {
    if(this.opcoesRapidasAreas?.length) {
      const areaJaExiste = this.opcoesRapidasAreas.find(l => l.hash === area.hash)
      if(!areaJaExiste) {
        this.opcoesRapidasAreas.push(area)
      }
    } else {
      this.opcoesRapidasAreas = [area]
    }
  }

  async abrirOpcoesDeManejo() {
    this.opcoes.emit()
  }

  emit() {
    this.opcoesRapidasAreasChange.emit(this.opcoesRapidasAreas)
    this.manejoChange.emit(this.manejo)
  }
}
