import { Injectable } from '@angular/core'
import { GlobalService } from '../global.service'
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http'
import { JWTCode, PATH, URL_CLOUD_FUNCTONS, URL_CLOUD_FUNCTONS_CAMPO } from './config'
import { Preferences } from '@capacitor/preferences'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private global: GlobalService, public http: HttpClient) {}

  async postAuth(endpoint = '', dados, retentativa = false) {
    return new Promise((resolve, reject) => {
      var token = this.global.get('token')

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
      this.http.post(URL_CLOUD_FUNCTONS + PATH + endpoint, dados, { headers }).subscribe(
        (data) => resolve(data),
        async (err) => {
          if (err.error.name === JWTCode && !retentativa && err.error.token) {
            await this.setToken(err.error.token)
            const response = await this.postAuth(endpoint, dados, true)
            resolve(response)
          } else reject(err.error)
        }
      )
    })
  }

  getAuth(endpoint = '', retentativa = false, campo = false): Promise<any> {
    return new Promise((resolve, reject) => {
      let token = this.global.get('token')
      let headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + token })

      const requisicaoObservable = this.http.get((campo ? URL_CLOUD_FUNCTONS_CAMPO : URL_CLOUD_FUNCTONS) + PATH + endpoint, { headers }).subscribe(
        (data) => {
          resolve(data)
          requisicaoObservable.unsubscribe()
        },
        async (err: HttpErrorResponse) => {
          if (err.error.name === JWTCode && !retentativa && err.error.token) {
            await this.setToken(err.error.token)
            const response = await this.getAuth(endpoint, true)
            resolve(response)
          } else reject(err.error)
        }
      )
    })
  }

  async getArquivoSyncAdonis(endpoint = '', callback,): Promise<any> {
    // const PATH = '/api/v2/peao'
    
    return new Promise((resolve, reject) => {
      let token = this.global.get('token')
      const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + token)
      const req = new HttpRequest('GET', URL_CLOUD_FUNCTONS + PATH + endpoint, {
        headers,
        reportProgress: true,
        responseType: 'text'
      })

      const requisicaoObservable = this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.DownloadProgress) callback(Math.round(100 * (event.loaded / event.total)))
          else if (event.type === HttpEventType.Response) {
            resolve(JSON.parse(event.body))
            requisicaoObservable.unsubscribe()
          }
        },
        (err: any) => {
          reject(err)
          requisicaoObservable.unsubscribe()
        }
      )
    })
  }

  decompressJsonArray(lista: { [key: string]: any[] }): {[key: string]: any }[] {
    const chaves = lista?.__keys
    const arrayOriginal = {}

    chaves.map(key => {
        let index = 0
        lista[key].map(() => {
            arrayOriginal[index] = arrayOriginal[index] || {}
            arrayOriginal[index][key] = lista[key][index]
            index++
        })
    })

    return Object.values(arrayOriginal)
  }
  
  async getArquivoStorage(url = '', callback): Promise<any> {
    // const PATH = '/api/v2/peao'
    
    return new Promise((resolve, reject) => {
      const req = new HttpRequest('GET', url, {
        reportProgress: true,
        responseType: 'text'
      })

      const requisicaoObservable = this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.DownloadProgress) callback(Math.round(100 * (event.loaded / event.total)))
          else if (event.type === HttpEventType.Response) {
            const parseado = JSON.parse(event.body)
            Object.keys(parseado).map((id_propriedade) => {
              Object.keys(parseado[id_propriedade]).map((tabela) => {
                parseado[id_propriedade][tabela] = this.decompressJsonArray(parseado[id_propriedade][tabela])
              })
            })
            resolve(parseado)
            requisicaoObservable.unsubscribe()
          }
        },
        (err: any) => {
          reject(err)
          requisicaoObservable.unsubscribe()
        }
      )
    })
  }

  async setToken(token) {
    await Preferences.set({
      key: 'token',
      value: JSON.stringify({
        token: token
      })
    })
    this.global.set('token', token)
  }
}
