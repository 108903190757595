import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { BuscarService } from '../../services/buscar.service'
import { Racas } from '../../utils'
import Utils from '../../utils/utils'
import { ChaveRacas } from '../../utils/types'

@Component({
  selector: 'app-lista-racas',
  templateUrl: './lista-racas.component.html',
  styleUrls: ['./lista-racas.component.scss']
})
export class ListaRacasComponent implements OnInit {
  public isFiltroMultiplos = false
  public racas: any
  public termoBusca: string
  public propriedade = {
    nome: 'Nome da Fazenda'
  }
  public multilplosRacasSelecionadas: { [key in ChaveRacas]?: boolean } = {}
  public utils = Utils

  constructor(private buscarService: BuscarService, private modalCtrl: ModalController) {}

  ngOnInit() {
    this.racas = Racas
    this.buscarService.setListaCompleta(Object.values(Racas))
  }

  formataAnimais(animal) {
    return Object.keys(animal)
  }

  getObjetoRacasPreenchidoComChaveEValor(objetoRacasFiltrado) {
    let objetoFiltradoFormatado = {}
    for(const value of Object.values(objetoRacasFiltrado)) {
      const key = this.utils.getChavePorValor(Racas, value)
      objetoFiltradoFormatado[key] = value
    }
    return objetoFiltradoFormatado
  }

  buscar(termo: string) {
    let racasFiltradas = this.buscarService.searchOpcoes(termo)
    let racasFiltradaFormatadaEmObjeto = this.utils.converterArrayEmObjeto(racasFiltradas)
    let objetoFiltradoFormatado = this.getObjetoRacasPreenchidoComChaveEValor(racasFiltradaFormatadaEmObjeto)
    this.racas = objetoFiltradoFormatado
  }

  selecionarMultiplasRacas(event) {
    if(this.multilplosRacasSelecionadas[event]) {
      delete this.multilplosRacasSelecionadas[event]
    } else {
      this.multilplosRacasSelecionadas[event] = true
    }
  }

  selecionarAnimal(racaKey: ChaveRacas) {
    this.dismiss(racaKey, this.racas[racaKey])
  }
  
  confirmarSelecaoMultiplasRacas() {
    const racas = Object.keys(this.multilplosRacasSelecionadas)
    this.modalCtrl.dismiss({ racasKey: racas })
  }

  dismiss(racaKey?: ChaveRacas, racaValue?: string) {
    if(this.isFiltroMultiplos) {
      this.confirmarSelecaoMultiplasRacas()
    } else {
      this.modalCtrl.dismiss({ racaKey, racaValue })
    }
  }
}
