import { labelsExposicoes } from '../../../utils';
import { Exposicao } from '../../../utils/types';
import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-lista-exposicoes',
  templateUrl: './popover-lista-exposicoes.component.html',
  styleUrls: ['./popover-lista-exposicoes.component.scss'],
})
export class PopoverListaExposicoesComponent implements OnInit {
  public exposicoes: Exposicao[] = []

  public labelsExposicoes = labelsExposicoes

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  async selecionarExposicao(exposicao: Exposicao): Promise<void> {
    await this.popoverCtrl.dismiss(exposicao)
  }
}
