import { Component, OnInit } from '@angular/core'
import { Device } from '@capacitor/device'
import { ModalController } from '@ionic/angular'
import { GuiaDeUsoComponent } from '../guia-de-uso/guia-de-uso.component'
import {
  SelecionarDispositivoModel,
  ListaMarcasDispositivos,
  Leitores,
  Balancas,
  ValorNomeDispositivos,
  guiaPassosAWR250,
  guiaPassosKM3Plus,
  DispositivoModel,
  BalancasLista,
  LeitoresLista,
  DispositivosComServicesSeparados
} from './dispositivos-bluetooth.data'
import Utils from '../../utils/utils'
import { ConexoesBluetoothService } from '../../services/dispositivos-bluetooth/conexoes-bluetooth.service'
import { Preferences } from '@capacitor/preferences'
@Component({
  selector: 'app-modal-dispositivos-bluetooth',
  templateUrl: './modal-dispositivos-bluetooth.component.html',
  styleUrls: ['./modal-dispositivos-bluetooth.component.scss'],
})
export class ModalDispositivosBluetoothComponent implements OnInit {

  public tipoDispositivo: 'leitor' | 'balanca' = 'balanca'
  public marcasDispositivos = ListaMarcasDispositivos
  public dispositivos: (SelecionarDispositivoModel<BalancasLista> | SelecionarDispositivoModel<LeitoresLista>)[]
  public dispositivoSelecionado: DispositivoModel
  public utils = Utils
  balancaAnterior = ''
  leitorAnterior = ''

  constructor(
    private modalCtrl: ModalController, 
    private conexoesBluetoothSeparadaCtrl: ConexoesBluetoothService
  ) { }

  async ngOnInit() {
    if(this.tipoDispositivo === 'balanca') {
      this.dispositivos = Balancas
    } else {
      this.dispositivos = Leitores
    }

    const info = await Device.getInfo()

    if(info.platform === 'ios') {
      this.removerDispositivosSerial()
    }

    this.removerMarcasNaoUtilizadas();

    this.balancaAnterior = (await Preferences.get({key: 'balancaAnterior'})).value
    this.leitorAnterior = (await Preferences.get({key: 'leitorAnterior'})).value
  }

  removerMarcasNaoUtilizadas() {
    let marcasInclusas = []
    for(const dispositivo of this.dispositivos) {
      if(!marcasInclusas.includes(dispositivo.marca)) {
        marcasInclusas.push(dispositivo.marca);
      }
    }

    this.marcasDispositivos = marcasInclusas;
  }

  removerDispositivosSerial() {
    const dispositivosFiltrados = this.dispositivos.filter((device) => device.tipoBluetooth !== 'serial')
    this.dispositivos = dispositivosFiltrados
  }

  isAlgumDispositivoComEssaMarca(marca: string) {
    return this.dispositivos.some(dispositivo => dispositivo.marca === marca)
  }
  
  async selecionaDispositivo(dispositivo: DispositivoModel) {

    this.dispositivoSelecionado = dispositivo

    if (this.tipoDispositivo === 'balanca') {
      Preferences.set({key: 'balancaAnterior', value: this.dispositivoSelecionado.nome})
    } else {
      Preferences.set({key: 'leitorAnterior', value: this.dispositivoSelecionado.nome})
    }

    if(this.dispositivoSelecionado.value.includes(ValorNomeDispositivos.leitores.AWR250) || this.dispositivoSelecionado.value.includes(ValorNomeDispositivos.balancas.KM3PLUS)) {
      const valorGenericoDoDispositivo = this.utils.getValorGenericoDispositivo(this.dispositivoSelecionado.value)
      
      const props = {
        'AWR250_': {
          titulo: 'Guia de uso Leitor AWR250',
          passosParaUso: guiaPassosAWR250
        },
        'KM3-PLUS': {
          titulo: 'Guia de uso Balança KM3 Plus',
          passosParaUso: guiaPassosKM3Plus
        }
      }
      const modal = await this.modalCtrl.create({
        component: GuiaDeUsoComponent,
        componentProps: props[valorGenericoDoDispositivo],
        cssClass: 'modal-narrow'
      });

      await modal.present()
      await modal.onDidDismiss()
    }

    const valueDispositivo: DispositivosComServicesSeparados = this.dispositivoSelecionado.value
    this.conexoesBluetoothSeparadaCtrl.conectarDispositivo(valueDispositivo)
    this.dismiss()
  }
  
  async conectarDispositivo() {
    const valueDispositivo: DispositivosComServicesSeparados = this.dispositivoSelecionado.value
    this.conexoesBluetoothSeparadaCtrl.conectarDispositivo(valueDispositivo)
    this.dismiss()
  }

  dismiss(value?: string) {
    this.modalCtrl.dismiss(value)
  }
}
