import { Leitores, ValorNomeDispositivos } from '../../../components/modal-dispositivos-bluetooth/dispositivos-bluetooth.data'
import { setObserverMensagemStatusConexao } from '../../../services/bluetooth/dispositivos/observer-dispositivos'
import { ErrorBluetooth } from '../../../services/bluetooth/Error/ErrorBluetooth'
import {
  BluetoothConexaoDispositivoIsolado,
  DispositivoConexao,
  DispositivoLowenergy,
  DispositivoSelecionado,
  OpcoesBuscarPorDispositivos,
  OpcoesBuscarPorDispositivosLowEnergy,
  OpcoesConexaoBluetooth,
  OpcoesNotificacoesBluetoothLowEnergy,
  ResultadoConexaoBluetooth,
  StatusConexao
} from '../../../services/bluetooth/interfaces/bluetooth-interface'
import { GlobalService } from '../../../services/global.service'
import { UtilsService } from '../../../services/utils.service'
import { Injectable } from '@angular/core'
import { BleClient } from '@capacitor-community/bluetooth-le'
import { VastaRX } from '@vasta/rx'
import Utils from '../../../utils/utils'
import { HelperLowEnergyService } from '../_helpers/helper-low-energy.service'

@Injectable({
  providedIn: 'root'
})
export class LeitorAllFlexAWR250Service implements BluetoothConexaoDispositivoIsolado {
  private idAparelho = ValorNomeDispositivos.leitores.AWR250
  private dadosAparelho = Leitores.find((leitor) => leitor.value === this.idAparelho)
  private dadosLeitorConexao: DispositivoSelecionado = {
    name: this.idAparelho,
    nome_modelo: this.dadosAparelho.nome,
    id: null
  }

  private isDesconectandoDispositivoManualmente: boolean

  private mensagemErroPerdaDeConexaoDoDispositivo = 'Conexão leitor perdida, tente conecta-lo novamente'
  private utils = Utils

  private opcoesDeNotificacoesLE: Omit<OpcoesNotificacoesBluetoothLowEnergy, 'deviceId'> = {
    service: '0000180f-0000-1000-8000-00805f9b34fb',
    characteristic: '00002a19-0000-1000-8000-00805f9b34fb'
  }

  constructor(
    private bluetoothLEHelper: HelperLowEnergyService,
    private utilsCtrl: UtilsService,
    private globalCtrl: GlobalService
  ) { }



  async estabelecerConexao(): Promise<void> {
    try {
      this.bluetoothLEHelper.emitStatusConexaoBastao('conectando')

      console.log('this.leitorAwr250: ',this.dadosLeitorConexao)

      const leitorEncontrado = await this.buscarDispositivo({ nomeDispositivo: this.dadosLeitorConexao.name })
      const resultadoConexao = await this.estabelecerConexaoComLeitor({ deviceId: leitorEncontrado.deviceId })
      this.dadosLeitorConexao.id = leitorEncontrado.deviceId

      if (resultadoConexao.isConnected) {
        this.bluetoothLEHelper.emitStatusConexaoBastao('conectado', this.dadosLeitorConexao)
        await this.receberDadosDoLeitor(leitorEncontrado)
      }
    } catch (error) {
      console.error(error)

      this.bluetoothLEHelper.emitStatusConexaoBastao('erro-conexao')

      if (error instanceof ErrorBluetooth) {
        this.utilsCtrl.exibirToast(error.message, this.utils.tempoDeExibicaoDeMensagemDeErro(error.message))
      }
    }
  }

  async estabelecerConexaoComLeitor(opcoes: OpcoesConexaoBluetooth): Promise<ResultadoConexaoBluetooth> {
    return new Promise((resolve, reject) => {
      this.bluetoothLEHelper.pluginConectarBalanca({ deviceId: opcoes.deviceId }).subscribe(
        (success: ResultadoConexaoBluetooth) => {
          console.warn('sucesso ao conectar: ', success)

          if (success.isConnected) {
            const { deviceId, isConnected } = success

            // Habilitar para verificar quando o bluetooth for desconectado
            this.habilitarNotificacoesBluetooth()

            this.emitirResultadoConexao({
              message: 'Leitor conectado',
              isConnected: true
            })

            resolve({
              deviceId,
              isConnected
            } as ResultadoConexaoBluetooth)
          }
        },
        (fail: ResultadoConexaoBluetooth) => {
          console.error('Conexão perdida!! ', fail)

          if (!this.isDesconectandoDispositivoManualmente) {
            this.emitirResultadoConexao({
              message: this.mensagemErroPerdaDeConexaoDoDispositivo,
              isConnected: false
            })
          }

          const error = new ErrorBluetooth(this.mensagemErroPerdaDeConexaoDoDispositivo)
          reject(error)
        }
      )
    })
  }

  private async receberDadosDoLeitor(device: DispositivoLowenergy): Promise<void> {
    console.warn('Receber dados do leitor')

    setObserverMensagemStatusConexao({
      mensagemStatusConexao: 'Pronto para receber dados do leitor',
      isEsconderMensagem: true
    })

    this.saidaDeDadosLeitor(device)
  }

  saidaDeDadosLeitor(dispositivo: DispositivoLowenergy): void {
    const opcoesNotificacoes: OpcoesNotificacoesBluetoothLowEnergy = {
      ...this.opcoesDeNotificacoesLE,
      deviceId: dispositivo.deviceId
    }

    this.bluetoothLEHelper.startNotifications(opcoesNotificacoes).subscribe((result: DataView) => {
      const credencialAnimal = this.formatarValorDeLeitorAWR250(result)
      VastaRX.setState('credencialAnimal', credencialAnimal)
    })
  }

  private formatarValorDeLeitorAWR250(result: DataView): string {
    const enc = new TextDecoder('utf-8')
    const arr = new Uint8Array(result.buffer)
    const dado = enc.decode(arr)
    console.log('dado: ', dado)
    return dado.trim()
  }

  private habilitarNotificacoesBluetooth(): void {
    this.bluetoothLEHelper.pluginHabilitarNotificacoesBluetooth().subscribe((resultadoBluetooth) => {
      if (!resultadoBluetooth.isEnabled) {
        this.emitirResultadoConexao({
          message: this.mensagemErroPerdaDeConexaoDoDispositivo,
          isConnected: false
        })
      }
    })
  }

  private emitirResultadoConexao(opcoes: Omit<ResultadoConexaoBluetooth, 'deviceId' | 'statusConexao'>): void {
    this.utilsCtrl.exibirToast(opcoes.message)
    this.globalCtrl.set('isBastaoConectado', opcoes.isConnected)

    const statusConexao: StatusConexao = opcoes.isConnected ? 'conectado' : 'desconectado'

    this.bluetoothLEHelper.emitStatusConexaoBastao(statusConexao, this.dadosLeitorConexao)

    setObserverMensagemStatusConexao({
      mensagemStatusConexao: opcoes?.message,
      isEsconderMensagem: !opcoes.isConnected
    })
  }

  async buscarDispositivo(opcoes: OpcoesBuscarPorDispositivos): Promise<DispositivoLowenergy> {
    console.log('buscarDispositivo')

    const resultadoBluetooth = await this.bluetoothLEHelper.isBluetoothLEHabilitado()

    if (!resultadoBluetooth?.isEnabled) {
      throw new ErrorBluetooth('O bluetooth está desabilitado')
    }

    const dispositivosConectados = await this.bluetoothLEHelper.getDispositivosConectados({
      services: [this.opcoesDeNotificacoesLE.service]
    })
    console.warn('dispositivosConectados: ', dispositivosConectados)

    if (dispositivosConectados?.length) {
      const dispositivoEncontrado = dispositivosConectados.find((device) => device?.name?.startsWith(opcoes.nomeDispositivo))
      console.warn('dispositivo já estava conectado: ', dispositivoEncontrado)

      if (dispositivoEncontrado) {
        return dispositivoEncontrado
      }
    }

    /************ BUSCAR POR DISPOSITIVOS QUE NÃO ESTEJAM CONECTADOS ********************************** */
    const opcoesDeBusca: OpcoesBuscarPorDispositivosLowEnergy = {
      limiteDeBuscas: 10,
      services: [this.opcoesDeNotificacoesLE.service]
    }

    const isBuscarDispositivoPorPainel = false

    let dispositivoEncontrado: DispositivoLowenergy = null

    /*************** PROCURAR DISPOSITIVOS DENTRO DE UM PAINEL ******************  */
    if (isBuscarDispositivoPorPainel) {
      dispositivoEncontrado = await this.bluetoothLEHelper.buscarDispositivosPorEmPainel(opcoesDeBusca)
    } else {
      /*************** PROCURAR DISPOSITIVOS DE FORMA AUTOMÁTICA ******************  */

      const dispositivos = await this.bluetoothLEHelper.pluginBuscarDispositivo(opcoesDeBusca)
      console.warn('dispositivos lowenergy ', dispositivos)

      const leitoresEncontrados = dispositivos.devices.filter((device) => device?.name?.startsWith(opcoes.nomeDispositivo))

      if (!leitoresEncontrados || !leitoresEncontrados.length) {
        throw new ErrorBluetooth('Nenhum dispositivo encontrado')
      }

      console.log('leitoresEncontrados', leitoresEncontrados)
      if (leitoresEncontrados.length === 1) {
        dispositivoEncontrado = leitoresEncontrados[0]
      } else {
        try {
          dispositivoEncontrado = await this.bluetoothLEHelper.actionSheetSeletorDispositivo({ devices: leitoresEncontrados })
        } catch (e) {
          console.error('e', e)
          if (!dispositivoEncontrado) {
            throw new ErrorBluetooth('Falha ao listar dispositivos')
          }
        }
      }
      console.warn('dispositivo lowenergy Encontrado: ', dispositivoEncontrado)
    }

    if (!dispositivoEncontrado) {
      throw new ErrorBluetooth('Dispositivo não encontrado')
    }

    console.warn('dispositivoEncontrado: ', dispositivoEncontrado)

    return dispositivoEncontrado
  }

  public async desconectarDispositivo(device: DispositivoLowenergy): Promise<void> {
    try {
      this.isDesconectandoDispositivoManualmente = true
      
      await BleClient.disconnect(device.deviceId)

      this.emitirResultadoConexao({
        isConnected: false,
        message: 'Dispositivo desconectado'
      })

    } catch(error) {
      console.error(error)
    } finally {
      this.isDesconectandoDispositivoManualmente = false
    }
  }
}
